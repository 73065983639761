import axios from 'axios';
import store from '../store/store';
import { routerExport } from '../main.js';

function getToken() {
  return store.getters.getToken; // Adjust according to your storage method
}

function getSelectedTrial() {
  return store.getters.getSelectedTrial; 
}

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_ROOT_PATH_API, 
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  config => {
    config.headers.Authorization = `Bearer ${getToken()}`;
    
    const selectedTrial = getSelectedTrial();
    if (selectedTrial) {
      config.headers['experiment-id'] = selectedTrial.id; 
    }

    return config;
  },
  error => Promise.reject(error)
);

// Response interceptor to handle 401 errors
axiosInstance.interceptors.response.use(response => {
  return response;
}, error => {
  const isAuthenticated = store.getters.isAuthenticated;
  if (error.response && error.response.status === 401 && isAuthenticated ) {
      store.commit('clearAuth'); // Clear the token from localStorage
      alert('Session timed out');
      routerExport.push('/login'); // Redirect to the login page
  }
  return Promise.reject(error);
});

export default axiosInstance;
