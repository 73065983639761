<template>
  <div>
    <ul class="side-menu md-list nav md-theme-default">
      <div  v-for="(menu) in sidebarItemsWithSubMenu" :key="menu.id">
      <li
        v-if="!Array.isArray(menu)"
        class="side-bar-item pl-1"
        :class="{ active: activeItem === menu.name }"
        @click.prevent="handleNavigation(menu)"
      >
        <router-link :to="'/'+menu.slug" class="m-0">
          <i class="md-icon md-icon-font md-theme-default">{{ menu.icon }}</i>
          <label class="w-100 my-auto"> {{ menu.name }}</label>
        </router-link>
      </li>

      <!-- Main menu with submenus -->
      <li  v-if="Array.isArray(menu)" class="p-0">
        <!-- <p>{{menu}}</p> -->
        <div v-if="Array.isArray(menu[0])">
        <a
          href="javascript:void(0)"
          class="m-0 side-bar-item"
          style="padding-top: 6px; padding-bottom: 6px"
          type="button"
          data-toggle="collapse"
          :data-target="'#collapseMenu' + menu[0][0].id"
          aria-expanded="false"
          :aria-controls="'collapseMenu' + menu[0][0].id"
          @click.prevent="handleNavigation(menu[0][0])"
        >
          <i class="md-icon md-icon-font md-theme-default">{{ menu[0][0].icon }}</i>
          <label class="my-auto w-100">{{ menu[0][0].sub_menu }}</label>
          <i class="mdi mdi-chevron-down nav-arrow my-auto"></i>
          <i class="mdi mdi-chevron-up nav-arrow my-auto"></i>
        </a>
        </div>
        <div v-if="!Array.isArray(menu[0])">
        <a
          href="javascript:void(0)"
          class="m-0 side-bar-item"
          style="padding-top: 6px; padding-bottom: 6px"
          type="button"
          data-toggle="collapse"
          :data-target="'#collapseMenu' + menu[0].id"
          aria-expanded="false"
          :aria-controls="'collapseMenu' + menu[0].id"
        
        >
          <i class="md-icon md-icon-font md-theme-default">{{ menu[0].icon }}</i>
          <label class="my-auto w-100">{{ menu[0].sub_menu }}</label>
          <i class="mdi mdi-chevron-down nav-arrow my-auto"></i>
          <i class="mdi mdi-chevron-up nav-arrow my-auto"></i>
        </a>
        </div>


        <ul class="collapse side-sub-menu level-2" :id="'collapseMenu' + (Array.isArray(menu[0]) ? menu[0][0].id : menu[0].id)">

          <div v-for="(submenuGroup, groupIndex) in menu" :key="groupIndex" >

   
          <li v-if="!Array.isArray(submenuGroup)" class="side-bar-item" :class="[{ active: activeItem === submenuGroup.name}]" @click.prevent="handleNavigation(submenuGroup)">
            <!-- Check if the submenu group is a single item or a group -->
                <router-link
                class="m-0"
                :to="'/'+submenuGroup.slug"
                style="padding-top: 6px; padding-bottom: 6px"
              >{{ submenuGroup.name }}</router-link>


            
          </li>
          <li v-if="Array.isArray(submenuGroup)" >
              <!-- Group title -->
              <a
                href="javascript:void(0)"
                class="m-0 side-bar-item"
                style="padding-top: 6px; padding-bottom: 6px"
                type="button"
                data-toggle="collapse"
                :data-target="'#collapseGroup' + groupIndex"
                aria-expanded="false"
                :aria-controls="'collapseGroup' + groupIndex"
              >
                <label class="my-auto w-100">{{ submenuGroup[0].sub_menu2 }}</label>
                <i class="mdi mdi-chevron-down nav-arrow my-auto"></i>
                <i class="mdi mdi-chevron-up nav-arrow my-auto"></i>
              </a>

              <!-- Submenu items -->
              <ul class="collapse side-sub-menu" :id="'collapseGroup' + groupIndex" >
                <li
                class="side-bar-sub-item submenu-list-design"
                  v-for="subItem in submenuGroup"
                  :key="subItem.id"
                  :class="{ active: activeItem === subItem.name }"
                  @click.prevent="handleNavigation(subItem)"
                >
       
                  <router-link
                    class="m-0 py-2 side-bar-item"
                    :to="'/'+subItem.slug"
                    style="padding-top: 6px; padding-bottom: 6px"
                  >-{{ subItem.name }}</router-link>
                </li>
              </ul>
          </li>
          </div>
        </ul>
      </li>
      </div>

    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeItem: "",
      sidebarMenuList: []
    };
  },
  mounted() {
     this.sidebarMenuList =  this.$store.getters.getSubMenus;
    this.getSidebarMenu();
  },
  computed:{
        sidebarItemsWithSubMenu() {
            var groupBy = function (data, key) {
                // `data` is an array of objects, `key` is the key (or property accessor) to group by
                // reduce runs this anonymous function on each element of `data` (the `item` parameter,
                // returning the `storage` parameter at the end
                return data.reduce(function (storage, item) {
                    // get the first instance of the key by which we're grouping
                    {
                        var group = item[key];

                        // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
                        storage[group] = storage[group] || [];

                        // add this item to its group within `storage`

                        storage[group].push(item);

                        // return the updated storage to the reduce function, which will then loop through the next

                        return storage;
                    }
                }, {}); // {} is the initial value of the storage
            };
            var newSidebarItems = [];
            if (Object.keys(this.sidebarMenuList).length === 0) {
                return [];
            }
            let sidebarItemsCopy = Object.values(this.sidebarMenuList);
            let subMenusPushed = [];
            let index = 0;
            this.sidebarMenuList.forEach((sidebarItem) => {
                if (!sidebarItem.sub_menu) {
                    newSidebarItems[index] = sidebarItem;
                    index++;
                } else {
                    if (!subMenusPushed.includes(sidebarItem.sub_menu)) {
                        subMenusPushed.push(sidebarItem.sub_menu);
                        newSidebarItems[index] = sidebarItemsCopy.filter(
                            (item) => item.sub_menu === sidebarItem.sub_menu
                        );
                        let data1 = [];
                        let data = groupBy(newSidebarItems[index], "sub_menu2");
                        Object.keys(data).map((key) => {
                            if (key == "null") {
                                data1.push(...data[key]);
                            } else {
                                data1.push(data[key]);
                            }
                        });

                        newSidebarItems[index] = data1;
                        index++;
                    }
                }
            });

            return newSidebarItems;
        },
  },
  methods: {
    handleNavigation(item) {
      this.activeItem = item.name;
      this.$store.commit('setActiveSideBar',item)
    },
    getSidebarMenu() {
      axios.get(`/indous-research/get-sidebar-menu`).then((response) => {
        this.sidebarMenuList = response.data.sidebarMenuList;
        this.$store.commit("setSubMenus",this.sidebarMenuList )
      }).catch((err) => {
        // console.error(err);
      });
    }
  }
};
</script>
<style scoped>
.side-menu {
  list-style-type: none;
  padding: 0;
}

.side-bar-item {
  color: #fff;
  padding: 6px 6px;
  border-radius: 3px;
  cursor: pointer;
}

.side-bar-item:hover {
  background-color: grey;
}

.side-bar-item a {
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.side-bar-item.active,
.side-bar-item.active:hover {
  background-color: #4caf50;
}
.side-bar-sub-item {
  color: #fff;
  /* padding: 10px 27px; */
  border-radius: 1px;
  cursor: pointer;
}
.side-bar-sub-item:hover {
  background-color: grey;
}
.side-sub-menu {
  list-style-type: none;
  padding-left: 5%;
  background-color: #3c693d;
}
/* .side-sub-menu  li {
  border-left: 2px dotted;
  color: whitesmoke;
} */
.submenu-list-design {
  border-left: 1px dotted;
  color: rgba(245, 245, 245, 0.696);
}

.side-sub-menu .side-bar-item.active {
  background-color: #1a3d1b;
}

i {
  font-size: 20px;
  margin-right: 15px;
}
a[aria-expanded="true"] .mdi-chevron-down {
  display: none;
}
a[aria-expanded="false"] .mdi-chevron-up {
  display: none;
}
</style>