// =========================================================
// * Vue Material Dashboard - v1.5.2
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard
// * Copyright 2024 Creative Tim (https://www.creative-tim.com)
// * Licensed under MIT (https://github.com/creativetimofficial/vue-material-dashboard/blob/master/LICENSE.md)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";

// router setup
import {createRouter} from "./routes/routes";

// Plugins
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import Notifications from "./components/NotificationPlugin";
import Validation from "./components/NotificationPlugin/Validation.vue"
// MaterialDashboard plugin
import MaterialDashboard from "./material-dashboard";

import Chartist from "chartist";
import './assets/css/app.css';
import axios from "./plugins/axios.js"
import store from "./store/store.js"
import {debounce,generatePassword} from "./plugins/helper.js"

/* Global registration of functions */
Vue.prototype.$Chartist = Chartist;
// serach time delay
Vue.prototype.$debounce = debounce;
// auto generater password
Vue.prototype.$generatePassword = generatePassword;
// storage
Vue.prototype.$store = store;

Vue.use(VueRouter);
Vue.use(MaterialDashboard);
Vue.use(GlobalComponents);
Vue.use(GlobalDirectives);
Vue.use(Notifications);
Vue.use(Validation)
// register validation component 
Vue.component('validation',Validation)
window.axios = axios
/* eslint-disable no-new */
let routerExport;
async function init(){
  // call dynamic routes
  const router = await createRouter();
  routerExport = router;
  new Vue({
    el: "#app",
    render: (h) => h(App),
    router,
    data: {
      Chartist: Chartist,
    },
  });
 
  // Navigation Guard
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.isAuthenticated;
  const subMenus  = store.getters.getSubMenus ?? [];
  const isPermitted = subMenus.some((i)=>`/${i.slug}` == to.fullPath)
// console.log('per',isPermitted);
  if(isAuthenticated && to.name == 'Login' || (isAuthenticated && !isPermitted && from.name !='Login')) {
    next(from.fullPath)
    return;
  }
  if (to.name !== 'Login' && !isAuthenticated) {
    next({ name: 'Login' })
    return ;
  } 
    next();
});

}

init();

export {routerExport};