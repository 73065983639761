<template>
  <div>
    <div v-if="!page">
      <div class="">
        <div class="row align-items-center mb-3">
          <div class="col-auto">
            <ul class="nav nav-pills">
              <li v-if="add" class="nav-item">
                <a href="#" :class="['nav-link', { active: isActive('list') }]" @click.prevent="setActiveTab('list')">
                  Groupwise Formula
                </a>
              </li>
              <li class="nav-item">
                <a href="#" :class="['nav-link', { active: isActive('feedformula') }]"
                  @click.prevent="setActiveTab('feedformula')">
                  Feed Formula
                </a>
              </li>
              <li class="nav-item">
                <a href="#" :class="['nav-link', { active: isActive('apply') }]" @click.prevent="setActiveTab('apply')">
                  Penwise Allotment of Experimental Group
                </a>
              </li>
            </ul>
          </div>
        </div>


      </div>
      <div class="modal fade" id="mailModel" tabindex="-1" role="dialog" aria-labelledby="mailModelLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content" style="height: auto">
            <div class="modal-content" style="width: 100%">
              <div class="modal-header bg-success">
                <h5 class="modal-title text-white" id="mailModel"></h5>
              </div>
              <!-- <div class="modal-body">
                <form @submit.prevent="">
                <div style="padding-left: 0px; height: auto">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="font-TimesNewRoman font-weight-bold">Corrected feed formula for {{ displayTrialNo
                          }} has already been saved.<br>Do you want to update allotment details?
                          <span class="text-danger">*</span> 
                        </label>
                      <input
                                                      type="email"
                                                      placeholder="Enter Email"
                                                      v-model="email"
                                                      class="form-control"
                                                      required
                                                  >
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div>
                      <div class="alert alert-success alert-dismissible mb-1" role="alert" v-if="checkPrev == true">
                                                  {{ message }}
                                                  <button
                                                      type="button"
                                                      class="close"
                                                      data-dismiss="alert"
                                                      aria-label="Close"
                                                  >
                                                      <span aria-hidden="true">&times;</span>
                                                  </button>
                                              </div>
                      <button type="button" class="btn btn-success" @click="switchTabModal()">
                        yes
                      </button>
                      <button type="button" @click="clearModal()" class="btn btn-light  ml-2">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div v-if="activeTab === 'feedformula'">
        <div class="col-md-5 d-flex">
            
          <!-- <select required v-model="trialNo" id="country" md-dense>
                              <option v-for="trial in trialNumbers" :key="trial.id" :value="trial.id">
                                {{ trial.trial_no }}
                              </option>
                            </select> -->
            <div>
              <md-button @click="ffPdfdownload"  type="button" class="md-success" :disabled="download1"><md-icon>download</md-icon>{{download1 ? 'Downloading...':'Download PDF'}}
              </md-button>
            </div>
        </div>
        <!-- <loading-bar v-if="feedFormulaLoader"></loading-bar> -->
        <div ref="pdfContent">
          <div class="pt-1"></div>
            <div class="col-md-5 d-flex mt-4 pr-4 pb-2">
              <label for="movie" class="mt-2 font-weight-bold" style="height:5px;"> Trial No: &nbsp;&nbsp;</label>
              <input v-model="this.displayTrialNo" readonly style="width: 40%; height: 30px;"/>
            </div>

          <loading-bar v-if="preStarterTab"></loading-bar>
            <div v-else>
                <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                  <md-card v-if="preStarter.some(trial => trial.ingredients.length > 0)">
                    <md-card-header data-background-color="green">
                      <h4 class="title" style="margin-bottom:-6px;margin-top: -6px;">Pre-Starter</h4>
                    </md-card-header>
                    <md-card-content style="padding-top: 0px;padding-right: 17px;padding-bottom: 15px;padding-left: 17px;">
                      <div class="feedFormulaTable">
                        <table>
                          <thead>
                            <tr>
                              <th class="alterTable">Ingredients (in k.gs)</th>
                              <th class="" v-for="(trial, index) in preStarter" :key="index">
                                T{{ trial.trial_group_id }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="ingredient in uniquePrestarterIngredients" :key="ingredient">
                              <td>{{ ingredient }}</td>
                              <td v-for="trial in preStarter" :key="trial.trial_group_id">
                                {{ getIngredientQuantity(trial.ingredients, ingredient) }}
                              </td>
                            </tr>
                            <tr>
                              <td><b>Total</b></td>
                              <td v-for="trial in preStarter" :key="trial.trial_group_id">
                                <b>{{ getTotal(trial.ingredients) }}</b>
                              </td>
                            </tr>
                            <tr v-if="edit && feedCostEditable">
                              <td><b>Edit</b></td>
                              <td v-for="trial in preStarter" :key="trial.trial_group_id">
                                <span>
                                  <button @click="onEditFeedFormulaPreStarter(trial.trial_group_id)"
                                    class="btn btn-info btn-sm round-2">
                                    <i class="mdi mdi-pencil mx-auto"></i>
                                  </button>
                                </span>
                              </td>
                            </tr>
                            <!-- <tr v-if="allEditFlag && edit">
                              <td><b>Edit</b></td>
                              <td v-for="trial in preStarter" :key="trial.trial_group_id">
                                <button @click="onEditFeedFormulaPreStarter(trial.trial_group_id)"
                                  class="btn btn-info btn-sm round-2">
                                  <i class="mdi mdi-pencil mx-auto"></i>
                                </button>
                              </td>
                            </tr> -->
                          </tbody>
                        </table>
                      </div>
                    </md-card-content>
                  </md-card>
                </div>
            </div>
          
          <loading-bar v-if="starterTab"></loading-bar>
            <div v-else>
              <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card v-if="starter.some(trial => trial.ingredients.length > 0)">
                  <md-card-header data-background-color="green">
                    <h4 class="title" style="margin-bottom:-6px;margin-top: -6px;">Starter</h4>
                  </md-card-header>
                  <md-card-content style="padding-top: 0px;padding-right: 17px;padding-bottom: 15px;padding-left: 17px;">
                    <div class="feedFormulaTable">
                      <table>
                        <thead>
                          <tr>
                            <th class="alterTable">Ingredients (in k.gs)</th>
                            <th class="" v-for="(trial, index) in starter" :key="index">
                              T{{ trial.trial_group_id }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="ingredient in uniquestarterIngredients" :key="ingredient">
                            <td>{{ ingredient }}</td>
                            <td v-for="trial in starter" :key="trial.trial_group_id">
                              {{ getIngredientQuantity(trial.ingredients, ingredient) }}
                            </td>
                          </tr>
                          <tr>
                            <td><b>Total</b></td>
                            <td v-for="trial in starter" :key="trial.trial_group_id">
                              <b>{{ getTotal(trial.ingredients) }}</b>
                            </td>
                          </tr>
                          <tr v-if="edit && feedCostEditable">
                <td><b>Edit</b></td>
                <td v-for="trial in starter" :key="trial.trial_group_id">
                  <span>
                    <button @click="onEditFeedFormulaStarter(trial.trial_group_id)"
                      class="btn btn-info btn-sm round-2">
                      <i class="mdi mdi-pencil mx-auto"></i>
                    </button>
                  </span>
                </td>
                </tr>
                        </tbody>
                      </table>
                    </div>
                  </md-card-content>
                </md-card>
              </div>
            </div>

          <loading-bar v-if="fn1Tab"></loading-bar>
            <div v-else>
              <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card v-if="fn1.some(trial => trial.ingredients.length > 0)">
                  <md-card-header data-background-color="green">
                    <h4 class="title" style="margin-bottom:-6px;margin-top: -6px;">FN1</h4>
                  </md-card-header>
                  <md-card-content style="padding-top: 0px;padding-right: 17px;padding-bottom: 15px;padding-left: 17px;">
                    <div class="feedFormulaTable">
                      <table>
                        <thead>
                          <tr>
                            <th class="alterTable">Ingredients (in k.gs)</th>
                            <th class="" v-for="(trial, index) in fn1" :key="index">
                              T{{ trial.trial_group_id }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="ingredient in uniquefn1Ingredients" :key="ingredient">
                            <td>{{ ingredient }}</td>
                            <td v-for="trial in fn1" :key="trial.trial_group_id">
                              {{ getIngredientQuantity(trial.ingredients, ingredient) }}
                            </td>
                          </tr>
                          <tr>
                            <td><b>Total</b></td>
                            <td v-for="trial in fn1" :key="trial.trial_group_id">
                              <b>{{ getTotal(trial.ingredients) }}</b>
                            </td>
                          </tr>
                          <tr v-if="edit && feedCostEditable">
                            <td><b>Edit</b></td>
                            <td v-for="trial in fn1" :key="trial.trial_group_id">
                              <span>
                                <button @click="onEditFeedFormulaFn1(trial.trial_group_id)"
                                  class="btn btn-info btn-sm round-2">
                                  <i class="mdi mdi-pencil mx-auto"></i>
                                </button>
                              </span>
                            </td>
                          </tr>
                          <!-- <tr v-if="allEditFlag && edit">
                            <td><b>Edit</b></td>
                            <td v-for="trial in fn1" :key="trial.trial_group_id">
                              <button @click="onEditFeedFormulaFn1(trial.trial_group_id)"
                                class="btn btn-info btn-sm round-2">
                                <i class="mdi mdi-pencil mx-auto"></i>
                              </button>
                            </td>
                          </tr> -->
                        </tbody>
                      </table>
                    </div>
                  </md-card-content>
                </md-card>
              </div>
            </div>

          <loading-bar v-if="fn2Tab"></loading-bar>
            <div v-else>
              <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card v-if="fn2.some(trial => trial.ingredients.length > 0)">
                  <md-card-header data-background-color="green">
                    <h4 class="title" style="margin-bottom:-6px;margin-top: -6px;">FN2</h4>
                  </md-card-header>
                  <md-card-content style="padding-top: 0px;padding-right: 17px;padding-bottom: 15px;padding-left: 17px;">
                    <div class="feedFormulaTable">
                      <table>
                        <thead>
                          <tr>
                            <th class="alterTable">Ingredients (in k.gs)</th>
                            <th class="" v-for="(trial, index) in fn2" :key="index">
                              T{{ trial.trial_group_id }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="ingredient in uniquefn2Ingredients" :key="ingredient">
                            <td>{{ ingredient }}</td>
                            <td v-for="trial in fn2" :key="trial.trial_group_id">
                              {{ getIngredientQuantity(trial.ingredients, ingredient) }}
                            </td>
                          </tr>
                          <tr>
                            <td><b>Total</b></td>
                            <td v-for="trial in fn2" :key="trial.trial_group_id">
                              <b>{{ getTotal(trial.ingredients) }}</b>
                            </td>
                          </tr>
                          <tr v-if="edit && feedCostEditable">
                            <td><b>Edit</b></td>
                            <td v-for="trial in fn2" :key="trial.trial_group_id">
                              <span>
                                <button @click="onEditFeedFormulaFn2(trial.trial_group_id)"
                                  class="btn btn-info btn-sm round-2">
                                  <i class="mdi mdi-pencil mx-auto"></i>
                                </button>
                              </span>
                            </td>
                          </tr>
                          <!-- <tr v-if="allEditFlag && edit">
                            <td><b>Edit</b></td>
                            <td v-for="trial in fn2" :key="trial.trial_group_id">
                              <button @click="onEditFeedFormulaFn2(trial.trial_group_id)"
                                class="btn btn-info btn-sm round-2">
                                <i class="mdi mdi-pencil mx-auto"></i>
                              </button>
                            </td>
                          </tr> -->
                        </tbody>
                      </table>
                    </div>
                  </md-card-content>
                </md-card>
              </div>
            </div>
        </div>
        <div class="row-layout2">
          <div id="buttons">

            <div>
              <!-- <md-button
                    type="button"
                    class="md-success mr-2"
                    @click="openModal"  :disabled="disableSubmitButton"
                    >{{
                      requestStatus ? "Saving..." : "Verify and Submit"
                    }}</md-button
                  > -->

              <!-- <md-button v-if="!isUpdate" @click="save(true)" class="md-success" :disabled="requestStatus">{{ requestStatus ?
                      'Saving...' : 'Save' }}</md-button> -->
                      <div v-if="this.fn1.length>=1">
                        <md-button @click="openModal" class="md-success" :disabled="verifyAndSub" style="margin-left: 10px;">{{ requestStatus2 ?
                      'Verified and Submitting...' : 'Verify and Submit' }}</md-button>
                      </div>
              <!-- <div v-else> -->
                <!-- <md-button  @click="update(true)" type="button" class="md-success"
                :disabled="requestStatus5">{{ requestStatus5 ? 'Updating...' : 'Update' }}</md-button> -->
                <!-- <md-button  @click="update(false)" type="button" class="md-success"
                :disabled="requestStatus6"
                style="margin-left: 10px;">{{ requestStatus6 ? 'Verified and Submitting...' : 'Verify and Submit' }}</md-button> -->
              <!-- </div> -->
            </div>
            <!-- <div style="padding-left: 10px;">
                        <md-button type="submit" @click="toggleRslt" class="md-success">Submit</md-button>
                      </div> -->
            <!-- <div style="padding-left: 10px;">
              <md-button @click="clearr" type="button" class="md-danger">Cancel</md-button>
            </div> -->
            <md-dialog :md-active.sync="isModalActive">
                <md-dialog-title>Verify & Submit</md-dialog-title>
                <md-dialog-content>
                  Are you sure you want to submit the form?
                </md-dialog-content>
                <md-dialog-actions>
                  <md-button class="md-primary" @click="update(false)"
                    >Yes, Submit</md-button
                  >
                  <md-button class="md-secondary" @click="closeModal"
                    >Cancel</md-button
                  >
                </md-dialog-actions>
            </md-dialog>

          </div>
        </div>
      </div>

      <div v-if="activeTab === 'list'">
        <form class="form-style">
          <div>

            <md-card>
              <md-card-header data-background-color="green">
                <h4 class="title">CORRECTED FEED FORMULA</h4>
              </md-card-header>
              <md-card-content>
                <md-card-content>
                  <div>
                  </div>


                  <div class="table-layout">
                    <div class="structx" style="padding-bottom:4%">
                      <label class="md-body-2">Trial Number:</label>
                      <div class="test1">
                        <md-field>
                          <!-- <label for="trialno">Select</label> -->
                          <md-input v-model="displayTrialNo" readonly></md-input>
                          <!-- <md-select required v-model="trialNo" id="country" md-dense> -->
                          <!-- <md-option v-for="trial in trialNumbers" :key="trial.id" :value="trial.id">
                              </md-option> -->
                          <!-- </md-select> -->
                        </md-field>
                        <!-- <label v-if="checkPrev" style="color:red;text-align:left"><span>Please update in penwise allotment of experimental group</span></label> -->
                      </div>
                      <div class="test1"></div>
                      <div class="test1"></div>
                    </div>
                    <!-- <div v-if="trialNoCheckLoader">
                    </div> -->
                    <loading-bar v-if="trialNoCheckLoader"></loading-bar>
                    <div v-else class="structx">
                      <label v-if="!checkPrev" for="country" class="test1"><span class="md-body-2">Select Feed
                          Category:</span></label>

                      <div class="test2" v-if="!checkPrev">
                        <md-field>
                          <label for="chickcategory">Select</label>
                          <md-select required v-model="chickCategory" id="country" md-dense>
                            <md-option v-for="item in chickCategoryList" :key="item.id" :value="item.id">
                              {{ item.category_name }}
                            </md-option>
                          </md-select>
                        </md-field>
                        <validation :errors="errors" field="chick_category"></validation>
                      </div>
                      <label for="country" class="test3" v-if="showFormula"><span v-if="!checkPrev"
                          class="md-body-2">Formula:</span></label>
                      <div v-else></div>

                      <div class="test4" v-if="showFormula">
                        <md-field v-if="!checkPrev">
                          <md-select required v-model="finisherFormula" name="country" id="country" md-dense>
                            <md-option v-for="item in finisherFormulaList" :key="item.id" :value="item.id">
                              {{ item.formula_name }}
                            </md-option>
                          </md-select>
                        </md-field>
                        <label v-if="checkFormulaEntry" style="color:red"><span>Please choose the formula</span></label>
                      </div>
                      <div v-else></div>


                      <label v-if="!checkPrev" for="country" class="test3"><span class="md-body-2">Trial
                          Group:</span></label>

                      <div v-if="!checkPrev" class="test4">
                        <md-field>
                          <label for="trialgroup">Select</label>
                          <md-select required v-model="trialGroup" id="country" md-dense>
                            <md-option v-for="item in trialGroupList" :key="item.id" :value="item.id">
                              {{ item.trial_group_id }}
                            </md-option>
                          </md-select>
                        </md-field>
                        <label v-if="oldEntry" style="color:red"><span>Data already added to the selected trial
                            group/formula</span></label>
                        <validation :errors="errors" field="trial_group_id"></validation>
                      </div>
                      <div class="test1"></div>

                      <div class="test2">

                      </div>
                      <label v-if="!checkPrevPens" for="country" class="test1"><span
                          class="md-body-2">Select</span></label>
                      <div v-if="!checkPrevPens">
                        <div class="col-mk-3">
                          <span class="md-body-2">Pens</span>
                          <div class="w-100">
                            <div class="checkbox-select__trigger" :class="{ isActive: isDropdownOpen }"
                              @click="toggleDropdown">
                              <span style="font-size: 17px;">{{ (selectedItems.length) ? `${selectedItems.length} pens selected` : 'Select the Pens' }}</span>
                              <i :class="dropdownIconClass"></i>
                            </div>
                            <div id="div" class="checkbox-select__dropdown" v-show="isDropdownOpen">
                              <div class="checkbox-select__search-wrapp">
                                <input type="text" placeholder="Search" v-model="searchQuery" />
                              </div>
                              <div class="checkbox-select__col">
                                <div class="checkbox-select__select-all">
                                  <!-- <input
                                id="divselectAll"
                                type="checkbox"
                                @change="toggleSelectAll"
                                :checked="allSelected"
                                /> -->
                                  <!-- <label for="divselectAll">Select All</label> -->
                                </div>
                                <div class="checkbox-select__info">
                                  {{ selectedItems.length }} SELECTED
                                </div>
                              </div>
                              <ul id="customScroll" class="checkbox-select__filters-wrapp"
                                data-simplebar-auto-hide="false">
                                <li v-for="(option, index) in pensCheckBoxList" :key="index" style="list-style: none;">
                                  <div class="checkbox-select__check-wrapp">
                                    <input :id="'div' + index" class="conditions-check" type="checkbox" :value="option"
                                      v-model="selectedItems" />
                                    <label :for="'div' + index" class="text-capitalize">
                                      P{{ option }}
                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <validation :errors="errors" field="pen_id"></validation>
                        </div>
                      </div>

                      <label v-if="!checkPrevPBC" for="country" class="test3"><span class="md-body-2">Penwise Bird
                          Count:</span></label>

                      <div v-if="!checkPrevPBC" class="test4">
                        <md-field>
                          <label>Quantity</label>
                          <md-input v-model="penwiseBirdCount" type="number" @input="checkInput(penwiseBirdCount)" :readonly="!tempIngreEdit"></md-input>
                        </md-field>
                        <validation :errors="errors" field="penwsie_bird_count"></validation>

                      </div>

                      <label v-if="!checkPrevFPC" for="country" class="test3"><span class="md-body-2">Feed Per
                          Chick(kg):</span></label>
                      <div v-if="!checkPrevFPC" class="test1">
                        <md-field>
                          <label>Quantity</label>
                          <md-input v-model="feedPerChick" type="number" @input="checkInput(feedPerChick)" :readonly="!tempIngreEdit"></md-input>
                        </md-field>
                        <validation :errors="errors" field="feed_per_chick"></validation>
                      </div>

                      <label v-if="!checkPrevFPP" for="country" class="test3"><span class="md-body-2">Feed Per
                          Pen(kg):</span></label>

                      <div v-if="!checkPrevFPP" class="test4">
                        <md-field>
                          <label>Quantity</label>
                          <md-input readonly v-model="feedPerPen" type="number" @input="checkInput(feedPerPen)"></md-input>
                        </md-field>
                        <validation :errors="errors" field="feed_per_pen"></validation>
                      </div>

                      <label v-if="!checkPrev" for="country" class="test3"><span class="md-body-2"></span></label>
                      <div v-if="!checkPrev" class="test1">

                        <!-- <md-input v-model="feedPerChick" type="number"></md-input> -->

                        <!-- <validation  :errors="errors" field="feed_per_chick"></validation> -->
                      </div>

                      <label v-if="!checkPrev" for="country" class="test3"><span class="md-body-2"></span></label>

                      <div v-if="!checkPrevCal" class="test4">
                        <!-- <md-field> -->
                        <!-- <md-input v-model="feedPerPen" ></md-input> -->
                        <!-- <md-button @click="calculateCFF" class="md-primary">Calculate</md-button> -->
                        <!-- <button >Calculate</button> -->
                        <!-- </md-field> -->
                        <!-- <validation  :errors="errors" field="feed_per_pen"></validation> -->
                      </div>

                    </div>
                  </div>

                  <form class="form-style" v-if="!editIngredients">
                    <div>
                      <loading-bar v-if="load"></loading-bar>
                      <div v-else>
                        <div v-if="tempx && !originalData">
                          <hr>
                          <div v-for="(row, index) in tempx" :key="index" class="ingredientsDisplay">
                            <div class="test1" id="input-item">
                              <label><span class="md-body-2">Ingredient :</span></label>
                            </div>

                            <div class="ingredientsStyle" id="input-item">
                              <md-field>
                                <md-input v-model="row.ingredientName" :value="row.selectedIngredient"
                                  :key="row.selectedIngredient" readonly></md-input>
                              </md-field>
                              <!-- <validation  :errors="errors" field="ingredient_id.0.ingredient"></validation> -->
                            </div>

                            <div class="quantityStyle" id="input-item">
                              <label><span class="md-body-2">Quantity :</span></label>
                            </div>

                            <div class="quantityValStyle" id="input-item">
                              <md-field>
                                <label>Quantity</label>
                                <md-input v-model="row.quantity" type="number" readonly></md-input>
                              </md-field>
                              <!-- <validation  :errors="errors" field="ingredient_id.0.quantity"></validation> -->
                            </div>

                            <!-- <div @click="removeTempRow(row)" style="cursor: pointer;">
                                              <md-icon>clear</md-icon>
                                            </div> -->

                          </div>
                        </div>

                        <div v-if="originalData">
                          <hr>
                          <div v-for="(row, index) in rows" :key="index" class="ingredientsDisplay">
                            <div class="test1" id="input-item">
                              <label><span class="md-body-2">Ingredient</span></label>
                            </div>

                            <div class="ingredientsStyle" id="input-item">
                              <md-field>
                                <label></label>
                                <md-input v-model="row.ingredientName" :value="row.selectedIngredient"
                                  :key="row.selectedIngredient" readonly></md-input>
                                <!-- <label>Select</label>
                                              <md-select v-model="row.selectedIngredient" required md-dense>
                                                <md-option v-for="item in ingredientsList" :key="item.id" :value="item.id">
                                                  {{ item.ingredients }}
                                                </md-option>
                                              </md-select> -->
                              </md-field>
                              <validation :errors="errors" field="ingredient_id.0.ingredient"></validation>
                            </div>

                            <div class="quantityStyle" id="input-item">
                              <label><span class="md-body-2">Quantity :</span></label>
                            </div>

                            <div class="quantityValStyle" id="input-item">
                              <md-field>
                                <label>Quantity</label>
                                <md-input v-model="row.quantity" type="number"></md-input>
                              </md-field>
                              <validation :errors="errors" field="ingredient_id.0.quantity"></validation>
                            </div>

                            <!-- <div @click="removeRow(row)" style="cursor: pointer;">
                              <md-icon>clear</md-icon>
                            </div> -->

                          </div>
                        </div>
                      </div>
                      <!-- <hr> -->
                      <br>
                      <div v-if="!originalData" class="structnew" style="padding-top: 20px;">
                        <!-- <div><md-button @click="addRow" class="md-primary">Add Row</md-button> </div> -->
                        <div class="test2">
                          <md-field v-if="!feedcost">
                            <!-- <label v-if="feedEntry" style="color:red">Enter feed cost</label> -->
                            <label><b>Feed Cost</b></label>
                            <md-input v-model="feedCost" type="number" required :readonly="!feedCostEditable"></md-input>
                          </md-field>
                        </div>
                        <label v-if="!feedcost" for="country" class="test3"><span class="md-body-2">Total</span></label>
                        <div class="test4" v-if="!feedcost">
                          <md-field>
                            <label v-if="!feedcost">Quantity</label>
                            <md-input v-if="!feedcost" v-model="total" @input="checkInput(total)" readonly></md-input>
                          </md-field>
                        </div>

                        <div class="test5">

                        </div>
                      </div>
                      <div v-else class="structnew" style="padding-top: 20px;">
                        <!-- <div><md-button @click="addRow" class="md-primary">Add Row</md-button> </div> -->
                        <div class="test2">
                          <!-- <md-field>
                                <label v-if="feedEntry" style="color:red">Enter feed cost</label>
                                  <label v-if="!checkPrev"><b>Feed Cost</b></label>
                                    <md-input v-if="!checkPrev" v-model="feedCost"></md-input>
                                </md-field> -->
                        </div>
                        <!-- <label for="country" class="test3"><span class="md-body-2">Total</span></label> -->
                        <div class="test4">
                          <!-- <md-field>
                                    <label>Quantity</label>
                                    <md-input v-model="total" readonly></md-input>
                                  </md-field> -->
                        </div>

                        <div class="test5">

                        </div>
                      </div>
                    </div>
                  </form>
                </md-card-content>
              </md-card-content>
            </md-card>
          </div>
        </form>
        <div class="row-layout2">
          <div id="buttons">
            <div>
              <md-button v-if="!isUpdate" @click="save(true)" class="md-success" :disabled="requestStatus">{{ requestStatus ?
      'Saving...' : 'Save' }}</md-button>
              <span v-if="!isUpdate" style="padding-left: 10px;">
                <md-button @click="clearr" type="button" class="md-danger">Cancel</md-button>
              </span>
              <!-- <md-button v-if="!isUpdate" @click="openModal" class="md-success" :disabled="requestStatus2" style="margin-left: 10px;">{{ requestStatus2 ?
      'Verified and Submitting...' : 'Verify and Submit' }}</md-button> -->
              <div v-else>
                <md-button  @click="update(true)" type="button" class="md-success"
                :disabled="requestStatus5">{{ requestStatus5 ? 'Updating...' : 'Update' }}</md-button>
                <!-- <md-button  @click="update(false)" type="button" class="md-success"
                :disabled="requestStatus6"
                style="margin-left: 10px;">{{ requestStatus6 ? 'Verified and Submitting...' : 'Verify and Submit' }}</md-button> -->
                <span style="padding-left: 10px;">
                  <md-button @click="clearr" type="button" class="md-danger">Cancel</md-button>
                </span>
              </div>
            </div>
            <!-- <div style="padding-left: 10px;">
                        <md-button type="submit" @click="toggleRslt" class="md-success">Submit</md-button>
                      </div> -->
          </div>
        </div>
      </div>

      <div v-if="activeTab === 'apply'">
        <div class="col-md-5 d-flex">
          <!-- <div class="col-md-5 d-flex mt-4 pr-4">
            <label for="movie" class="mt-2 font-weight-bold" style="height:5px"> Trial No: &nbsp;&nbsp;</label>
            <label for="trialno">Select</label>
            <input v-model="trialNumbers[0].trial_no" readonly style="width: 60%;height:30px" />
          </div> -->
            <!-- <select required v-model="trialNo" id="country" md-dense>
                                <option v-for="trial in trialNumbers" :key="trial.id" :value="trial.id">
                                  {{ trial.trial_no }}
                                </option> -->
            <!-- </select> -->
          <div>
            <md-button @click="apPdfDownload"  type="button" class="md-success" :disabled="download2"><md-icon>download</md-icon>{{download2 ? 'Downloading...':'Download PDF'}}
            </md-button>
          </div>
        </div>
        <div style="margin-left:2%;width:80%">
          <loading-bar v-if="allottedPensLoader"></loading-bar>
          <div ref="pdfContent2" v-else>
          <div class="pt-1"></div>
          <div class="col-md-5 d-flex mt-4 pr-4 pb-2">
              <label for="movie" class="mt-2 font-weight-bold" style="height:5px;"> Trial No: &nbsp;&nbsp;</label>
              <input v-model="this.displayTrialNo" readonly style="width: 60%; height: 30px;"/>
            </div>
            <md-card>
              <md-card-header data-background-color="green">
                <h4 class="title">Allotted Pens</h4>
              </md-card-header>

              <md-card-content>
                <md-table :table-header-color="tableHeaderColor" class="custom-table">
                  <tr>
                    <th>Pens</th>
                    <th v-for="trial in tempTrialPens" :key="trial.trial_group_id">
                      T{{ trial.trial_group_id }}
                    </th>
                  </tr>

                  <tr>
                    <td :rowspan="maxPenSize + 2">Allotted Pens</td>
                  </tr>
                  <tr v-for="penIndex in Array.from({ length: maxPenSize }, (_, i) => i + 1)" :key="penIndex">
                    <td v-for="trialx in tempTrialPens" :key="trialx.trial_group_id">
                      <div v-if="editableColumn === trialx.trial_group_id">
                        <span>P</span><input type="number" v-model="trialx.pen_id[penIndex - 1]" class="editInput" min="1" max="25">
                      </div>
                      <div v-else>
                        {{ (trialx.pen_id[penIndex - 1]) ? `P${trialx.pen_id[penIndex - 1]}` : null }}
                      </div>
                    </td>
                    <td> </td>
                  </tr>

                  <tr>
                    <td v-for="trialx in tempTrialPens" :key="trialx.trial_group_id">
                      <template v-if="allEditFlagx && edit">
                        <button v-if="editableColumn === trialx.trial_group_id" @click="onSave(trialx.trial_group_id)"
                          class="btn btn-success btn-sm round-2">
                          <i class="mdi mdi-content-save mx-auto"></i>
                        </button>
                        <button v-else @click="onEdit(trialx.trial_group_id)" class="btn btn-info btn-sm round-2">
                          <i class="mdi mdi-pencil mx-auto"></i>
                        </button>
                        <button v-if="editableColumn === trialx.trial_group_id" @click="onCancel"
                          class="btn btn-warning btn-sm round-2">
                          <i class="mdi mdi-close mx-auto"></i>
                        </button>
                      </template>
                    </td>
                    <td>Total</td>
                  </tr>
                  <tr>
                    <td>Replicas</td>
                    <td v-for="trial in tempTrialPens" :key="trial.trial_group_id">
                      {{ maxPenSize }}
                    </td>
                    <td><b>{{ maxPenSize * tempTrialPens.length }}</b></td>
                  </tr>
                  <tr>
                    <td>Chick Count</td>
                    <td v-for="trial in tempTrialPens" :key="trial.trial_group_id">
                      {{ tempPenwiseBirdCount * maxPenSize }}
                    </td>
                    <td><b>{{ tempPenwiseBirdCount * maxPenSize * tempTrialPens.length }}</b></td>
                  </tr>
                </md-table>
                <loading-bar v-if="load"></loading-bar>
                <md-button v-if="updateBtnVisible" @click="updatePens()" type="button" class="md-success"
                  :disabled="requestStatus">{{ requestStatusofPens ? 'Updating...' : 'Update' }}</md-button>
                <md-button v-if="updateBtnVisible" @click="cancelPenUpdate()" type="button" class="md-danger"
                  style="margin-left: 12px;" :disabled="requestStatus">{{ 'Cancel' }}</md-button>
              </md-card-content>
            </md-card>
          </div>
        </div>
      </div>
    </div>
    <div v-else>


      <md-button type="submit" @click="toggleRslt" class="md-success">Back</md-button>
    </div>
  </div>

</template>

<script>
// import htmlToPdfMake from "html-to-pdfmake";
// import pdfMake from "pdfmake/build/pdfmake";
// import pdfFonts from "pdfmake/build/vfs_fonts";
// import jsPDF from "jspdf";

import jsPDF from 'jspdf';
import "jspdf-autotable";
import html2canvas from 'html2canvas';
import LoadingBar from "../components/LoadingBar.vue";
let id = 0;
export default {
  name: "CorrectedFeedFormula",
  components: { LoadingBar },
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isModalActive: false,
      tempStr: [],
      updatedPens: [],
      requestStatusofPens: false,
      updateBtnVisible: false,
      tempPrestarter: [],
      tempStarter: [],
      tempFn1: [],
      tempFn2: [],
      checkPrev: false,
      editIngredients: false,
      headingVal: null,
      trialNo: '',
      trialNumbers: [],
      trialPens: [],
      tempTrialPens: [],
      chickCategory: '',
      maxPens: [0, 1, 2, 3, 4],
      chickCategoryList: [],
      trialGroupList: [],
      trialGroup: '',
      selectedItems: [],
      finisherFormulaList: [],
      penwiseBirdCount: null,
      finisherFormula: '',
      feedPerChick: null,
      feedPerPen: null,
      feedCost: null,
      requestStatus: false,
      isUpdate: false,
      load: false,
      originalData: true,
      oldEntry: false,
      // feedFormulaLoader: false,
      trialNoCheckLoader: false,
      checkFormulaEntry: false,
      ingredientsData: [],
      editableColumn: null,
      editedValues: {},
      total: null,
      page: false,
      showFormula: false,
      activeTab: '', // Default active tab
      isDropdownOpen: false,
      searchQuery: '',
      rows: [
        // { selectedIngredient: '', quantity: '', font: '', id: id++} // Initial row
      ],
      tempx: [],
      temp2: [],
      temp4: [],
      trNo: '',
      penBirdCnt: '',
      penList: [],
      starter: [],
      prestarter: [],
      fn1: [],
      fn2: [],
      errors: {},
      pensCheckBoxList: [],
      feedEntry: false,
      displayTrialNo: '',
      uniquePrestarterIngredients: [],
      uniquestarterIngredients: [],
      uniquefn1Ingredients: [],
      uniquefn2Ingredients: [],
      schickCategory: '',
      strialGroup: '',
      sformula: null,
      checkPrevPens: true,
      checkPrevPBC: true,
      checkPrevFPC: true,
      checkPrevFPP: true,
      checkPrevCal: true,
      editFlag: false,
      editFlagG: false,
      tempfeedPerChick: null,
      tempTrialGroup: '',
      tempChickCategory: '',
      feedcost: true,
      completedTrialNo: false,
      allottedPensLoader: false,
      totalTrialGroups: null,
      tempTrialGroupList: [],
      tempChickCategoryList: [],
      completedTrialFlag: false,
      // ffEditFlag: true,
      // penAllotEditFlag: true,
      maxPenSize: null,
      download1:false,
      download2:false,
      complete:false,
      allEditFlag:true,
      allEditFlagx:true,
      tempPenwiseBirdCount:null,
      add: false,
      edit: false,
      totalff:null,
      requestStatus6: false,
      tempTrialGroupList2: [],
      fcEditable:true,
      verifyAndSub: true,
      preStarterTab: false,
      starterTab: false,
      fn1Tab: false,
      fn2Tab: false,
      e: false,
      requestStatus5: false,
      requestStatus2: false,
      tempIngreEdit: true,
      feedCostEditable: true
      // tempTrialPens: [],
    };
  },

  computed: {
    dropdownIconClass() {
      return this.isDropdownOpen ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down';
    },

    filteredOptions() {
      const query = this.searchQuery.toLowerCase();

      return this.penList
        .filter(pen => pen.pen_name.includes(query))
        .map(pen => pen.id);
    },

    allSelected() {
      return this.selectedItems.length === this.penList.length;
    }
  },

  watch: {
    trialGroupList: {
      handler(newVal, oldVal) {

      },
    },
    tempPenwiseBirdCount: {
      handler(newVal, oldVal) {
      },
    },
    totalff(newVal,oldVal){
    },
    feedCostEditable(newVal,oldVal){
    },
    completedTrialFlag(newValue, oldValue) {
      // if(newValue && this.activeTab=='list'){
      //     $("#mailModel").modal("show");
      //   }
      //   else{
      //     $("#mailModel").modal("hide");
      //   }
    },
    
    activeTab: {
      handler(newVal, oldVal) {
        if(this.completedTrialFlag && newVal=='list'){
          // $("#mailModel").modal("show");
        }
        else{
          // $("#mailModel").modal("hide");
        }
      }
    },
    
    // trialGroup: {
    //   immediate: true,
    //   handler(newVal, oldVal) {
    //     console.log("kk");
    //    if(newVal!=oldVal){
    //      console.log(newVal,"kk");
    //      console.log(oldVal,"ll");
    //      this.penwiseBirdCount=null;
    //      this.feedPerChick=null;
    //      this.feedPerPen=null;
    //    }
    //   },
    // },

    tempTrialPens: {
      handler(newVal, oldVal) {

      },
      deep: true // Watch deeply to detect changes within nested objects
    },

    trialNumbers: {
        immediate: true,
        handler(newList) {
          // if (newList.length > 0) {
            this.trialNo = newList.id;
            this.displayTrialNo = newList.trial_no;
          // }
        }
      },

    penwiseBirdCount: 'calculateFeedPerPen',

    feedPerChick: 'calculateFeedPerPen',

    trialNo(newVal, oldVal) {

      // this.trialNumbers.map((i)=>{
      //   if(i.id==newVal){
      //     this.displayTrialNo=i.trial_no;
      //   }
      // })
      this.testt(newVal);
      this.clearr();
    },

    chickCategoryList(Value) {
      if(this.chickCategory==""){
        this.trialGroupList=this.tempTrialGroupList2;
      }
    },

    chickCategory(Value) {
      if (Value === 3) {
        this.showFormula = true;
      } else {
        this.showFormula = false;
        this.finisherFormula = '';
      }
      this.trialGroup = '';
      this.oldEntry = false;

      this.checkPrevPens = false;
      this.checkPrevPBC = false;
      this.checkPrevFPC = false;
      this.checkPrevFPP = false;
      this.checkPrevCal = false;

      this.tempx = [];
      this.originalData = true;
      if (Value == 1) {
        let tempu = [];
        tempu = this.preStarter.map(i => i.trial_group_id);
        this.trialGroupList = this.tempTrialGroupList.filter(i => !tempu.includes(i.id));
      } else if (Value == 2) {
        let tempv = [];
        tempv = this.starter.map(i => i.trial_group_id);
        this.trialGroupList = this.tempTrialGroupList.filter(i => !tempv.includes(i.id));
      }
      // this.controlInputs();
      // this.getTrialGroup();
    },

    trialGroup(newValue, oldValue) {
      this.tempx = [];
      this.originalData = true;
      this.oldEntry = false;
      this.checkPrevPens = false;
      this.checkPrevPBC = false;
      this.checkPrevFPC = false;
      this.checkPrevFPP = false;
      this.checkPrevCal = false;
      this.trialGroup=newValue;
      this.controlInputs(newValue);
      if (this.chickCategory === 3 && (this.finisherFormula === null || this.finisherFormula === "")) {
        this.checkFormulaEntry = true;
      } else {
        // this.controlInputs(newValue);
        if (this.trialPens.some(i => i.trial_group_id === newValue)) {
          this.filterPreviousPens(newValue);
        } else {
          this.penListManipulation();
        }
      }
    },

    finisherFormula() {
      if (this.finisherFormula == 1 && this.chickCategory == 3) {
        let tempw = [];
        tempw = this.fn1.map(i => i.trial_group_id);
        this.trialGroupList = this.tempTrialGroupList.filter(i => !tempw.includes(i.id));
      } else if (this.finisherFormula == 2 && this.chickCategory == 3) {
        let tempy = [];
        tempy = this.fn2.map(i => i.trial_group_id);
        this.trialGroupList = this.tempTrialGroupList.filter(i => !tempy.includes(i.id));
      }
      this.oldEntry = false;
      this.checkPrevPens = false;
      this.checkPrevPBC = false;
      this.checkPrevFPC = false;
      this.checkPrevFPP = false;
      this.checkPrevCal = false;

      this.checkFormulaEntry = false;
      this.trialGroup = '';
      this.oldEntry = false;
      this.tempx = [];
      this.originalData = true;
      // console.log("kk",this.trialGroup);
      // if(this.trialGroup!=="" || this.trialGroup!==null){
      //    this.controlInputs();
      // }
    },

    selectedItems: 'calculateFeedPerPen',

    feedPerPen(newValue,oldValue) {
      
    },

    rows: {
      handler(newRows) {
        this.ingredientsData = newRows.map(row => ({
          ingredient: row.selectedIngredient,
          quantity: row.quantity
        }));
      },
      deep: true
    },

    rows: {
      handler(newRows) {
        // Compute the total of the `number` fields
        this.total = newRows.reduce((sum, row) => sum + (Number(row.quantity) || 0), 0).toFixed(2);
      },
      deep: true // Watch for changes in the array's objects
    },

    tempx: {
      handler(newRows) {
        this.total = newRows.reduce((sum, row) => sum + (Number(row.quantity) || 0), 0).toFixed(2);
      },
      deep: true, // Watch for changes in the array's objects
    },

    total(newTotal) {
      this.total = newTotal;
      this.feedcost = false;

    }
  },

  mounted() {
    this.add = this.$store.getters.getActiveSideBar.permissions[0].add
    this.edit = this.$store.getters.getActiveSideBar.permissions[0].edit
    if(!this.$store.getters.getActiveSideBar.permissions[0].add){
      this.activeTab = 'feedformula';
    } else{
      this.activeTab = 'list';
    }
    // if(this.$store.getters.getActiveSideBar.permissions[0].edit){
    //   // this.activeTab = 'feedformula';
    //   this.allEditFlag=!this.allEditFlag;
    // }
    this.getTrialGroup();
    this.getChickCategory();
    this.getPens();
    this.getFormula();
    this.getTrialNo();
    this.checkLatestExperiment();
  },

  methods: {
    openModal() {
      this.isModalActive = true;
      //this.requestStatusSave=false;
    },
    closeModal() {
      this.isModalActive = false;
    },
    checkInput(val){
        if(val<0){
          this.$notify({
            message: `negative value found ${val}`,
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'danger'
          });
        }
    },
//     downloadPdf() {
//       this.download=true;

//   // var pdf = new jsPDF();
//     pdfMake.vfs = pdfFonts.pdfMake.vfs;

//     // Assuming this.tempDiv contains your HTML content
//     const htmlContent = document.getElementById('preview').innerHTML;

//     // Convert HTML to PDFMake JSON
//     const pdfMakeJson = htmlToPdfMake(htmlContent);

//     // Create the PDF
//     const pdfDoc = pdfMake.createPdf({ content: pdfMakeJson });

//     // Save the PDF (prompt user to download)
//     pdfDoc.download('Appointment_Letter.pdf');
//     this.download=false;

// },

      ffPdfdownload(){
        if(this.complete==false){
          this.allEditFlag=false;
        }
        this.download1=true;
        setTimeout(()=>{
          this.exportToPDF();
        },0);
      },

      apPdfDownload(){
        // if(this.ffEditFlag==true){
          if(this.complete==false){
                      this.allEditFlag=false;
                      this.allEditFlagx=false;
          }
                  this.download2=true;
        setTimeout(()=>{
          this.exportToPDF2();
        },0);
      },

      exportToPDF() {
          const pdfContent = this.$refs.pdfContent;
          html2canvas(pdfContent, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

            // Add the main heading
            pdf.setFontSize(16);
            pdf.text("Corrected Feed Formula", pdfWidth / 2, 20, { align: "center" });

            // Adjust Y position for the image to reduce padding below the heading
            const imageYPosition = 25; // Decrease this value to reduce the space under the heading
            pdf.addImage(imgData, 'PNG', 0, imageYPosition, pdfWidth, pdfHeight);
            pdf.save('Corrected_Feed_Formula.pdf');
          }).then(() => {
            this.download1 = false;
            if (this.complete == false) {
              this.allEditFlag = true;
              this.allEditFlagx = true;
            }
          });
        },

        exportToPDF2() {
        this.download2 = true;
        const pdf = new jsPDF('l', 'mm', 'a4'); // Change 'p' to 'l' for landscape orientation

        // Main heading
        pdf.setFontSize(16);
        pdf.text("Corrected Feed Formula", pdf.internal.pageSize.getWidth() / 2, 20, {
          align: "center",
        });

        // Print "Trial No" on the left side, keeping it on the same line as the main heading
        pdf.setFontSize(12);
        pdf.text(`Trial No: ${this.displayTrialNo}`, 7, 20); // X = 7 for left, Y = 20 to align with the center heading

        pdf.setFontSize(12);
        pdf.text(`Allotted Pens`, 7, 36);
        
  const headings = [
    { content: 'Pens', colSpan: 1, styles: { halign: 'center' } },
    ...this.tempTrialPens.map(trial => ({
      content: `T${trial.trial_group_id}`,
      colSpan: 1,
      styles: { halign: 'center' }
    })),
    { content: 'Total', colSpan: 1, styles: { halign: 'center' } }
  ];

  const maxPenSize = this.maxPenSize;
  const rows = [];

  // First row: "Allotted Pens" with the first pen IDs
  rows.push([
    { content: 'Allotted Pens', rowSpan: maxPenSize, styles: { fontStyle: 'bold' } },  // Only in the first row
    ...this.tempTrialPens.map(trial => `P${trial.pen_id[0] || ''}`),
    { content: '', colSpan: 1 } // Empty cell for Total column
  ]);

  // Remaining pen rows, without the first column ("Allotted Pens")
  for (let penIndex = 1; penIndex < maxPenSize; penIndex++) {
    rows.push([
      ...this.tempTrialPens.map(trial => `P${trial.pen_id[penIndex] || ''}`),
      { content: '', colSpan: 1 } // Empty cell for Total column
    ]);
  }

  // Add rows for Replicas and Chick Count with Total column
  rows.push([
    { content: 'Replicas', colSpan: 1, styles: { fontStyle: 'bold' } },
    ...this.tempTrialPens.map(() => maxPenSize),
    { content: maxPenSize * this.tempTrialPens.length, colSpan: 1, styles: { fontStyle: 'bold' } } // Total for Replicas
  ]);

  rows.push([
    { content: 'Chick Count', colSpan: 1, styles: { fontStyle: 'bold' } },
    ...this.tempTrialPens.map(() => this.tempPenwiseBirdCount * maxPenSize),
    { content: this.tempPenwiseBirdCount * maxPenSize * this.tempTrialPens.length, colSpan: 1, styles: { fontStyle: 'bold' } } // Total for Chick Count
  ]);

  // Set the table header and rows dynamically
  const tableWidth = pdf.internal.pageSize.getWidth() - 20; // Available width (subtract margins)
  const columnWidths = [
    45, // Width for the first column (for "Allotted Pens")
    ...new Array(this.tempTrialPens.length).fill(35), // Width for each trial column
    35 // Width for the Total column
  ];

  pdf.autoTable({
    startY: 40, // Position the table starting point
    head: [headings], // Table headings
    body: rows, // Table rows
    styles: { halign: 'center', fontSize: 12 }, // Increase font size for table content
    theme: 'grid', // Grid theme to create lines around table cells
    columnStyles: columnWidths.reduce((styles, width, index) => {
      styles[index] = { cellWidth: width };
      return styles;
    }, {}),
    margin: { left: (pdf.internal.pageSize.getWidth() - tableWidth) / 2 }, // Center the table
    didDrawCell: (data) => {
      // This ensures "Allotted Pens" only appears once in the rowspan
      if (data.row.index > 0 && data.column.index === 0) {
        data.cell.text = '';  // Clear the text for rows after the first one
      }
    }
  });

  // Save the generated PDF
  pdf.save('Allotted_Pens.pdf');

  // Reset flags after generating the PDF
  this.download2 = false;
  if (this.complete === false) {
    this.allEditFlag = true;
    this.allEditFlagx = true;
  }
},

    checkLatestExperiment() {
          axios
            .get(`/indous-research/check-latest-experiment`)
            .then((response) => {
              this.feedCostEditable = response.data.is_latest;
            })
            .catch((error) => {
              // console.error("Error checking latest experiment:", error);
            });
        },

        // calculateCFF() {
        //   if (this.rows) {
        //       this.originalData = false;
        //       this.tempx = JSON.parse(JSON.stringify(this.rows));
        //       this.tempx.forEach(row => {
        //         // const quantityNumber = parseFloat(row.quantity);
        //         if (!isNaN(row.quantity)) {
        //           row.quantity = (((row.quantity) * (this.feedPerPen * 5)) / 1000).toFixed(3);
        //         }
        //       });
        //   }
        // },

    onEditFeedFormulaPreStarter(val) {
      this.clearr();
      this.e=true;
      this.fcEditable=true;
      this.editFlag = true;
      this.editFlagG = true;
      this.switchEditFf();

      // this.checkPrevPBC = false;
      this.checkPrevPens = true;
      this.checkPrev = true;
      // this.checkPrevPBC = true;
      this.checkPrevFPC = false;
      this.checkPrevFPP = true;
      this.feedcost = false;
      this.editIngredients = false;
      this.isUpdate = true;
      // this.clearr();

      // this.checkPrev=true;
      // this.checkPrevPBC=false;
      // this.checkPrevFPC=false;
      // this.checkPrevFPP=false;
      // this.rows=this.
      // this.checkLatestExperiment();
      const selectedGroup = this.tempPrestarter.find(i => i.trial_group_id == val);
      this.chickCategory = selectedGroup.chick_category;
      const tempo = selectedGroup.feed_cost;
      this.feedCost = tempo;
      this.tempChickCategory = selectedGroup.chick_category;
      this.tempTrialGroup = selectedGroup.trial_group_id;
      this.selectedItems = Array(this.maxPenSize).fill(1);
      this.tempIngreEdit = selectedGroup.editable;
      this.penwiseBirdCount = selectedGroup.penwsie_bird_count;
      this.FeedFormulaIngredientsFetch(selectedGroup.trial_group_id)
        .then(() => {
      if (selectedGroup) {
        // this.trialNo = sele
        this.tempChickCategory = selectedGroup.chick_category;
        this.tempTrialGroup = selectedGroup.trial_group_id;
        this.trialGroup = selectedGroup.trial_group_id;
        // this.feedCost = tempo;
        //   console.log(this.feedCost,"qoooo");
        //   console.log(selectedGroup.feed_cost,"oooo");
        // this.penwiseBirdCount = selectedGroup.penwsie_bird_count;
        // this.feedPerChick = selectedGroup.feed_per_chick;

        //code to handle  checkPrevPBC, checkPrevFPC, checkPrevFPP
        // this.tempFeedPerChick=selectedGroup.feed_per_chick;

        this.editFlag = true;

        //code to handle  checkPrevPBC, checkPrevFPC, checkPrevFPP
        // this.FeedFormulaIngredientsFetch(selectedGroup.chick_category);

        this.formula = null;

        this.checkPrev = false;

        this.penwiseBirdCount = selectedGroup.penwsie_bird_count;
        this.feedPerChick = selectedGroup.feed_per_chick;


        //code to handle  checkPrevPBC, checkPrevFPC, checkPrevFPP
        // this.checkExistingInputs(this.trialNo, selectedGroup.chick_category, selectedGroup.trial_group_id);

        // this.FeedFormulaIngredientsFetch();
        // console.log("ji",this.rows);

        // this.chickCategory=selectedGroup.chick_category;
        // this.trialGroup=selectedGroup.trial_group_id;
        // this.formula=null;

        /*const ingredients = selectedGroup.ingredients.map(ingredient => ({
          selectedIngredient: ingredient.ingredient_id,
          quantity: ingredient.quantity,
          ingredientName: ingredient.ingredient.ingredients // Access the correct property for the name
        }));
        // this.originalData=false;
        this.tempx = ingredients;
        this.originalData = false;*/

        // this.trialGroup=selectedGroup.trial_group_id;
        // this.checkPrev=true;
        // this.checkPrevPBC=true;
        // this.checkPrevFPC=true;
        // this.checkPrevFPP=true;
        // this.checkPrevCal=true;


        // this.penwiseBirdCount=selectedGroup.penwsie_bird_count;
        // console.log("ff",this.tempFeedPerChick);
        // this.feedPerPen=selectedGroup.feed_per_pen;
        }
      })
        .catch(error => {
          // console.error('Error fetching ingredients:', error);
        });
    },

    feedFormulaPreStarterTable() {
      const prestarterIngredientSet = new Set();
      this.preStarter.forEach(trial => {
        trial.ingredients.forEach(ingredient => {
          prestarterIngredientSet.add(ingredient.ingredient_name);
        });
      });
      this.uniquePrestarterIngredients = Array.from(prestarterIngredientSet);
    },

    feedFormulaStarterTable() {
      const starterIngredientSet = new Set();
      this.starter.forEach(trial => {
        trial.ingredients.forEach(ingredient => {
          starterIngredientSet.add(ingredient.ingredient_name);
        });
      });
      this.uniquestarterIngredients = Array.from(starterIngredientSet);
    },

    feedFormulaFn1Table() {
      const fn1IngredientSet = new Set();
      this.fn1.forEach(trial => {
        trial.ingredients.forEach(ingredient => {
          fn1IngredientSet.add(ingredient.ingredient_name);
        });
      });
      this.uniquefn1Ingredients = Array.from(fn1IngredientSet);
    },

    feedFormulaFn2Table() {
      const fn2ingredientsSet = new Set();
      this.fn2.forEach(trial => {
        trial.ingredients.forEach(ingredient => {
          fn2ingredientsSet.add(ingredient.ingredient_name);
        });
      });
      this.uniquefn2Ingredients = Array.from(fn2ingredientsSet);
    },


    onEditFeedFormulaStarter(val) {
      this.clearr();
      this.e=true;
      this.fcEditable=true;
      this.editFlag = true;
      this.editFlagG = true;
      this.switchEditFf();

      // this.checkPrevPBC = false;
      this.checkPrevPens = true;
      this.checkPrev = true;
      // this.checkPrevPBC = true;
      this.checkPrevFPC = false;
      this.checkPrevFPP = true;
      this.feedcost = false;
      this.editIngredients = false;
      this.isUpdate = true;
      // this.checkLatestExperiment();
      const selectedGroup = this.tempStarter.find(i => i.trial_group_id == val);
      this.chickCategory = selectedGroup.chick_category;
      const tempo = selectedGroup.feed_cost;
      this.feedCost = tempo;
      this.tempChickCategory = selectedGroup.chick_category;
      this.tempTrialGroup = selectedGroup.trial_group_id;
      this.selectedItems.length=this.maxPenSize;
      this.penwiseBirdCount = selectedGroup.penwsie_bird_count;
      this.tempIngreEdit = selectedGroup.editable;
      this.FeedFormulaIngredientsFetch(selectedGroup.trial_group_id)
        .then(() => {
      if (selectedGroup) {
        // this.trialNo = sele
        this.tempChickCategory = selectedGroup.chick_category;
        this.tempTrialGroup = selectedGroup.trial_group_id;
        this.trialGroup = selectedGroup.trial_group_id;

        this.editFlag = true;

        //code to handle  checkPrevPBC, checkPrevFPC, checkPrevFPP
        // this.FeedFormulaIngredientsFetch(selectedGroup.chick_category);

        this.formula = null;

        this.checkPrev = false;
        
          this.feedPerChick = selectedGroup.feed_per_chick;
          // this.editFlagG = false;
        }
    })
        .catch(error => {
          // console.error('Error fetching ingredients:', error);
        });

      // this.clearr();
      // this.switchEditFf();
      // const selectedGroup = this.tempStarter.find(i => i.trial_group_id == val);
      // if (selectedGroup) {
      //   this.tempChickCategory = selectedGroup.chick_category;
      //   this.tempTrialGroup = selectedGroup.trial_group_id;
      //   this.feedCost = selectedGroup.feed_cost;

      //   this.editFlag = true;
      //   this.formula = null;

      //   const ingredients = selectedGroup.ingredients.map(ingredient => ({
      //     selectedIngredient: ingredient.ingredient_id,
      //     quantity: ingredient.quantity,
      //     ingredientName: ingredient.ingredient.ingredients // Access the correct property for the name
      //   }));

      //   this.tempx = ingredients;
      //   this.originalData = false;

      //   // this.checkPrev=true;
      //   // this.checkPrevPBC=true;
      //   // this.checkPrevFPC=true;
      //   // this.checkPrevFPP=true;
      //   this.checkPrevCal = true;
      //   this.editIngredients = false;
      //   this.isUpdate = true;
      // }

      /*this.checkPrevPens = true;
      this.checkPrev = true;
      this.feedcost = false;
      this.checkPrevPBC = true;
      this.checkPrevFPC = true;
      this.checkPrevFPP = true;*/

      // this.switchEditFf();
      // // console.log(this.tempStarter);
      // const selectedGroup = this.tempStarter.find(i => i.trial_group_id == val);
      // // console.log(selectedGroup);
      // if (selectedGroup) {
      //   // console.log("kkoo",selectedGroup.chick_category);
      //   this.schickCategory=selectedGroup.chick_category;
      //   this.strialGroup=selectedGroup.trial_group_id;
      //   this.sformula=null;

      //   // this.chickCategory=selectedGroup.chick_category;
      //   // this.trialGroup=selectedGroup.trial_group_id;
      //   // this.formula=null;
      //   const ingredients = selectedGroup.ingredients.map(ingredient => ({
      //         selectedIngredient: ingredient.ingredient_id,
      //         quantity: ingredient.quantity,
      //         ingredientName: ingredient.ingredient.ingredients // Access the correct property for the name
      //     }));
      //     this.originalData=false;
      //     // console.log("yyyii",ingredients);
      //     this.tempx=ingredients;
      //     // console.log("yyyii",this.tempx);
      //     // console.log("b",this.originalData);
      //     this.checkPrev=true;
      //     this.checkPrevPens=true;
      //     this.checkPrevPBC=true;
      //     this.checkPrevFPC=true;
      //     this.checkPrevFPP=true;
      //     this.checkPrevCal=true;
      //     this.editIngredients=false;
      //     this.isUpdate = true;


      //     // console.log(this.checkPrev);
      // } else {
      //     // console.log('No matching trial_group_id found.');
      // }
    },

    onEditFeedFormulaFn1(val) {
      this.clearr();
      this.e=true;
      this.fcEditable=true;
      this.rows = [];
      this.editFlag = true;
      this.editFlagG = true;
      this.switchEditFf();

      // this.checkPrevPBC = false;
      this.checkPrevPens = true;
      this.checkPrev = true;
      // this.checkPrevPBC = true;
      this.checkPrevFPC = false;
      this.checkPrevFPP = true;
      this.feedcost = false;
      this.editIngredients = false;
      this.isUpdate = true;
      // this.checkLatestExperiment();
      const selectedGroup = this.tempFn1.find(i => i.trial_group_id == val);
      this.chickCategory = selectedGroup.chick_category;
      const tempo = selectedGroup.feed_cost;
      this.feedCost = tempo;
      this.tempChickCategory = selectedGroup.chick_category;
      this.tempTrialGroup = selectedGroup.trial_group_id;
      // this.penwiseBirdCount = selectedGroup.penwsie_bird_count;
      // this.feedPerChick = selectedGroup.feed_per_chick;
      this.finisherFormula = selectedGroup.formula;
      this.selectedItems.length=this.maxPenSize;
      this.penwiseBirdCount = selectedGroup.penwsie_bird_count;
      this.tempIngreEdit = selectedGroup.editable;
      this.FeedFormulaIngredientsFetch(selectedGroup.trial_group_id,selectedGroup.formula)
        .then(() => {
      if (selectedGroup) {
        // this.trialNo = sele
        this.tempChickCategory = selectedGroup.chick_category;
        this.tempTrialGroup = selectedGroup.trial_group_id;
        this.trialGroup = selectedGroup.trial_group_id;
        
        this.editFlag = true;

        //code to handle  checkPrevPBC, checkPrevFPC, checkPrevFPP
        // this.FeedFormulaIngredientsFetch(selectedGroup.chick_category);

        this.formula = null;

        this.checkPrev = false;

          this.penwiseBirdCount = selectedGroup.penwsie_bird_count;
          this.feedPerChick = selectedGroup.feed_per_chick;
          // this.editFlagG = false;
        }
    })
        .catch(error => {
          // console.error('Error fetching ingredients:', error);
        });



      // this.clearr();
      /*this.switchEditFf();

      const selectedGroup = this.tempFn1.find(i => i.trial_group_id == val);
      if (selectedGroup) {
        this.tempChickCategory = selectedGroup.chick_category;
        this.tempTrialGroup = selectedGroup.trial_group_id;
        this.editFlag = true;
        this.formula = null;

        const ingredients = selectedGroup.ingredients.map(ingredient => ({
          selectedIngredient: ingredient.ingredient_id,
          quantity: ingredient.quantity,
          ingredientName: ingredient.ingredient.ingredients // Access the correct property for the name
        }));

        this.feedCost = selectedGroup.feed_cost;
        this.tempx = ingredients;
        this.originalData = false;
        // this.checkPrev=true;
        // this.checkPrevPBC=true;
        // this.checkPrevFPC=true;
        // this.checkPrevFPP=true;
        this.checkPrevCal = true;
        this.editIngredients = false;
        this.isUpdate = true;
      }
      this.checkPrevPens = true;
      this.checkPrev = true;
      this.feedcost = false;
      this.checkPrevPBC = true;
      this.checkPrevFPC = true;
      this.checkPrevFPP = true;



      // this.switchEditFf();
      // // console.log(this.tempFn1);
      // const selectedGroup = this.tempFn1.find(i => i.trial_group_id == val);
      // // console.log(selectedGroup);
      // if (selectedGroup) {
      //   // console.log("kkoo",selectedGroup.chick_category);
      //   this.schickCategory=selectedGroup.chick_category;
      //   this.strialGroup=selectedGroup.trial_group_id;
      //   this.sformula=selectedGroup.formula;

      //     const ingredients = selectedGroup.ingredients.map(ingredient => ({
      //         selectedIngredient: ingredient.ingredient_id,
      //         quantity: ingredient.quantity,
      //         ingredientName: ingredient.ingredient.ingredients // Access the correct property for the name
      //     }));

      //     this.originalData=false;
      //     this.tempx=ingredients;
      //     // console.log("yyy",ingredients);
      //     this.checkPrev=true;
      //     this.checkPrevPens=true;
      //     this.checkPrevPBC=true;
      //     this.checkPrevFPC=true;
      //     this.checkPrevFPP=true;
      //     this.checkPrevCal=true;

      //     this.editIngredients=false;
      //     this.isUpdate = true;
      //     // this.checkFormulaEntry=true;
      //     // console.log(this.checkPrev);
      // } else {
      //     // console.log('No matching trial_group_id found.');
      // }*/
    },

    onEditFeedFormulaFn2(val) {
      this.clearr();
      this.e=true;
      this.fcEditable=true;
      this.editFlag = true;
      this.editFlagG = true;
      this.switchEditFf();

      // this.checkPrevPBC = false;
      this.checkPrevPens = true;
      this.checkPrev = true;
      // this.checkPrevPBC = true;
      this.checkPrevFPC = false;
      this.checkPrevFPP = true;
      this.feedcost = false;
      this.editIngredients = false;
      this.isUpdate = true;
      // this.checkLatestExperiment();

      const selectedGroup = this.tempFn2.find(i => i.trial_group_id == val);
      this.chickCategory = selectedGroup.chick_category;
      const tempo = selectedGroup.feed_cost;
      this.feedCost = tempo;
      this.tempChickCategory = selectedGroup.chick_category;
      this.tempTrialGroup = selectedGroup.trial_group_id;
      this.finisherFormula = selectedGroup.formula;
      this.selectedItems.length=this.maxPenSize;
      this.penwiseBirdCount = selectedGroup.penwsie_bird_count;
      this.tempIngreEdit = selectedGroup.editable;
      this.FeedFormulaIngredientsFetch(selectedGroup.trial_group_id,selectedGroup.formula)
        .then(() => {
      if (selectedGroup) {
        // this.trialNo = sele
        this.tempChickCategory = selectedGroup.chick_category;
        this.tempTrialGroup = selectedGroup.trial_group_id;
        this.trialGroup = selectedGroup.trial_group_id;

        this.editFlag = true;

        //code to handle  checkPrevPBC, checkPrevFPC, checkPrevFPP
        // this.FeedFormulaIngredientsFetch(selectedGroup.chick_category);

        this.formula = null;

        this.checkPrev = false;
        
          this.penwiseBirdCount = selectedGroup.penwsie_bird_count;
          this.feedPerChick = selectedGroup.feed_per_chick;
          // this.editFlagG = false;
        }
    })
        .catch(error => {
          // console.error('Error fetching ingredients:', error);
        });



      // this.clearr();
     /* this.switchEditFf();

      const selectedGroup = this.tempFn2.find(i => i.trial_group_id == val);
      if (selectedGroup) {
        this.tempChickCategory = selectedGroup.chick_category;
        this.tempTrialGroup = selectedGroup.trial_group_id;
        this.editFlag = true;
        this.formula = null;

        const ingredients = selectedGroup.ingredients.map(ingredient => ({
          selectedIngredient: ingredient.ingredient_id,
          quantity: ingredient.quantity,
          ingredientName: ingredient.ingredient.ingredients // Access the correct property for the name
        }));
        this.feedCost = selectedGroup.feed_cost;
        this.tempx = ingredients;
        this.originalData = false;

        // this.checkPrev=true;
        // this.checkPrevPBC=true;
        // this.checkPrevFPC=true;
        // this.checkPrevFPP=true;
        this.checkPrevCal = true;
        this.editIngredients = false;
        this.isUpdate = true;
      }
      this.checkPrevPens = true;
      this.checkPrev = true;
      this.feedcost = false;
      this.checkPrevPBC = true;
      this.checkPrevFPC = true;
      this.checkPrevFPP = true;

      // this.switchEditFf();

      // // console.log(this.tempFn2);
      // const selectedGroup = this.tempFn2.find(i => i.trial_group_id == val);
      // // console.log(selectedGroup);
      // if (selectedGroup) {
      //   // console.log("kkoo",selectedGroup.chick_category);
      //   // this.chickCategory=selectedGroup.chick_category;
      //   // this.trialGroup=selectedGroup.trial_group_id;
      //   // this.finisherFormula=selectedGroup.formula;

      //   this.schickCategory=selectedGroup.chick_category;
      //   this.strialGroup=selectedGroup.trial_group_id;
      //   this.sformula=selectedGroup.formula;

      //     const ingredients = selectedGroup.ingredients.map(ingredient => ({
      //         selectedIngredient: ingredient.ingredient_id,
      //         quantity: ingredient.quantity,
      //         ingredientName: ingredient.ingredient.ingredients // Access the correct property for the name
      //     }));
      //     this.originalData=false;
      //     this.tempx=ingredients;
      //     // console.log("yyy",ingredients);
      //     this.checkPrev=true;
      //     this.checkPrevPens=true;
      //     this.checkPrevPBC=true;
      //     this.checkPrevFPC=true;
      //     this.checkPrevFPP=true;
      //     this.checkPrevCal=true;

      //     this.editIngredients=false;
      //     this.isUpdate = true;
      //     // this.checkFormulaEntry=true;

      //     // console.log(this.checkPrev)
      // } else {
      //     // console.log('No matching trial_group_id found.');
      // }*/
    },

    switchTabModal() {
      // $("#mailModel").modal("show");
      $("#mailModel").modal("hide");
      this.setActiveTab('apply');
    },

    switchEditFf() {
      // $("#mailModel").modal("show");
      // $("#mailModel").modal("hide");
      this.setActiveTab('list');
    },

    clearModal() {
      $("#mailModel").modal("hide");
    },

    getTrialNo() {
          this.trialNumbers = this.$store.getters.getSelectedTrial;
    },

    getChickCategory() {
      axios.get('/indous-research/master/chick-category').then(response => {
        this.chickCategoryList = response.data.chickCategoryList;
        this.tempChickCategoryList = response.data.chickCategoryList;
      })
        .catch(error => {
          'There was an error fetching the chick categories:', error
        });
    },

    getTrialGroup() {
      axios.get('/indous-research/master/trial-group').then(response => {
        this.trialGroupList = response.data.trialGroupList;
      })
        .then(() => {
          axios.get('/indous-research/experiment-information/get-trial-group-list').then(response => {
            const temp = response.data;
            const ans = this.trialGroupList.filter((i) => temp.includes(i.id));
            this.tempTrialGroupList2 = this.trialGroupList.filter((i) => response.data.includes(i.id));
            // console.log("ans", ans);

            this.tempTrialGroupList = ans;
            this.totalTrialGroups = ans.length;
          });
        })
        // .then(()=>{
        //   this.getAllFeedFormulaLists(newVal);
        // })
        .catch(error => {
          // console.error('There was an error fetching the trial group list', error);
        });
    },


    // getCreatedTrialGroup(){
    //   axios.get('/indous-research/experiment-information/get-trial-group-list').then(response=>{
    //     const temp = response.data;
    //     // this.tempTrialGroupList = response.data.trialGroupList;
    //     //   this.totalTrialGroups = response.data.trialGroupList.length;
    //     // console.log(temp);
    //   })
    //   .catch(error=>{
    //     'There was an error fetching the trial group list', error
    //   });
    // },

    getPens() {
      axios.get('/indous-research/master/pens').then(response => {
        this.penList = response.data.penList;
      })
        .catch(error => {
          'There was an error fetching the pen list', error
        });
    },

    getFormula() {
      axios.get('/indous-research/master/formulas').then(response => {
        this.finisherFormulaList = response.data.finisherFormulaList;
      })
        .catch(error => {
          'There was an error fetching the pen list', error
        });
    },

    getAllotedPenLists(newVal) {
        this.allottedPensLoader = true;
        
        axios.get(`/indous-research/get-alloted-pen-lists/${newVal}`).then(response => {
          // Normalize and sort pens by pen_id for each trial group
          this.trialPens = response.data.result.map(group => ({
            trial_group_id: group.trial_group_id,
            pen_id: Object.keys(group.pens)
              .map(key => group.pens[key].pen_id)    // Convert object to array
              .sort((a, b) => a - b)                 // Sort pen_id in ascending order
          }));
          
          this.tempPenwiseBirdCount = response.data.penwisebirdcount;

          // Calculate the max pen size to determine how many rows to render
          this.maxPenSize = Math.max(...response.data.result.map(group => Object.keys(group.pens).length));

          // Sort trialPens by trial_group_id (just in case)
          this.tempTrialPens = this.trialPens.sort((a, b) => {
            return a.trial_group_id - b.trial_group_id;
          });

          this.allottedPensLoader = false;
        }).catch(error => {
          // Handle error if there's any issue with the request
          // console.error('There was an error fetching the allotted pen list', error);
        });
      },

    filterPreviousPens(newValue) {
      this.pensCheckBoxList = this.penList.map(i => i.id);
      this.pensCheckBoxList = this.trialPens.find(group => group.trial_group_id === newValue)?.pen_id ?? [];
      this.selectedItems = this.trialPens.find(group => group.trial_group_id === newValue)?.pen_id ?? [];
    },

    onEdit(trialGroupId) {
      this.editableColumn = trialGroupId;
      this.headingVal = trialGroupId;
      const trialGroupIdPens = this.trialPens.find(trial => trial.trial_group_id === trialGroupId);

      if (trialGroupIdPens && trialGroupIdPens.pen_id.length >= 1) {
        this.editedValues = trialGroupIdPens.pen_id.reduce((acc, penId, index) => {
          acc[index] = penId;
          return acc;
        }, {});
      }
    },

    updatePens() {
      const convertedPenNumber = this.tempTrialPens.map(item => {
        return {
          ...item,
          pen_id: item.pen_id.map(pen => Number(pen))
        };
      });

      const tempSavedPenStore = convertedPenNumber.map(item => item.pen_id).flat(1);

      const duplicateCheck = new Set();

      for (let num of tempSavedPenStore) {
        if (num > 25) {
          this.$notify({
            message: `P${num} is greater than P25`,
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'danger'
          });
          return;
        }

        if (num < 1) {
          this.$notify({
            message: `Pen number cannot be less than 1`,
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'danger'
          });
          return;
        }

        if (duplicateCheck.has(num)) {
          this.$notify({
            message: `P${num} is repeated`,
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'danger'
          });
          return;
        } else {
          duplicateCheck.add(num);
        }
      }

      this.updatedPens = convertedPenNumber;
      this.updatedPens = this.updatedPens.map(group => {
        return {
          trial_group_id: group.trial_group_id,
          pen_id: group.pen_id.sort((a, b) => a - b) // Sort pen_id in ascending order
        };
      });
      if (this.updatedPens) {
        this.requestStatusofPens = true;
        axios.post('/indous-research/update-trial-group-pens', {
          trial_no: this.trialNo, // Pass the trialNo if you have it in your state
          updatedPens: this.updatedPens
        })
          .then(response => {
            this.editableColumn = null;
            this.editedValues = {};
            this.$notify({
              message: response.data.message,
              horizontalAlign: 'right',
              verticalAlign: 'top',
              type: 'success'
            });
            this.updatedPens = [];
            this.updateBtnVisible = (response.data.message == "Pens successfully updated!") ? false : true;
            this.getAllotedPenLists(this.trialNo);
            this.requestStatusofPens = false;
          })
          .catch(error => {
            // console.error('There was an error updating the pens:', error);
          });
      }
    },

    cancelPenUpdate() {
      this.tempTrialPens = this.trialPens;
      // console.log(this.tempTrialPens);
      this.getAllotedPenLists(this.trialNo);
      this.updateBtnVisible = false;
    },

    onSave(trialGroupId) {

      this.updateBtnVisible = true;
      this.editableColumn = null;

      // return convertedPenNumber;

      // console.log("check",Array.from(tempSavedPenStore).map(i=>i));

      // // const 
      // // for
      // console.log(this.trialPens.map(i=>i.pen_id.map(k=> k)).flat(1));
      // console.log(this.trialPens.map(i=>i.pen_id.map(k=> k)).flat(1).reduce((total,value)=>total+value,0));
      // console.log(this.trialPens.map(i=>i.pen_id.map(k=> k)).flat(1).for((i+1)=>{ if(i==)});
      // console.log("flat",tempSavedPenStore);

      // const trialGroupIdPens = this.trialPens.find(trial => trial.trial_group_id === trialGroupId);
      // this.updatedPens=trialGroupIdPens.pen_id.map(i=>Number(i));

      // tempSavedPenStore.add();
      // this.tempStr.push(trialGroupIdPens);
      // console.log("trialPens",this.trialPens);
      // console.log("trialGroupIdPens",trialGroupIdPens);
      // console.log("updatedPens",this.updatedPens);
      // console.log("tempStr",this.tempStr);

    },

    onCancel() {
      this.editableColumn = null;
      this.editedValues = {};
    },

    controlInputs(val) {
      if(!this.editFlagG){
        if (this.trialNo && this.chickCategory && this.trialGroup) {
            this.penwiseBirdCount = null;
            this.feedPerChick = null;
            this.feedPerPen = null;
            // this.checkExistingInputs();
            this.load = true;
            this.FeedFormulaIngredientsFetch(this.trialGroup,this.finisherFormula);
            // this.getFeedFormulaIngredients();
          }
      }

      // if (this.trialNo && this.chickCategory && this.trialGroup === '') {
      //   // this.checkExistingInputs();
      //   // this.getTrialGroup();
      //   this.penwiseBirdCount = null;
      //   this.feedPerChick = null;
      //   this.feedPerPen = null;
      //   this.getFeedFormulaIngredients();
      // }
    },

    FeedFormulaIngredientsFetch(val,formula) {
      //  = '';
      this.oldEntry = false;
      this.checkPrevPens = false;
      this.checkPrevPBC = false;
      this.checkPrevFPC = false;
      this.checkPrevFPP = false;
      this.checkPrevCal = false;

      this.tempx = [];
      this.originalData = true;
      axios.post('/indous-research/feed-formula-ingredients', {
        trialNo: this.trialNo,
        chickCategory: this.chickCategory,
        trialGroup: val,
      })
        .then(response => {
          this.rows = response.data.map(ingredient => ({
            selectedIngredient: ingredient.ingredient_id,
            quantity: ingredient.quantity,
            ingredientName: ingredient.ingredient_name
          }));
          this.totalff = response.data.reduce((acc, ingredient) => {
            return acc + parseFloat(ingredient.quantity);
          }, 0);
          this.load = false;
          if (this.editFlag) {
            this.feedPerChick = this.tempFeedPerChick;
            this.checkPrevPens = true;
          }
          if(response.data.length === 0){
              this.$notify({
              message: 'Please update in the feed formula using concentrate.',
              horizontalAlign: 'right',
              verticalAlign: 'top',
              type: 'danger'
            });
          }
          // this.total = this.rows.reduce((sum, row) => sum + (Number(row.quantity) || 0), 0).toFixed(2);
        })
        .then(()=>{
          // this.FeedFormulaIngredientsFetch(val);
          this.checkExistingInputs(val,formula);
        })
        .catch(error => {
          this.$notify({
            message: 'Please update in the feed formula using concentrate.',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'danger'
          });
          this.load = false;
          this.rows = [];
        });
    },

    // getFeedFormulaIngredients() {
    //   // if (this.trialNo && this.chickCategory && this.trialGroup === '') {
       
    //   //   this.FeedFormulaIngredientsFetch();
    //   // }
    // },

    // filterTrialGroups(){
    //   axios.post('/indous-research/get-entered-trial-group', {
    //     trialNo:this.trialNo,
    //     chickCategory:this.chickCategory,
    //     finisherFormula: this.finisherFormula
    //   })
    //   .then(response => {
    //     const data=response.data;
    //     console.log(this.trialGroupList,"before");
    //     let temper=[];
    //     temper=this.trialGroupList;
    //     if(data){
    //     console.log(temper,"jjjjj");
    //     console.log(data,"data");
    //     console.log(temper.filter(i=>!data.includes(i.id)),"testing");
    //     this.trialGroupList=temper.filter(i=>!data.includes(i.id));
    //     console.log(this.trialGroupList);
    //     }
    //   })
    // },

    checkExistingInputs(val,formula) {
      axios.post('/indous-research/check-existing-inputs', {
        trialNo: this.trialNo,
        chickCategory: this.chickCategory,
        trialGroup: val,
        finisherFormula: formula
      })
        .then(response => {
          const data = response.data;
          this.feedPerChick = data.feedPerChick;
          if(this.e!=true){
              this.penwiseBirdCount = data.penwiseBirdCount;
          };
          this.feedCost = data.feedCost;
          this.feedPerPen = data.feedPerPen;
          if(data.feedCost!=null){
            this.fcEditable=false;
          } else{
            this.fcEditable=true;
          }
          if(this.e==true){
            this.fcEditable=true;
          }
          if (data.hasOwnProperty('ingredients')) {
            if (data.ingredients.length > 0) {
              this.originalData = false;
            }
          } else{
            this.originalData = true;
          }


          if (data && Object.keys(data).length > 0 && data.ingredients.length > 0) {
            // this.penwiseBirdCount = data.penwiseBirdCount;
          // if(this.e==true){
          //     this.penwiseBirdCount = data.penwiseBirdCount;
          // }
            // this.feedCost = data.feedCost;
            this.feedcost = true;
            // this.feedPerPen = data.feedPerPen;
            // this.feedPerChick = data.feedPerChick;
            // this.finisherFormula = data.finisherFormula;
            // this.selectedItems = data.pens.map(pen => pen.pen_id);
            // this.rows = data.ingredients.map(ingredient => ({
            //   selectedIngredient: ingredient.ingredient_id,
            //   quantity: ingredient.quantity
            // }));
            // this.isUpdate = true;
            // this.originalData=false;
            // console.log("fetch",this.selectedItems.length);
            // console.log("1");
            // this.filterPreviousPens();
            if (data.ingredients.length > 0) {
              // this.originalData=!this.originalData;
              this.oldEntry = true;
              this.originalData = false;

              if (this.editFlag) {
                this.checkPrevPBC = false;
                this.checkPrevFPC = false;
                this.checkPrevFPP = false;
              } else {
                this.checkPrevPens = true;
                this.checkPrevPBC = true;
                this.checkPrevFPC = true;
                this.checkPrevFPP = true;
                this.checkPrevCal = true;
              }

            } else {
              this.oldEntry = false;
              this.checkPrevPens = false;
              this.checkPrevPBC = false;
              this.checkPrevFPC = false;
              this.checkPrevFPP = false;
              this.checkPrevCal = false;
              // console.log("false");
            }
            // this.originalData=false;
            // return;
          } else {
            // console.log("2");
            this.oldEntry = false;
            this.checkPrevPens = false;
            this.checkPrevPBC = false;
            this.checkPrevFPC = false;
            this.checkPrevFPP = false;
            this.checkPrevCal = false;
            this.feedcost = false;
            this.tempx = [];
            this.feedPerChick = '';
            this.feedPerPen = null;
            // this.feedCost='';
            // this.originalData=true;
          }
        })
        .catch(error => {
          // this.isUpdate = false;
        });
      // console.log("3");
      // }
    },

    update(val) {
      this.editFlagG = false;
      if(val==false){
        this.closeModal();
        this.requestStatus6 = true;
        axios.put("/indous-research/corrected-feed-formula/" + this.trialNo, {
          experiment_info_id: this.trialNo,
          editable: val,
      })
        .then((response) => {
          this.$notify({
            message: response.data.message,
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          });
          this.requestStatus6 = false;
          this.allEditFlag = false;
          this.verifyAndSub = true;
          this.testt(this.trialNo);
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          // this.requestStatus5 = false;
          this.clearr();
        });
      }else{
        this.requestStatus5 = true;
      this.ingredientsData = this.tempx.map(row => ({
        ingredient: row.selectedIngredient,
        quantity: row.quantity
      }));
      // console.log(this.trialNo);
      // console.log(this.chickCategory);
      // console.log(this.finisherFormula);
      // console.log(this.trialGroup);
      // this.schickCategory=selectedGroup.chick_category;
      // this.strialGroup=selectedGroup.trial_group_id;
      // this.sformula=selectedGroup.formula;
      axios.put("/indous-research/corrected-feed-formula/" + this.trialNo, {
        trialNo: this.trialNo,
        experiment_info_id: this.trialNo,
        trial_group_id: this.tempTrialGroup,
        chick_category: this.tempChickCategory,
        formula: this.finisherFormula,
        penwise_bird_count: this.penwiseBirdCount,
        feed_per_chick: this.feedPerChick,
        feed_per_pen: this.feedPerPen,
        feed_cost: this.feedCost,
        editable: this.verifyAndSub ? false : true,
        // penwsie_bird_count: this.penwiseBirdCount,
        // feed_per_chick: this.feedPerChick,
        // pen_id: this.selectedItems,
        ingredient_id: this.ingredientsData,
      })
        .then((response) => {
          this.$notify({
            message: response.data.message,
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          });
          this.requestStatus5 = false;
          this.requestStatus6 = false;
          // this.isUpdate = false;
          if (response.data.message == "Successfully updated!") {
            this.clearr();
            this.checkPrev = false;
            this.feedcost = false;
            this.checkPrevPens = false;
            this.checkPrevPBC = false;
            this.checkPrevFPC = false;
            this.checkPrevFPP = false;
            this.checkPrevCal = false;
            this.oldEntry = false;
            this.getAllFeedFormulaLists(this.trialNo);
            // this.uniquePrestarterIngredients = [];
            this.setActiveTab('feedformula');
            // this.editIngredients=true;
            this.isUpdate = false;
            this.fcEditable=false;
          }
          this.clearr();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          // this.requestStatus5 = false;
          this.clearr();
        });
      }
      this.editFlag = false;
      this.checkPrevPens = true;
    },

    penListManipulation() {
      if(this.e!=true){
        this.selectedItems = [];
      }
      // if(this.selectedItems.length<1){
        // this.filterPreviousPens();
        this.pensCheckBoxList = this.penList;
        
        let excludedPenIds = this.trialPens
        .filter(i => i.pen_id !== this.penList.id)
        .map(i => i.pen_id).flat();
        
        // Filter the penList to exclude pens with IDs in excludedPenIds
        //ash
      this.pensCheckBoxList = this.penList
        .filter(pen => !excludedPenIds.includes(pen.id)).map(i => i.id);
    },

    calculateFeedPerPen() {
      if (this.penwiseBirdCount && this.feedPerChick && this.selectedItems.length>0) {
        //   this.feedPerPen = this.penwiseBirdCount * this.feedPerChick;
        // this.feedPerPen = ((this.feedPerPen/1000)*1.0).toFixed(1);

        // this.feedPerPen = this.penwiseBirdCount * ((this.feedPerChick)*1000);
        // console.log("feedperpen",this.feedPerPen);
        // this.feedPerPen = ((this.feedPerPen).toFixed(1))/1000;

        this.feedPerPen = (this.penwiseBirdCount * (this.feedPerChick)).toFixed(2);
        this.originalData = false;

        // if(this.tempo99!=this.selectedItems.length){
        //   this.tempo99=this.selectedItems.length;
        //   this.feedPerPen = this.feedPerPen-1;

        //   this.feedPerPen = this.feedPerPen+1;
        // }
      if (this.rows) {
        this.tempx = JSON.parse(JSON.stringify(this.rows));
        this.tempx.forEach(row => {
          // const quantityNumber = parseFloat(row.quantity);
          if (!isNaN(row.quantity)) {
            row.quantity = (((row.quantity) * (this.feedPerPen * this.selectedItems.length)) / this.totalff).toFixed(3);    
            // row.quantity = ((row.quantity) * (newValue * 5)).toFixed(3);
          }
        });
      } 
      }else{
        // (this.tempo99==this.selectedItems.length) ?
         this.originalData = true;
        }
      // this.feedcost=false;
    },

    getAllFeedFormulaLists(newVal) {
      axios.get('/indous-research/master/trial-group').then(response => {
        this.trialGroupList = response.data.trialGroupList;
      }).then(() => {
          axios.get('/indous-research/experiment-information/get-trial-group-list').then(response => {
            this.tempTrialGroupList = this.trialGroupList.filter((i) => response.data.includes(i.id));
            this.tempTrialGroupList2 = this.trialGroupList.filter((i) => response.data.includes(i.id));

            // this.tempTrialGroupList = ans;
            // this.totalTrialGroups = ans.length;
          });
        })
        .then(() => {

          axios.get('/indous-research/master/chick-category').then(response => {
            this.chickCategoryList = response.data.chickCategoryList;
            this.tempChickCategoryList = response.data.chickCategoryList;
          }).then(
                    // Proceed with the axios request only after retrieving the trial number
                axios.post('/indous-research/check-experiment-info', {
                experiment_info_id: this.trialNo
              })
              .then(response => {
                if (response.data.exists) {
                  this.complete = true;
                  this.allEditFlag = false;
                  this.allEditFlagx = false;
                  // this.penAllotEditFlag = false;
                } else{
                  this.complete = false;
                  this.allEditFlag = true;
                  this.allEditFlagx = true;
                  // this.penAllotEditFlag = true;
                }
            }))
            .then(() => {
              // For prestarter
              this.preStarterTab=true;
              axios.post('/indous-research/prestarter-feed-formula', {
                experiment_info_id: newVal,
                category_id: 1,
                formula: null
              })
                .then(response => {
                  this.preStarter = response.data.map(i => {
                  if (i.editable === 1) {
                    this.verifyAndSub = false;
                  }
                  return {
                    trial_group_id: i.trial_group_id,
                    editable: i.editable,
                    ingredients: i.ingredients.map(ingredient => ({
                      quantity: ingredient.quantity,
                      ingredient_name: ingredient.ingredient ? ingredient.ingredient.ingredients : ''
                    }))
                  };
                });
                this.preStarterTab=false;

                  if (response.data.length === this.tempTrialGroupList.length && this.tempTrialGroupList.length != null) {
                    // this.chickCategoryList=this.tempChickCategoryList.slice(1,2);
                    if (this.chickCategoryList.length == 3) {
                      this.chickCategoryList.splice(0, 1);
                    } else if (this.chickCategoryList.length == 2 && this.chickCategoryList[0].id == 1) {
                      this.chickCategoryList.splice(0, 1);
                    } else if (this.chickCategoryList.length == 1 && this.chickCategoryList[0].id == 1) {
                      this.chickCategoryList.splice(0, 1);
                    }
                  }
                  this.tempPrestarter = response.data;
                  this.feedFormulaPreStarterTable();
                })
            })
            .then(() => {
              // For starter
              this.starterTab=true;
              axios.post('/indous-research/starter-feed-formula', {
                experiment_info_id: newVal,
                category_id: 2,
                formula: null
              })
                .then(response => {
                  this.starterTab=false;
                  this.starter = response.data.map(i => {
                  if (i.editable === 1) {
                    this.verifyAndSub = false;
                  }
                  return {
                    trial_group_id: i.trial_group_id,
                    editable: i.editable,
                    ingredients: i.ingredients.map(ingredient => ({
                      quantity: ingredient.quantity,
                      ingredient_name: ingredient.ingredient ? ingredient.ingredient.ingredients : ''
                    }))
                  };
                });
                  if (response.data.length === this.tempTrialGroupList.length && this.tempTrialGroupList.length != null) {
                    // this.chickCategoryList=this.tempChickCategoryList.slice(1,1);
                    if (this.chickCategoryList.length == 3) {
                      this.chickCategoryList.splice(1, 1);
                    } else if (this.chickCategoryList.length == 2 && this.chickCategoryList[0].id == 2) {
                      this.chickCategoryList.splice(0, 1);
                    } else if (this.chickCategoryList.length == 1 && this.chickCategoryList[0].id == 2) {
                      this.chickCategoryList.splice(0, 1);
                    }
                  }
                  this.tempStarter = response.data;
                  this.feedFormulaStarterTable();
                }
                )
            })
            .then(() => {
              // this.starter = this.starter.map(trial => ({
              //       ...trial,
              //       editable: !!trial.editable // Cast editable to boolean
              //     }));
              // For FN1
              this.fn1Tab=true;
              axios.post('/indous-research/fn1-feed-formula', {
                experiment_info_id: newVal,
                category_id: null,
                formula: 1
              })
                .then(response => {
                  this.fn1Tab=false;
                  this.fn1 = response.data.map(i => {
                  if (i.editable === 1) {
                    this.verifyAndSub = false;
                  }
                  return {
                    trial_group_id: i.trial_group_id,
                    editable: i.editable,
                    ingredients: i.ingredients.map(ingredient => ({
                      quantity: ingredient.quantity,
                      ingredient_name: ingredient.ingredient ? ingredient.ingredient.ingredients : ''
                    }))
                  };
                });
                  //   if(response.data.length === this.totalTrialGroups){
                  //   this.chickCategoryList=this.chickCategoryList.slice(3);
                  //   console.log("fn1",response.data.length)
                  //   console.log("totalgrps",this.totalTrialGroups)
                  //   console.log(this.chickCategoryList);
                  // }
                  this.tempFn1 = response.data;
                  if (response.data.length == this.tempTrialGroupList.length && this.tempTrialGroupList.length != null) {
                    // this.chickCategoryList=this.tempChickCategoryList.slice(1,2);
                    if (this.finisherFormulaList.length == 2) {
                      this.finisherFormulaList.splice(0, 1);
                    }
                    //   this.finisherFormulaList.splice(0,1);
                  }
                  this.feedFormulaFn1Table();
                  if (this.preStarter.length >= 1) {
                    this.preStarter.sort((a, b) => a.trial_group_id - b.trial_group_id);
                  }
                  if (this.starter.length >= 1) {
                    this.starter.sort((a, b) => a.trial_group_id - b.trial_group_id);
                  }
                  if (this.fn1.length >= 1) {
                    this.fn1.sort((a, b) => a.trial_group_id - b.trial_group_id);
                  }
                  if (this.fn2.length >= 1) {
                    this.fn2.sort((a, b) => a.trial_group_id - b.trial_group_id);
                  }
                }
                )
            })
            .then(() => {
              // For FN2
              this.fn2Tab=true;
              axios.post('/indous-research/fn2-feed-formula', {
                experiment_info_id: newVal,
                category_id: null,
                formula: 2
              })
                .then(response => {
                  this.fn2Tab=false;
                    this.fn2 = response.data.map(i => {
                    if (i.editable === 1) {
                      this.verifyAndSub = false;
                    }
                    return {
                      trial_group_id: i.trial_group_id,
                      editable: i.editable,
                      ingredients: i.ingredients.map(ingredient => ({
                        quantity: ingredient.quantity,
                        ingredient_name: ingredient.ingredient ? ingredient.ingredient.ingredients : ''
                      }))
                    };
                  });
                  response.data.map
                  if (response.data.length === this.tempTrialGroupList.length && this.tempTrialGroupList.length != null) {
                    this.chickCategoryList = [];
                  }
                  this.tempFn2 = response.data;
                  this.feedFormulaFn2Table();
                  // (this.preStarter.length>=1) ? this.preStarter.sort((a, b) => a.trial_group_id - b.trial_group_id) : '';
                  // (this.starter.length>=1) ? this.starter.sort((a, b) => a.trial_group_id - b.trial_group_id) : '';
                  // (this.fn1.length>=1) ? this.fn1.sort((a, b) => a.trial_group_id - b.trial_group_id) : '';
                  // (this.fn2.length>=1) ? this.fn2.sort((a, b) => a.trial_group_id - b.trial_group_id) : '';
                  // this.starter.sort((a, b) => a.trial_group_id - b.trial_group_id);
                  // this.fn1.sort((a, b) => a.trial_group_id - b.trial_group_id);
                  // this.fn2.sort((a, b) => a.trial_group_id - b.trial_group_id);
                  this.trialNoCheckLoader = false;
                  // if(this.preStarter.length==this.fn2.length){}
                  // if(this.fn2.length==this.trialGroupList.length){
                  // console.log("complete",response.data.length);
                  // console.log("complete2",this.totalTrialGroups);
                  if (this.tempTrialGroupList.length != null && response.data.length == this.tempTrialGroupList.length) {
                    this.completedTrialFlag = true;
                    this.checkPrev = true;
                    this.feedcost = true;
                    this.checkPrevPens = true;
                    this.checkPrevPBC = true;
                    this.checkPrevFPC = true;
                    this.checkPrevFPP = true;
                    this.checkPrevCal = true;
                    this.editIngredients = true;
                    this.completedTrialNo = false;
                    // if(this.activeTab === 'list'){
                      //   $("#mailModel").modal("show");
                      // }
                    // $("#mailModel").modal("show");
                    // console.log("hiiiii");
                    // if("activeTab === 'list'"){
                      // console.log("hiiiii2");
                      // $("#mailModel").modal("show");
                      // } else{
                        // $("#mailModel").modal("hide");
                        // }
                  } else{
                    this.completedTrialFlag = false;
                    this.checkPrev = false;
                    this.checkPrevPens = false;
                    this.checkPrevPBC = false;
                    this.checkPrevFPC = false;
                    this.checkPrevFPP = false;
                    this.checkPrevCal = false;
                    this.editIngredients = false;
                    this.completedTrialNo = true;
                    // $("#mailModel").modal("hide");
                  }
                  // this.feedFormulaLoader = false;
                  // this.preStarterTab = false;
                  // this.starterTab = false;
                  // this.fn1Tab = false;
                  // this.fn2Tab = false;
                })
            })
        })
        .catch(error => {
          // console.error('There was an error!', error);
        });
    },

    // .catch(error => {
    //   // console.error('There was an error!', error);
    // });



    // .catch(error => {
    //   // console.error('There was an error!', error);
    // });


    //   .catch(error => {
    //       // console.error('There was an error!', error);
    //     });
    //if(this.preStarter.length==5 && this.starter.length==5 && this.fn1.length==5 && this.fn2.length==5){

    //   updateMaxPens() {
    //   let max = 0;
    //   this.starter.forEach(trial => {
    //     if (trial.pens && trial.pens.length > max) {
    //       max = trial.pens.length;
    //     }
    //   });
    //   // this.maxPens = max;
    // },

    getIngredientQuantity(ingredients, ingredientName) {
      const ingredient = ingredients.find(
        ing => ing.ingredient_name === ingredientName
      );
      return ingredient ? ingredient.quantity : "";
    },

    getTotal(ingredients) {
      return ingredients.reduce((total, ing) => {
        return total + parseFloat(ing.quantity);
      }, 0).toFixed(3);
    },

    testt(newVal) {
      // this.feedFormulaLoader = true;
      this.preStarterTab = true;
      this.starterTab = true;
      this.fn1Tab = true;
      this.fn2Tab = true;
      this.getAllotedPenLists(newVal);
      this.trialNoCheckLoader = true;
      this.getAllFeedFormulaLists(newVal);
    },

    saveData() {
      this.ingredientsData = this.tempx.map(row => ({
        ingredient: row.selectedIngredient,
        quantity: row.quantity
      }));
    },

    isActive(tab) {
      return this.activeTab === tab;
    },

    toggleEdit(index) {
      this.editingRow[index] = !this.editingRow[index];
      this.page = !this.page;
      this.clearr();
      this.page = !this.page;
    },


    save(val) {
      this.isModalActive = false;

      if(this.feedCost==null){
        this.$notify(
          {
            message: "Please enter the Feed Cost",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'danger'
          })
        return;
      }

      if (this.chickCategory === 3 && (this.finisherFormula === null || this.finisherFormula === "")) {
        this.$notify(
          {
            message: "Please choose the formula",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'danger'
          })
        this.checkFormulaEntry = true;
        return;
      }
      else {
        this.checkFormulaEntry = false;
      }

      // if(this.feedCost == null){
        // let fc = alert("Do you want to continue without adding Feed Cost?");

        // if(fc == null){
        //   //  this.save();
        //    return;
        // }
        // this.$notify(
        //   {
        //     message: "Feed cost was not added",
        //     horizontalAlign: 'right',
        //     verticalAlign: 'top',
        //     type: 'danger'
        //   })
        // return;
      // }

      if (this.oldEntry || this.checkPrev) {
        this.$notify(
          {
            message: "Data already added to the selected trial group/formula",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'danger'
          })
        return;
      }

      if(this.maxPenSize > 0){
          if(this.selectedItems.length<this.maxPenSize || this.selectedItems.length>this.maxPenSize){
          this.$notify(
            {
              message: `Please choose ${this.maxPenSize} pens`,
              horizontalAlign: 'right',
              verticalAlign: 'top',
              type: 'danger'
            })
            return;
          }
        }
      // if(this.tempx.length < 1){
      //   this.$notify(
      //           {
      //             message:"Please click on calculate button",
      //             horizontalAlign: 'right',
      //             verticalAlign: 'top',
      //             type: 'danger'
      //           })
      //   return;
      // }
      // if(this.feedCost===null || this.feedCost===""){
      //   this.feedEntry=true;
      //   this.$notify(
      //           {
      //             message:"Enter Feed cost",
      //             horizontalAlign: 'right',
      //             verticalAlign: 'top',
      //             type: 'danger'
      //           })
      //   return;
      // }
      this.feedEntry = false;
      // this.requestStatus = true;
      if(val){
        this.requestStatus = true;
       } else{
        this.requestStatus2 = true;
       }

      this.saveData();
      // console.log("k",this.selectedItems);
      axios.post('/indous-research/corrected-feed-formula', {
        experiment_info_id: this.trialNo,
        trial_group_id: this.trialGroup,
        chick_category: this.chickCategory,
        formula: this.finisherFormula,
        penwsie_bird_count: this.penwiseBirdCount,
        feed_per_chick: this.feedPerChick,
        feed_per_pen: this.feedPerPen,
        pen_id: this.selectedItems.sort((a, b) => a - b),
        ingredient_id: this.ingredientsData,
        feed_cost: this.feedCost,
        editable: val
      }).then((response) => {
        this.$notify(
          {
            message: response.data.message,
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          })
        // this.requestStatus = false;
        // if(val){
        // this.requestStatus = false;
      //  } else{
        this.requestStatus2 = false;
        this.requestStatus = false;
      //  }
        this.testt(this.trialNo);
        this.errors = {};
        this.clearr();
      }).catch((error) => {
        this.requestStatus = false;
        this.errors = error.response.data.errors;
      });
    },

    setActiveTab(tab) {
      this.activeTab = tab;
      // console.log(this.activeTab);
    },

    addRow() {
      this.rows.push({ ingredients: '', number: 0, font: '', isEditing: false, id: id++ });
    },

    removeRow(row) {
      this.rows = this.rows.filter((i) => i !== row);
    },

    removeTempRow(row) {
      // this.tempx=this.tempx.filter((i)=>i!==row);
      // Find the index of the row that was clicked
      const index = this.tempx.indexOf(row);

      if (index > -1) {
        this.tempx[index].quantity = 0;
      }
    },

    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },

    toggleSelectAll() {
      if (this.allSelected) {
        this.selectedItems = [];
      } else {
        this.selectedItems = [...this.filteredOptions];
      }
    },

    clearr() {
      this.e=false;
      this.editFlagG = false;
      this.tempFeedPerChick = null;
      this.tempTrialGroup = '';
      this.chickCategory = '';
      this.trialGroup = '';
      this.selectedItems = [];
      this.penwiseBirdCount = '';
      this.finisherFormula = '';
      this.feedPerChick = '';
      this.feedPerPen = null;
      this.feedCost = '';
      this.checkFormulaEntry = false;
      this.temp2 = [];
      this.rows = [];
      this.tempx = [];
      this.checkPrev = false;
      this.feedcost = false;
      this.checkPrevPens = false;
      this.oldEntry = false;
      this.isUpdate = false;
      this.originalData = true;
      this.editFlag = false;
      if (this.completedTrialFlag) {
        this.checkPrev = true;
        this.feedcost = true;
        this.checkPrevPens = true;
        this.checkPrevPBC = true;
        this.checkPrevFPC = true;
        this.checkPrevFPP = true;
        this.checkPrevCal = true;

        this.editIngredients = true;
        this.completedTrialNo = false;
      }
    },

    toggleRslt() {
      this.page = !this.page;
      // this.clearr();
    }
  },

};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid black;
  padding: 8px;
  /* text-align: center; */
}

th {
  background-color: #f2f2f2;
}

.action-cell {
  width: 50px;
}


.structax {
  display: grid;
  grid-template-columns: 75px 230px 58px 200px;
  grid-gap: 25px;
  padding-top: 20px;
}

.structx {
  display: grid;
  grid-template-columns: 125px 210px 118px 200px;
  grid-gap: 25px;
  margin: 0% 2%;
}

.structx label {
  display: flex;
  justify-content: right;
  /* Center horizontally (optional) */
  align-items: flex-end;
}

.structnew {
  display: grid;
  grid-template-columns: 135px 340px 180px 0px 0px;
  grid-gap: 25px;
  grid-auto-rows: 50px;
  margin-left: 20px;
}

.md-field {
  margin: 0px 0px;
}

.grid-item {
  height: 50px;
}

/* Ensure that each test class has some default width */
.test1 {
  text-align: right;
  /* grid-column: 1 / 5; */
}

.test3 {
  text-align: right;
  /* grid-column: 3 / 5; */
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  /* margin: 25px 0; */
  font-size: 15px;
  font-weight: 500;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  /* background-color: #009879; */
  /* color: #ffffff; */
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
  border: 1px solid #dddddd;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.styled-table tbody tr:hover {
  background-color: #f1f1f1;
}

.styled-table td button {
  background: none;
  border: none;
  cursor: pointer;
}

.styled-table td button:focus {
  outline: none;
}

hr {
  border: none;
  height: 2px;
  background-color: #000;
  margin-top: 10px;
}

.custom-md-field {
  /* Adjust the overall size of the md-field if needed */
  width: 190px;
  /* Adjust as needed */
}

.custom-md-input {
  /* Adjust the size of the input */
  width: 50px;
  /* Change the width as needed */
  /* height: 30px; Change the height as needed */
  /*padding: 5px;  Adjust padding as needed */
  font-size: 14px;
  /* Adjust font size as needed */
}

.row {
  --ct-gutter-x: 1.5rem;
  --ct-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--ct-gutter-y));
  margin-right: calc(-0.5 * var(--ct-gutter-x));
  margin-left: calc(-0.5 * var (--ct-gutter-x));
}

.tab-content {
  padding: 20px 0 0 0;
  border: none;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff !important;
  background-color: #71b6f9 !important;
}

.align-items-center {
  align-items: center !important;
  padding: 0px 65px 0px 20px;
}

.custm {
  height: 30px;
  width: 90px;
}

.col {
  flex: 1 0 0%;
}

.mt-0 {
  margin-top: 0 !important;
}

.header-title {
  font-size: 1rem;
  margin: 0 0 7px 0;
}

.card-box {
  background-color: #fff;
  padding: 10px;
  -webkit-box-shadow: 0 .75rem 6rem rgba(56, 65, 74, .03);
  box-shadow: 0 .75rem 6rem rgba(56, 65, 74, .03);
  /* margin-bottom: 1px; */
  border-radius: .25rem;
}

.col-auto {
  flex: 0 0 auto;
  flex-direction: row;
  width: auto;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.input-group {
  display: flex;
}

button[type="submit"],
button[type="button"].cancel-button {
  /* padding: 10px 20px; */
  /* border: none;
border-radius: 4px; */
  cursor: pointer;
}

#buttons {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
}

/* button[type="button"].cancel-button:hover {
background-color: #b3b3b3;
} */

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: rgb(113, 182, 249);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 30px;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.md-list-item .md-list-item-container .md-ripple>span {
  border-radius: 3px;
  display: contents;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

.create-field {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.all-buttons {
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
}

.table-layout {
  display: flex;
  flex-direction: column;

}

.form-style {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.row-layout {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row-layout1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.row-layout2 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 10%;
}

.checkbox-select {
  width: 100%;
  height: auto;
  border-radius: 1rem;
}

.checkbox-select__trigger {
  justify-content: space-between;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background: #fff;
  position: relative;
  z-index: 100;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
}

.checkbox-select__trigger.isActive {
  border-radius: 10px 10px 0 0;
  background: #f2f2f2;
}



.checkbox-select__trigger:hover {
  background: #f4f4f4;
}

.checkbox-select__dropdown {
  display: none;
  background: #fff;
  box-shadow: 0 12px 15px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  /* padding-bottom: 25px; */
}

.checkbox-select__dropdown {
  display: block;
  /* Ensure it gets shown */
}

.checkbox-select__search-wrapp {
  padding: 10px 25px 5px;
}

.checkbox-select__search-wrapp input {
  width: 100%;
  height: 40px;
  border-width: 0 0 2px;
  border-style: solid;
  border-color: #000;
  font-size: 16px;
  background: transparent;
}

.checkbox-select__col {
  display: flex;
  font-size: 12px;
  padding: 0 25px;
  justify-content: space-between;
  text-transform: uppercase;
}

.checkbox-select__select-all label {

  cursor: pointer;
}

.checkbox-select__filters-wrapp {
  /* margin-top: 20px; */
  height: 157px;
  overflow-y: auto;
  padding-left: 0px;
}

.checkbox-select__check-wrapp {
  position: relative;
  padding: 0 25px;
  margin-bottom: 5px;
}

.checkbox-select__check-wrapp input[type="checkbox"] {
  display: none;
}

.checkbox-select__check-wrapp input[type="checkbox"]+label {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  line-height: 22px;
  padding-left: 30px;
  display: inline-block;
  user-select: none;
  transition: padding 0.25s ease;
}

.checkbox-select__check-wrapp input[type="checkbox"]+label:after {
  border: solid 2px #000;
  content: "";
  width: 22px;
  height: 22px;
  top: 0;
  left: 0;
  position: absolute;
}

.checkbox-select__check-wrapp input[type="checkbox"]+label:before {
  width: 14px;
  height: 14px;
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  background-color: #000;
  opacity: 0;
  transform: scale(0.5);
  transition: all 0.2s ease;
}

.checkbox-select__check-wrapp input[type="checkbox"]:checked+label:before {
  opacity: 1;
  transform: scale(1);
}

.col-md-3 {
  flex: 0 0 auto;
  /* width: 25%; */
}

#sec-tab {
  /* margin:0px 90px 0px 90px; */
}

#table2 th {
  text-align: center;
}

#table2 td {
  text-align: center;
}

.editInput {
  width: 45px;
  height: 25px;
}

.feedFormulaTable {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alterTable {
  width: 29%;
}

.ingredientsDisplay {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-top: 15px;
  padding-left: 7%;
  /* justify-content: space-between; */
}

.ingredientsDisplay div:nth-child(3) {
  margin-left: 20px;
  /* Add gap before the 4th div */
}

.ingredientsStyle {
  padding-left: 4%;
}

.quantityStyle {
  padding-left: 10%;
}

.quantityValStyle {
  padding-left: 4%;
}
</style>