<template>
  <div class="w-100">
    <div
      class="checkbox-select__trigger"
      :class="{
        isActive: activeTrigger,
      }"
      @click="showDropdown"
    >
      <span style="font-size: 0.9rem">{{
        checkedFilters.length == 0
          ? placeholder
          : `${checkedFilters.length} items selected`
      }}</span>
      <i
        class="mdi mdi-custom-icon"
        :class="dropdown ? 'mdi-chevron-up' : 'mdi-chevron-down'"
      ></i>
    </div>
    <div
      :id="this.id"
      style="position: absolute;z-index: 1000;"
      :style="{ display: this.dropdown ? 'block' : 'none' }"
      :class="{
        activeSearch: showLoader,
        'checkbox-select__dropdown': this.dropdown,
      }"
    >
      <div class="checkbox-select__search-wrapp">
        <input
          type="text"
          @focus="showLoader = true"
          @blur="showLoader = false"
          placeholder="Search"
          v-model="search"
        />
      </div>
      <div class="checkbox-select__col">
        <div class="checkbox-select__select-all" @click="selectAll">
          <label :for="this.id + 'selectAll'">{{ selectAllText }}</label>
        </div>
        <div class="checkbox-select__info">
          {{ checkedFilters.length }}
          SELECTED
        </div>
      </div>
      <ul
        id="customScroll"
        class="checkbox-select__filters-wrapp"
        data-simplebar-auto-hide="false"
      >
        <li style="list-style: none" v-for="(filter, index) in filteredList" :key="index">
          <div class="checkbox-select__check-wrapp">
            <input
              :id="id + index"
              class="conditions-check"
              v-model="checkedFilters"
              :value="filter"
              type="checkbox"
              @change="handleChange"
            />
            <label :for="id + index" class="text-capitalize">
              <slot name="optionMod" :option="filter">{{
                filter[label].toLowerCase()
              }}</slot>
            </label>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import "../assets/css/multiselect.css";
import "../assets/scss/multiselect.scss";
export default {
  props: {
    placeholder: {
      type: String,
      default: "Select the applicable",
    },
    filters: {
       
      default: [],
    },
    searchBy: {
      type: String,
      default: "",
    },
    selected_items: {
      type: Array,
    },
    label: {
      type: String,
    },
    id: {
      type: String,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    expand: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: "",
      checkedFilters: [],
      activeTrigger: false,
      dropdown: false,
      showLoader: false,
      options: [],
      value: [],
      
    };
  },
  mounted() {
    if (this.selected_items.length != 0)
      this.selected_items.map((item) => this.checkedFilters.push(item));
    this.dropdown = this.expand; // expand dropdown(show) by default  - prop
  },
  computed: {
    filteredList() {
      return this.filters.filter((item) => {
        return item[this.searchBy].toLowerCase().includes(this.search.toLowerCase());
      });
    },
    selectAllText() {
      if (this.checkedFilters.length == this.filters.length) {
        return "Clear All";
      }
      return "Select All";
    },
  },
  watch: {
    selected_items: function () {
      if (this.selected_items.length == 0) {
        this.checkedFilters = [];
      }
    },
  },
  methods: {
    selectAll: function () {
      if (this.checkedFilters.length == this.filters.length) this.checkedFilters = [];
      else if (this.checkedFilters.length !== this.filters.length) {
        this.checkedFilters = [];
        for (var filter in this.filters) {
          this.checkedFilters.push(this.filters[filter]);
        }
      }

      this.$emit("selected", this.checkedFilters);
    },
    handleChange(event) {
      this.$emit("selectedItem", JSON.stringify(event.target._value));
      this.$emit("selected", this.checkedFilters);
    },

    showDropdown: function () {
      if (this.dropdown == false && !this.isDisabled) {
        this.dropdown = true;
        this.activeTrigger = true;
        TweenMax.fromTo(
          `#${this.id}`,
          0.55,
          {
            autoAlpha: 0,
            y: -10,
          },
          {
            autoAlpha: 1,
            y: 0,
            ease: Power2.easeOut,
          }
        );
      } else {
        this.dropdown = false;
        this.activeTrigger = false;

        TweenMax.to(`#${this.id}`, 0.2, {
          autoAlpha: 0,
          y: -10,
          ease: Power2.easeOut,
        });
      }
    },
  },
};
</script>
<style scoped>

</style>