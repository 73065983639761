<template>
  <div class="content">
    <div class="md-layout">
      
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50">
          <div class="md-card md-theme-default">
            <div class="md-card-header card-chart" style="background: #fdfdfd; padding: 5px 10px;">
              <canvas ref="feedChart" style="max-height: 300px;"></canvas>
            </div>
            <div class="md-card-content">
              <h4 class="title">Cumulative Weekly Feed Consumption (in Kgs)</h4>
              <!-- <p class="category">Last Performance</p> -->
            </div>
            <div class="md-card-actions md-alignment-left">
              <div class="stats">
                <!-- <i class="md-icon md-icon-font md-theme-default">access_time</i> updated 10 days ago  -->
              </div>
            </div>
          </div>
        </div>

        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50">
          <div class="md-card md-theme-default">
            <div class="md-card-header card-chart" style="background: #fdfdfd; padding: 5px 10px;">
              <canvas ref="bodyWtChart" style="max-height: 300px;"></canvas>
            </div>
            <div class="md-card-content">
              <h4 class="title">Cumulative Weekly Body Weight (in Kgs)</h4>
              <!-- <p class="category">Last Performance</p> -->
            </div>
            <div class="md-card-actions md-alignment-left">
              <div class="stats">
                <!-- <i class="md-icon md-icon-font md-theme-default">access_time</i> updated 10 days ago  -->
              </div>
            </div>
          </div>
        </div>

      <!-- <div class="md-layout-item md-medium-size-100 md-xlarge-size-100 md-size-50">
      <chart-card
          :chart-data="dataCompletedTasksChart.data"
          :chart-options="dataCompletedTasksChart.options"
          :chart-type="'Line'"
          data-background-color="green"
        >
          <template slot="content">
            <h4 class="title">Cummulative Weekly body Weight</h4>
            <p class="category">Last Performance</p>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>access_time</md-icon>
              campaign sent 26 minutes ago
            </div>
          </template>
        </chart-card>
        </div> -->
        
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  // StatsCard,
  ChartCard,
  // NavTabsCard,
  // NavTabsTable,
  // OrderedTable,
} from "@/components";

export default {
  components: {
    // StatsCard,
    // ChartCard,
    // NavTabsCard,
    // NavTabsTable,
    // OrderedTable,
  },
  mounted(){
    this.selectedTrial = this.$store.getters.getSelectedTrial.id;
    this.$store.watch(
      (state) => state.selectedTrial,
      (newTrial, oldTrial) => {
        if (newTrial !== oldTrial) {
          this.selectedTrial = newTrial.id;
        }
      }
    );
  },
  watch: {
    selectedTrial(){
      this.fetchFeedData();
    }
  },
  data() {
    return {
      selectedTrial: "",
      feedConsumptionChart: null,
      bodyWeightChart: null,
      feedConsumedData: [],
      bodyWeightData: [],
      dailySalesChart: {
        data: {
          labels: ["M", "T", "W", "T", "F", "S", "S"],
          series: [[12, 17, 7, 17, 23, 18, 38]],
        },
        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      dataCompletedTasksChart: {
        data: {
          labels: ["12am", "3pm", "6pm", "9pm", "12pm", "3am", "6am", "9am"],
          series: [[230, 750, 450, 300, 280, 240, 200, 190]],
        },

        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      // emailsSubscriptionChart: {
      //   data: {
      //     labels: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"], // Weekly labels
      //     series: [
      //       [0.5, 1.0, 1.5, 2.0, 2.0], // Data for T1
      //       [1.0, 1.5, 2.0, 2.5, 4.0], // Data for T2
      //       [1.5, 2.0, 2.5, 3.0, 3.0], // Data for T3
      //       [2.0, 2.5, 3.0, 3.5, 2.5], // Data for T4
      //       [2.0, 2.5, 3.0, 3.5, 2.1], // Data for T5
      //     ],
      //   },
      //   options: {
      //     axisX: {
      //       showGrid: false,
      //     },
      //     seriesBarDistance: 10,
      //     stackBars: false,
      //     low: 0.0,       // Start from 0.0
      //     high: 4.0,      // Go up to 4.0
      //     axisY: {
      //       onlyInteger: false,
      //       type: this.$Chartist.FixedScaleAxis,
      //       ticks: [0.0, 0.5, 1.0, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0], // Y-axis increments
      //     },
      //     chartPadding: {
      //       top: 50,
      //       right: 5,
      //       bottom: 10,
      //       left: 0,
      //     },
      //     height: '400px', // Increase chart height
      //     series: {
      //       'series-0': {
      //         showArea: true,
      //         className: 'ct-series-a'
      //       },
      //       'series-1': {
      //         showArea: true,
      //         className: 'ct-series-b'
      //       },
      //       'series-2': {
      //         showArea: true,
      //         className: 'ct-series-c'
      //       },
      //       'series-3': {
      //         showArea: true,
      //         className: 'ct-series-d'
      //       },
      //       'series-4': {
      //         showArea: true,
      //         className: 'ct-series-e'
      //       },
      //     }
      //   },
      //   responsiveOptions: [
      //     [
      //       "screen and (max-width: 640px)",
      //       {
      //         seriesBarDistance: 5,
      //         axisX: {
      //           labelInterpolationFnc: function (value) {
      //             return value[0]; // Show shorter labels on small screens
      //           },
      //         },
      //       },
      //     ],
      //   ],
      // }
    };
  },
  methods: {
    async fetchFeedData(){
      try {
        const response = await axios.get(`/indous-research/get-average-body-weight-feed-consumed-fcr/${this.selectedTrial}`);
        this.feedConsumedData = response.data.feedConsumedResult;
        this.bodyWeightData = response.data.bodyWtResult;
        this.initializeCharts();
      } catch (error) {
        
      }
    },

    initializeCharts(){
      this.chartFeedConsumption();
      this.chartBodyWeight();
    },

    chartFeedConsumption() {
      // Destroy the existing chart
      if(this.feedConsumptionChart){
        this.feedConsumptionChart.destroy();
      }

      const feedConsumedDays = [];
      const trialGroupIds = [];

      const trialGroupColors = [
        '#2C728E', // T1
        '#21908C', // T2
        '#27AD81', // T3
        '#5DC863', // T4
        '#AADC32', // T5
        '#FDE725', // T6
      ];

      this.feedConsumedData.forEach( (item, index) => {
        item.data.forEach( data => {
          if(!trialGroupIds.includes(data.trial_group_id)){
            trialGroupIds.push(data.trial_group_id);
          }
        })
      })

      const datasets = trialGroupIds.map((id, index) => ({
        label: `T${id}`,
        data: [],
        // backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
        backgroundColor: trialGroupColors[index] || '#000000', 
      }));

      this.feedConsumedData.forEach( item => {
        feedConsumedDays.push(item.age_in_days_or_week/7);
          datasets.forEach(d => {
            const feedConsumedDataForTrial = item.data.find(feedData => `T${feedData.trial_group_id}` === d.label);
            if (feedConsumedDataForTrial) {
              d.data.push(parseFloat(feedConsumedDataForTrial.total_feed_consumed_per_bird));
            } else {
              d.data.push('');
            }
          });
      });

      // Initialize Chart
      this.feedConsumptionChart = new Chart(this.$refs.feedChart, {
        type: 'bar',
        data: {
          labels: feedConsumedDays,
          datasets: datasets,
        },
        options: {
          plugins: {
            datalabels: {
              anchor: 'end',
              align: 'top',
              color: '#000',
              font: {
                size: 11,
                // weight: 'bold',
              },
              rotation: 270,
              offset: 0,
            }
          },
          responsive: true,
          scales: {
            x: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'WEEK',  // Y-axis title
                font: {
                  size: 14,
                  weight: 'bold',
                },
                color: '#333'  // Title color
              },
            },
            y: {
              beginAtZero: true,
              min: 0,
              max: 5,
              title: {
                display: true,
                text: 'Feed in KG',  // Y-axis title
                font: {
                  size: 14,
                  weight: 'bold',
                },
                color: '#333'  // Title color
              },
            }
          },
        },
        plugins: [ChartDataLabels],
      });
    },

    chartBodyWeight(){
      // Destroy the existing chart
      if(this.bodyWeightChart){
        this.bodyWeightChart.destroy();
      }
      const bodyWeightDays = [];
      const trialGroupIds = [];

      const trialGroupColors = [
        '#440154', // T1
        '#472D7B', // T2
        '#3B528B', // T3
        '#2C728E', // T4
        '#21908C', // T5
        '#27AD81', // T6
      ];

      this.bodyWeightData.forEach( (item, index) => {
        if(item.age_in_days_or_week != 1){
          item.data.forEach( data => {
            if(!trialGroupIds.includes(data.trial_group_id)){
              trialGroupIds.push(data.trial_group_id);
            }
          })
        }
      })

      const datasets = trialGroupIds.map((id, index) => ({
        label: `T${id}`,
        data: [],
        // backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
        backgroundColor: trialGroupColors[index] || '#000000', 
      }));

      this.bodyWeightData.forEach( item => {
        if (item.age_in_days_or_week != 1) {
          bodyWeightDays.push(item.age_in_days_or_week/7);
          datasets.forEach(d => {
            const bodyWtDataForTrial = item.data.find(bodyWtData => `T${bodyWtData.trial_group_id}` === d.label);
            if (bodyWtDataForTrial) {
              d.data.push(parseFloat(bodyWtDataForTrial.total_avg_body_wt_bird));
            } else {
              d.data.push('');
            }
          });
        }
      });

      // Initialize Chart
      this.bodyWeightChart = new Chart(this.$refs.bodyWtChart, {
        type: 'bar',
        data: {
          labels: bodyWeightDays,
          datasets: datasets,
        },
        options: {
          plugins: {
            datalabels: {
              anchor: 'end',
              align: 'top',
              color: '#000',
              font: {
                size: 11,
                // weight: 'bold',
              },
              rotation: 270,
              offset: 0,
            }
          },
          responsive: true,
          scales: {
            x: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'WEEK',  // Y-axis title
                font: {
                  size: 14,
                  weight: 'bold',
                },
                color: '#333'  // Title color
              },
            },
            y: {
              beginAtZero: true,
              min: 0,
              max: 5,
              title: {
                display: true,
                text: 'Body wt in KG',  // Y-axis title
                font: {
                  size: 14,
                  weight: 'bold',
                },
                color: '#333'  // Title color
              },
            }
          },
        },
        plugins: [ChartDataLabels],
      });
    }
  }
};
</script>
