<template>
  <div>
    <!-- <div v-if="chck">
      <md-card>
        <md-card-content>
          <md-card-content>
            <div
              class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
            >
              <md-card>
                <md-card-header data-background-color="green">
                  <h4 class="title">Body Weight Details in Grams</h4>
                </md-card-header>
                <md-card-content>
                  <md-table
                    v-model="BodyWeightx"
                    :table-header-color="tableHeaderColor"
                  >
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                      <md-table-cell md-label="Age in Days">{{
                        item.AgeinDays
                      }}</md-table-cell>
                      <md-table-cell md-label="T1 Group">{{
                        item.T1
                      }}</md-table-cell>
                      <md-table-cell md-label="T2 Group">{{
                        item.T2
                      }}</md-table-cell>
                      <md-table-cell md-label="T3 Group">{{
                        item.T3
                      }}</md-table-cell>
                      <md-table-cell md-label="T4 Group">{{
                        item.T4
                      }}</md-table-cell>
                      <md-table-cell md-label="T5 Group">{{
                        item.T5
                      }}</md-table-cell>
                    </md-table-row>
                  </md-table>
                </md-card-content>
              </md-card>
            </div>
          </md-card-content>
        </md-card-content>
      </md-card>

      <md-card>
        <md-card-content>
          <md-card-content>
            <div
              class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
            >
              <md-card>
                <md-card-header data-background-color="green">
                  <h4 class="title">Feed Consumption Per Bird (in Kgs)</h4>
                </md-card-header>
                <md-card-content>
                  <md-table
                    v-model="BodyWeighty"
                    :table-header-color="tableHeaderColor"
                  >
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                      <md-table-cell md-label="Age in Days">{{
                        item.AgeinDays
                      }}</md-table-cell>
                      <md-table-cell md-label="T1 Group">{{
                        item.T1
                      }}</md-table-cell>
                      <md-table-cell md-label="T2 Group">{{
                        item.T2
                      }}</md-table-cell>
                      <md-table-cell md-label="T3 Group">{{
                        item.T3
                      }}</md-table-cell>
                      <md-table-cell md-label="T4 Group">{{
                        item.T4
                      }}</md-table-cell>
                      <md-table-cell md-label="T5 Group">{{
                        item.T5
                      }}</md-table-cell>

                    </md-table-row>
                  </md-table>
                </md-card-content>
              </md-card>
            </div>
          </md-card-content>
        </md-card-content>
      </md-card>
    </div>

    <div v-if="fcr">
      <md-card>
        <md-card-content>
          <md-card-content>
            <div
              class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
            >
              <md-card>
                <md-card-header data-background-color="green">
                  <h4 class="title">Day Wise FCR</h4>
                </md-card-header>
                <md-card-content>
                  <md-table
                    v-model="BodyWeighta"
                    :table-header-color="tableHeaderColor"
                  >
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                      <md-table-cell md-label="Age in Days">{{
                        item.AgeinDays
                      }}</md-table-cell>
                      <md-table-cell md-label="T1 Group">{{
                        item.T1
                      }}</md-table-cell>
                      <md-table-cell md-label="T2 Group">{{
                        item.T2
                      }}</md-table-cell>
                      <md-table-cell md-label="T3 Group">{{
                        item.T3
                      }}</md-table-cell>
                      <md-table-cell md-label="T4 Group">{{
                        item.T4
                      }}</md-table-cell>
                      <md-table-cell md-label="T5 Group">{{
                        item.T5
                      }}</md-table-cell>

                    </md-table-row>
                  </md-table>
                </md-card-content>
              </md-card>
            </div>
          </md-card-content>
        </md-card-content>
      </md-card>
    </div>

    <div v-if="day35">
      <md-card>
        <md-card-content>
          <md-card-content>
            <div
              class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
            >
              <md-card>
                <md-card-header data-background-color="green">
                  <h4 class="title">Day 35 Result Summary</h4>
                </md-card-header>
                <md-card-content>
                  <md-table
                    v-model="Day35Result"
                    :table-header-color="tableHeaderColor"
                  >
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                      <md-table-cell md-label="Parameter">
                        <md-field>
                          <md-input
                            v-model="item.AgeinDays"
                            readonly
                          ></md-input>
                        </md-field>
                      </md-table-cell>
                      <md-table-cell md-label="T1 Group">
                        <md-field>
                          <md-input v-model="item.T1" readonly></md-input>
                        </md-field>
                      </md-table-cell>
                      <md-table-cell md-label="T2 Group">
                        <md-field>
                          <md-input v-model="item.T2" readonly></md-input>
                        </md-field>
                      </md-table-cell>
                      <md-table-cell md-label="T3 Group">
                        <md-field>
                          <md-input v-model="item.T3" readonly></md-input>
                        </md-field>
                      </md-table-cell>
                      <md-table-cell md-label="T4 Group">
                        <md-field>
                          <md-input v-model="item.T4" readonly></md-input>
                        </md-field>
                      </md-table-cell>
                      <md-table-cell md-label="T5 Group">
                        <md-field>
                          <md-input v-model="item.T5" readonly></md-input>
                        </md-field>
                      </md-table-cell>

                    </md-table-row>
                  </md-table>
                </md-card-content>
              </md-card>
            </div>
          </md-card-content>
        </md-card-content>
      </md-card>
    </div> -->

    <div v-if="!chck && !fcr && !day35">
      <div>
        <div class="row align-items-center mb-3">
          <div class="col-auto">
            <ul class="nav nav-pills">
              <li class="nav-item">
                <a
                  href="#"
                  :class="['nav-link', { active: isActive('BodyWeight') }]"
                  @click.prevent="setActiveTab('BodyWeight')"
                >
                  Body weight Per Bird (in Kgs)
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="#"
                  :class="['nav-link', { active: isActive('FeedConsumption') }]"
                  @click.prevent="setActiveTab('FeedConsumption')"
                >
                  Feed Consumption Per Bird (in Kgs)
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="#"
                  :class="['nav-link', { active: isActive('summary') }]"
                  @click.prevent="setActiveTab('summary')"
                >
                  Summary
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="#"
                  :class="['nav-link', { active: isActive('weekwise') }]"
                  @click.prevent="setActiveTab('weekwise')"
                >
                 Penwise weekly Data
                </a>
              </li>
              <!-- <li class="nav-item">
                <a
                  href="#"
                  :class="['nav-link', { active: isActive('FCR') }]"
                  @click.prevent="setActiveTab('FCR')"
                >
                  FCR Day/Day 35 Summary
                </a>
              </li> -->
            </ul>
          </div>
        </div>
      </div>

      <div v-if="activeTab === 'BodyWeight'">
      <hr />
        <BodyWeightPerBird  @update:selectedTrial="handleSelectedTrial"/>
      </div>
    </div>

    <div v-if="activeTab === 'FeedConsumption'">
      <hr />
      <FeedConsumptionPerBird/>
    </div>

    <div v-if="activeTab === 'summary'">
      <FcrSummary />
    </div>

    <div v-if="activeTab === 'weekwise'">
      <PenwiseWeeklyData />
    </div>

    <!-- <div v-if="activeTab === 'FCR'">
      
    </div> -->
    <div></div>
  </div>
</template>
<script>
import BodyWeightPerBird from '../components/BodyWeightPerBird.vue';
import FeedConsumptionPerBird from '../components/FeedConsumptionPerBird.vue';
import FcrSummary from '../components/FcrSummary.vue';
import PenwiseWeeklyData from '../components/PenwiseWeeklyData.vue';
let id1 = 0;

let id2a = 0;
let id2b = 0;

export default {
  components:{
    BodyWeightPerBird,
    FeedConsumptionPerBird,
    FcrSummary,
    PenwiseWeeklyData
  },
  data() {
    return {
      // userLoginData: [],
      selectedTrialFromParent:null,
      trials: [],
      selectedTrial: null,
      trialGroupList: [],
      selectedTrialGroup: null,
      penList: [],
      chck: false,
      fcr: false,
      day35: false,
      startDate: "",
      currentDate: "",
      temp: "",
      rh: "",

      id1: 0,
      id2: 0,
      k: 0,
      j: 0,
      bwView: [],
      fcView: [],
      bwView2: [],
      fcView2: [],
      activeTabMain: "view",
      activeTab: "BodyWeight",
      activeTaba: "a",
      temp2: [],
      addRow1: [
        {
          id: 0,
          week: "Week",
          isEditable: false,
          mainData: {
            first1: {
              1: "",
              2: "",
              3: "",
              4: "",
              5: "",
              6: "",
              7: "",
              8: "",
              9: "",
              10: "",
              11: "",
              12: "",
              13: "",
              14: "",
              15: "",
              16: "",
            },
            sec1: {
              1: "",
              2: "",
              3: "",
              4: "",
              5: "",
              6: "",
              7: "",
              8: "",
              9: "",
              10: "",
              11: "",
              12: "",
              13: "",
              14: "",
              15: "",
              16: "",
            },
            third1: {
              1: "",
              2: "",
              3: "",
              4: "",
              5: "",
              6: "",
              7: "",
              8: "",
              9: "",
              10: "",
              11: "",
              12: "",
              13: "",
              14: "",
              15: "",
              16: "",
            },
            forth1: {
              1: "",
              2: "",
              3: "",
              4: "",
              5: "",
              6: "",
              7: "",
              8: "",
              9: "",
              10: "",
              11: "",
              12: "",
              13: "",
              14: "",
              15: "",
              16: "",
            },
            fifth: {
              1: "",
              2: "",
              3: "",
              4: "",
              5: "",
              6: "",
              7: "",
              8: "",
              9: "",
              10: "",
              11: "",
              12: "",
              13: "",
              14: "",
              15: "",
              16: "",
            },
          },
        },
      ],

      addRow2: [
        {
          id: 0,
          week: "Week ",
          isEditable: false,
          mainData: {
            first1: {
              1: "",
              2: "",
              3: "",
              4: "",
              5: "",
              6: "",
              7: "",
              8: "",
              9: "",
              10: "",
              11: "",
              12: "",
            },
            sec1: {
              1: "",
              2: "",
              3: "",
              4: "",
              5: "",
              6: "",
              7: "",
              8: "",
              9: "",
              10: "",
              11: "",
              12: "",
            },
            third1: {
              1: "",
              2: "",
              3: "",
              4: "",
              5: "",
              6: "",
              7: "",
              8: "",
              9: "",
              10: "",
              11: "",
              12: "",
            },
            forth1: {
              1: "",
              2: "",
              3: "",
              4: "",
              5: "",
              6: "",
              7: "",
              8: "",
              9: "",
              10: "",
              11: "",
              12: "",
            },
            fifth: {
              1: "",
              2: "",
              3: "",
              4: "",
              5: "",
              6: "",
              7: "",
              8: "",
              9: "",
              10: "",
              11: "",
              12: "",
            },
          },
        },
      ],
      ageGroups: [
        { age: 1, t1: 43.53, t2: 42.93, t3: 43.26, t4: 43.33, t5: 43.13 },
        { age: 7, t1: 184.25, t2: 184.4, t3: 177.67, t4: 178, t5: 148.44 },
        { age: 14, t1: 504.33, t2: 499.96, t3: 483.98, t4: 483.23, t5: 360.22 },
        { age: 21, t1: 909, t2: 908, t3: 896, t4: 869, t5: 669 },
        { age: 28, t1: 1428, t2: 1398, t3: 1422, t4: 1396, t5: 1098 },
        { age: 35, t1: 2002, t2: 1957, t3: 1972, t4: 1987, t5: 1721 },
      ],
      feedConsumption: [
        { age: 7, t1: 156.34, t2: 156.34, t3: 156.34, t4: 156.34, t5: 156.34 },
        { age: 14, t1: 578.55, t2: 578.55, t3: 578.55, t4: 578.55, t5: 578.55 },
        { age: 21, t1: 1269, t2: 1269, t3: 1269, t4: 1269, t5: 1269 },
        { age: 28, t1: 2164, t2: 2164, t3: 2164, t4: 2164, t5: 2164 },
        { age: 35, t1: 2164, t2: 2164, t3: 2164, t4: 2164, t5: 2164 },
        { age: 35, t1: 3243, t2: 3243, t3: 3243, t4: 3243, t5: 3243 },
      ],
      BodyWeighta: [
        {
          AgeinDays: "7",
          T1: "43.53",
          T2: "42.93",
          T3: "43.26",
          T4: "43.33",
          T5: "43.13",
        },
        {
          AgeinDays: "14",
          T1: "43.53",
          T2: "42.93",
          T3: "43.26",
          T4: "43.33",
          T5: "43.13",
        },
        {
          AgeinDays: "21",
          T1: "43.53",
          T2: "42.93",
          T3: "43.26",
          T4: "43.33",
          T5: "43.13",
        },
        {
          AgeinDays: "28",
          T1: "43.53",
          T2: "42.93",
          T3: "43.26",
          T4: "43.33",
          T5: "43.13",
        },
        {
          AgeinDays: "35",
          T1: "43.53",
          T2: "42.93",
          T3: "43.26",
          T4: "43.33",
          T5: "43.13",
        },
      ],

      Day35Result: [
        {
          AgeinDays: "Bodyweight/Bird",
          T1: "43.53",
          T2: "42.93",
          T3: "43.26",
          T4: "43.33",
          T5: "43.13",
        },
        {
          AgeinDays: "Feed Consumed/Bird",
          T1: "43.53",
          T2: "42.93",
          T3: "43.26",
          T4: "43.33",
          T5: "43.13",
        },
        {
          AgeinDays: "FCR",
          T1: "43.53",
          T2: "42.93",
          T3: "43.26",
          T4: "43.33",
          T5: "43.13",
        },
        {
          AgeinDays: "CFCR",
          T1: "43.53",
          T2: "42.93",
          T3: "43.26",
          T4: "43.33",
          T5: "43.13",
        },
        {
          AgeinDays: "Livability",
          T1: "43.53",
          T2: "42.93",
          T3: "43.26",
          T4: "43.33",
          T5: "43.13",
        },
        {
          AgeinDays: "BEI",
          T1: "43.53",
          T2: "42.93",
          T3: "43.26",
          T4: "43.33",
          T5: "43.13",
        },
        {
          AgeinDays: "Feed Cost/Kg",
          T1: "43.53",
          T2: "42.93",
          T3: "43.26",
          T4: "43.33",
          T5: "43.13",
        },
        {
          AgeinDays: "FD Cost/K.g Live Bird wt",
          T1: "43.53",
          T2: "42.93",
          T3: "43.26",
          T4: "43.33",
          T5: "43.13",
        },
      ],
      BodyWeightx: [
        {
          AgeinDays: "7",
          T1: "43.53",
          T2: "42.93",
          T3: "43.26",
          T4: "43.33",
          T5: "43.13",
        },
        {
          AgeinDays: "14",
          T1: "184.53",
          T2: "182.93",
          T3: "177.26",
          T4: "178.33",
          T5: "148.13",
        },
        {
          AgeinDays: "21",
          T1: "504.53",
          T2: "499.93",
          T3: "488.26",
          T4: "483.33",
          T5: "360.13",
        },
        {
          AgeinDays: "28",
          T1: "909",
          T2: "908",
          T3: "896",
          T4: "869",
          T5: "669",
        },
        {
          AgeinDays: "35",
          T1: "2002",
          T2: "1957",
          T3: "43.26",
          T4: "43.33",
          T5: "43.13",
        },
      ],
      BodyWeighty: [
        {
          AgeinDays: "7",
          T1: "156.53",
          T2: "142.93",
          T3: "153.26",
          T4: "133.33",
          T5: "163.13",
        },
        {
          AgeinDays: "14",
          T1: "578.53",
          T2: "558.93",
          T3: "554.26",
          T4: "546.33",
          T5: "452.13",
        },
        {
          AgeinDays: "21",
          T1: "1269",
          T2: "1260",
          T3: "43.26",
          T4: "43.33",
          T5: "43.13",
        },
        {
          AgeinDays: "28",
          T1: "43.53",
          T2: "42.93",
          T3: "43.26",
          T4: "43.33",
          T5: "43.13",
        },
        {
          AgeinDays: "35",
          T1: "43.53",
          T2: "42.93",
          T3: "43.26",
          T4: "43.33",
          T5: "43.13",
        },
      ],
      tempBodyWeight: [
        {
          AgeinDays: "7",
          T1: "43.53",
          T2: "42.93",
          T3: "43.26",
          T4: "43.33",
          T5: "43.13",
        },
        {
          AgeinDays: "14",
          T1: "43.53",
          T2: "42.93",
          T3: "43.26",
          T4: "43.33",
          T5: "43.13",
        },
        {
          AgeinDays: "21",
          T1: "43.53",
          T2: "42.93",
          T3: "43.26",
          T4: "43.33",
          T5: "43.13",
        },
        {
          AgeinDays: "28",
          T1: "43.53",
          T2: "42.93",
          T3: "43.26",
          T4: "43.33",
          T5: "43.13",
        },
        {
          AgeinDays: "35",
          T1: "43.53",
          T2: "42.93",
          T3: "43.26",
          T4: "43.33",
          T5: "43.13",
        },
      ],
      BodyWeight: [
        {
          selectVal: "",
          trailGroup: "",
          penNo: "",
          crateWt: "",
          tareWt: "",
          totalWt: "",
          bodyWt: "",
          mortality: "",
          mortalBird: "",
          male: "",
          female: "",
          temp: "",
          humidity: "",
          vaccineMed: "",
          medicineGiven: "",
          isEditing: false,
          id: id1,
        },
      ],

      //     FeedConsumption: [
      //   { ageDays:'',feedTrailGroup:'',feedPenNo:'',noBirds:'',feedAlloted:'',feedConsumed:'',feedConsumedPerBird:'',isEditing: false, id: id2++}
      // ],
    };
  },
  created() {
  },
  methods: { 
    handleSelectedTrial(trial) {
      this.selectedTrialFromParent = trial;
    },
    isActive(tab) {
      return this.activeTab === tab;
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    isActiveMain(tab) {
      return this.activeTaba === tab;
    },
    setActiveTabMain(tab) {
      this.activeTaba = tab;
    },
    toggleEdit(item) {
      item.isEditing = !item.isEditing;
    },
    saveChanges(item) {
      item.isEditing = false;
      // Additional logic to save changes if needed
    },
    addin1() {
      this.id1++;
      this.addRow1.push({
        id: this.id1,
        isEditable: false,
        mainData: {
          first1: {
            1: "",
            2: "",
            3: "",
            4: "",
            5: "",
            6: "",
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
          },
          sec1: {
            1: "",
            2: "",
            3: "",
            4: "",
            5: "",
            6: "",
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
          },
          third1: {
            1: "",
            2: "",
            3: "",
            4: "",
            5: "",
            6: "",
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
          },
          forth1: {
            1: "",
            2: "",
            3: "",
            4: "",
            5: "",
            6: "",
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
          },
          fifth: {
            1: "",
            2: "",
            3: "",
            4: "",
            5: "",
            6: "",
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
            13: "",
            14: "",
            15: "",
            16: "",
          },
        },
      });
    },
    addin2() {
      this.id2++;
      this.addRow2.push({
        id: this.id2,
        isEditable: false,
        mainData: {
          first1: {
            1: "",
            2: "",
            3: "",
            4: "",
            5: "",
            6: "",
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
          },
          sec1: {
            1: "",
            2: "",
            3: "",
            4: "",
            5: "",
            6: "",
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
          },
          third1: {
            1: "",
            2: "",
            3: "",
            4: "",
            5: "",
            6: "",
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
          },
          forth1: {
            1: "",
            2: "",
            3: "",
            4: "",
            5: "",
            6: "",
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
          },
          fifth: {
            1: "",
            2: "",
            3: "",
            4: "",
            5: "",
            6: "",
            7: "",
            8: "",
            9: "",
            10: "",
            11: "",
            12: "",
          },
        },
      });
    },
    editRow1(index) {
      this.bwView2[index].isEditable = !this.bwView2[index].isEditable;
    },
    editRow2(index) {
      this.fcView2[index].isEditable = !this.fcView2[index].isEditable;
    },
    submt1() {
      this.bwView2.push(...this.addRow1);
      this.id1++;
      this.addRow1 = [
        {
          id: this.id1,
          isEditable: false,
          mainData: {
            first1: {
              1: "",
              2: "",
              3: "",
              4: "",
              5: "",
              6: "",
              7: "",
              8: "",
              9: "",
              10: "",
              11: "",
              12: "",
              13: "",
              14: "",
              15: "",
              16: "",
            },
            sec1: {
              1: "",
              2: "",
              3: "",
              4: "",
              5: "",
              6: "",
              7: "",
              8: "",
              9: "",
              10: "",
              11: "",
              12: "",
              13: "",
              14: "",
              15: "",
              16: "",
            },
            third1: {
              1: "",
              2: "",
              3: "",
              4: "",
              5: "",
              6: "",
              7: "",
              8: "",
              9: "",
              10: "",
              11: "",
              12: "",
              13: "",
              14: "",
              15: "",
              16: "",
            },
            forth1: {
              1: "",
              2: "",
              3: "",
              4: "",
              5: "",
              6: "",
              7: "",
              8: "",
              9: "",
              10: "",
              11: "",
              12: "",
              13: "",
              14: "",
              15: "",
              16: "",
            },
            fifth: {
              1: "",
              2: "",
              3: "",
              4: "",
              5: "",
              6: "",
              7: "",
              8: "",
              9: "",
              10: "",
              11: "",
              12: "",
              13: "",
              14: "",
              15: "",
              16: "",
            },
          },
        },
      ];
    },
    submt2() {
      this.fcView2.push(...this.addRow2);
      this.id2++;
      this.addRow2 = [
        {
          id: this.id2,
          isEditable: false,
          mainData: {
            first1: {
              1: "",
              2: "",
              3: "",
              4: "",
              5: "",
              6: "",
              7: "",
              8: "",
              9: "",
              10: "",
              11: "",
              12: "",
            },
            sec1: {
              1: "",
              2: "",
              3: "",
              4: "",
              5: "",
              6: "",
              7: "",
              8: "",
              9: "",
              10: "",
              11: "",
              12: "",
            },
            third1: {
              1: "",
              2: "",
              3: "",
              4: "",
              5: "",
              6: "",
              7: "",
              8: "",
              9: "",
              10: "",
              11: "",
              12: "",
            },
            forth1: {
              1: "",
              2: "",
              3: "",
              4: "",
              5: "",
              6: "",
              7: "",
              8: "",
              9: "",
              10: "",
              11: "",
              12: "",
            },
            fifth: {
              1: "",
              2: "",
              3: "",
              4: "",
              5: "",
              6: "",
              7: "",
              8: "",
              9: "",
              10: "",
              11: "",
              12: "",
            },
          },
        },
      ];
    },
    addRowBodyWeight() {
      this.BodyWeight.push({
        selectVal: "",
        trailGroup: "",
        penNo: "",
        crateWt: "",
        tareWt: "",
        totalWt: "",
        bodyWt: "",
        mortality: "",
        mortalBird: "",
        male: "",
        female: "",
        temp: "",
        humidity: "",
        vaccineMed: "",
        medicineGiven: "",
        isEditing: false,
        id: Date.now() + Math.random(),
      });
    },
    addRowFeedConsumption() {
      this.FeedConsumption.push({
        ageDays: "",
        feedTrailGroup: "",
        feedPenNo: "",
        noBirds: "",
        feedAlloted: "",
        feedConsumed: "",
        feedConsumedPerBird: "",
        isEditing: false,
        id: Date.now() + Math.random(),
      });
    },
    resetBw() {
      this.BodyWeight = [
        {
          selectVal: "",
          trailGroup: "",
          penNo: "",
          crateWt: "",
          tareWt: "",
          totalWt: "",
          bodyWt: "",
          mortality: "",
          mortalBird: "",
          male: "",
          female: "",
          temp: "",
          humidity: "",
          vaccineMed: "",
          medicineGiven: "",
          isEditing: false,
          id: id1,
        },
      ];
    },
    resetCf() {
      // this.FeedConsumption=[
      //     { ageDays:'',feedTrailGroup:'',feedPenNo:'',noBirds:'',feedAlloted:'',feedConsumed:'',feedConsumedPerBird:'',isEditing: false, id: id2++}
      //   ]
    },
    result() {
      this.chck = false;
      this.fcr = false;
      this.day35 = false;
    },
    resultx() {
      this.chck = true;
      this.fcr = false;
      this.day35 = false;
    },
    fcrx() {
      this.fcr = true;
      this.day35 = false;
      this.chck = false;
    },
    day35x() {
      this.day35 = true;
      this.fcr = false;
      this.chck = false;
    },

    clear() {
      this.selectVal = "";
      this.trailGroup = "";
      this.penNo = "";
    },
    displayBw() {
      this.bwView.push(...this.BodyWeight);
      this.BodyWeight = [
        {
          selectVal: "",
          trailGroup: "",
          penNo: "",
          crateWt: "",
          tareWt: "",
          totalWt: "",
          bodyWt: "",
          mortality: "",
          mortalBird: "",
          male: "",
          female: "",
          temp: "",
          humidity: "",
          vaccineMed: "",
          isEditing: false,
          id: 0,
        },
      ];
      this.clearBodyWeightInputs();
    },
    clearBodyWeightInputs() {
      this.BodyWeight = this.BodyWeight.map((item) => ({
        selectVal: "",
        trailGroup: "",
        penNo: "",
        crateWt: "",
        tareWt: "",
        totalWt: "",
        bodyWt: "",
        mortality: "",
        mortalBird: "",
        male: "",
        female: "",
        temp: "",
        humidity: "",
        vaccineMed: "",
        isEditing: false,
        id: Date.now() + Math.random(),
      }));
    },
    displayCf() {
      this.fcView.push(...this.FeedConsumption);
      this.FeedConsumption = [
        {
          ageDays: "",
          feedTrailGroup: "",
          feedPenNo: "",
          noBirds: "",
          feedAlloted: "",
          feedConsumed: "",
          feedConsumedPerBird: "",
          isEditing: false,
          id: 0,
        },
      ];
      this.clearFcInputs();
      // this.fcView=[];
      // this.fcView.push(...this.FeedConsumption);
    },
    clearFcInputs() {
      this.BodyWeight = this.BodyWeight.map((item) => ({
        ageDays: "",
        feedTrailGroup: "",
        feedPenNo: "",
        noBirds: "",
        feedAlloted: "",
        feedConsumed: "",
        feedConsumedPerBird: "",
        isEditing: false,
        id: Date.now() + Math.random(),
      }));
    },
    removeBodyWeightRow(row) {
      this.bwView = this.bwView.filter((r) => r.id !== row.id);
    },
    removeFeedRow(row) {
      this.FeedConsumption = this.FeedConsumption.filter((r) => r !== row);
    },
  },
  watch: {
    bwView2: {
      handler(newValue, oldValue) {
        // This function will run every time bwView changes
        // You can perform any additional actions or logic here
      },
      deep: true, // This is optional but useful if bwView is an array or object
    },
    fcView2: {
      handler(newValue, oldValue) {
        // This function will run every time bwView changes
        // You can perform any additional actions or logic here
      },
      deep: true, // This is optional but useful if bwView is an array or object
    },
  },
};
</script>

<style>
.table-container {
  display: table;
  width: 100%;
  border-collapse: collapse;
}
.custm-header,
.custm-row {
  display: table-row;
}
.t-cell {
  display: table-cell;
  border: 1px solid #000;
  /* padding: 8px; */
}
.t-cell[rowspan] {
  vertical-align: middle;
}

.table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-collapse: collapse;
}

.custm-header,
.custm-row {
  display: flex;
}

.t-cell {
  flex: 1;
  /* padding: 8px; */
  border: 1px solid #ddd;
  box-sizing: border-box;
  text-align: center;
}

.custm-header {
  background-color: #f5f5f5;
  font-weight: bold;
}

.select-style,
input[type="number"] {
  width: 100%; /* Adjust this to change the width */
  height: 40px; /* Adjust this to change the height */
  padding: 8px; /* Adjust this for inner padding */
  box-sizing: border-box;
  font-size: 16px; /* Adjust this for font size */
}

.custm-row {
  background-color: #fff;
}

.t-cell:last-child {
  border-right: none;
}

.select-style {
  width: 100%;
  padding: 4px;
  box-sizing: border-box;
}

input[type="number"] {
  width: 100%;
  padding: 4px;
  box-sizing: border-box;
}

.table-cell {
  /* flex: 1; */
  padding: 8px;
  border: 1px solid #ddd;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
}
.styled-table td {
  /* padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    width: auto; */

  /* padding: 12px 15px; */
  border: 1px solid #dddddd;
  /* width: 100%; */
  height: 40px;
  /* padding: 8px; */
  /* box-sizing: border-box; */
  font-size: 18px;
}
.styled-table th {
  font-size: 12px;
  background-color: #f2f2f2;
}
.styled-table .slct,
.styled-table .custm {
  width: 100%;
  box-sizing: border-box;
}

/* .table-cell input,
.table-cell .md-select,
.table-cell .md-field {
  width: 100%;
} */

.col-auto {
  flex: 0 0 auto;
  flex-direction: row;
  width: auto;
}
.card-box {
  background-color: #fff;
  padding: 10px;
  -webkit-box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  /* margin-bottom: 1px; */
  border-radius: 0.25rem;
}

.align-items-center {
  align-items: center !important;
  padding: 0px 65px 0px 20px;
}

.row {
  --ct-gutter-x: 1.5rem;
  --ct-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--ct-gutter-y));
  margin-right: calc(-0.5 * var(--ct-gutter-x));
  margin-left: calc(-0.5 * var (--ct-gutter-x));
}
.row-layout2 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 2%;
}

#buttons {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #71b6f9 !important;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.struct {
  display: grid;
  grid-template-columns: 105px 140px 58px 200px;
  grid-gap: 25px;
  /* grid-auto-rows: 50px;  */
  /*grid-auto-rows: 50px;  Set all rows to 50px height */
}

.sec-row {
  display: grid;
  grid-template-columns: 135px 800px 90px 100px;
  grid-gap: 25px;
  grid-auto-rows: 50px;
  margin-left: 20px;
  padding-bottom: 30px;
}

/* .md-field {
  margin: 0px 0px;
} */

.grid-item {
  height: 50px;
}

/* .test1, .test2, .test3, .test4, .test5 {
  flex: 1; /* Adjust the flex-grow as needed 
} */

/* Ensure that each test class has some default width */
.test1 {
  text-align: right;
  /* grid-column: 1 / 5; */
}
.test3 {
  text-align: right;
  /* grid-column: 3 / 5; */
}
/* .test4 {
    grid-column: 4 / 5;
}
.test5 {
    grid-column: 5 / 5;
} */

.testing {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-top: 15px;
  padding-left: 20px;
  justify-content: space-between;
  gap: 20px; /* Add gap between flex items */
}

hr {
  border: none;
  height: 2px;
  background-color: #000;
  /* margin-top: 5px; */
}

.custom-md-field {
  /* Adjust the overall size of the md-field if needed */
  width: 190px; /* Adjust as needed */
}

.custom-md-input {
  /* Adjust the size of the input */
  width: 50px; /* Change the width as needed */
  /* height: 30px; Change the height as needed */
  /*padding: 5px;  Adjust padding as needed */
  font-size: 14px; /* Adjust font size as needed */
}
.row {
  --ct-gutter-x: 1.5rem;
  --ct-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--ct-gutter-y));
  margin-right: calc(-0.5 * var(--ct-gutter-x));
  margin-left: calc(-0.5 * var (--ct-gutter-x));
}

.tab-content {
  padding: 20px 0 0 0;
  border: none;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #71b6f9 !important;
}

.align-items-center {
  align-items: center !important;
  padding: 0px 65px 0px 20px;
}

/* .mb-3 {
    margin-bottom: 1.5rem !important;
  } */

.col {
  flex: 1 0 0%;
}

.mt-0 {
  margin-top: 0 !important;
}

.header-title {
  font-size: 1rem;
  margin: 0 0 7px 0;
}

.card-box {
  background-color: #fff;
  padding: 10px;
  -webkit-box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  /* margin-bottom: 1px; */
  border-radius: 0.25rem;
}

.table-container {
  width: 100%;
  overflow-x: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.table-header,
.table-row {
  display: flex;
  width: 2200px; /* Adjust this value based on the number of columns */
}

.table-cell {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  box-sizing: border-box;
}

.table-header {
  background-color: #f5f5f5;
  font-weight: bold;
}

.table-cell select,
.table-cell input {
  width: 100%;
  padding: 4px;
  box-sizing: border-box;
}

.table-cell .slct {
  width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  flex-direction: row;
  width: auto;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  /* margin: 25px 0; */
  font-size: 15px;
  font-weight: 500;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  /* background-color: #009879; */
  /* color: #ffffff; */
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
  border: 1px solid #dddddd;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.styled-table tbody tr:hover {
  background-color: #f1f1f1;
}

.styled-table td button {
  background: none;
  border: none;
  cursor: pointer;
}

.styled-table td button:focus {
  outline: none;
}

.slct2 {
  height: 40px;
  width: 130px;
}
/* .form-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
} */

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

/* input[type="text"],
input[type="password"],
input[type="email"],
select {
   width: 100%; 
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
} */

.input-group {
  display: flex;
}

/* .input-group input {
  flex: 1;
} */

/* .input-group button {
  margin-left: 10px;
  padding: 8px 12px;
  border: 1px solid #007bff;
  border-radius: 4px;
  background-color: #fff;
  color: #007bff;
  cursor: pointer;
} */

/* .input-group button:hover {
  background-color: #007bff;
  color: #fff;
} */

button[type="submit"],
button[type="button"].cancel-button {
  /* padding: 10px 20px; */
  /* border: none;
  border-radius: 4px; */
  cursor: pointer;
}

/* button[type="submit"].save-button {
  background-color: #28a745;
  color: #fff;
} */

/* button[type="button"].cancel-button {
  background-color: #ccc;
  color: #333;
  margin-left: 10px;
} */

#buttons {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
}

/* button[type="button"].cancel-button:hover {
  background-color: #b3b3b3;
} */

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: rgb(113, 182, 249);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.md-list-item .md-list-item-container .md-ripple > span {
  border-radius: 3px;
  display: contents;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

.create-field {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.all-buttons {
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
}

.table-layout {
  display: flex;
  flex-direction: column;
}

.form-style {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.row-layout {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row-layout1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.row-layout2 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 10%;
}

.checkbox-select {
  width: 100%;
  height: auto;
  border-radius: 1rem;
}
.checkbox-select__trigger {
  justify-content: space-between;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background: #fff;
  position: relative;
  z-index: 100;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
}
.checkbox-select__trigger.isActive {
  border-radius: 10px 10px 0 0;
  background: #f2f2f2;
}
.checkbox-select__trigger:hover {
  background: #f4f4f4;
}
.checkbox-select__dropdown {
  display: none;
  background: #fff;
  box-shadow: 0 12px 15px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  /* padding-bottom: 25px; */
}
.checkbox-select__dropdown {
  display: block; /* Ensure it gets shown */
}
.checkbox-select__search-wrapp {
  padding: 10px 25px 5px;
}
.checkbox-select__search-wrapp input {
  width: 100%;
  height: 40px;
  border-width: 0 0 2px;
  border-style: solid;
  border-color: #000;
  font-size: 16px;
  background: transparent;
}
.checkbox-select__col {
  display: flex;
  font-size: 12px;
  padding: 0 25px;
  justify-content: space-between;
  text-transform: uppercase;
}
.checkbox-select__select-all label {
  cursor: pointer;
}
.checkbox-select__filters-wrapp {
  /* margin-top: 20px; */
  height: 157px;
  overflow-y: auto;
  padding-left: 0px;
}
.checkbox-select__check-wrapp {
  position: relative;
  padding: 0 25px;
  margin-bottom: 5px;
}
.checkbox-select__check-wrapp input[type="checkbox"] {
  display: none;
}
.checkbox-select__check-wrapp input[type="checkbox"] + label {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  line-height: 22px;
  padding-left: 30px;
  display: inline-block;
  user-select: none;
  transition: padding 0.25s ease;
}
.checkbox-select__check-wrapp input[type="checkbox"] + label:after {
  border: solid 2px #000;
  content: "";
  width: 22px;
  height: 22px;
  top: 0;
  left: 0;
  position: absolute;
}
.checkbox-select__check-wrapp input[type="checkbox"] + label:before {
  width: 14px;
  height: 14px;
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  background-color: #000;
  opacity: 0;
  transform: scale(0.5);
  transition: all 0.2s ease;
}
.checkbox-select__check-wrapp input[type="checkbox"]:checked + label:before {
  opacity: 1;
  transform: scale(1);
}
.col-md-3 {
  flex: 0 0 auto;
  /* width: 25%; */
}

.temp-th {
  padding: 0px 0px;
}

#myTable {
  /* width: 100%; */
  border-collapse: collapse;
  table-layout: fixed;
}

#myTable td {
  /* padding: 10px; */
  text-align: center;
  border: 1px solid #ddd;

  height: 40px;
}

#myTable th {
  border: 1px solid #ddd;
  padding: 10px;
  background-color: #f5f5f5;
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.form-container {
  display: grid;
  grid-template-columns: repeat(3, 30%);
  /* flex-wrap: wrap; */

  grid-gap: 10px;
  /* max-width: 600px; */
  margin: 0% 2%;
}

.form-groupx {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 100px;
}

.form-groupx label {
  margin-bottom: 5px;
}

.form-groupx input,
select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.styled-tablex {
  width: 100%;
  border-collapse: collapse;
}
.styled-tablex th,
.styled-tablex td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  /* width: auto; */
}
.styled-tablex th {
  background-color: #f5f5f5;
  font-size: 16px;
}
.styled-tablex .slct,
.styled-tablex .custm {
  width: 100%;
  box-sizing: border-box;
}
</style>