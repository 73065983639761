<template>
  <div class="card-content">
    <div class="md-layout-item md-medium-size-50 md-size-4">
      <form>
        <md-card style="max-width: 480px; height: 495px">
          <md-card-content>
            <!-- <div style="height:6rem"></div> -->
            <form @submit.prevent="submit">
            <div class="card-content">
              
              <div class="logo-container">
                <img class="img-width" src="@/assets/img/chicken_1.png" alt="Logo" />
              </div>

              <h4 class="title font-weight-bold text-muted">INDOUS RESEARCH</h4>
              <h4 class="title">LOGIN</h4>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-90">
                <md-field>
                  <label>User Name</label>
                  <md-input required v-model="userName" type="text"></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-90">
                <md-field :md-toggle-password="false">
                  <label>Password</label>
                  <md-input
                  required
                    v-model="password"
                    id="password"
                    :type="ShowPassword ? 'text' : 'password'"
                  ></md-input>
                </md-field>
                <div v-if="errorMsg">
              <p class="text-danger">{{errorMsg}}</p>
             </div>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-90">
                <div class="md-layout-item md-sm small-size">
                  <md-checkbox v-model="ShowPassword" value="1"
                    >Show Password</md-checkbox
                  >
                </div>
                <div class="md-layout-item md-sm small-size">
                  <md-checkbox v-model="RememberMe" value="1"
                    >Remember Me</md-checkbox
                  >
                </div>
              </div>
            </div>
            <md-button
            :disabled="requestLoad"
              type="submit"
              class="md-primary md-block"
              >{{requestLoad ? 'Login...':'Login'}}</md-button
            >
            </form>
          </md-card-content>
        </md-card>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ShowPassword: false,
      RememberMe: false,
      userName: "",
      password: "",
      errorMsg:"",
      requestLoad:false
    };
  },
mounted(){
  // console.log('toke',this.$store.getters.getToken);
},
watch:{
  userName:function(){
    this.errorMsg = ""
  },
  password:function(){
    this.errorMsg = ""
  }
},
  methods: {
    submit(){
      this.requestLoad = true;
      axios.post('/login',{
        user_name:this.userName,
        password:this.password
        }).then((response) => {
          this.requestLoad = false
          this.$store.commit('setToken',response.data.token)
          this.$store.commit('setUser',response.data.user)
          this.$store.commit('setSubMenus',response.data.subMenus)
          this.$store.commit('setActiveSideBar',response.data.subMenus[0])
          const path = `${response.data.subMenus[0].slug}`;

          this.$router.push('/dashboard');

          
        }).catch((err) => {
          this.requestLoad = false;
          // console.error(err);
          if(err?.response) this.errorMsg = err.response.data.error
           
        });
    },
  },
};
</script>
<style scoped>
.small-size {
  font-size: 12px; /* Adjust this value as needed */
}

.logo-container {
  /* border-radius: 50%; */
  overflow: hidden;
  width: 67px; /* Adjust size as needed */
  height: 67px; /* Adjust size as needed */
  margin-bottom: 05px; /* Space between image and text */
}

.logo-container img {
  width: 53px;
  height: 66px;
  object-fit: cover;
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4%;
}

.card-footer {
  display: flex;
  flex-direction: column;
  align-items: left;
}

label {
  display: block;
  margin-bottom: 5px;
}
</style>
