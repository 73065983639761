<template>
    <div>
        <div class="sticky-top bg-white border-top border-bottom ml-3 mr-3">
                    <div class="ml-4 mr-4">
                        <div class="d-flex">
                            <div class="ml-4"></div><div class="ml-5"></div><div class="ml-5"></div>
                            <div v-if="!this.preview" class="col-md-1">
                                <md-button class="md-success" @click="previewToggle()">
                                    <md-icon>remove_red_eye</md-icon> Preview
                                </md-button>
                            </div>

                            <div v-if="this.preview" class="ml-5">
                                <md-button class="md-info" @click="BackFn">
                                Back
                                </md-button>
                            </div>

                            <div v-if="this.preview" class="pl-4">
                                <!-- <md-button class="md-success" @click="exportToPDF">
                                    <md-icon>download</md-icon> Download PDF
                                </md-button> -->
                                <md-button @click="exportToPDF"  type="button" class="md-success" :disabled="download"><md-icon>download</md-icon>{{download ? 'Downloading...':'Download PDF'}}
                                </md-button>
                            </div>
                        </div>
                    </div>
        </div>
    <div class="row align-items-center mb-3">
        <div class="col-md-4 d-flex ">
          <!-- <md-field class="custom-md-field">
            <label for="movie">Select Trial No</label>
            <md-select
              v-model="movie"
              name="movie"
              id="movie"
              class="custom-md-input"
            >
              <md-option value="T10/10/22">T10/10/22</md-option>
              <md-option value="T11/10/22">T11/10/22</md-option>
              <md-option value="T12/10/22">T12/10/22</md-option>
            </md-select>
          </md-field> -->
          <!-- <label for="movie" class="mt-2 font-weight-bold"> Trial No:    </label>     
             <select name="" class="form-control width-10 ml-2 pl-2" id="">
              <option disabled>Select</option>
            <option value="T10/10/22">T10/10/22</option>
            <option value="T11/10/22">T11/10/22</option>
            <option value="T12/10/22">T12/10/22</option>
          </select> -->
        </div>

          <div class="col-md-2">
            <!-- <md-button class="greyblue-button">Generate Report</md-button> -->
            <!-- <button type="button" class="btn btn-sm btn-info">
              <md-icon class="text-white">list</md-icon> Generate Report
            </button> -->
          </div>
  
          <div class="col-md-2">
            <!-- <md-button class="bg-info">
              <md-icon>download</md-icon> Export Data
            </md-button> -->
            <!-- <button type="button" class="btn btn-sm btn-primary">
              <md-icon class="text-white">download</md-icon>Export Data
            </button> -->
          </div>
  
          <div class="col-md-2">

            <!-- <button type="button" class="btn btn-sm btn-success">
              <md-icon class="text-white">download</md-icon> Download PDF
            </button> -->
          </div>
        <div class="col-md-2 d-flex">
            <!-- <md-field class="custom-md-field">
          <label>Search...</label>
          <md-input v-model="initial"></md-input>
        </md-field>
        <md-icon>search</md-icon> -->
        </div>
        <!-- 
                <md-field class="custom-md-field" style="margin:0px 0px 0px 0px">
                    <label>Search...</label>
                    <md-input v-model="initial"></md-input>
                </md-field>
                <md-icon>search</md-icon> -->
      </div>
      <div class="d-flex justify-content-center" id="theme">
          <div style="width:69%">
              <md-card>
                  <md-card-content>
                    <div ref="pdfContent" class="content-to-export">
                        <div class="pr-5 pl-5 pt-2">
                            <div class="border border-dark pl-3 pr-3">
                                <div class="d-flex justify-content-around">
                                    <div class="col-2"> <!-- 3 out of 12 column width -->
                                    </div>
                                    <div class="col-5">
                                        <div><h3 class="mt-1"><u>INDOUS RESEARCH</u></h3></div>
                                    </div>
                                    <div class="col-4">
                                        <h5 class="mt-2 mb-0 pt-1"><b>{{ this.trialNo }}</b></h5>
                                        <!-- <div class="pt-2" v-if="!this.preview">
                                            <div>
                                                <select required v-model="trialNo" id="country" md-dense>
                                                    <option v-for="trial in trialNumbers" :key="trial.id" :value="trial.trial_no">
                                                                        {{ trial.trial_no }}
                                                    </option>
                                                </select> -->
                                                <!-- <input v-if="!this.preview" v-model="trialNumbers[0].trial_no" readonly style="padding:9px;font-size: medium;font-weight: 600;width:15%" />
                                            </div>
                                        </div> -->
                                    </div>
                                        <!-- <div><h3><u>INDOUS RESEARCH</u></h3></div> -->
                                        <!-- <div><h4 class="mt-4 mb-0"><b>{{ reportNumber }}</b></h4></div> -->
                                        
                                </div>
                            <div class="certificate-container">
                        
                            <div class="content">
                                <p
                                    :contenteditable="contentEdit" 
                                    :class="{ 'editing': currentEditing === 1 }" 
                                    @focus="onEdit(1)" 
                                    @blur="onBlur"
                                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Indous Research</strong> is a unit of <strong>Indous Holdings</strong>, which is an offshoot subsidiary of <strong>Bharath Agrovet Industries</strong>.</p>
                        
                                <p
                                    :contenteditable="contentEdit" 
                                    :class="{ 'editing': currentEditing === 2 }" 
                                    @focus="onEdit(2)" 
                                    @blur="onBlur"
                                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Bharath Agrovet Industries</strong> is into vertical integration of Broilers, which includes Breeders, Hatcheries, Feed Mills, Processing Plant and Retail Outlets. It has a well-equipped Wet Chemistry Laboratory with NIR facility and Premix Plant.</p>
                        
                                <p
                                    :contenteditable="contentEdit" 
                                    :class="{ 'editing': currentEditing === 3 }" 
                                    @focus="onEdit(3)" 
                                    @blur="onBlur"
                                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Indous Research</strong>, at present, is focusing on Broiler Nutrition Trials having the facility to conduct Trials on 5 Experimental Groups, with each Trial Group having 5 Replicas of 30 chicks each. In total, Trials will be conducted on 750 chicks. <strong>Indous Research</strong> has its own Premix Plant and a regular Mash Feed Plant.</p>

                                <!-- <div class="container">
                                <div class="row">
                                    <div class="col-sm">
                                        <strong>Principal Investigator:</strong>
                                    </div>
                                    <div class="col-sm">
                                        <p>Dr Arun Kumar Rai, B.V.Sc, PGDHRM</p>
                                        <p>Managing Partner</p>
                                        <p>Bharath Agrovet Industries</p>
                                        <p>Mahaveera Circle, Pumpwell, Mangalore - 575002</p>
                                    </div>
                                </div>
                                </div>

                                <div class="container">
                                    <div class="row">
                                        <div class="col-12 col-md-8"><strong>Principal Investigator:</strong></div>
                                            <div class="col-6 col-md-4">
                                                <p>Dr Arun Kumar Rai, B.V.Sc, PGDHRM</p>
                                                <p>Managing Partner</p>
                                                <p>Bharath Agrovet Industries</p>
                                                <p>Mahaveera Circle, Pumpwell, Mangalore - 575002</p>
                                            </div>
                                    </div>
                                </div> -->

                                <div class="d-flex">
                                    <div class="pt-2 pr-4">
                                        <strong
                                        :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 12 }" 
                                        @focus="onEdit(12)" 
                                        @blur="onBlur"
                                        >Principal Investigator:- </strong>
                                    </div>
                                    <div class="text-left pl-4">
                                        <strong>
                                            <p
                                                :contenteditable="contentEdit" 
                                                :class="{ 'editing': currentEditing === 4 }" 
                                                @focus="onEdit(4)" 
                                                @blur="onBlur"
                                            >
                                                Name
                                            </p>
                                        </strong>
                                        <!-- <p><strong>Dr Arun Kumar Rai, B.V.Sc, PGDHRM</strong></p> -->
                                        <p
                                            :contenteditable="contentEdit" 
                                            :class="{ 'editing': currentEditing === 5 }" 
                                            @focus="onEdit(5)" 
                                            @blur="onBlur"
                                        >
                                            Address
                                        </p>
                                    </div>
                                </div>
                                <br>
                                <div class="d-flex">
                                    <div class="pt-2 pr-3">
                                        <strong
                                        :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 14 }" 
                                        @focus="onEdit(14)" 
                                        @blur="onBlur"
                                        >Co Principal Investigators:- </strong>
                                    </div>
                                    <div class="text-left">
                                        <strong>
                                            <p
                                                :contenteditable="contentEdit" 
                                                :class="{ 'editing': currentEditing === 6 }" 
                                                @focus="onEdit(6)" 
                                                @blur="onBlur"
                                            >
                                                Name and Address
                                            </p>
                                        </strong>
                                        <!-- <ul class="pl-3"> -->
                                            <!-- <li> -->
                                                <!-- <p><strong>1) Dr Sowmya Bharath, M.V.Sc (Path), DABT, DIBTP</strong></p>
                                                    <div class="ml-3">
                                                        <p><strong>(American board certified Toxicologist and </strong></p>
                                                        <p><strong>Indian Board certified Toxico Pathologist)</strong></p>
                                                        <p>No 1167, 6th Block,</p> 
                            +                            <p>HMT layout, Vidyaranyapura</p>
                                                        <p>Bengaluru - 560097</p>
                                                    </div>
                                                <br>
                                        
                                                <p><strong>2) A Venkatesh Prasad, B.Sc (AGRI), PGDMM</strong></p>
                                                    <div class="ml-3">
                                                        <p>CEO, Indous Holdings</p>
                                                        <p>Pumpwell, Mangalore</p>
                                                    </div>
                                                <br>
                                        
                                                <strong><p>3) Dr Ashnni Rai, B.A.M.S</p>
                                                    <div class="ml-3">
                                                        <p>Consultant, Ayurvedic Principles</p>
                                                        <p>2-95/3, Avhni, Airport Road</p>
                                                        <p>Near Guru Mandira, Gurunagara, Maryhill, Konchady</p>
                                                        <p>Mangalore</p>
                                                    </div>
                                                </strong> -->
                                            <!-- </li>
                                        </ul> -->
                                    </div>
                                </div>
                                <br>
                                <div class="d-flex">
                                    <div class="pt-2 pr-2">
                                        <strong>Sponsoring Agency:- </strong>
                                    </div>
                                    <div class="text-left pl-5">
                                        <p
                                                :contenteditable="contentEdit" 
                                                :class="{ 'editing': currentEditing === 7 }" 
                                                @focus="onEdit(7)" 
                                                @blur="onBlur"
                                        ><strong>Bharath Agrovet Industries</strong></p>
                                        <p
                                                :contenteditable="contentEdit" 
                                                :class="{ 'editing': currentEditing === 8 }" 
                                                @focus="onEdit(8)" 
                                                @blur="onBlur"
                                        >Mahaveera Circle</p>
                                        <p
                                                :contenteditable="contentEdit" 
                                                :class="{ 'editing': currentEditing === 9 }" 
                                                @focus="onEdit(9)" 
                                                @blur="onBlur"
                                        >Pumpwell</p>
                                        <p
                                                :contenteditable="contentEdit" 
                                                :class="{ 'editing': currentEditing === 10 }" 
                                                @focus="onEdit(10)" 
                                                @blur="onBlur"
                                        >Mangalore - 575002</p>
                                    </div>
                                </div>
                            </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </md-card-content>
            </md-card>
        </div>
    </div>
</div>
  </template>
  
  <script>
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
  export default {
    data() {
      return {
        subtitle: "T10/10/22",
        reportNumber: "T10/10/22",
        preview: false,
        trialNo: '',
        load: true,
        currentEditing: null,
        contentEdit:true,
        download:false
      };
    },

    mounted(){
        this.trialNo = this.$store.getters.getSelectedTrial.trial_no;
        // this.getTrialNo();
        this.$material.locale.dateFormat = "dd-MM-yyyy";
    },

    methods:{
        onEdit(val){
            this.currentEditing=val;
        },

        onBlur(){
            this.currentEditing=null;
        },

        previewToggle(){
            this.preview=true;
            this.contentEdit=false;
        },

        getTrialNo(){
            axios.get('/indous-research/experiment-information/get-trial-no-list').then(response=>{
                const temp = response.data.trialNoList[0].trial_no;
                this.trialNo = "T" + temp.slice(5);
            })
        },

        BackFn(){
            this.preview=false;
            this.contentEdit=true;
        },

        exportToPDF() {
            this.download=true;
            const pdfContent = this.$refs.pdfContent;
            html2canvas(pdfContent, { scale: 2 }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save('Research_Team.pdf');
                this.download=false;
            });
        },
    }
  };
  </script>
  
  <style scoped>
  .certificate-container {
    font-family: Arial, sans-serif;
    /* padding: 20px; */
  }
  
  .header {
    text-align: center;
    /* margin-bottom: 20px; */
  }
  
  /* .header h1 {
    font-size: 36px;
    font-weight: bold; 
     margin-bottom: 10px;
  } */
  
  .header h2 {
    font-size: 18px;
    font-weight: normal;
    /* margin-bottom: 20px; */
  }
  
  .report-details {
    text-align: right;
    margin-bottom: 20px;
  }
  
  .content p {
    margin: 10px 0;
    line-height: 1.6;
  }
  
  .investigators {
    margin-top: 20px;
  }
  
  .investigators ol {
    padding-left: 20px;
  }
  
  .investigators ol li {
    margin-bottom: 10px;
  }
  
  .sponsor {
    margin-top: 20px;
  }
  </style>
  