<template>
  <div>
    <div class="row align-items-center mb-3">
      <!-- <label for="movie" class="mt-2 font-weight-bold"> Trial No: </label> -->
      <label for="trialNo" style="padding-right: 5px">Start Date :</label>
      <input
        :value="
          trialNoList && trialNoList.start_date ? trialNoList.start_date : ''
        "
        readonly
        style="width: 15%"
      />

      <!-- <label for="trialNo" style="padding-right: 5px; padding-left: 10px">
        End Date :</label
      >
      <input
        :value="trialNoList && trialNoList.end_date ? trialNoList.end_date : ''"
        readonly
        style="width: 15%"
      /> -->
    </div>
    <md-card v-if="loadingmain">
            <md-table class="custom-table">
              <tr>
                <td colspan="100%">
                  <loading-bar></loading-bar>
                </td>
              </tr>
            </md-table>
          </md-card>
    <div>
      <div class="row align-items-center mb-3" v-if="!loadingmain">
        <div class="col"></div>
        <div class="col-auto">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a
                href="#"
                :class="['nav-link', { active: isActiveMain('list') }]"
                @click.prevent="setActiveTabMain('list')"
              >
                List
              </a>
            </li>
            <li class="nav-item" v-if="(!hideCreate) && (isEditable === 1 || isEditable === null)">
              <a
                href="#"
                :class="['nav-link', { active: isActiveMain('create') }]"
                @click.prevent="setActiveTabMain('create')"
                @click="onEdit()"
              >
                Create
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="activeTabMain === 'list'">
      <div class="row align-items-center mb-3">
        <div class="col-auto">
          <ul class="nav nav-pills">
            <!-- Loop through filtered tabs -->
            <li class="nav-item" v-for="tab in tabList" :key="tab.id">
              <a
                href="#"
                :class="[
                  'nav-link',
                  { active: isActiveList(tab.trial_group_id.toString()) },
                ]"
                @click.prevent="setActiveTabList(tab.trial_group_id.toString())"
              >
                {{ "T" + tab.trial_group_id }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row mb-12" v-if="!loadingmain" >
        <div class="col-md-11 mb-1 d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            :disabled="!isDataAvailable"
            @click="exportToExcel"
          >
            <md-icon class="text-white">download</md-icon> Export Excel
          </button>
        </div>
      </div>
      <div>
        <md-dialog :md-active.sync="showWarningModal">
          <md-dialog-title>Warning</md-dialog-title>
          <md-dialog-content>
            {{ warningMessage }}
          </md-dialog-content>
          <md-dialog-actions>
            <md-button class="md-secondary" @click="closeWarningModal"
              >Ok</md-button
            >
          </md-dialog-actions>
        </md-dialog>
      </div>

      <!-- Loop through filtered tabs to display content -->
      <div v-for="tab in filteredTabs" :key="tab.id">
        <div v-if="tab.trial_group_id.toString() === activeTabList">
           <md-card v-if="loading">
            <md-table class="custom-table">
              <tr>
                <td colspan="100%">
                  <loading-bar></loading-bar>
                </td>
              </tr>
            </md-table>
          </md-card> 

          <md-card  >
            <md-card-header data-background-color="green">
              <h4 class="title">{{ "T" + tab.trial_group_id + " GROUPS" }}</h4>
            </md-card-header>
            <md-card-content>
              <h4 class="title">Raw materials</h4>
              <md-table
                class="custom-table"
                style="display: block;  margin-bottom: 0;"
              >
                <tr>
                  <th style="width: 25%; text-align: center">Ingredients</th>
                  <th style="width: 25%">PBS</th>
                  <th style="width: 25%">BS</th>
                  <th style="width: 25%">BF1</th>
                </tr>
                <tr v-for="item in RawList" :key="item.id">
                  <td style="width: 25%; text-align: left; padding-left: 100px">
                    {{ getIngredientRawList(item.ingredient_id) }}
                  </td>
                  <td style="width: 25%">{{ item.pbs }}</td>
                  <td style="width: 25%">{{ item.bs }}</td>
                  <td style="width: 25%">{{ item.bf1 }}</td>
                </tr>
                <!-- <tr v-if="RawList.length === 0">
                  <td colspan="12">No records found</td>
                </tr> -->
              <!-- </md-table> -->
              <!-- <h4 class="title">Additives</h4> -->
              <!-- <md-table
                class="custom-table"
                style="display: block;margin-top: 0;"
              > -->
                <!-- <tr>
                  <th style="width: 25%; text-align: center">Ingredients</th>
                  <th style="width: 25%">PBS</th>
                  <th style="width: 25%">PBS-2</th>
                  <th style="width: 25%">BS</th>
                </tr> -->
                <!-- <tr v-for="item in AdditiveList" :key="item.id">
                  <td style="width: 25%; text-align: left; padding-left: 100px">
                    {{ getIngredientAdditiveList(item.ingredient_id) }}
                  </td>
                  <td style="width: 25%">{{ item.pbs }}</td>
                  <td style="width: 25%">{{ item.pbs2 }}</td>
                  <td style="width: 25%">{{ item.bs }}</td>
                 <td>{{ item.pbs2 }}</td> 
                </tr> -->
                <!-- <tr v-if="AdditiveList.length === 0">
                  <td colspan="12">No records found</td>
                </tr> -->
              <!-- </md-table>
               <h4 class="title">Nutrients</h4> 
              <md-table class="custom-table"  style="display: block;margin-top: 0;">
                 <tr>
                  <th style="width: 25%; text-align: center">Ingredients</th>
                  <th style="width: 25%">PBS</th>
                  <th style="width: 25%">BS</th>
                  <th style="width: 25%">BF1</th>
                </tr> 
                <tr v-for="item in NutrientList" :key="item.id">
                  <td style="width: 25%; text-align: left; padding-left: 100px">
                    {{ getIngredientNutrientList(item.ingredient_id) }}
                  </td>
                  <td style="width: 25%">{{ item.pbs }}</td>
                  <td style="width: 25%">{{ item.bs }}</td>
                  <td style="width: 25%">{{ item.bf1 }}</td>
                  
                </tr> -->
                <tr>
    <td style="width: 25%; text-align: left; padding-left: 100px"><strong>Total</strong></td>
    <td style="width: 25%">{{ totalPBS }}</td>
    <td style="width: 25%">{{ totalBS }}</td>
    <td style="width: 25%">{{ totalBF }}</td>
  </tr>
                <!-- <tr v-for="item in RawList" :key="item.id">
                  <td style="width: 25%; text-align: left; padding-left: 100px"> Total</td>
                  <td style="width: 25%"> {{ calculateTotal(item.pbs) }}</td>
                  <td style="width: 25%"></td>
                  <td style="width: 25%"></td>
                </tr> -->
                <!-- <tr v-if="NutrientList.length === 0">
                  <td colspan="12">No records found</td>
                </tr> -->

                <!-- <tr v-for="item in TotalList" :key="item.id">
                  <th style="padding: 10px; text-align: center">COST/KG(Rs)</th>
                  <th class="title">{{ item.cost_per_bird_pbs }}</th>
                  <th>{{ item.cost_per_bird_bs }}</th>

                  <th>{{ item.cost_per_bird_bf }}</th>
                </tr>
                <tr class="table-cell" v-for="item in TotalList" :key="item.id">
                  <th style="padding: 10px; text-align: center">GRAMS/BIRD</th>
                  <th>{{ item.gram_per_bird_pbs }}</th>
                  <th>{{ item.gram_per_bird_ps }}</th>
                  <th>{{ item.gram_per_bird_bf }}</th>
                </tr>
                <tr class="table-cell" v-for="item in TotalList" :key="item.id">
                  <th style="padding: 10px; text-align: center">BAGS/1000</th>
                  <th>{{ item.bags_per_thousand_pbs }}</th>
                  <th>{{ item.bags_per_thousand_ps }}</th>
                  <th>{{ item.bags_per_thousand_bf }}</th>
                </tr> -->
                <!-- <tr v-if="TotalList.length === 0">
                  <td colspan="12">No records found</td>
                </tr> -->
              </md-table>
            </md-card-content>
          </md-card>
        </div>
      </div>
    </div>

    <div v-if="activeTabMain === 'create'">
      <form @submit.prevent="submit()" @input="dataChanged = true">
        <!-- <div class="row align-items-center mb-3"> -->
        <div>
          <div class="col-auto">
            <ul class="nav nav-pills">
              <li class="nav-item" v-for="tab in tabList" :key="tab.id">
                <a
                  href="#"
                  :class="[
                    'nav-link',
                    {
                      active: isActiveList(tab.trial_group_id.toString()),
                      disabled: !isActiveList(tab.trial_group_id.toString()),
                    },
                  ]"
                  @click.prevent="
                    setActiveTabList(tab.trial_group_id.toString())
                  "
                  v-show="isActiveList(tab.trial_group_id.toString())"
                >
                  {{ "T" + tab.trial_group_id }}
                </a>
              </li>
            </ul>
            <div   >
              <label for="movie" class="mt-2 font-weight-bold" style="margin:0%;" > Trial No: </label>
          <select
            
            v-model="trialNoDrp"
           
            style="margin-top:10px;" >
            <option v-for="item in trialNoListPrevious" :key="item?.id" :value="item?.id">
              {{ item?.trial_no }}
            </option>
          </select>

              <md-button
                type="button"     
                @click="onEditPrevious()"
                class="md-success mr-2"
                :disabled="isButtonClicked"
               >Load previous experiment T1 data
              </md-button>
              <!-- :disabled="isDataAvailable || isButtonClicked" -->

              <md-button
                type="button"
                @click="onEditT1()"
                class="md-success mr-2"
                :disabled="isButtonT1Clicked"
                style="float:right;" v-if="activeTabList !== '1'">Load current T1 data 
              </md-button>

            </div>
          </div>
          <!-- </div> -->
          <!-- </div> -->
        </div>
        <div style="width: 100%">
          <md-card>
            <md-card-header data-background-color="green">
              <h4 class="title">Premix Formula</h4>
            </md-card-header>
            <md-card-content>
              <div>
                <md-card-content>
                  <md-card-content>
                    <hr />

                    <div>
                      <div class="table-header">
                        <div class="table-cell">Raw Material</div>
                        <div class="table-cell">PBS</div>
                        <div class="table-cell">BS</div>
                        <div class="table-cell">BF-1</div>
                        <div class="table-cell">Action</div>
                      </div>

                      <div
                        v-for="(row, index) in rawMaterials"
                        :key="index"
                        class="table-row"
                      >
                        <div class="table-cell">
                          <select v-model="row.ingredient_id">
                            <option
                              v-for="ingredient in availableIngredients(index)"
                              :key="ingredient.id"
                              :value="ingredient.id"
                            >
                              {{ ingredient.ingredients }}
                            </option>
                          </select>
                        </div>
                        <div class="table-cell">
                          <input type="text" v-model="row.pbs" @input="validateInputRow(row, 'pbs')" />
                        </div>
                        <div class="table-cell">
                          <input type="text" v-model="row.bs" @input="validateInputRow(row, 'bs')" />
                        </div>
                        <div class="table-cell">
                          <input type="text" v-model="row.bf1"  @input="validateInputRow(row, 'bf1')"/>
                        </div>
                        <div
                          class="table-cell"
                          @click="removeRowRaw(row)"
                          style="
                            cursor: pointer;
                            display: flex;
                            flex-direction: column;
                          "
                        >
                          <md-icon>clear</md-icon>
                        </div>
                      </div>
                    </div>
                    <div class="struct2">
                      <div>
                        <md-button @click="addRowRaw" class="md-primary"
                          >Add Row</md-button
                        >
                      </div>
                    </div>

                    <hr />
<!-- 
                    <div>
                      <div class="table-header">
                        <div class="table-cell">ADDITIVES</div>
                        <div class="table-cell">PBS</div>
                        <div class="table-cell">PBS-2</div>
                        <div class="table-cell">BS</div>
                        <div class="table-cell">Action</div>
                      </div>
                      <div
                        v-for="(row, index) in additives"
                        :key="index"
                        class="table-row"
                      >
                        <div class="table-cell">
                         <md-field > 
                          <select v-model="row.additive_id">
                            <option
                              v-for="additive in availableAddIngredients(index)"
                              :key="additive.id"
                              :value="additive.id"
                            >
                              {{ additive.ingredients }}
                            </option>
                          </select>
                           </md-field>
                        </div>
                        <div class="table-cell">
                          <input type="text" v-model="row.additive_pbs" @input="validateInputRow(row, 'additive_pbs')" />
                        </div>
                        <div class="table-cell">
                          <input type="text" v-model="row.additive_pbs2" @input="validateInputRow(row, 'additive_pbs2')" />
                        </div>
                        <div class="table-cell">
                          <input type="text" v-model="row.additive_bs" @input="validateInputRow(row, 'additive_bs')"/>
                        </div>
                        <div
                          class="table-cell"
                          @click="removeRowAdditives(row)"
                          style="cursor: pointer"
                        >
                          <md-icon>clear</md-icon>
                        </div>
                      </div>
                    </div>
                    <div class="struct2">
                      <div>
                        <md-button @click="addRowAdditives" class="md-primary"
                          >Add Row</md-button
                        >
                      </div>
                    </div> -->
                    <!-- <hr />
                    <div>
                      <div class="table-header">
                        <div class="table-cell">NUTRIENTS</div>
                        <div class="table-cell">PBS</div>
                        <div class="table-cell">BS</div>
                        <div class="table-cell">BF-1</div>
                        <div class="table-cell">Action</div>
                      </div>
                      <div
                        v-for="(row, index) in nutrients"
                        :key="index"
                        class="table-row"
                      >
                        <div class="table-cell"> -->
                          <!-- <md-field> -->
                          <!-- <select v-model="row.nutrient_id">
                            <option
                              v-for="nutrient in availableNutIngredients(index)"
                              :key="nutrient.id"
                              :value="nutrient.id"
                            >
                              {{ nutrient.ingredients }}
                            </option>
                          </select> -->
                          <!-- </md-field> -->
                        <!-- </div>
                        <div class="table-cell">
                          <input type="text" v-model="row.nutri_pbs" @input="validateInputRow(row, 'nutri_pbs')"  />
                        </div>
                        <div class="table-cell">
                          <input type="text" v-model="row.nutri_bs" @input="validateInputRow(row, 'nutri_bs')" />
                        </div>
                        <div class="table-cell">
                          <input type="text" v-model="row.nurtri_bf1" @input="validateInputRow(row, 'nurtri_bf1')" />
                        </div>
                        <div
                          class="table-cell"
                          @click="removeRowNutrients(row)"
                          style="cursor: pointer"
                        >
                          <md-icon>clear</md-icon>
                        </div>
                      </div>
                    </div>
                    <div class="struct2">
                      <div>
                        <md-button @click="addRowNutrients" class="md-primary"
                          >Add Row</md-button
                        >
                      </div>
                    </div>

                    <hr /> -->

                    <!-- <div class="table-header">
                      <div class="table-cell">COST PER KG</div>
                      <div class="table-cell">
                        <input type="text" v-model="costperbirdpbs" @input="validateInputcostperbirdpbs" />
                      </div>
                      <div class="table-cell">
                        <input type="text" v-model="costperbirdbs" @input="validateInputcostperbirdbs" />
                      </div>
                      <div class="table-cell">
                        <input type="text" v-model="costperbirdbf" @input="validateInputcostperbirdbf" />
                      </div>
                    </div>
                    <div class="table-header">
                      <div class="table-cell">GRAM/BIRD</div>
                      <div class="table-cell">
                        <input type="text" v-model="gramperbirdpbs" @input="validateInputgramperbirdpbs" />
                      </div>
                      <div class="table-cell">
                        <input type="text" v-model="gramperbirdps" @input="validateInputgramperbirdps" />
                      </div>
                      <div class="table-cell">
                        <input type="text" v-model="gramperbirdbf" @input="validateInputgramperbirdbf" />
                      </div>
                    </div>
                    <div class="table-header">
                      <div class="table-cell">BAGS/1000</div>
                      <div class="table-cell">
                        <input type="text" v-model="bagsperthousandpbs" @input="validateInputbagsperthousandpbs"  />
                      </div>
                      <div class="table-cell">
                        <input type="text" v-model="bagsperthousandps" @input="validateInputbagsperthousandps" />
                      </div>
                      <div class="table-cell">
                        <input type="text" v-model="bagsperthousandbf" @input="validateInputbagsperthousandbf" />
                      </div>
                    </div>-->
                  </md-card-content> 
                </md-card-content>
              </div>
            </md-card-content>
            <div class="row-layout2">
              <div id="buttons">
                <div>
                  <md-button
                    type="button"
                    class="md-success mr-2"
                    @click="submit(true)"
                    >{{ requestStatusSave ? "Saving..." : "Save" }}</md-button
                  >
                  <md-button
                    type="button"
                    class="md-success mr-2"
                    @click="openModal"
                    :disabled="disableSubmitButton"
                    >{{
                      requestStatus ? "Saving..." : "Verify and Submit"
                    }}</md-button
                  >
                </div>
                <div style="padding-left: 10px">
                  <md-button type="button" class="md-danger" @click="clear"
                    >Reset</md-button
                  >
                </div>
              </div>
              <md-dialog :md-active.sync="isModalActive">
                <md-dialog-title>Verify & Submit</md-dialog-title>
                <md-dialog-content>
                  Are you sure you want to submit the form?
                </md-dialog-content>
                <md-dialog-actions>
                  <md-button class="md-primary" @click="submit(false)"
                    >Yes, Submit</md-button
                  >
                  <md-button class="md-secondary" @click="closeModal"
                    >Cancel</md-button
                  >
                </md-dialog-actions>
              </md-dialog>
            </div>
          </md-card>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import LoadingBar from "../components/LoadingBar.vue";
export default {
  components: {
    LoadingBar,
  },
  data() {
    return {
      hideCreate: false,
      showWarningModal: false,
      warningMessage: "",
      isEditableFetched: false,
      // isEditable:false,
      tabList: [],
      tabListSecond: [],
      id1: 0,
      id2: 0,
      id3: 0,
      trialNo: "",
      raw_id: 0,
      additive_id: 0,
      nutrient_id: 0,
      costperbirdpbs: "",
      costperbirdbs: "",
      costperbirdbf: "",
      gramperbirdpbs: "",
      gramperbirdps: "",
      gramperbirdbf: "",
      bagsperthousandpbs: "",
      bagsperthousandps: "",
      bagsperthousandbf: "",
      loading: true,
      loadingmain:true,
      errors: {},
      trialNumbers: [],
      ingredientRawList: [],
      ingredientAdditiveList: [],
      ingredientNutrientList: [],
      RawList: [],
      AdditiveList: [],
      NutrientList: [],
      TotalList: [],
      // total:[],
      trial_no: "",
      activeTab: "1",
      activeTabList: "1",
      activeTabMain: "list", // Default active tab
      trialNoDrp: "",
      selectedIngredient: null,
      requestStatus: false, // disable submit button if submit request loading
      requestStatusSave: false,
      filteredIngredients: [], // Initialize with an empty array
      ingredientsRawData: [],
      trialNoList: [],
      trialNoListPrevious: [],
      rawMaterials: [
        { ingredient_id: "", pbs: "", bs: "", bf1: "" }, // Initial row
      ],

      additives: [
        {
          additive_id: "",
          additive_pbs: "",
          additive_pbs2: "",
          additive_bs: "",
        }, // Initial row
      ],
      nutrients: [
        { nutrient_id: "", nutri_pbs: "", nutri_bs: "", nurtri_bf1: "" }, // Initial row
      ],
      total: [
        {
          costperbirdpbs: "",
          costperbirdbs: "",
          costperbirdbf: "",
          gramperbirdpbs: "",
          gramperbirdps: "",
          gramperbirdbf: "",
          bagsperthousandpbs: "",
          bagsperthousandps: "",
          bagsperthousandbf: "",
        },
      ],
      isModalActive: false,
      isEditable: null,
      isButtonClicked: false,
      isButtonT1Clicked:false,
    };
  },
  methods: {
    fetchIsEditable() {
      this.loading = true;
      axios
        .get("/base-feed-formula/is-editable", {
          params: {
            trial_group_id: this.activeTabList,
          },
        })
        .then((response) => {
          this.isEditable = response.data.is_editable;
          this.isPreviousEditable = response.data.previous_editable;
          this.loading = false;
        })
        .catch((error) => {
          // console.error("Error fetching is_editable:", error);
          // this.error = "Failed to fetch is_editable status";
          this.loading = false;
        });
    },

    exportToExcel() {
      axios
        .post(
          "indous-research/reports/download-premix-formula-report",
          {
            RawList: this.RawList,
            AdditiveList: this.AdditiveList,
            NutrientList: this.NutrientList,
            TotalList: this.TotalList,
            TrialGroup: this.activeTabList,
          },
          {
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "premix-formula-report.xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {});
    },
    getIngredientRawList(rawId) {
      const data = this.ingredientRawList.find((c) => c.id === rawId);
      return data ? data.ingredients : "Unknown";
    },
    getIngredientAdditiveList(rawId) {
      const data = this.ingredientAdditiveList.find((c) => c.id === rawId);
      return data ? data.ingredients : "Unknown";
    },
    getIngredientNutrientList(rawId) {
      const data = this.ingredientNutrientList.find((c) => c.id === rawId);
      return data ? data.ingredients : "Unknown";
    },
    isActive(tab) {
      return this.activeTab === tab;
    },
    isActiveMain(tab) {
      return this.activeTabMain === tab;
    },

    availableIngredients(currentIndex) {
      const selectedIngredients = this.rawMaterials
        .filter((row, index) => index !== currentIndex)
        .map((row) => row.ingredient_id);

      return this.ingredientRawList.filter(
        (ingredient) => !selectedIngredients.includes(ingredient.id)
      );
    },
    availableAddIngredients(currentIndex) {
      const selectedIngredients = this.additives
        .filter((row, index) => index !== currentIndex)
        .map((row) => row.additive_id);

      return this.ingredientAdditiveList.filter(
        (additive) => !selectedIngredients.includes(additive.id)
      );
    },
    availableNutIngredients(currentIndex) {
      const selectedIngredients = this.nutrients
        .filter((row, index) => index !== currentIndex)
        .map((row) => row.nutrient_id);

      return this.ingredientNutrientList.filter(
        (nutrient) => !selectedIngredients.includes(nutrient.id)
      );
    },

    isActiveList(id) {
      return this.activeTabList === id.toString();
    },
    setActiveTab(trial_group_id) {
      this.activeTab = trial_group_id;
      this.loadingmain=false;
    },
    // setActiveTabList(tab) {
    //   this.activeTabList = tab.toString();
    //   if (this.activeTabMain === "list") {
    //     this.fetchRawList();
    //     this.fetchAdditiveList();
    //     this.fetchNutrientList();
    //     this.fetchTotalList();
    //     this.fetchIsEditable();
    //   }
    // },
    closeWarningModal() {
      this.showWarningModal = false;
    },
    setActiveTabList(tab) {
  const newTabNumber = parseInt(tab); 
  let canSwitch = true;

  
  if (this.isPreviousEditable[newTabNumber] === 0) {
    this.activeTabList = newTabNumber.toString();
    if (this.activeTabMain === "list") {
    this.fetchRawList();
    this.fetchAdditiveList();
    this.fetchNutrientList();
    this.fetchTotalList();
    this.fetchIsEditable();
  }
  }
  
//  //if ( this.trialNoListPrevious[0].trial_no=== this.trialNoList.trial_no)
//   {
//   for (let i = 1; i < newTabNumber; i++) {
    
//     if (this.isPreviousEditable[i] === 1) {
//     //  canSwitch = false;
//     //this.showWarningModal = true;
//     //this.warningMessage = `You cannot switch to T${newTabNumber} because the current tab is incomplete. Kindly fill the tab before proceeding.`;
// ///this.warningMessage = `Please complete T${i} before proceeding.`;
//       break; 
//     }
//   }
// }
  // if (!canSwitch) {
  //   this.showWarningModal = true;
  //   //this.warningMessage = `You cannot switch to T${newTabNumber} because the current tab is incomplete. Kindly fill the tab before proceeding.`;
  //   this.warningMessage = `Please complete T${this.isPreviousEditable[newTabNumber]} before proceeding.`;

  //   this.activeTabList = newTabNumber.toString();
  //   if (this.activeTabMain === "list") {
  //   this.fetchRawList();
  //   this.fetchAdditiveList();
  //   this.fetchNutrientList();
  //   this.fetchTotalList();
  //   this.fetchIsEditable();
  // }
  //   return;
  // }
  this.activeTabList = newTabNumber.toString();

 
  if (this.activeTabMain === "list") {
    this.fetchRawList();
    this.fetchAdditiveList();
    this.fetchNutrientList();
    this.fetchTotalList();
    this.fetchIsEditable();
  }
},




    setActiveTabMain(tab) {
      this.activeTabMain = tab;
      if (this.activeTabMain === "list") {
        this.fetchRawList();
        this.fetchAdditiveList();
        this.fetchNutrientList();
        this.fetchTotalList();
        this.fetchIsEditable();
      }
    },

    addRowRaw() {
      this.rawMaterials.push({
        ingredient_id: "",
        pbs: "",
        bs: "",
        bf1: "",
        isEditing: false,
        id: this.id1++,
      });
    },
    addRowAdditives() {
      this.additives.push({
        additive_id: "",
        additive_pbs: "",
        additive_pbs2: "",
        additive_bs: "",
        isEditing: false,
        id: this.id2++,
      });
    },
    addRowNutrients() {
      this.nutrients.push({
        nutrient_id: "",
        nutri_pbs: "",
        nutri_bs: "",
        nurtri_bf1: "",
        isEditing: false,
        id: this.id3++,
      });
    },

    removeRowRaw(row) {
      this.rawMaterials = this.rawMaterials.filter((i) => i !== row);
    },
    removeRowAdditives(row) {
      this.additives = this.additives.filter((i) => i !== row);
    },
    removeRowNutrients(row) {
      this.nutrients = this.nutrients.filter((i) => i !== row);
    },

    fetchIngredientsRaw() {
      axios
        .get(
          "/indous-research/master/get-ingredient-master-filter?ingredient_type_id=1"
        )
        .then((response) => {
          this.ingredientRawList = response.data.feedIngredientRawList;
        });
    },

    fetchIngredientsAdditive() {
      axios
        .get(
          `/indous-research/master/get-ingredient-master-filter?ingredient_type_id=1`
         
        )
        .then((response) => {
          this.ingredientAdditiveList = response.data.feedIngredientRawList;
        });
    },
    fetchIngredientsNutrient() {
      axios
        .get(
          "/indous-research/master/get-ingredient-master-filter?ingredient_type_id=1"
        )
        .then((response) => {
          this.ingredientNutrientList = response.data.feedIngredientRawList;
        });
    },
    fetchControl() {
      // this.loading=true;
      this.isEditableFetched=true;
      axios
        .get("/experiment-info/fetch-control")
        .then((response) => {
          if (response.data.success) {
            this.tabList = response.data.data;
            this.isEditableFetched=false;
            const count = response.data.count;
            this.hideCreate = count > 0;
            if (this.tabList.length > 0) {
              this.setActiveTab(this.tabList[0].trial_group_id);
            }
          } else {
          }
        })
        .catch((error) => {
          this.isEditableFetched=false;
        });
    },

    fetchRawList() {
      this.loading = true;
      this.RawList = [];
      this.RawList.length > 0 ||
        axios
          .get(
            `/indous-research/base-feed-formula-raw?control_id=${this.activeTabList}`
          )
          .then((response) => {
            this.loading = false;
            this.RawList = response.data;
          })
          .catch((error) => {
            this.loading = false;
          });
    },

    fetchAdditiveList() {
      this.loading = true;
      this.AdditiveList = [];
      axios
        .get(
          `/indous-research/base-feed-formula-additive?control_id=${this.activeTabList}`
        )
        .then((response) => {
          this.AdditiveList = response.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    fetchNutrientList() {
      this.loading = true;
      this.NutrientList = [];
      axios
        .get(
          `/indous-research/base-feed-formula-nutrient?control_id=${this.activeTabList}`
        )
        .then((response) => {
          this.loading = false;
          this.NutrientList = response.data;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    fetchTotalList() {
      this.loading = true;
      this.TotalList = [];
      axios
        .get(
          `/indous-research/base-feed-formula-total?control_id=${this.activeTabList}`
        )
        .then((response) => {
          this.loading = false;
          this.TotalList = response.data;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
      getTrialNoList() {
 //   if (!this.startDate) return;

       axios
         .get(`/indous-research/experiment-information/get-trial-no-list`)
       .then((response) => {
           this.trialNoListPrevious = response.data.trialNoList;
           if (this.trialNoListPrevious.length > 0) {
      //  this.trialNoListPrevious.shift(); // Remove the first item from the list
      }
           this.trialNo = this.trialNoListPrevious?.trial_no;
           this.trialNoDrp = this.trialNoListPrevious?.id;
          //  this.expInfoId = this.trialNoList?.id;
          //  this.startDate = this.trialNoList?.start_date;
          // this.fetchTrialSchedulePlanList();
         });
   },
 
  

    submit(isEditable) {
      if (isEditable) {
        // this.requestStatus = true;
        this.requestStatusSave = true;
      } else {
        this.requestStatus = true;
      }
      // this.requestStatus = true;
      // this.requestStatusSave=true;
      this.isModalActive = false;
      axios
        .post("/indous-research/base-feed-formula", {
          //  trial_group_id: this.activeTab,
          trial_group_id: this.activeTabList,
          ingredient_raw: JSON.stringify(this.rawMaterials),
          ingredient_additive: JSON.stringify(this.additives),
          ingredient_nutrient: JSON.stringify(this.nutrients),
          cost_per_bird_pbs: this.costperbirdpbs,
          cost_per_bird_bs: this.costperbirdbs,
          cost_per_bird_bf: this.costperbirdbf,
          gram_per_bird_pbs: this.gramperbirdpbs,
          gram_per_bird_ps: this.gramperbirdps,
          gram_per_bird_bf: this.gramperbirdbf,
          bags_per_thousand_pbs: this.bagsperthousandpbs,
          bags_per_thousand_ps: this.bagsperthousandps,
          bags_per_thousand_bf: this.bagsperthousandbf,
          is_editable: isEditable,
        })
        .then((response) => {
          this.requestStatus = false;
          this.requestStatusSave = false;
          this.clear();
          this.$notify({
            message: response.data.message,
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
         //this.setActiveTabMain('list');
     
        })
        .catch((error) => {
          this.requestStatus = false;
          this.requestStatusSave = false;
          this.errors = error.response.data.errors;
        });
           //  this.activeTabMain === "list"
          this.setActiveTabMain('list');
    },

    onEdit() {
      // console.log(this.activeTabList);
      this.loading = true;
      this.clear();
      this.isButtonClicked = true;
      this.isButtonT1Clicked=true;
      axios
        .get(
          `/indous-research/base-feed-formula-raw?control_id=${this.activeTabList}`
        )
        .then((response) => {
          this.rawMaterials = response.data.map((rawItem) => ({
            ingredient_id: rawItem.ingredient_id,
            pbs: rawItem.pbs,
            bs: rawItem.bs,
            bf1: rawItem.bf1,
          }));
          // console.log("rawMaterials", this.rawMaterials);
          // this.addRowRaw();
          this.loading = false;
          if (this.rawMaterials.length === 0) {
            this.rawMaterials = [
              { ingredient_id: "", pbs: "", bs: "", bf1: "" }, // Initial row
            ];
            this.isButtonClicked = false;
            this.isButtonT1Clicked=false;
          }
        })
        .catch((error) => {
          this.loading = false;
          // console.error(error); // Log the error for debugging
        });

      this.loading = true;
      axios
        .get(
          `/indous-research/base-feed-formula-additive?control_id=${this.activeTabList}`
        )
        .then((response) => {
          this.additives = response.data.map((additiveItem) => ({
            additive_id: additiveItem.ingredient_id,
            additive_pbs: additiveItem.pbs,
            additive_pbs2: additiveItem.pbs2,
            additive_bs: additiveItem.bs,
          }));
          this.loading = false;
          if (this.additives.length === 0) {
            this.additives = [
              {
                additive_id: "",
                additive_pbs: "",
                additive_pbs2: "",
                additive_bs: "",
              },
            ];
            this.isButtonClicked = false;
            this.isButtonT1Clicked=false;
          }
        })
        .catch((error) => {
          this.loading = false;
          // console.error(error); // Log the error for debugging
        });

      this.loading = true;
      axios
        .get(
          `/indous-research/base-feed-formula-nutrient?control_id=${this.activeTabList}`
        )
        .then((response) => {
          this.nutrients = response.data.map((nutrientItem) => ({
            nutrient_id: nutrientItem.ingredient_id,
            nutri_pbs: nutrientItem.pbs,
            nutri_bs: nutrientItem.bs,
            nurtri_bf1: nutrientItem.bf1,
          }));
          this.loading = false;
          if (this.nutrients.length === 0) {
            this.nutrients = [
              { nutrient_id: "", nutri_pbs: "", nutri_bs: "", nurtri_bf1: "" }, // Initial row
            ];
             this.isButtonClicked = false;
      this.isButtonT1Clicked=false;
          }
        })
        .catch((error) => {
          this.loading = false;
          // console.error(error); // Log the error for debugging
        });

      this.loading = true;
      axios
        .get(
          `/indous-research/base-feed-formula-total?control_id=${this.activeTabList}`
        )
        .then((response) => {
          const totalItem = response.data.length > 0 ? response.data[0] : null; // Check if the array is not empty

          if (totalItem) {
            // If totalItem exists (not null), set the values
            this.costperbirdpbs = totalItem.cost_per_bird_pbs;
            this.costperbirdbs = totalItem.cost_per_bird_bs;
            this.costperbirdbf = totalItem.cost_per_bird_bf;
            this.gramperbirdpbs = totalItem.gram_per_bird_pbs;
            this.gramperbirdps = totalItem.gram_per_bird_ps;
            this.gramperbirdbf = totalItem.gram_per_bird_bf;
            this.bagsperthousandpbs = totalItem.bags_per_thousand_pbs;
            this.bagsperthousandps = totalItem.bags_per_thousand_ps;
            this.bagsperthousandbf = totalItem.bags_per_thousand_bf;
          } else {
            // If totalItem is null, reset the values to empty strings
            this.costperbirdpbs = "";
            this.costperbirdbs = "";
            this.costperbirdbf = "";
            this.gramperbirdpbs = "";
            this.gramperbirdps = "";
            this.gramperbirdbf = "";
            this.bagsperthousandpbs = "";
            this.bagsperthousandps = "";
            this.bagsperthousandbf = "";
          }

          this.loading = false;
          // console.log(totalItem);
        })
        .catch((error) => {
          this.loading = false;
          // console.error(error); // Log the error for debugging
        });
      
      

    },

    onEditT1() {
      this.loading = true;
      this.isButtonT1Clicked = true;
      this.isButtonClicked=true;
      axios
        .get(
          `/indous-research/base-feed-formula-raw?control_id=1`
        )
        .then((response) => {
          this.rawMaterials = response.data.map((rawItem) => ({
            ingredient_id: rawItem.ingredient_id,
            pbs: rawItem.pbs,
            bs: rawItem.bs,
            bf1: rawItem.bf1,
          }));
          // console.log("rawMaterials", this.rawMaterials);
          // this.addRowRaw();
          this.loading = false;
          if (this.rawMaterials.length === 0) {
            this.rawMaterials = [
              { ingredient_id: "", pbs: "", bs: "", bf1: "" }, // Initial row
            ];
          }
        })
        .catch((error) => {
          this.loading = false;
          // console.error(error); // Log the error for debugging
        });

      this.loading = true;
      axios
        .get(
          `/indous-research/base-feed-formula-additive?control_id=1`
        )
        .then((response) => {
          this.additives = response.data.map((additiveItem) => ({
            additive_id: additiveItem.ingredient_id,
            additive_pbs: additiveItem.pbs,
            additive_pbs2: additiveItem.pbs2,
            additive_bs: additiveItem.bs,
          }));
          this.loading = false;
          if (this.additives.length === 0) {
            this.additives = [
              {
                additive_id: "",
                additive_pbs: "",
                additive_pbs2: "",
                additive_bs: "",
              },
            ];
          }
        })
        .catch((error) => {
          this.loading = false;
          // console.error(error); // Log the error for debugging
        });

      this.loading = true;
      axios
        .get(
          `/indous-research/base-feed-formula-nutrient?control_id=1`
        )
        .then((response) => {
          this.nutrients = response.data.map((nutrientItem) => ({
            nutrient_id: nutrientItem.ingredient_id,
            nutri_pbs: nutrientItem.pbs,
            nutri_bs: nutrientItem.bs,
            nurtri_bf1: nutrientItem.bf1,
          }));
          this.loading = false;
          if (this.nutrients.length === 0) {
            this.nutrients = [
              { nutrient_id: "", nutri_pbs: "", nutri_bs: "", nurtri_bf1: "" }, // Initial row
            ];
          }
        })
        .catch((error) => {
          this.loading = false;
          // console.error(error); // Log the error for debugging
        });

      this.loading = true;
      axios
        .get(
          `/indous-research/base-feed-formula-total?control_id=1`
        )
        .then((response) => {
          const totalItem = response.data.length > 0 ? response.data[0] : null; // Check if the array is not empty

          if (totalItem) {
            // If totalItem exists (not null), set the values
            this.costperbirdpbs = totalItem.cost_per_bird_pbs;
            this.costperbirdbs = totalItem.cost_per_bird_bs;
            this.costperbirdbf = totalItem.cost_per_bird_bf;
            this.gramperbirdpbs = totalItem.gram_per_bird_pbs;
            this.gramperbirdps = totalItem.gram_per_bird_ps;
            this.gramperbirdbf = totalItem.gram_per_bird_bf;
            this.bagsperthousandpbs = totalItem.bags_per_thousand_pbs;
            this.bagsperthousandps = totalItem.bags_per_thousand_ps;
            this.bagsperthousandbf = totalItem.bags_per_thousand_bf;
          } else {
            // If totalItem is null, reset the values to empty strings
            this.costperbirdpbs = "";
            this.costperbirdbs = "";
            this.costperbirdbf = "";
            this.gramperbirdpbs = "";
            this.gramperbirdps = "";
            this.gramperbirdbf = "";
            this.bagsperthousandpbs = "";
            this.bagsperthousandps = "";
            this.bagsperthousandbf = "";
          }

          this.loading = false;
          // console.log(totalItem);
        })
        .catch((error) => {
          this.loading = false;
          // console.error(error); // Log the error for debugging
        });
    },

    onEditPrevious() {
      this.loading = true;
      this.isButtonClicked = true;
      this.isButtonT1Clicked=true;
      axios
        .get(`/indous-research/base-feed-formula-raw-previous?control_id=1&trial_id=${this.trialNoDrp}`)
        .then((response) => {
          this.rawMaterials = response.data.map((rawItem) => ({
            ingredient_id: rawItem.ingredient_id,
            pbs: rawItem.pbs,
            bs: rawItem.bs,
            bf1: rawItem.bf1,
          }));
          this.loading = false;
          if (this.rawMaterials.length === 0) {
            this.clear();
          }
        })
        .catch((error) => {
          this.loading = false;
          // console.error(error); // Log the error for debugging
        });

      // this.loading = true;
      // axios
      //   .get(
      //     `/indous-research/base-feed-formula-additive-previous?control_id=1&trial_id=${this.trialNoDrp}`
      //   )
      //   .then((response) => {
      //     this.additives = response.data.map((additiveItem) => ({
      //       additive_id: additiveItem.ingredient_id,
      //       additive_pbs: additiveItem.pbs,
      //       additive_pbs2: additiveItem.pbs2,
      //       additive_bs: additiveItem.bs,
      //     }));
      //     this.loading = false;
      //     if (this.additives.length === 0) {
      //       this.clear();
      //     }
      //   })
      //   .catch((error) => {
      //     this.loading = false;
      //     // console.error(error); // Log the error for debugging
      //   });

      // this.loading = true;
      // axios
      //   .get(
      //     `/indous-research/base-feed-formula-nutrient-previous?control_id=1&trial_id=${this.trialNoDrp}`
      //   )
      //   .then((response) => {
      //     this.nutrients = response.data.map((nutrientItem) => ({
      //       nutrient_id: nutrientItem.ingredient_id,
      //       nutri_pbs: nutrientItem.pbs,
      //       nutri_bs: nutrientItem.bs,
      //       nurtri_bf1: nutrientItem.bf1,
      //     }));
      //     this.loading = false;
      //     if (this.nutrients.length === 0) {
      //       this.clear();
      //     }
      //   })
      //   .catch((error) => {
      //     this.loading = false;
      //     // console.error(error); // Log the error for debugging
      //   });

      // this.loading = true;
      // axios
      //   .get(`/indous-research/base-feed-formula-total-previous?control_id=1&trial_id=${this.trialNoDrp}`)
      //   .then((response) => {
      //     const totalItem = response.data[0]; // Access the first element of the array
      //     //this.total = {
      //     this.costperbirdpbs = totalItem.cost_per_bird_pbs;
      //     this.costperbirdbs = totalItem.cost_per_bird_bs;
      //     this.costperbirdbf = totalItem.cost_per_bird_bf;
      //     this.gramperbirdpbs = totalItem.gram_per_bird_pbs;
      //     this.gramperbirdps = totalItem.gram_per_bird_ps;
      //     this.gramperbirdbf = totalItem.gram_per_bird_bf;
      //     this.bagsperthousandpbs = totalItem.bags_per_thousand_pbs;
      //     this.bagsperthousandps = totalItem.bags_per_thousand_ps;
      //     this.bagsperthousandbf = totalItem.bags_per_thousand_bf;
      //     // };
      //     this.loading = false;
      //   })
      //   .catch((error) => {
      //     this.loading = false;
      //     // console.error(error); // Log the error for debugging
      //   });
    },
    calculateTotal(pbs) {
    return pbs;
  },
   
    validateInputcostperbirdpbs(event) {
      const value = event.target.value;
      const regex = /^\d*\.?\d*$/;
      if (!regex.test(value)) {
        this.costperbirdpbs = value.slice(0, -1);
      
      }},
      validateInputcostperbirdbs(event) {
      const value = event.target.value;
      const regex = /^\d*\.?\d*$/;
      if (!regex.test(value)) {
       
        this.costperbirdbs = value.slice(0, -1);
      
      }},
      validateInputcostperbirdbf(event) {
      const value = event.target.value;
      const regex = /^\d*\.?\d*$/;
      if (!regex.test(value)) {
        this.costperbirdbf = value.slice(0, -1);
      
      }},
      

      validateInputgramperbirdpbs(event) {
      const value = event.target.value;
      const regex = /^\d*\.?\d*$/;
      if (!regex.test(value)) {
        this.gramperbirdpbs = value.slice(0, -1);
      
      }},
      validateInputgramperbirdps(event) {
      const value = event.target.value;
      const regex = /^\d*\.?\d*$/;
      if (!regex.test(value)) {
        this.gramperbirdps = value.slice(0, -1);
      
      }},
      validateInputgramperbirdbf(event) {
      const value = event.target.value;
      const regex = /^\d*\.?\d*$/;
      if (!regex.test(value)) {
        this.gramperbirdbf = value.slice(0, -1);
      
      }},

      validateInputbagsperthousandpbs(event) {
      const value = event.target.value;
      const regex = /^\d*\.?\d*$/;
      if (!regex.test(value)) {
        this.bagsperthousandpbs = value.slice(0, -1);
      
      }},

      validateInputbagsperthousandps(event) {
      const value = event.target.value;
      const regex = /^\d*\.?\d*$/;
      if (!regex.test(value)) {
        this.bagsperthousandps = value.slice(0, -1);
      
      }},
      validateInputbagsperthousandbf(event) {
      const value = event.target.value;
      const regex = /^\d*\.?\d*$/;
      if (!regex.test(value)) {
        this.bagsperthousandbf = value.slice(0, -1);
      
      }},


      validateInputRow(row, field) {
      const value = row[field];
      const regex = /^\d*\.?\d*$/;
      if (!regex.test(value)) {
        row[field] = value.slice(0, -1);
      }
    },
  
    clear() {
      (this.rawMaterials = [
        { ingredient_id: "", pbs: "", bs: "", bf1: "" }, // Initial row
      ]),
        (this.additives = [
          {
            additive_id: "",
            additive_pbs: "",
            additive_pbs2: "",
            additive_bs: "",
          }, // Initial row
        ]),
        (this.nutrients = [
          { nutrient_id: "", nutri_pbs: "", nutri_bs: "", nurtri_bf1: "" }, // Initial row
        ]),
        (this.costperbirdpbs = ""),
        (this.costperbirdbs = ""),
        (this.costperbirdbf = ""),
        (this.gramperbirdpbs = ""),
        (this.gramperbirdps = ""),
        (this.gramperbirdbf = ""),
        (this.bagsperthousandpbs = ""),
        (this.bagsperthousandps = ""),
        (this.bagsperthousandbf = "");
      this.isButtonClicked = false;
      this.isButtonT1Clicked=false;
    },
    openModal() {
      this.isModalActive = true;
      this.requestStatus = true;
      //this.requestStatusSave=false;
    },
    closeModal() {
      this.isModalActive = false;
      this.requestStatus = false;
    },
  },
  watch: {
    // checkPrev:
  },

  ingredientRawList: {
    immediate: true,
    handler(newList) {
      if (newList.length > 0) {
        this.raw_id = newList[0].id;
      }
    },
  },
  ingredientAdditiveList: {
    immediate: true,
    handler(newList) {
      if (newList.length > 0) {
        this.addtive_id = newList[0].id;
      }
    },
  },
  ingredientNutrientList: {
    immediate: true,
    handler(newList) {
      if (newList.length > 0) {
        this.nutrient_id = newList[0].id;
      }
    },
  },

  computed: {
    filteredTabs() {
      return this.tabList.filter(
        (tab) => tab.trial_group_id.toString() === this.activeTabList
      );
    },
    isDataAvailable() {
      return (
        this.RawList.length > 0 &&
        this.AdditiveList.length > 0 &&
        this.NutrientList.length > 0 &&
        this.TotalList.length > 0
      );
    },
    totalPBS() {
      const total =this.RawList.reduce((sum, item) => sum + parseFloat(item.pbs || 0), 0.0);
      return total.toFixed(3); // Adjust the number of decimal places as needed
  },
  totalBS() {
      const total =this.RawList.reduce((sum, item) => sum + parseFloat(item.bs || 0), 0.0);
      return total.toFixed(3); // Adjust the number of decimal places as needed
  },
  totalBF() {
      const total =this.RawList.reduce((sum, item) => sum + parseFloat(item.bf1 || 0), 0.0);
      return total.toFixed(3); // Adjust the number of decimal places as needed
  },
    // isDataAvailableSave() {
    //   return (
    //     this.RawList.length > 0 &&
    //     this.AdditiveList.length > 0 &&
    //     this.NutrientList.length > 0 &&
    //    this.TotalList.length > 0
    //   );
    // },

    disableSaveButton() {
      let length = this.rawMaterials.length;
      let additivelength = this.additives.length;
      let nutrientlength = this.nutrients.length;
      let lastRawItem = this.rawMaterials[length - 1];
      let lastAdditivesItem = this.additives[additivelength - 1];
      // console.log('Additive items ',lastAdditivesItem);
      let lastnutrientsItem = this.nutrients[nutrientlength - 1];
      return (lastRawItem.ingredient_id === "" ||
        lastRawItem.pbs === "" ||
        lastRawItem.bs === "" ||
        lastRawItem.bf1 === "") &&
        (lastAdditivesItem.additive_id === "" ||
          lastAdditivesItem.additive_pbs === "" ||
          lastAdditivesItem.additive_pbs2 === "" ||
          lastAdditivesItem.additive_bs === "") &&
        (lastnutrientsItem.nutrient_id === "" ||
          lastnutrientsItem.nutri_pbs === "" ||
          lastnutrientsItem.bs === "" ||
          lastnutrientsItem.nurtri_bf1 === "") &&
        this.costperbirdpbs === "" &&
        this.costperbirdbs === "" &&
        this.costperbirdbf === "" &&
        this.gramperbirdpbs === "" &&
        this.gramperbirdps === "" &&
        this.gramperbirdbf === "" &&
        this.bagsperthousandpbs === "" &&
        this.bagsperthousandps === "" &&
        this.bagsperthousandbf === "" // &&
        ? //  this.requestStatus
          true
        : false;
    },

    disableSubmitButton() {
      let length = this.rawMaterials.length;
      let additivelength = this.additives.length;
      let nutrientlength = this.nutrients.length;
      let lastRawItem = this.rawMaterials[length - 1];
      //let lastAdditivesItem = this.additives[additivelength - 1];
      // console.log('Additive items ',lastAdditivesItem);
     // let lastnutrientsItem = this.nutrients[nutrientlength - 1];
      return lastRawItem.ingredient_id == "" ||
        lastRawItem.pbs == "" ||
        lastRawItem.bs == "" ||
        lastRawItem.bf1 == "" 
        // lastAdditivesItem.additive_id == "" ||
        // lastAdditivesItem.additive_pbs == "" ||
        // lastAdditivesItem.additive_pbs2 == "" ||
        // lastAdditivesItem.additive_bs == "" ||
        // lastnutrientsItem.nutrient_id == "" ||
        // lastnutrientsItem.nutri_pbs == "" ||
        // lastnutrientsItem.bs == "" ||
        // lastnutrientsItem.nurtri_bf1 == "" ||
        // this.costperbirdpbs == "" ||  this.costperbirdpbs == null ||
        // this.costperbirdbs == "" || this.costperbirdbs==null  ||
        // this.costperbirdbf == "" ||  this.costperbirdbf==null ||
        // this.gramperbirdpbs == "" || this.gramperbirdpbs==null ||
        // this.gramperbirdps == "" || this.gramperbirdps ==null ||
        // this.gramperbirdbf == "" ||  this.gramperbirdbf==null ||
        // this.bagsperthousandpbs == "" || this.bagsperthousandpbs==null || 
        // this.bagsperthousandps == "" || this.bagsperthousandps ==null ||
        // this.bagsperthousandbf == ""  || this.bagsperthousandbf ==null 
        ?
        true : false;
    },
  },

  mounted() {
    
    this.trialNoList = this.$store.getters.getSelectedTrial;
    this.fetchIngredientsRaw();
    this.fetchIngredientsAdditive();
    this.fetchIngredientsNutrient();
    // this.addRowRaw();
    // this.addRowAdditives();
    // this.addRowNutrients();
    this.fetchRawList();
    this.fetchAdditiveList();
    this.fetchNutrientList();
    this.fetchTotalList();
    this.fetchControl();
    this.fetchIsEditable();
    // this.clear();
    this.getTrialNoList();
  },
 
};
</script>

<style>
.table-row {
  display: flex;
  /* width: 2000px;  Adjust this value based on the number of columns */
}

.table-cell {
  /* flex: 1; */
  padding: 8px;
  /* border: 1px solid #ddd; */
  max-width: 250px;
}

.styled-table {
  /* width: 100%; */
  border-collapse: collapse;
}
.styled-table th,
.styled-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  /* width: auto; */
}
.styled-table th {
  background-color: #f2f2f2;
}
.styled-table .slct,
.styled-table .custm {
  width: 100%;
  box-sizing: border-box;
}

/* .table-cell input,
.table-cell .md-select,
.table-cell .md-field {
  width: 100%;
} */

.col-auto {
  flex: 0 0 auto;
  flex-direction: row;
  /* width: auto; */
}
.card-box {
  background-color: #fff;
  padding: 10px;
  -webkit-box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  /* margin-bottom: 1px; */
  border-radius: 0.25rem;
}

.align-items-center {
  align-items: center !important;
  padding: 0px 65px 0px 20px;
}

.row {
  --ct-gutter-x: 1.5rem;
  --ct-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--ct-gutter-y));
  margin-right: calc(-0.5 * var(--ct-gutter-x));
  margin-left: calc(-0.5 * var (--ct-gutter-x));
}
.row-layout2 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 10%;
}

#buttons {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #71b6f9 !important;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.struct {
  display: grid;
  grid-template-columns: 105px 140px 58px 200px;
  grid-gap: 25px;
  /* grid-auto-rows: 50px;  */
  /*grid-auto-rows: 50px;  Set all rows to 50px height */
}

.struct2 {
  display: grid;
  grid-template-columns: 135px 250px 58px 150px 100px;
  grid-gap: 25px;
  grid-auto-rows: 50px;
  margin-left: 20px;
  padding: 10px 0px;
}

/* .md-field {
  margin: 0px 0px;
} */

.grid-item {
  height: 50px;
}

/* .test1, .test2, .test3, .test4, .test5 {
  flex: 1; /* Adjust the flex-grow as needed 
} */

/* Ensure that each test class has some default width */
.test1 {
  text-align: right;
  /* grid-column: 1 / 5; */
}
.test3 {
  text-align: right;
  /* grid-column: 3 / 5; */
}
/* .test4 {
    grid-column: 4 / 5;
}
.test5 {
    grid-column: 5 / 5;
} */

.testing {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-top: 15px;
  padding-left: 20px;
  justify-content: space-between;
  gap: 20px; /* Add gap between flex items */
}

hr {
  border: none;
  height: 2px;
  background-color: #000;
  /* margin-top: 40px; */
}

.custom-md-field {
  /* Adjust the overall size of the md-field if needed */
  width: 190px; /* Adjust as needed */
}

.custom-md-input {
  /* Adjust the size of the input */
  width: 50px; /* Change the width as needed */
  /* height: 30px; Change the height as needed */
  /*padding: 5px;  Adjust padding as needed */
  font-size: 14px; /* Adjust font size as needed */
}
.row {
  --ct-gutter-x: 1.5rem;
  --ct-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--ct-gutter-y));
  margin-right: calc(-0.5 * var(--ct-gutter-x));
  margin-left: calc(-0.5 * var (--ct-gutter-x));
}

.tab-content {
  padding: 20px 0 0 0;
  border: none;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #71b6f9 !important;
}

.align-items-center {
  align-items: center !important;
  padding: 0px 65px 0px 20px;
}

/* .mb-3 {
    margin-bottom: 1.5rem !important;
  } */

.col {
  flex: 1 0 0%;
}

.mt-0 {
  margin-top: 0 !important;
}

.header-title {
  font-size: 1rem;
  margin: 0 0 7px 0;
}

.card-box {
  background-color: #fff;
  padding: 10px;
  -webkit-box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  /* margin-bottom: 1px; */
  border-radius: 0.25rem;
}

.table-header,
.table-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  text-align: center;
}

.table-cell {
  /* flex: 1; */
  padding: 8px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  max-width: 250px;
}

.table-header {
  background-color: #f5f5f5;
  font-weight: bold;
}

.table-cell select,
.table-cell input {
  /* width: 100%; */
  padding: 4px;
  box-sizing: border-box;
}

.table-cell .slct {
  width: 100%;
  height: 100%;
}

.col-auto {
  flex: 0 0 auto;
  flex-direction: row;
  /* width: auto; */
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.styled-table {
  /* width: 100%; */
  border-collapse: collapse;
  /* margin: 25px 0; */
  font-size: 15px;
  font-weight: 500;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* min-width: 400px; */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  /* background-color: #009879; */
  /* color: #ffffff; */
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
  border: 1px solid #dddddd;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.styled-table tbody tr:hover {
  background-color: #f1f1f1;
}

.styled-table td button {
  background: none;
  border: none;
  cursor: pointer;
}

.styled-table td button:focus {
  outline: none;
}

.slct {
  height: 20px;
  /* width:130px; */
}
/* .form-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
} */

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

/* input[type="text"],
input[type="password"],
input[type="email"],
select {
   width: 100%; 
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
} */

.input-group {
  display: flex;
}

/* .input-group input {
  flex: 1;
} */

/* .input-group button {
  margin-left: 10px;
  padding: 8px 12px;
  border: 1px solid #007bff;
  border-radius: 4px;
  background-color: #fff;
  color: #007bff;
  cursor: pointer;
} */

/* .input-group button:hover {
  background-color: #007bff;
  color: #fff;
} */

button[type="submit"],
button[type="button"].cancel-button {
  /* padding: 10px 20px; */
  /* border: none;
  border-radius: 4px; */
  cursor: pointer;
}

/* button[type="submit"].save-button {
  background-color: #28a745;
  color: #fff;
} */

/* button[type="button"].cancel-button {
  background-color: #ccc;
  color: #333;
  margin-left: 10px;
} */

#buttons {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
}

/* button[type="button"].cancel-button:hover {
  background-color: #b3b3b3;
} */

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: rgb(113, 182, 249);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.md-list-item .md-list-item-container .md-ripple > span {
  border-radius: 3px;
  display: contents;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

.create-field {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.all-buttons {
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
}

.table-layout {
  display: flex;
  flex-direction: column;
}

.form-style {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.row-layout {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row-layout1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.row-layout2 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 10%;
}

.checkbox-select {
  /* width: 100%; */
  height: auto;
  border-radius: 1rem;
}
.checkbox-select__trigger {
  justify-content: space-between;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background: #fff;
  position: relative;
  z-index: 100;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
}
.checkbox-select__trigger.isActive {
  border-radius: 10px 10px 0 0;
  background: #f2f2f2;
}
.checkbox-select__trigger:hover {
  background: #f4f4f4;
}
.checkbox-select__dropdown {
  display: none;
  background: #fff;
  box-shadow: 0 12px 15px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  /* padding-bottom: 25px; */
}
.checkbox-select__dropdown {
  display: block; /* Ensure it gets shown */
}
.checkbox-select__search-wrapp {
  padding: 10px 25px 5px;
}
.checkbox-select__search-wrapp input {
  /* width: 100%; */
  height: 40px;
  border-width: 0 0 2px;
  border-style: solid;
  border-color: #000;
  font-size: 16px;
  background: transparent;
}
.checkbox-select__col {
  display: flex;
  font-size: 12px;
  padding: 0 25px;
  justify-content: space-between;
  text-transform: uppercase;
}
.checkbox-select__select-all label {
  cursor: pointer;
}
.checkbox-select__filters-wrapp {
  /* margin-top: 20px; */
  height: 157px;
  overflow-y: auto;
  padding-left: 0px;
}
.checkbox-select__check-wrapp {
  position: relative;
  padding: 0 25px;
  margin-bottom: 5px;
}
.checkbox-select__check-wrapp input[type="checkbox"] {
  display: none;
}
.checkbox-select__check-wrapp input[type="checkbox"] + label {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  line-height: 22px;
  padding-left: 30px;
  display: inline-block;
  user-select: none;
  transition: padding 0.25s ease;
}
.checkbox-select__check-wrapp input[type="checkbox"] + label:after {
  border: solid 2px #000;
  content: "";
  /* width: 22px; */
  height: 22px;
  top: 0;
  left: 0;
  position: absolute;
}
.checkbox-select__check-wrapp input[type="checkbox"] + label:before {
  /* width: 14px; */
  height: 14px;
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  background-color: #000;
  opacity: 0;
  transform: scale(0.5);
  transition: all 0.2s ease;
}
.checkbox-select__check-wrapp input[type="checkbox"]:checked + label:before {
  opacity: 1;
  transform: scale(1);
}
.col-md-3 {
  flex: 0 0 auto;
  /* width: 25%; */
}
</style>