<template>
    <div v-if="message">
        <p class="text-danger">
           * {{message}}
        </p>
    </div>
</template>
<script>
export default {
    data:()=>({

    }),
    props:{
        errors:Object,
        field:String
    },
    computed:{
        message(){
            let msg = ''
            if( this.field in this.errors){
                msg = this.errors[this.field][0]
            }
            return msg;
        }
    }
}
</script>