<template>
  <div>
    <div>
      <div class="row align-items-center mb-3">
        <div class="col">
        </div>
        <div class="col-auto">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a
                href="#"
                :class="['nav-link', { active: isActive('list') }]"
                @click.prevent="setActiveTab('list'),fetchFeedIngredientList()"
              >
                List
              </a>
            </li>
            <li class="nav-item">
              <a
                href="#"
                :class="['nav-link', { active: isActive('create') }]"
                @click.prevent="setActiveTab('create')"
              >
                Create
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div v-if="activeTab === 'list'">
      <div class="row align-items-center mb-3">     
        <div class="col">
          <label for="movie" class="custom-input-sm">Show         
             <select name="" class="form-control" id="" v-model="paginate"  @change="onChangeFilter(1)">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="1000">All</option>
          </select></label>
        </div>

     

        <md-field class="custom-md-field" style="margin: 0px 0px 0px 0px">
          <label>Search...</label>
          <md-input v-model="search" @input="$debounce(onChangeFilter)"></md-input>
        </md-field>
        <md-icon>search</md-icon>
      </div>
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">FEED INGREDIENT MASTER</h4>
          </md-card-header>
          <md-card-content>
            <md-table :table-header-color="tableHeaderColor" class="custom-table">
              <tr>
                <th>Sl No</th>
                <th>Ingredients</th>
                <th>Action</th>
              </tr>
              <tr  v-for="(item,index) in feedIngredientList?.data" :key="item.id">
                               <td >{{ feedIngredientList.from + index }}</td>
                               <td >{{ item.ingredients  }}</td>  
                               <td><button @click="onEdit(item)" class="btn btn-info btn-sm round-2"><i class="mdi mdi-pencil mx-auto"></i></button> </td> 
                            </tr>             
                          </md-table>
                          <loading-bar v-if="load"></loading-bar>
                    </md-card-content>
                    <div class="row px-3">
                        <div class="col-md-6">
                         
                  Showing
                  {{ feedIngredientList.from ? feedIngredientList.from : 0 }}
                  to
                  {{ feedIngredientList.to ? feedIngredientList.to : 0 }} of
                  {{ feedIngredientList.total ? feedIngredientList.total : 0 }}
                  entries
             
                        </div>
                   <div class="col-md-6">
                    <pagination :pagination="feedIngredientList" :offset="4"   @paginate="onChangeFilter"></pagination>
                 </div>
                 </div>                  
                </md-card>
            </div>
        </div>

    <div v-if="activeTab === 'create'" class="mt-3">
      <form @submit.prevent="submit()">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
        >
          <md-card style="width: 55%">
            <md-card-header data-background-color="green">
              <h4 class="title">FEED INGREDIENT MASTER</h4>
            </md-card-header>
            <md-card-content>
           <!-- Ingredient group -->
              <div class="radio-container d-flex">
                <!-- <label class="ml-1">        
                  <input type="radio" name="options" value="1" v-model="options" id="1"  @input="clearErrors" hidden/> 
                </label> -->
                <!-- <label class="ml-4">
                  <input type="radio" name="options" value="2" v-model="options" id="2"  @input="clearErrors"/>
                  Additives                  
                </label>
                <label class="ml-4">
                  <input type="radio" name="options" value="3" v-model="options" id="3" @input="clearErrors"/>
                  Nutrients              
                </label> -->
              </div>
              <div> <validation    :errors="errors" field="ingredient_type_id" ></validation></div>
              <div class="form-group">
                <md-field>
                  <label>Ingredient</label>
                  <md-input v-model="ingredientName" type="name" @input="clearErrors"></md-input>
                </md-field>
                <validation    :errors="errors" field="ingredients" ></validation>
              </div>

              <!-- Buttons -->
              <div id="buttons">
                  <div>
                    <md-button v-if="!isUpdate" type="submit" class="md-success" :disabled="requestStaus">{{requestStaus ? 'Saving...':'Save'}}</md-button>
                    <md-button v-else @click="update()"  type="button" class="md-success" :disabled="requestStaus">{{requestStaus ? 'Updating...':'Update'}}</md-button>
                </div>
                <div style="padding-left: 10px">
                  <md-button type="button" @click="clearFields(),setActiveTab('list')" class="md-danger">Cancel</md-button>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import LoadingBar from '../../components/LoadingBar.vue';
import Validation from '../../components/NotificationPlugin/Validation.vue';
import Pagination from '../../components/Pagination.vue';



export default {
  components: { Validation,Pagination,LoadingBar },
  name: "FeedIngredient",
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
 
  data() {
        return {
            search:"",
            paginate:5,
            activeTab: 'list', // Default active tab
            feedIngredientList: {
                data:[],
                total: 5,
                per_page: 5,
                last_page: 1,
                from: 1,
                to: 1,
                current_page: 1,
            },
            options:"",
            ingredientName:"",
            errors: {},
            load: false,
            requestStaus:false
        };
    },
    mounted(){
        this.fetchFeedIngredientList();
    },
    // computed:{
    //     hasErrors() {
    //         return Object.keys(this.errors).length > 0;
    //     }
    // },
    methods: {
        isActive(tab) {
            return this.activeTab === tab;
        },
        setActiveTab(tab) {
            this.activeTab = tab;
            this.isUpdate = false
        },

        fetchFeedIngredientList(){
          this.load = true;
          this.feedIngredientList.data = [];
            axios.get(`/indous-research/master/feedIngredient-master?search=${this.search}&paginate=${this.paginate}&page=${this.feedIngredientList.current_page}`).then(response=>{
               this.feedIngredientList = response.data.feedIngredientList
               this.load = false;
            })
        },     
        onPaginate(page){
            this.feedIngredientList.current_page = page
            this.fetchFeedIngredientList()
        },
        onChangeFilter(page = 1) { // page number
      this.feedIngredientList.current_page = page;

      this.fetchFeedIngredientList();
    },

   submit() {
      this.requestStaus = true
      axios
        .post("/indous-research/master/feedIngredient-master", {
          ingredient_type_id:1,
          ingredients:this.ingredientName,
        })
        .then((response) => {
          this.requestStaus = false
          this.clearFields()
      this.$notify(
        {
          message:response.data.message,
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'success'
        })
        })
        .catch((error) => {
          this.requestStaus = false
          this.errors = error.response.data.errors;
        });
    },
    onEdit(item){ // display saved data in fields
     this.setActiveTab('create')
     this.id = item.id;
     this.options = 1;
     this.ingredientName = item.ingredients;
     this.isUpdate = true; // display update button if true else submit
    }, 
    update(){
      this.requestStaus = true
      axios
        .put("/indous-research/master/feedIngredient-master/"+this.id, {
          ingredient_type_id: 1,
          ingredients: this.ingredientName,
        })
        .then((response) => {
          this.requestStaus = false
     this.$notify(
        {
          message: response.data.message,
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'success'
        })
         this.clearFields()
         this.setActiveTab('list')
        })
        .catch((error) => {
          this.requestStaus = false
          this.errors = error.response.data.errors;
        });
    },
    clearErrors() {
      this.errors = {};
    },
    clearFields(){ // clear form fields after submit or update
       this.ingredientName = ""
      this.clearErrors()
      this.fetchFeedIngredientList()
    }
  }
};
</script>

<style scoped>
.custom-md-field {
  /* Adjust the overall size of the md-field if needed */
  width: 190px;
  /* Adjust as needed */
}

.custom-md-input {
  /* Adjust the size of the input */
  width: 50px;
  /* Change the width as needed */
  /* height: 30px; Change the height as needed */
  /*padding: 5px;  Adjust padding as needed */
  font-size: 14px;
  /* Adjust font size as needed */
}

.row {
  --ct-gutter-x: 1.5rem;
  --ct-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--ct-gutter-y));
  margin-right: calc(-0.5 * var(--ct-gutter-x));
  margin-left: calc(-0.5 * var (--ct-gutter-x));
}

.tab-content {
  padding: 20px 0 0 0;
  border: none;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #71b6f9 !important;
}

.align-items-center {
  align-items: center !important;
  padding: 0px 65px 0px 20px;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.col {
  flex: 1 0 0%;
}

.mt-0 {
  margin-top: 0 !important;
}

.header-title {
  font-size: 1rem;
  margin: 0 0 7px 0;
}

.card-box {
  background-color: #fff;
  padding: 1.2rem 0rem 0.2rem 0rem;
  /* list/create */
  -webkit-box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  margin-bottom: 1px;
  border-radius: 0.25rem;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

/* .form-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
} */

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

/* input[type="text"],
input[type="password"],
input[type="email"],
select {
   width: 100%; 
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
} */

.input-group {
  display: inline;
}

/* .input-group input {
  flex: 1;
} */

/* .input-group button {
  margin-left: 10px;
  padding: 8px 12px;
  border: 1px solid #007bff;
  border-radius: 4px;
  background-color: #fff;
  color: #007bff;
  cursor: pointer;
} */

/* .input-group button:hover {
  background-color: #007bff;
  color: #fff;
} */

button[type="submit"],
button[type="button"].cancel-button {
  /* padding: 10px 20px; */
  /* border: none;
  border-radius: 4px; */
  cursor: pointer;
}

/* button[type="submit"].save-button {
  background-color: #28a745;
  color: #fff;
} */

/* button[type="button"].cancel-button {
  background-color: #ccc;
  color: #333;
  margin-left: 10px;
} */

#buttons {
  display: flex;
  flex-direction: row;
}

/* button[type="button"].cancel-button:hover {
  background-color: #b3b3b3;
} */

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: rgb(113, 182, 249);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

.create-field {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.all-buttons {
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
}
</style>