<template>

    <!-- trial(experiment) selection -->
    <div>
        <!-- <div class="form-container">
            <div class="form-groupx">
                <label for="trial-no">Select Trial No</label>
                <select id="trial-no" v-model="selectedTrial" @change="fetchTrialGroups()">
                    <option v-for="trial in trials" :key="trial.id" :value="trial.id">
                    {{ trial.trial_no }}
                    </option>
                </select>
            </div>
        </div> -->

        <div class="row align-items-start mb-3 mt-3 form-container">
            <div v-if="showDownloadButton">
                <button @click="downloadPDF" type="button" class="btn btn-sm btn-success">
                    <md-icon class="text-white">download</md-icon> Download PDF
                </button>
            </div>
        </div>
        
        <div v-if="loadingMain">
            <LoadingBar />
        </div>
        <div v-else-if="!this.trials">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-content>
                        <md-card-header data-background-color="red">
                            <div class="section-title text-center">Experiment details not found</div>
                        </md-card-header>
                        
                    </md-card-content>
                </md-card>
            </div>
        </div>
        <div v-else-if="!this.trialGroupList.length > 0">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-content>
                        <md-card-header data-background-color="red">
                            <div class="section-title text-center">No trial group data found for the given experiment info ID</div>
                        </md-card-header>
                        
                    </md-card-content>
                </md-card>
            </div>
        </div>
        
        <div v-else ref="pdfContent">

            <!-- Body Wt Per Bird in Kgs -->
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-content>
                        <md-card-header data-background-color="green">
                            <h4 class="title">Body Wt Per Bird in Kgs</h4>
                        </md-card-header>
                        <div class="section-title"></div>
                        <div v-if="loading"><LoadingBar></LoadingBar></div>
                        <table class="custom-table" v-else>
                            <thead>
                                <tr>
                                    <th>Age in Days</th>
                                    <th v-for="trial in trialGroupList" :key="trial.id">{{ 'T' + trial.id }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="bodyWt in bodyWeightPerBird" :key="bodyWt.age_in_days_or_week">
                                    <td class="font-weight-bold">{{ bodyWt.age_in_days_or_week }}</td>
                                    <td v-for="trial in trialGroupList" :key="trial.id" class="font-weight-bold">
                                        <span v-if="bodyWt.data.find(bodyWtData => bodyWtData.trial_group_id === trial.id)">
                                            {{ bodyWt.data.find(bodyWtData => bodyWtData.trial_group_id === trial.id).total_avg_body_wt_bird }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                    <!-- <td class="font-weight-bold" v-for="bodyWtData in bodyWt.data" :key="bodyWtData.trial_group_id">{{ bodyWtData ? bodyWtData.total_avg_body_wt_bird : '-' }}</td>
                                    <template v-if="bodyWt.data.length < trialGroupList.length">
                                        <td v-for="trial in (trialGroupList.length - bodyWt.data.length)" :key="trial.id">{{ '-' }}</td>
                                    </template> -->
                                    
                                </tr>
                            </tbody>
                        </table>
                    </md-card-content>
                </md-card>
            </div>



            <!-- Feed Consumption Per Bird in Kgs -->
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-content>
                        <md-card-header data-background-color="green">
                            <h4 class="title">Feed Consumption Per Bird in Kgs</h4>
                        </md-card-header>
                        <div class="section-title"></div>
                        <div v-if="loading"><LoadingBar></LoadingBar></div>
                        <table class="custom-table" v-else>
                            <thead>
                                <tr>
                                    <th>Age in Days</th>
                                    <th v-for="trial in trialGroupList" :key="trial.id">{{ 'T' + trial.id }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="feedConsumed in feedConsumptionPerBird" :key="feedConsumed.age_in_days_or_week">
                                    <td class="font-weight-bold">{{ feedConsumed.age_in_days_or_week }}</td>
                                    <td v-for="trial in trialGroupList" :key="trial.id" class="font-weight-bold">
                                        <span v-if="feedConsumed.data.find(feedConsumedData => feedConsumedData.trial_group_id === trial.id)">
                                            {{ feedConsumed.data.find(feedConsumedData => feedConsumedData.trial_group_id === trial.id).total_feed_consumed_per_bird }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                    <!-- <td class="font-weight-bold" v-for="feedConsumedData in feedConsumed.data" :key="feedConsumedData.trial_group_id">{{ feedConsumedData ? feedConsumedData.total_feed_consumed_per_bird : '-' }}</td>
                                    <template v-if="feedConsumed.data.length < trialGroupList.length">
                                        <td v-for="trial in (trialGroupList.length - feedConsumed.data.length)" :key="trial.id">{{ '-' }}</td>
                                    </template> -->
                                </tr>
                            </tbody>
                        </table>
                    </md-card-content>
                </md-card>
            </div>
            


            <!-- Day Wise FCR -->
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-content>
                        <md-card-header data-background-color="green">
                            <h4 class="title">Day Wise FCR</h4>
                        </md-card-header>
                        <div class="section-title"></div>
                        <div v-if="loading"><LoadingBar></LoadingBar></div>
                        <table class="custom-table" v-else>
                            <thead>
                                <tr>
                                    <th>Age in Days</th>
                                    <th v-for="trial in trialGroupList" :key="trial.id">{{ 'T' + trial.id }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="fcr in dayWiseFcrData" :key="fcr.age_in_days_or_week">
                                    <td class="font-weight-bold">{{ fcr.age_in_days_or_week }}</td>
                                    <td v-for="trial in trialGroupList" :key="trial.id" class="font-weight-bold">
                                        <span v-if="fcr.data.find(fcrData => fcrData.trial_group_id === trial.id)">
                                            {{ fcr.data.find(fcrData => fcrData.trial_group_id === trial.id).fcr }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                    <!-- <td class="font-weight-bold" v-for="fcrData in fcr.data" :key="fcrData.trial_group_id">{{ fcrData ? fcrData.fcr : '-' }}</td>
                                    <template v-if="fcr.data.length < trialGroupList.length">
                                        <td v-for="trial in (trialGroupList.length - fcr.data.length)" :key="trial.id">{{ '-' }}</td>
                                    </template> -->
                                </tr>
                            </tbody>
                        </table>
                    </md-card-content>
                </md-card>
            </div>



            <!-- Day 35 Result Summary -->
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-content>
                        <md-card-header data-background-color="green">
                            <h4 class="title">Final Report</h4>
                        </md-card-header>
                        <div class="section-title"></div>
                        <div v-if="loading"><LoadingBar></LoadingBar></div>
                        <table class="custom-table" v-else>
                            <thead>
                                <tr>
                                    <th>Parameter</th>
                                    <th v-for="trial in trialGroupList" :key="trial.id">{{ 'T' + trial.id }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="font-weight-bold">Bodyweight / Bird (Kg)</td>
                                    <td v-for="trial in trialGroupList" :key="trial.id" class="font-weight-bold">
                                        <span v-if="day35BodyWeight && day35BodyWeight.find(day35BodyWt => day35BodyWt.trial_group_id === trial.id)">
                                            {{ formatDecimalPoints(day35BodyWeight.find(day35BodyWt => day35BodyWt.trial_group_id === trial.id).total_avg_body_wt_bird) }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                    <!-- <td class="font-weight-bold" v-for="(day35BodyWt, index) in day35BodyWeight" :key="index">{{ day35BodyWt ? formatDecimalPoints(day35BodyWt.total_avg_body_wt_bird) : '-' }}</td>
                                    <template v-if="day35BodyWeight.length < trialGroupList.length">
                                        <td v-for="trial in (trialGroupList.length - day35BodyWeight.length)" :key="trial.id">{{ '-' }}</td>
                                    </template> -->

                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Feed Consumed / Bird (Kg)</td>
                                    <td v-for="trial in trialGroupList" :key="trial.id" class="font-weight-bold">
                                        <span v-if="day35FeedConsumption && day35FeedConsumption.find(feedData => feedData.trial_group_id === trial.id)">
                                            {{ formatDecimalPoints(day35FeedConsumption.find(feedData => feedData.trial_group_id === trial.id).total_feed_consumed_per_bird) }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                    <!-- <td class="font-weight-bold" v-for="(feedData, index) in day35FeedConsumption" :key="index">{{ feedData ? formatDecimalPoints(feedData.total_feed_consumed_per_bird) : '-' }}</td>
                                    <template v-if="day35FeedConsumption.length < trialGroupList.length">
                                        <td v-for="trial in (trialGroupList.length - day35FeedConsumption.length)" :key="trial.id">{{ '-' }}</td>
                                    </template> -->
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">FCR</td>
                                    <td v-for="trial in trialGroupList" :key="trial.id" class="font-weight-bold">
                                        <span v-if="day35Fcr && day35Fcr.find(fcrData => fcrData.trial_group_id === trial.id)">
                                            {{ formatDecimalPoints(day35Fcr.find(fcrData => fcrData.trial_group_id === trial.id).fcr) }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                    <!-- <td class="font-weight-bold" v-for="(fcrData, index) in day35Fcr" :key="index">{{ fcrData ? formatDecimalPoints(fcrData.fcr) : '-' }}</td>
                                    <template v-if="day35Fcr.length < trialGroupList.length">
                                        <td v-for="trial in (trialGroupList.length - day35Fcr.length)" :key="trial.id">{{ '-' }}</td>
                                    </template> -->
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">CFCR</td>
                                    <td v-for="trial in trialGroupList" :key="trial.id" class="font-weight-bold">
                                        <span v-if="cfcrDay35 && cfcrDay35.find(cfcrData => cfcrData.trial_group_id === trial.id)">
                                            {{ formatDecimalPoints(cfcrDay35.find(cfcrData => cfcrData.trial_group_id === trial.id).cfcr) }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                    <!-- <td class="font-weight-bold" v-for="(cfcrData, index) in cfcrDay35" :key="index">{{ cfcrData ? formatDecimalPoints(cfcrData.cfcr) : '-' }}</td>
                                    <template v-if="cfcrDay35.length < trialGroupList.length">
                                        <td v-for="trial in (trialGroupList.length - cfcrDay35.length)" :key="trial.id">{{ '-' }}</td>
                                    </template> -->
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Livability (%)</td>
                                    <td v-for="trial in trialGroupList" :key="trial.id" class="font-weight-bold">
                                        <span v-if="livabilityPercentage && livabilityPercentage.find(livabilityData => livabilityData.trial_group_id === trial.id)">
                                            {{ formatDecimalPoints(livabilityPercentage.find(livabilityData => livabilityData.trial_group_id === trial.id).livability, 'fixTo2') }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                    <!-- <td class="font-weight-bold" v-for="(livabilityData, index) in livabilityPercentage" :key="index">{{ livabilityData ? formatDecimalPoints(livabilityData.livability, 'fixTo2') : '-' }}</td>
                                    <template v-if="livabilityPercentage.length < trialGroupList.length">
                                        <td v-for="trial in (trialGroupList.length - livabilityPercentage.length)" :key="trial.id">{{ '-' }}</td>
                                    </template> -->
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">BEI</td>
                                    <td v-for="trial in trialGroupList" :key="trial.id" class="font-weight-bold">
                                        <span v-if="day35Bei && day35Bei.find(beiData => beiData.trial_group_id === trial.id)">
                                            {{ formatDecimalPoints(day35Bei.find(beiData => beiData.trial_group_id === trial.id).bei, 'fixTo2') }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                    <!-- <td class="font-weight-bold" v-for="(beiData, index) in day35Bei" :key="index">{{ beiData ? formatDecimalPoints(beiData.bei, 'fixTo2') : '-' }}</td>
                                    <template v-if="day35Bei.length < trialGroupList.length">
                                        <td v-for="trial in (trialGroupList.length - day35Bei.length)" :key="trial.id">{{ '-' }}</td>
                                    </template> -->
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Feed Cost / Kg</td>
                                    <td v-for="trial in trialGroupList" :key="trial.id" class="font-weight-bold">
                                        <span v-if="feedCostData && feedCostData.find(feedCost => feedCost.trial_group_id === trial.id)">
                                            {{ feedCostData.find(feedCost => feedCost.trial_group_id === trial.id).cost || '-' }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                    <!-- <td class="font-weight-bold" v-for="(feedCost, index) in feedCostData" :key="index">{{ feedCost ? feedCost.cost : '-' }}</td>
                                    <template v-if="feedCostData.length < trialGroupList.length">
                                        <td v-for="trial in (trialGroupList.length - feedCostData.length)" :key="trial.id">{{ '-' }}</td>
                                    </template> -->
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">FD Cost/K.g Live Bird wt with FCR</td>
                                    <td v-for="trial in trialGroupList" :key="trial.id" class="font-weight-bold">
                                        <span v-if="feedCostPerLiveBirdWtFcr && feedCostPerLiveBirdWtFcr.find(feedCostLiveBird => feedCostLiveBird.trial_group_id === trial.id)">
                                            {{ formatDecimalPoints(feedCostPerLiveBirdWtFcr.find(feedCostLiveBird => feedCostLiveBird.trial_group_id === trial.id).fcr_cost, 'fixTo2') }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                    
                                </tr>

                                <tr>
                                    <td class="font-weight-bold">FD Cost/K.g Live Bird wt with CFCR</td>
                                    <td v-for="trial in trialGroupList" :key="trial.id" class="font-weight-bold">
                                        <span v-if="feedCostPerLiveBirdWtCfcr && feedCostPerLiveBirdWtCfcr.find(feedCostLiveBird => feedCostLiveBird.trial_group_id === trial.id)">
                                            {{ formatDecimalPoints(feedCostPerLiveBirdWtCfcr.find(feedCostLiveBird => feedCostLiveBird.trial_group_id === trial.id).cfcr_cost, 'fixTo2') }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="font-weight-bold">Body wt with Mortality Birds</td>
                                    <td v-for="trial in trialGroupList" :key="trial.id" class="font-weight-bold">
                                        <span v-if="mortalBirdData && mortalBirdData.find(mortalData => mortalData.trial_group_id === trial.id)">
                                            {{ mortalBirdData.find(mortalData => mortalData.trial_group_id === trial.id).mortalityBirdWt }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="font-weight-bold">FCR with Mortality birds</td>
                                    <td v-for="trial in trialGroupList" :key="trial.id" class="font-weight-bold">
                                        <span v-if="mortalBirdFcrData && mortalBirdFcrData.find(mortalData => mortalData.trial_group_id === trial.id)">
                                            {{ mortalBirdFcrData.find(mortalData => mortalData.trial_group_id === trial.id).mortal_bird_fcr }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </md-card-content>
                </md-card>
            </div>


            <!-- Male & Female Birds Ratio as Equal Numbers -->
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-content>
                        <md-card-header data-background-color="green">
                            <h4 class="title">Results by Considering Male & Female Birds Ratio as Equal Numbers</h4>
                        </md-card-header>
                        <div class="section-title"></div>
                        <div v-if="loading"><LoadingBar></LoadingBar></div>
                        <table class="custom-table" v-else>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th v-for="trial in trialGroupList" :key="trial.id">{{ 'T' + trial.id }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="font-weight-bold">Body Weight</td>
                                    <td v-for="trial in trialGroupList" :key="trial.id" class="font-weight-bold">
                                        <span v-if="birdRatioData && birdRatioData.find(birdRatio => birdRatio.trial_group_id === trial.id)">
                                            {{ birdRatioData.find(birdRatio => birdRatio.trial_group_id === trial.id).group_average }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="font-weight-bold">CFCR</td>
                                    <td v-for="trial in trialGroupList" :key="trial.id" class="font-weight-bold">
                                        <span v-if="birdRatioCfcr && birdRatioCfcr.find(cfcr => cfcr.trial_group_id === trial.id)">
                                            {{ birdRatioCfcr.find(cfcr => cfcr.trial_group_id === trial.id).cfcr }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="font-weight-bold">Feed Cost/k.g live bird wt with CFCR</td>
                                    <td v-for="trial in trialGroupList" :key="trial.id" class="font-weight-bold">
                                        <span v-if="birdRatioFeedCostPerLiveBirdWtCfcr && birdRatioFeedCostPerLiveBirdWtCfcr.find(birdRatioFeedCost => birdRatioFeedCost.trial_group_id === trial.id)">
                                            {{ formatDecimalPoints(birdRatioFeedCostPerLiveBirdWtCfcr.find(birdRatioFeedCost => birdRatioFeedCost.trial_group_id === trial.id).bird_ratio_cfcr_cost, 'fixTo2') }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </md-card-content>
                </md-card>
            </div>

        <!-- <md-button type="submit" class="md-success">Submit</md-button> -->
        </div>
    </div>
</template>
<script>
    import jsPDF from 'jspdf'; 
    import LoadingBar from '../components/LoadingBar.vue';
    export default {
        components: {
            LoadingBar,
        },
        data(){
            return {
                showDownloadButton: false,
                selectedTrial: null,
                trialGroupList: [],
                loadingMain: false,
                loading: false,
                trials: [],
                bodyWeightPerBird: [],
                feedConsumptionPerBird: [],
                dayWiseFcrData: [],
                day35BodyWeight: [],
                day35FeedConsumption: [],
                day35Fcr: [],
                cfcrDay35: [],
                livabilityPercentage: [],
                feedCostData: [],
                feedCostPerLiveBirdWtFcr: [],
                feedCostPerLiveBirdWtCfcr: [],
                day35Bei: [],
                no_of_birds_remaining: [],
                no_of_birds_alloted: [],
                mortalBirdData: [],
                mortalBirdFcrData: [],
                birdRatioData: [],
                birdRatioCfcr: [],
                birdRatioFeedCostPerLiveBirdWtCfcr: []
            }
        },
        mounted(){
            // this.trials = this.$store.getters.getSelectedTrial;
            // this.selectedTrial = this.trials.id
            // // this.fetchTrials();
            // if(this.selectedTrial){
            //     this.fetchTrialGroups();
            // }
        },
        watch: {
            no_of_birds_remaining(){
                this.day35Summary();
            },
            no_of_birds_alloted(){
                this.day35Summary();
            },
            '$store.getters.getSelectedTrial.id': {
                handler(newVal, oldVal) {
                    if (newVal !== oldVal) {
                    // Update related data when id changes
                        this.trials = this.$store.getters.getSelectedTrial;
                        this.selectedTrial = this.trials.id;
                        this.showDownloadButton = false;
                        this.fetchTrialGroups();
                    }
                },
                immediate: true // Call handler immediately after the component is mounted
            },
        },
        methods: {
            // fetchTrials() {
            //     this.loadingMain = true;
            //     axios.get('/indous-research/master/trials')
            //         .then((response) => {
            //             this.trials = response.data;
            //             if (this.trials.length > 0) {
            //                 this.selectedTrial = this.trials[0].id;
            //                 this.fetchTrialGroups();
            //             }
            //         })
            //         .catch((error) => {
            //             this.loadingMain = false;
            //             this.trials = [];
            //             this.$notify({
            //                 message: 'Experiment details not found',
            //                 horizontalAlign: 'right',
            //                 verticalAlign: 'top',
            //                 type: 'danger'
            //             });
            //         //   console.error('Error fetching trials:', error);
            //         });
            // },
            async fetchTrialGroups(){
                this.showDownloadButton = false;
                this.loadingMain = true;
                this.clearDay35Data();
                this.clearData();
                if(this.selectedTrial != null){
                    try {
                        const response = await axios.get(`indous-research/master/trial-group-list?experiment_info_id=${this.selectedTrial}`);
                        this.trialGroupList = response.data;
                        this.fetchBodyWeightFeedConsumedPerBirdFcr();
                        this.loadingMain = false;
                    } catch (error) {
                        this.loadingMain = false;
                        this.trialGroupList = [];
                        this.$notify({
                            message: error.response.data.message || 'An error occured',
                            horizontalAlign: 'right',
                            verticalAlign: 'top',
                            type: 'danger'
                        });
                    }
                }
            },

            clearDay35Data(){
                this.day35BodyWeight = [];
                this.day35FeedConsumption = [];
                this.day35Fcr = [];
                this.cfcrDay35 = [];
                this.livabilityPercentage = [];
                // this.feedCostData = [];
                this.feedCostPerLiveBirdWtFcr = [];
                this.feedCostPerLiveBirdWtCfcr = [];
                this.day35Bei = [];
                this.birdRatioCfcr = [];
                this.birdRatioFeedCostPerLiveBirdWtCfcr = [];
            },

            clearData(){
                this.bodyWeightPerBird = [];
                this.feedConsumptionPerBird = [];
                this.dayWiseFcrData = [];
                this.mortalBirdData = [];
                this.mortalBirdFcrData = [];
            },

            formatDecimalPoints(value, key) {
                if (value !== null && value !== undefined && value !== "") {
                    return Number(value) % 1 === 0
                    ? Number(value).toFixed(0)
                    : key ? Number(value).toFixed(2): Number(value).toFixed(3);
                }
                return "";
            },

            async fetchBodyWeightFeedConsumedPerBirdFcr() {
                this.loading = true;
                this.clearData();
                try {
                    const response = await axios.get(`/indous-research/get-average-body-weight-feed-consumed-fcr/${this.selectedTrial}`);
                    this.bodyWeightPerBird = response.data.bodyWtResult;
                    this.feedConsumptionPerBird = response.data.feedConsumedResult;
                    this.dayWiseFcrData = response.data.fcrData;
                    this.mortalBirdData = response.data.mortalBirdDetails.mortalBirdData;
                    this.mortalBirdFcrData = response.data.mortalBirdDetails.mortalBirdFcr;
                    this.fetchBirdRatioData();
                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                }
            },
            async fetchBirdRatioData() {
                this.birdRatioData = [];
                try {
                    const response = await axios.get(`/indous-research/reports/bird-ratio?exp_info_id=${this.selectedTrial}`);
                    this.birdRatioData = response.data.birdRatioReportList;
                    this.fetchBirdCountAndFeedCostData();
                } catch (error) {
                    this.loading = false;
                }
            },
            async fetchBirdCountAndFeedCostData(){
                this.no_of_birds_remaining = [];
                this.no_of_birds_alloted = [];
                this.feedCostData = [];
                try {
                    const response = await axios.get(`/fetch-bird-count-details?expId=${this.selectedTrial}`);
                    this.no_of_birds_remaining = response.data.remainingBirds;
                    this.no_of_birds_alloted = response.data.allotedBirds;
                    this.feedCostData = response.data.feedCostData;
                    // this.mortalBirdDetails();
                    this.day35Summary();
                } catch (error) {
                    this.loading = false;
                }
            },
            // async mortalBirdDetails(){
            //     try {
            //         const response = await axios.get(`/fetch-mortal-bird-details?expId=${this.selectedTrial}`);
            //         this.mortalBirdData = response.data;
            //     } catch (error) {
                    
            //     }
            // },
            day35Summary(){
                this.clearDay35Data();
                // this.showDownloadButton = false;
                if(this.bodyWeightPerBird){
                    this.bodyWeightPerBird.forEach(bodyWeight => {
                        if(bodyWeight.age_in_days_or_week == 35){
                            this.day35BodyWeight = bodyWeight.data;
                        }
                    })
                }

                if(this.feedConsumptionPerBird){
                    this.feedConsumptionPerBird.forEach(feedData => {
                        if(feedData.age_in_days_or_week == 35){
                            this.day35FeedConsumption = feedData.data;
                        }
                    })
                }

                if(this.dayWiseFcrData){
                    this.dayWiseFcrData.forEach(fcr => {
                        if(fcr.age_in_days_or_week == 35){
                            this.day35Fcr = fcr.data;
                        }
                    })
                }

                if(this.day35BodyWeight && this.day35Fcr){
                    this.day35BodyWeight.forEach( day35bodyWtData => {
                        this.day35Fcr.forEach( day35fcrData => {
                            if(day35bodyWtData.trial_group_id == day35fcrData.trial_group_id){
                                const newObj = {};
                                newObj.cfcr = day35bodyWtData.total_avg_body_wt_bird && day35fcrData.fcr ? ((2 - Number(day35bodyWtData.total_avg_body_wt_bird)) * 0.2 + Number(day35fcrData.fcr)).toFixed(3) : 0;
                                newObj.trial_group_id = day35bodyWtData.trial_group_id;
                                this.cfcrDay35.push(newObj);
                            }
                        })
                    })
                }

                if(this.no_of_birds_alloted.length > 0 && this.no_of_birds_remaining.length){
                    this.no_of_birds_alloted.forEach( noOfBirdsAlloted => {
                        this.no_of_birds_remaining.forEach( noOfBirdsRemaining => {
                            if(noOfBirdsAlloted.trial_group_id == noOfBirdsRemaining.trial_group_id){
                                const newObj = {};
                                newObj.livability = noOfBirdsRemaining.no_of_birds_remaining && noOfBirdsAlloted.groupwise_bird_count ? ((Number(noOfBirdsRemaining.no_of_birds_remaining) * 100) / Number(noOfBirdsAlloted.groupwise_bird_count)).toFixed(3) : 0;
                                newObj.trial_group_id = noOfBirdsAlloted.trial_group_id;
                                this.livabilityPercentage.push(newObj);
                            }
                        })
                    })
                }

                // if(this.no_of_birds_alloted.length > 0){
                //     this.no_of_birds_alloted.forEach( noOfBirdsAlloted => {
                //         const newObj = {};
                //         newObj.cost = noOfBirdsAlloted.feed_cost ? noOfBirdsAlloted.feed_cost : 0; 
                //         newObj.trial_group_id = noOfBirdsAlloted.trial_group_id;
                //         this.feedCostData.push(newObj);
                //     })
                // }

                if(this.day35Fcr && this.feedCostData){
                    this.day35Fcr.forEach( fcrData => {
                        this.feedCostData.forEach( feedCost => {
                            if(fcrData.trial_group_id == feedCost.trial_group_id){
                                const newObj = {};
                                newObj.fcr_cost = fcrData.fcr && feedCost.cost ? (Number(fcrData.fcr) * Number(feedCost.cost)).toFixed(3) : 0;
                                newObj.trial_group_id = fcrData.trial_group_id;
                                this.feedCostPerLiveBirdWtFcr.push(newObj);
                            }
                        })
                    })
                }

                if(this.day35BodyWeight && this.livabilityPercentage && this.day35Fcr){
                    this.day35BodyWeight.forEach( bodyWeight => {
                        this.livabilityPercentage.forEach( livability => {
                            this.day35Fcr.forEach( fcrData => {
                                if(bodyWeight.trial_group_id == livability.trial_group_id && livability.trial_group_id == fcrData.trial_group_id){
                                    const newObj = {};
                                    newObj.bei = bodyWeight.total_avg_body_wt_bird && livability.livability && fcrData.fcr ? Math.round((Number(bodyWeight.total_avg_body_wt_bird) * Number(livability.livability) * 100) / (35 * fcrData.fcr)) : 0;
                                    newObj.trial_group_id = fcrData.trial_group_id;
                                    this.day35Bei.push(newObj);
                                }  
                            })
                        })
                    })
                }

                if(this.cfcrDay35 && this.feedCostData){
                    this.cfcrDay35.forEach( cfcrData => {
                        this.feedCostData.forEach( feedCost => {
                            if(cfcrData.trial_group_id == feedCost.trial_group_id){
                                const newObj = {};
                                newObj.cfcr_cost = cfcrData.cfcr && feedCost.cost ? (Number(cfcrData.cfcr) * Number(feedCost.cost)).toFixed(3) : 0;
                                newObj.trial_group_id = cfcrData.trial_group_id;
                                this.feedCostPerLiveBirdWtCfcr.push(newObj);
                            }
                        })
                    })
                }

                if(this.birdRatioData && this.day35Fcr){
                    this.day35Fcr.forEach( fcrData => {
                        this.birdRatioData.forEach( birdRatio => {
                            if(fcrData.trial_group_id == birdRatio.trial_group_id){
                                const newObj = {};
                                newObj.cfcr = birdRatio.group_average && fcrData.fcr ? ((2 - Number(birdRatio.group_average)) * 0.2 + Number(fcrData.fcr)).toFixed(3) : 0;
                                newObj.trial_group_id = fcrData.trial_group_id;
                                this.birdRatioCfcr.push(newObj);
                            }
                        })
                    })
                }

                if(this.birdRatioCfcr && this.feedCostData){
                    this.birdRatioCfcr.forEach( cfcrData => {
                        this.feedCostData.forEach( feedCost => {
                            if(cfcrData.trial_group_id == feedCost.trial_group_id){
                                const newObj = {};
                                newObj.bird_ratio_cfcr_cost = cfcrData.cfcr && feedCost.cost ? (Number(cfcrData.cfcr) * Number(feedCost.cost)).toFixed(3) : 0;
                                newObj.trial_group_id = cfcrData.trial_group_id;
                                this.birdRatioFeedCostPerLiveBirdWtCfcr.push(newObj);
                            }
                        })
                    })
                }
                
                setTimeout(() => {
                    this.showDownloadButton = true;
                }, 2500)
            },

            downloadPDF() {
                const doc = new jsPDF();

                // Main Heading (Final Report)
                doc.setFontSize(20); // Large font size for the main heading
                doc.setTextColor(40);

                const finalReport = "Final Report";
                const pageWidth = doc.internal.pageSize.width;
                const textWidth = doc.getTextWidth(finalReport);
                const xPosition = (pageWidth - textWidth) / 2;

                // Write the main heading at the top
                doc.text(finalReport, xPosition, 20); // Position at the top with Y position 25

                doc.setFontSize(11); // Set a smaller font size for the subheading
                const trialInfo = this.trials.trial_no; // Your trial info text
                const trialTextWidth = doc.getTextWidth(trialInfo);
                const trialXPosition = (pageWidth - trialTextWidth) / 2; // Center align the subheading

                // Write the trial information just below the main heading
                doc.text(trialInfo, trialXPosition, 27);

                // Subheading (Body Wt Per Bird in Kgs)
                doc.setFontSize(13); // Smaller font size for the subheading
                const leftMargin = 20; // Adjust left margin as needed
                const bodyWeightTableTitle = "Body Wt Per Bird in Kgs";

                // Write the subheading below the main heading
                doc.text(bodyWeightTableTitle, leftMargin, 45); // Adjusted Y position to 45 for space below the main heading

                // Prepare body data
                let trialGroupIds = [];
                this.bodyWeightPerBird.forEach(record => {
                    record.data.forEach(item => {
                        if (!trialGroupIds.includes(item.trial_group_id)) {
                            trialGroupIds.push(item.trial_group_id);
                        }
                    });
                });

                // Sort trial group IDs in ascending order
                trialGroupIds.sort((a, b) => a - b);

                // Create header row with dynamic trial groups (e.g., T1, T2, T3, ...)
                const headers = [["Age in Days", ...trialGroupIds.map(id => `T${id}`)]];

                // Prepare body data dynamically
                let body = [];
                this.bodyWeightPerBird.forEach((record) => {
                    const row = [
                        record.age_in_days_or_week, // Age in Days
                        ...trialGroupIds.map(id => this.getBodyWeightForTrial(record.data, id)) // Add body weight for each trial group
                    ];
                    body.push(row);
                });

                const tableWidth = pageWidth - 40;

                // Create the table starting further down below the subheading
                doc.autoTable({
                    startY: 48, // Start the table below the subheading (adjusted to 48 for more space)
                    margin: { top: 10, left: (pageWidth - tableWidth) / 2, right: (pageWidth - tableWidth) / 2 },
                    head: headers,
                    body: body,
                    headStyles: {
                        fillColor: [0, 57, 107],
                        halign: "center", // Center the text horizontally in header cells
                        fontSize: 11,
                        textColor: [255, 255, 255],
                    },
                    bodyStyles: {
                        halign: "center", // Center the text horizontally in body cells
                        fontSize: 9,
                        textColor: [0, 0, 0],
                        cellPadding: 2.2,
                        lineHeight: 1.6,
                    },
                    columnStyles: {
                        0: { cellWidth: 'auto' },
                        ...trialGroupIds.reduce((acc, _, index) => {
                            acc[index + 1] = { cellWidth: 'auto' }; // Set width for each trial group column
                            return acc;
                        }, {})
                    },
                });

                const previousTableHeight = doc.previousAutoTable.finalY; 
                const feedConsumptionTableTitle = "Feed Consumption Per Bird in Kgs";
                doc.text(feedConsumptionTableTitle, leftMargin, previousTableHeight + 20);

                let feedBody = [];
                this.feedConsumptionPerBird.forEach((record) => {
                    const row = [
                        record.age_in_days_or_week,
                        ...trialGroupIds.map(id => this.getFeedConsumptionForTrial(record.data, id))
                    ];
                    feedBody.push(row);
                });

                doc.autoTable({
                    startY: previousTableHeight + 23, 
                    margin: { top: 10, left: (pageWidth - tableWidth) / 2, right: (pageWidth - tableWidth) / 2 },
                    head: headers,
                    body: feedBody,
                    headStyles: {
                        fillColor: [0, 57, 107],
                        halign: "center",
                        fontSize: 11,
                        textColor: [255, 255, 255],
                    },
                    bodyStyles: {
                        halign: "center",
                        fontSize: 9,
                        textColor: [0, 0, 0],
                        cellPadding: 2.2,
                        lineHeight: 1.6,
                    },
                    columnStyles: {
                        0: { cellWidth: 'auto' },
                        ...trialGroupIds.reduce((acc, _, index) => {
                            acc[index + 1] = { cellWidth: 'auto' };
                            return acc;
                        }, {})
                    },
                });

                const feedConsumptionTableHeight = doc.previousAutoTable.finalY;
                const fcrTableTitle = "Day Wise FCR";
                doc.text(fcrTableTitle, leftMargin, feedConsumptionTableHeight + 20);

                let fcrBody = [];
                this.dayWiseFcrData.forEach((record) => {
                    const row = [
                        record.age_in_days_or_week,
                        ...trialGroupIds.map(id => this.getFCRForTrial(record.data, id))
                    ];
                    fcrBody.push(row);
                });

                doc.autoTable({
                    startY: feedConsumptionTableHeight + 23, 
                    margin: { top: 10, left: (pageWidth - tableWidth) / 2, right: (pageWidth - tableWidth) / 2 },
                    head: headers,
                    body: fcrBody,
                    headStyles: {
                        fillColor: [0, 57, 107],
                        halign: "center",
                        fontSize: 11,
                        textColor: [255, 255, 255],
                    },
                    bodyStyles: {
                        halign: "center",
                        fontSize: 9,
                        textColor: [0, 0, 0],
                        cellPadding: 2.2,
                        lineHeight: 1.6,
                    },
                    columnStyles: {
                        0: { cellWidth: 'auto' },
                        ...trialGroupIds.reduce((acc, _, index) => {
                            acc[index + 1] = { cellWidth: 'auto' };
                            return acc;
                        }, {})
                    },
                });

                doc.addPage();
                const newPageTopMargin = 20;
                const day35SummaryTitle = "Final Report";
                doc.text(day35SummaryTitle, leftMargin, newPageTopMargin); 

                const day35Headers = [["Parameter", ...trialGroupIds.map(id => `T${id}`)]];

                const summaryBody = [
                    ["Bodyweight / Bird (Kg)", ...this.getDay35Values(this.day35BodyWeight, trialGroupIds)],
                    ["Feed Consumed / Bird (Kg)", ...this.getDay35Values(this.day35FeedConsumption, trialGroupIds)],
                    ["FCR", ...this.getDay35Values(this.day35Fcr, trialGroupIds)],
                    ["CFCR", ...this.getDay35Values(this.cfcrDay35, trialGroupIds)],
                    ["Livability (%)", ...this.getDay35Values(this.livabilityPercentage, trialGroupIds)],
                    ["BEI", ...this.getDay35Values(this.day35Bei, trialGroupIds)],
                    ["Feed Cost / Kg", ...this.getDay35Values(this.feedCostData, trialGroupIds)],
                    ["FD Cost/K.g Live Bird wt with FCR", ...this.getDay35Values(this.feedCostPerLiveBirdWtFcr, trialGroupIds)],
                    ["FD Cost/K.g Live Bird wt with CFCR", ...this.getDay35Values(this.feedCostPerLiveBirdWtCfcr, trialGroupIds)],
                    ["Body wt with Mortality Birds", ...this.getDay35Values(this.mortalBirdData, trialGroupIds)],
                    ["FCR with Mortality birds", ...this.getDay35Values(this.mortalBirdFcrData, trialGroupIds)],
                ];

                doc.autoTable({
                    startY: 23,
                    margin: { top: 10, left: (pageWidth - tableWidth) / 2, right: (pageWidth - tableWidth) / 2 },
                    head: day35Headers,
                    body: summaryBody,
                    headStyles: {
                        fillColor: [0, 57, 107],
                        halign: "center",
                        fontSize: 11,
                        textColor: [255, 255, 255],
                    },
                    bodyStyles: {
                        halign: "center",
                        fontSize: 9,
                        textColor: [0, 0, 0],
                        cellPadding: 2.2,
                        lineHeight: 1.6,
                    },
                    columnStyles: {
                        0: { cellWidth: 'auto' },
                        ...trialGroupIds.reduce((acc, _, index) => {
                            acc[index + 1] = { cellWidth: 'auto' };
                            return acc;
                        }, {})
                    },
                });

                const day35TableHeight = doc.previousAutoTable.finalY;
                const birdRatioTitle = "Results by Considering Male & Female Birds Ratio as Equal Numbers";
                doc.text(birdRatioTitle, leftMargin, day35TableHeight + 20);

                const birdRatioHeaders = [[" ", ...trialGroupIds.map(id => `T${id}`)]];
                const birdRatioBody = [
                    ["Body Weight", ...this.getBirdRatioValues(this.birdRatioData, trialGroupIds)],
                    ["CFCR", ...this.getBirdRatioValues(this.birdRatioCfcr, trialGroupIds)],
                    ["Feed Cost/k.g live bird wt with CFCR", ...this.getBirdRatioValues(this.birdRatioFeedCostPerLiveBirdWtCfcr, trialGroupIds)],
                ];

                doc.autoTable({
                    startY: day35TableHeight + 23,
                    margin: { top: 10, left: (pageWidth - tableWidth) / 2, right: (pageWidth - tableWidth) / 2 },
                    head: birdRatioHeaders,
                    body: birdRatioBody,
                    headStyles: {
                        fillColor: [0, 57, 107],
                        halign: "center",
                        fontSize: 11,
                        textColor: [255, 255, 255],
                    },
                    bodyStyles: {
                        halign: "center",
                        fontSize: 9,
                        textColor: [0, 0, 0],
                        cellPadding: 2.2,
                        lineHeight: 1.6,
                    },
                    columnStyles: {
                        0: { cellWidth: 'auto' },
                        ...trialGroupIds.reduce((acc, _, index) => {
                            acc[index + 1] = { cellWidth: 'auto' };
                            return acc;
                        }, {})
                    },
                });

                // Save the PDF
                doc.save("final_report.pdf");
            },

            getBodyWeightForTrial(data, trialGroupId) {
                const trialData = data.find(item => item.trial_group_id === trialGroupId);
                return trialData ? trialData.total_avg_body_wt_bird : "-";
            },

            getFeedConsumptionForTrial(data, trialGroupId) {
                const trialData = data.find(item => item.trial_group_id === trialGroupId);
                return trialData ? trialData.total_feed_consumed_per_bird : "-";
            },
            getFCRForTrial(data, trialGroupId) {
                const trialData = data.find(item => item.trial_group_id === trialGroupId);
                return trialData ? trialData.fcr : "-";
            },
            getDay35Values(dataArray, trialGroupIds) {
                return trialGroupIds.map(id => {
                    const trialData = dataArray.find(item => item.trial_group_id === id);
                    return trialData ? this.formatDecimalPoints(trialData.total_avg_body_wt_bird) || this.formatDecimalPoints(trialData.total_feed_consumed_per_bird) || this.formatDecimalPoints(trialData.fcr) || this.formatDecimalPoints(trialData.cfcr) || this.formatDecimalPoints(trialData.livability, 'fix2To') || trialData.cost || this.formatDecimalPoints(trialData.fcr_cost, 'fix2To') || this.formatDecimalPoints(trialData.cfcr_cost, 'fix2To') || this.formatDecimalPoints(trialData.bird_ratio_cfcr_cost, 'fix2To') || this.formatDecimalPoints(trialData.bei, 'fixTo2') || trialData.mortalityBirdWt || trialData.mortal_bird_fcr || "-" : "-";
                });
            },
            getBirdRatioValues(dataArray, trialGroupIds) {
                // Map trial group values from the array, defaulting to "-" if no data is available
                return trialGroupIds.map(id => {
                    const trialData = dataArray.find(item => item.trial_group_id === id);
                    return trialData ? this.formatDecimalPoints(trialData.group_average) || this.formatDecimalPoints(trialData.cfcr) || this.formatDecimalPoints(trialData.bird_ratio_cfcr_cost, 'fixTo2') || "-" : "-";
                });
            }

        }
    }
</script>
<style scoped>
    .custom-table {
    width: 100%;
    border-collapse: collapse;
    }
    .custom-table th,
    .custom-table td {
    padding: 8px;
    /* text-align: left; */
    border-bottom: 1px solid #ddd;
    width: 50px;
    }
    .custom-table th {
    background-color: #f5f5f5;
    font-size: 16px;
    }
    .custom-table .slct,
    .custom-table .custm {
    width: 100%;
    box-sizing: border-box;
    }
</style>