<template>
  <div>
    <div v-if="activeTab === 'list'">
      <div class="row align-items-center mb-3">
        <div class="col-md-4 d-flex">
          <label for="" class="d-flex mt-2 font-weight-bold">
            Start date
          </label>
          <input
            type="text"
            v-model="startDate"
            disabled
            class="form-control w-50 ml-2 pl-2"
          />
        </div>
        <div class="col-md-4 d-flex">
          <label for="" class="d-flex mt-2 font-weight-bold"> End date </label>
          <input
            type="text"
            v-model="endDate"
            disabled
            class="form-control w-50 ml-2 pl-2"
          />
        </div>
      </div>

      <div class="row align-items-center mb-3">
        <div class="col-md-2">
      
        </div>
        <span class="md-body-2 m-3" v-if="Object.keys(reportData).length > 0 && availableWeeks.length > 0">Select Weeks</span>
        <div class="col-md-2" v-if="Object.keys(reportData).length > 0 && availableWeeks.length > 0">
  
          <div class="w-100">
            <div
              class="checkbox-select__trigger"
              :class="{ isActive: isDropdownOpen }"
              @click="toggleDropdown"
              :style="{ cursor: 'pointer' }"
            >
              <span style="font-size: 12px">Select the weeks</span>
              <i :class="isDropdownOpen ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"></i>
            </div>
            <div id="div" class="checkbox-select__dropdown" v-show="isDropdownOpen">
              <div class="checkbox-select__col">
                <div class="checkbox-select__info">
                  {{ selectedWeeks.length }} SELECTED
                </div>
              </div>
              <ul
                id="customScroll"
                class="checkbox-select__filters-wrapp"
                data-simplebar-auto-hide="false"
              >
                <li
                  v-for="(week, index) in availableWeeks"
                  :key="index"
                  style="list-style: none"
                >
                  <div class="checkbox-select__check-wrapp">
                    <input
                      :id="'week' + index"
                      class="conditions-check"
                      type="checkbox"
                      :value="week"
                      v-model="selectedWeeks"
                      @change="event => event.target.checked ? handleWeekSelection(week, index) : handleWeekDeselection(week, index)"
                    />
                    <label :for="'week' + index" class="text-capitalize">
                      {{ week }}
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-md-2">
          
          <!-- <md-button class="greyblue-button">Generate Report</md-button> -->
          <button
            type="button"
            class="btn btn-sm btn-info"
            @click="generateWeeklyReport"
            :disabled="!trialNoDrp"
          >
            <md-icon class="text-white">list</md-icon> Generate Report
          </button>
        </div>

        <div class="col-md-2">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            v-if="Object.keys(reportData).length > 0"
            @click="exportToExcel"
          >
            <md-icon class="text-white">download</md-icon> Export Excel
          </button>
        </div>

        <div class="col-md-2">
          <button
            v-if="Object.keys(reportData).length > 0"
            type="button"
            class="btn btn-sm btn-success"
            @click="exportToPDF"
          >
            <md-icon class="text-white">download</md-icon>Download PDF
          </button>
        </div>
      </div>

      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">WEEKLY PENWISE REPORT</h4>
          </md-card-header>
          <md-card-content>
            <md-table
              v-model="users"
              :table-header-color="tableHeaderColor"
              class="custom-table"
            >
              <tr
                class="bordered-cell"
                v-if="Object.keys(reportData).length === 0"
              >
                <th></th>
                <th
                  colspan="3"
                  class="highlight-black-color"
                  style="background-color: #b2beb5"
                >
                  Day 1
                </th>
                <th colspan="4" class="highlight-black-color">Week 1</th>
                <th
                  colspan="4"
                  class="highlight-black-color"
                  style="background-color: #b2beb5"
                >
                  Week 2
                </th>
                <th colspan="4" class="highlight-black-color">Week 3</th>
                <th
                  colspan="4"
                  class="highlight-black-color"
                  style="background-color: #b2beb5"
                >
                  Week 4
                </th>
                <th colspan="4" class="highlight-black-color">Week 5</th>
              </tr>
              <!-- Table Headers -->
              <tr class="bordered-cell" v-else>
                <th></th>
                <th
                  v-if="availableColumns.includes('Day 1')"
                  colspan="3"
                  class="highlight-black-color"
                  style="background-color: #b2beb5"
                >
                  Day 1
                </th>
                <!-- Dynamic Week columns -->
                <template v-for="(week, index) in selectedWeeks">
                  <th
                    :key="index"
                    :colspan="4"
                    class="highlight-black-color"
                    :style="index % 2 != 0 ? 'background-color: #b2beb5' : ''"
                  >
                    {{ week }}
                  </th>
               </template>
              </tr>
              <tr
                v-if="dataFetched && Object.keys(reportData).length > 0"
                class="bordered-cell"
              >
                <th class="highlight-black-color">Group No</th>
                <th>Pen No.</th>
                <template v-if="availableColumns.includes('Day 1')">
                  <th>No of birds</th>
                  <th class="highlight-black-color">Avg wt/bird</th>
                </template>
                <!-- <th>No of birds</th>
                <th class="highlight-black-color">Avg wt/bird</th> -->
                <template v-for="(week, index) in selectedWeeks">
                  <th :key="index">No of birds</th>
                  <th :key="'feed-consumed-' + week">Feed consumed per bird</th>
                  <th :key="'avg-wt-bird-' + week">Avg wt/bird</th>
                  <th class="highlight-black-color" :key="'fcr-' + week">
                    FCR
                  </th>
                </template>
              </tr>
              <tr v-if="loading">
                <td colspan="100%">
                  <loading-bar></loading-bar>
                </td>
              </tr>
              <tr v-if="showGenerate">
                <td colspan="100%" class="no-records-message">
                  Click the button to generate the report
                </td>
              </tr>
              <template
                v-if="dataFetched && Object.keys(reportData).length > 0"
              >
                <template v-for="(trialGroup, trialGroupId) in reportData">
                  <tr
                    v-for="(penData, penId, penIndex) in trialGroup.pens"
                    :key="penId"
                  >
                    <td
                      v-if="penIndex === 0"
                      class="font-weight-bold highlight-black-color highlight-bottom-color"
                      :rowspan="Object.keys(trialGroup.pens).length + 1"
                    >
                      T{{ trialGroupId }}
                    </td>
                    <td>{{ penId }}</td>
                    <!-- Day 1 -->
                    <template v-if="penData['Day 1']">
                      <td>{{ penData["Day 1"].no_of_birds || "-" }}</td>
                      <td class="highlight-black-color">
                        {{ penData["Day 1"].avg_wt_bird || "-" }}
                      </td>
                    </template>

                  <template v-for="week in selectedWeeks">
                    <template v-if="penData[week]" >
                      <td :key="week">{{ penData[week].no_of_birds || '-' }}</td>
                      <td :key="week">{{ penData[week].feed_consumed_per_bird || '-' }}</td>
                      <td :key="week">{{ penData[week].avg_wt_bird || '-' }}</td>
                      <td :key="week" class="highlight-black-color highlight-bg-color">{{ penData[week].fcr || '-' }}</td>
                    </template>
                  </template>
                    
                  </tr>

                  <!-- Totals row -->
                  <template>
                    <tr
                      :key="'total-' + trialGroupId"
                      style="background-color: #ffff96"
                    >
                      <td class="bordered-cell"><b>Total</b></td>
                      <!-- Week 1 Total -->
                      <template
                        v-if="
                          trialGroup.totals['Day 1'] &&
                          trialGroup.totals['Day 1'].no_of_birds > 0
                        "
                      >
                        <td class="font-weight-bold bordered-cell">
                          {{ trialGroup.totals["Day 1"].no_of_birds }}
                        </td>

                        <td
                          class="font-weight-bold bordered-cell highlight-black-color"
                        >
                          {{
                            trialGroup.totals["Day 1"].avg_wt_bird.toFixed(3)
                          }}
                        </td>
                      </template>
                    
                      
                     
                      <template v-for="week in selectedWeeks">
                          <template v-if="trialGroup.totals[week] && trialGroup.totals[week].no_of_birds > 0">
                            <td :key="week" class="bordered-cell font-weight-bold">{{ trialGroup.totals[week].no_of_birds }}</td>
                            <td  :key="week" class="bordered-cell font-weight-bold">{{ trialGroup.totals[week].feed_consumed_per_bird.toFixed(3) }}</td>
                            <td  :key="week" class="bordered-cell font-weight-bold">{{ trialGroup.totals[week].avg_wt_bird.toFixed(3) }}</td>
                            <td  :key="week" class="font-weight-bold bordered-cell highlight-black-color">{{ trialGroup.totals[week].fcr.toFixed(3) }}</td>
                          </template>
                      </template>
                    </tr>
                  </template>
                </template>

                <template>
                        <tr
                          :key="'total-' + trialGroupId"
                          class="border-dark"
                          style="background-color: #67e057"
                          v-if="Object.keys(reportData).length > 0"
                        >
                          <td><b>Grand Total</b></td>

                          <!-- Display Day 1 Total if applicable -->
                          <template v-if="grandTotal['Day 1'].no_of_birds > 0">
                            <td class="font-weight-bold"></td>
                            <td class="font-weight-bold">
                              {{ grandTotal["Day 1"].no_of_birds }} Birds
                            </td>
                            <td class="font-weight-bold"></td>
                          </template>

                          <!-- Iterate through selectedWeeks dynamically -->
                          <template v-for="week in selectedWeeks" >
                            <template v-if="grandTotal[week]?.no_of_birds > 0">
                              <td :key="week" class="font-weight-bold">
                                {{ grandTotal[week].no_of_birds }} Birds
                              </td>
                              <td :key="week" class="font-weight-bold"></td>
                              <td :key="week" class="font-weight-bold"></td>
                              <td :key="week" class="font-weight-bold"></td>
                            </template>
                          </template>
                        </tr>
                </template>
              </template>
                    <template
                      v-if="
                        dataFetched === false &&
                        Object.keys(reportData).length === 0 &&
                        !this.showGenerate
                      "
                    >
                      <tr>
                        <td colspan="100%" class="no-records-message">
                          No records found
                        </td>
                      </tr>
                    </template>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>
  
<script>
import LoadingBar from "../../components/LoadingBar.vue";
import jsPDF from "jspdf";
import "jspdf-autotable";
export default {
  name: "simple-table",
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      activeTab: "list", // Default active tab
      trialNoList: [],
      isDropdownOpen: false,
      selectedWeeks: [],
      availableColumns: [],
      availableWeeks: [],
      reportData: {},
      dataFetched: "",
      loading: false,
      showGenerate: true,
      expInfoId: "",
      trialNo: "",
      trialNoDrp: "",
      startDate: "",
      endDate: "",
    };
  },
  components: {
    LoadingBar,
  },

  mounted() {
    // this.getTrialNoList();
    this.trialNoDrp =this.$store.getters.getSelectedTrial.id;
    this.trialNo = this.$store.getters.getSelectedTrial.trial_no;
    this.startDate = this.$store.getters.getSelectedTrial.start_date;
    this.endDate = this.$store.getters.getSelectedTrial.end_date;
    this.filterStartDate();
  },
  watch: {
    // Watch for changes in the selected trial's id
    '$store.getters.getSelectedTrial.id': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          // Update related data when id changes
          this.trialNoDrp = newVal;
          this.trialNo = this.$store.getters.getSelectedTrial.trial_no;
          this.startDate = this.$store.getters.getSelectedTrial.start_date;
          this.endDate = this.$store.getters.getSelectedTrial.end_date;
          
          // Call the filterStartDate method whenever the trial id changes
          this.filterStartDate();
        }
      },
      immediate: true // Call handler immediately after the component is mounted
    }
  },

  computed: {
    selectedTrialNo(){
      return this.$store.getters.getSelectedTrial.trial_no;
    },
    grandTotal() {
      const totals = {
        "Day 1": { no_of_birds: 0 },
        "Week 1": { no_of_birds: 0 },
        "Week 2": { no_of_birds: 0 },
        "Week 3": { no_of_birds: 0 },
        "Week 4": { no_of_birds: 0 },
        "Week 5": { no_of_birds: 0 },
      };
      for (const trialGroup of Object.values(this.reportData)) {
        for (const penData of Object.values(trialGroup.pens)) {
          for (const week of Object.keys(totals)) {
            if (penData[week]) {
              totals[week].no_of_birds += penData[week].no_of_birds || 0;
            }
          }
        }
      }

      return totals;
    },
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    handleWeekSelection(selectedWeek, selectedIndex) {
  this.selectedWeeks = [];
  for (let i = 0; i <= selectedIndex; i++) {
    const week = this.availableWeeks[i];
    this.selectedWeeks.push(week);
  }
},

handleWeekDeselection(selectedWeek, selectedIndex) {
  for (let i = selectedIndex; i < this.availableWeeks.length; i++) {
    const week = this.availableWeeks[i];
    const index = this.selectedWeeks.indexOf(week);
    if (index !== -1) {
      this.selectedWeeks.splice(index, 1);
    }
  }
},
    determineAvailableColumns() {
      this.availableColumns = [];
      this.availableWeeks = [];
      if (Object.keys(this.reportData).length > 0) {
        Object.values(this.reportData).forEach((trialGroup) => {
          Object.values(trialGroup.pens).forEach((penData) => {
            if (penData["Day 1"]) {
              if (!this.availableColumns.includes("Day 1")) {
                this.availableColumns.push("Day 1");
              }
            }
            for (let i = 1; i <= 5; i++) {
              const week = `Week ${i}`;
              if (penData[week]) {
                if (!this.availableWeeks.includes(week)) {
                  this.availableWeeks.push(week);
                }
              }
            }
          });
        });
        this.selectedWeeks = [...this.availableWeeks];
      }
    },

    generateWeeklyReport() {
      this.loading = true;
      this.showGenerate = false;
      this.dataFetched = "";
      axios
        .get(
          `indous-research/reports/weekly-penwise-report?experiment_id=${this.trialNoDrp}`
        )
        .then((response) => {
          const data = response.data;
          this.reportData = data;
          this.dataFetched = true;
          this.loading = false;
          this.determineAvailableColumns();
        })
        .catch((error) => {
          this.reportData = {};
          this.dataFetched = false;
          this.loading = false;
        });
    },
    isActive(tab) {
      return this.activeTab === tab;
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    calculateTotal(pens, weekOrDay, field) {
      return Object.values(pens)
        .reduce((total, penData) => {
          const value = penData[weekOrDay]?.[field];
          return total + (value ? parseFloat(value) : 0);
        }, 0)
        .toFixed(2);
    },
    exportToPDF() {
      const doc = new jsPDF("landscape");
      doc.setFontSize(15);
      doc.setTextColor(40);
      doc.setFont("Helvetica", "bold");

      // Title
      const title = "Weekly Penwise Report";
      const pageWidth = doc.internal.pageSize.width;
      const textWidth = doc.getTextWidth(title);
      const xPosition = (pageWidth - textWidth) / 2;
      doc.text(title, xPosition, 10);

      // Trial details
      const trialNo = `Trial No: ${this.trialNo} `;
      const trialStartDate = `Start Date: ${this.startDate}`;
      const trialEndDate = `End Date: ${this.endDate}`;
      doc.setFontSize(10);
      doc.text(trialNo, 10, 20);
      doc.text(trialStartDate, pageWidth - 45, 20);
      doc.text(trialEndDate, pageWidth - 45, 25);
      doc.setFont("Helvetica", "normal");

      // Check if all the data (Day 1, Weeks 1 to 5) is available
      const hasDay1Data = Object.values(this.reportData).some((group) => {
        return Object.values(group.pens).some((penData) => penData["Day 1"]);
      });

      let hasAllWeeks = true;
      const availableWeeks = [...this.selectedWeeks];
      for (let i = 1; i <= 5; i++) {
        const hasWeekData = Object.values(this.reportData).some((group) => {
          return Object.values(group.pens).some(
            (penData) => penData[`Week ${i}`]
          );
        });
     
        if (hasWeekData) {
          // availableWeeks = this.selectedWeeks;
          // console.log('hasWeekData ',hasWeekData, ' availableWeeks', availableWeeks);
        } else {
          hasAllWeeks = false;
        }
      }
     
      const allDataPresent = hasDay1Data && availableWeeks.length === 5;
      const headers = [[{ content: "", colSpan: 2 }]];

      if (allDataPresent) {
        headers[0].push({
          content: "Day 1",
          colSpan: 2,
          styles: { halign: "center", cellWidth: "auto" },
        });
        headers[0].push({
          content: "Week 1",
          colSpan: 4,
          styles: { halign: "center", cellWidth: "auto" },
        });
        headers[0].push({
          content: "Week 2",
          colSpan: 4,
          styles: { halign: "center" },
        });
        headers[0].push({
          content: "Week 3",
          colSpan: 4,
          styles: { halign: "center" },
        });
        headers[0].push({
          content: "Week 4",
          colSpan: 4,
          styles: { halign: "center" },
        });
        headers[0].push({
          content: "Week 5",
          colSpan: 4,
          styles: { halign: "center" },
        });

        headers.push([
          "Group No",
          "Pen No.",
          "No of birds",
          "Avg wt bird",
          "No of birds",
          "Feed\nConsumed\nper\nbird",
          "Avg wt bird",
          "FCR",
          "No of birds",
          "Feed consumed per bird",
          "Avg wt bird",
          "FCR",
          "No of birds",
          "Feed consumed per bird",
          "Avg wt bird",
          "FCR",
          "No of birds",
          "Feed consumed per bird",
          "Avg wt bird",
          "FCR",
          "No of birds",
          "Feed consumed per bird",
          "Avg wt bird",
          "FCR",
        ]);
      } else {
        if (hasDay1Data) {
          headers[0].push({
            content: "Day 1",
            colSpan: 2,
            styles: { halign: "center" },
          });
        }

        availableWeeks.forEach((week) => {
          headers[0].push({
            content: week,
            colSpan: 4,
            styles: { halign: "center" },
          });
        });

        const rowHeaders = ["Group No", "Pen No."];

        if (hasDay1Data) {
          rowHeaders.push("No of birds", "Avg wt bird");
        }

        availableWeeks.forEach((week) => {
          rowHeaders.push(
            "No of birds",
            "Feed consumed per bird",
            "Avg wt bird",
            "FCR"
          );
        });

        headers.push(rowHeaders);
      }

      let body = [];
      let boldRows = [];
      let boldCells = [];

      Object.values(this.reportData).forEach((group) => {
        Object.entries(group.pens).forEach(([penId, penData], index) => {
          let row = [index === 0 ? `T${group.trial_group_id}` : "", penId];

          if (allDataPresent || hasDay1Data) {
            row.push(
              penData["Day 1"]?.no_of_birds || "-",
              penData["Day 1"]?.avg_wt_bird || "-"
            );
          }

          if (allDataPresent) {
            for (let i = 1; i <= 5; i++) {
              row.push(
                penData[`Week ${i}`]?.no_of_birds || "-",
                penData[`Week ${i}`]?.feed_consumed_per_bird || "-",
                penData[`Week ${i}`]?.avg_wt_bird || "-",
                penData[`Week ${i}`]?.fcr || "-"
              );
            }
          } else {
            availableWeeks.forEach((week) => {
              row.push(
                penData[week]?.no_of_birds || "-",
                penData[week]?.feed_consumed_per_bird || "-",
                penData[week]?.avg_wt_bird || "-",
                penData[week]?.fcr || "-"
              );
            });
          }

          body.push(row);

          if (index === 0) {
            boldCells.push({
              row: body.length - 1,
              column: 0,
            });
          }
        });

        let totalsRow = ["", "Total"];

        if (allDataPresent || hasDay1Data) {
          totalsRow.push(
            group.totals["Day 1"]?.no_of_birds || "-",
            group.totals["Day 1"]?.avg_wt_bird?.toFixed(3) || "-"
          );
        }

        if (allDataPresent) {
          for (let i = 1; i <= 5; i++) {
            totalsRow.push(
              group.totals[`Week ${i}`]?.no_of_birds || "-",
              group.totals[`Week ${i}`]?.feed_consumed_per_bird?.toFixed(3) ||
                "-",
              group.totals[`Week ${i}`]?.avg_wt_bird?.toFixed(3) || "-",
              group.totals[`Week ${i}`]?.fcr?.toFixed(3) || "-"
            );
          }
        } else {
          availableWeeks.forEach((week) => {
            totalsRow.push(
              group.totals[week]?.no_of_birds || "-",
              group.totals[week]?.feed_consumed_per_bird?.toFixed(
                3
              ) || "-",
              group.totals[week]?.avg_wt_bird?.toFixed(3) || "-",
              group.totals[week]?.fcr?.toFixed(3) || "-"
            );
          });
        }

        body.push(totalsRow);
        boldRows.push(body.length - 1);
      });

      let grandTotalRow = ["", "Grand Total"];

      if (allDataPresent || hasDay1Data) {
        grandTotalRow.push(
          this.grandTotal["Day 1"]?.no_of_birds || "-",
          "Birds"
        );
      }

      if (allDataPresent) {
        for (let i = 1; i <= 5; i++) {
          grandTotalRow.push(
            this.grandTotal[`Week ${i}`]?.no_of_birds || "-",
            "Birds",
            "",
            ""
          );
        }
      } else {
        availableWeeks.forEach((week) => {
          grandTotalRow.push(
            this.grandTotal[week]?.no_of_birds || "-",
            "Birds",
            "",
            ""
          );
        });
      }

      body.push(grandTotalRow);
      boldRows.push(body.length - 1);

      // Set column styles based on whether all data is present
      const columnStyles = allDataPresent
        ? {
            0: { cellWidth: 12.3 },
            1: { cellWidth: 12.3 },
            2: { cellWidth: 12 },
            3: { cellWidth: 12 },
            4: { cellWidth: 12 },
            5: { cellWidth: 12.3 },
            6: { cellWidth: 12 },
            7: { cellWidth: 12 },
            8: { cellWidth: 12 },
            9: { cellWidth: 12.3 },
            10: { cellWidth: 12 },
            11: { cellWidth: 12 },
            12: { cellWidth: 12 },
            13: { cellWidth: 12.3 },
            14: { cellWidth: 12 },
            15: { cellWidth: 12 },
            16: { cellWidth: 12 },
            17: { cellWidth: 12.3 },
            18: { cellWidth: 12 },
            19: { cellWidth: 12 },
            20: { cellWidth: 12 },
            21: { cellWidth: 12.3 },
            22: { cellWidth: 12 },
            23: { cellWidth: 12 },
            24: { cellWidth: 12 },
          }
        : {};

      // Create the table
      doc.autoTable({
        startY: 30,
        margin: { top: 8, left: 3, right: 3 },
        head: headers,
        headStyles: {
          fillColor: [0, 57, 107],
          halign: "center",
          fontSize: 7.6,
          textColor: [255, 255, 255],
          lineColor: [0, 0, 0],
        },
        body: body,
        styles: {
          fontSize: 7.6,
          textColor: [0, 0, 0],
          cellPadding: 2.2,
          lineHeight: 1.6,
          halign: "center",
        },
        columnStyles: columnStyles,
        didParseCell: function (data) {
          if (boldRows.includes(data.row.index)) {
            data.cell.styles.fontStyle = "bold";
            data.cell.styles.fillColor = [255, 255, 150];
          }
          const fcrColumns = [7, 11, 15, 19, 23]; // Bold FCR columns
          if (fcrColumns.includes(data.column.index)) {
            data.cell.styles.fontStyle = "bold";
          }
          if (
            boldCells.some(
              (cell) =>
                cell.row === data.row.index && cell.column === data.column.index
            )
          ) {
            doc.setFontSize(8);
            doc.setFont("Helvetica", "bold");
          }
          if (data.row.index === body.length - 1) {
            data.cell.styles.fontStyle = "bold";
            data.cell.styles.fillColor = [103, 224, 87];
          }
        },
      });

      doc.save("Penwise_Report.pdf");
    },

    exportToExcel() {
      // console.log(trialData);
      const trialNo = this.trialNo;
      const trialStartDate = this.startDate;
      const trialEndDate = this.endDate;
      axios
        .post(
          "indous-research/reports/download-penwise-excel-report",
          {
            reportData: this.reportData,
            trialNo: trialNo,
            trialStartDate: trialStartDate,
            trialEndDate: trialEndDate,
            selectedWeeks : this.selectedWeeks,
          },
          {
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "weekly-penwise-report.xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {});
    },

    getTrialNoList() {
      // if (!this.startDate) return;

      axios.get(`indous-research/reports/get-trial-list`).then((response) => {
        this.trialNoList = response.data.trialNoList;
        this.trialNo = this.trialNoList[0]?.trial_no;
        this.trialNoDrp = this.trialNoList[0]?.id;
        this.expInfoId = this.trialNoList[0]?.id;
        this.startDate = this.trialNoList[0]?.start_date;
        this.endDate = this.trialNoList[0]?.end_date;
      });
    },

    filterStartDate() {
      this.reportData = {};
      this.showGenerate = true;
      // this.startDate = trialData[0].start_date;
      // this.endDate = trialData[0].end_date;
      this.selectedWeeks = [];
    },
  },
};
</script>
  
  <style scoped>
.bordered-cell {
  border-bottom-color: #000000;
  border-top-color: #000000;
  border-bottom-width: 2px;
  border-top-width: 2px;
  border-style: solid;
}
.highlight-black-color {
  border-right-color: #000000;
  border-right-width: 2px;
}
.highlight-bottom-color {
  border-bottom-color: #000000;
  border-bottom-width: 2px;
  border-left-color: #000000;
  border-left-width: 2px;
}
.highlight-bg-color {
  background-color: #ffffe0;
}
.custom-total-row {
  border: 2px solid #000; /* Darker border color */
}
.custom-md-field {
  width: 190px;
}
.no-border {
  border: none; /* Remove border */
}
.no-records-message {
  text-align: center; /* Center the text horizontally */
  padding: 20px 0; /* Add space above and below the text */
  font-weight: bold; /* Make the text bold */
  color: #666; /* Optional: Change the text color for better visibility */
}

.custom-md-input {
  width: 50px;
  font-size: 14px;
}

.row {
  --ct-gutter-x: 1.5rem;
  --ct-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--ct-gutter-y));
  margin-right: calc(-0.5 * var(--ct-gutter-x));
  margin-left: calc(-0.5 * var (--ct-gutter-x));
}

.tab-content {
  padding: 20px 0 0 0;
  border: none;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #71b6f9 !important;
}

.align-items-center {
  align-items: center !important;
  padding: 0px 65px 0px 20px;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.col {
  flex: 1 0 0%;
}

.mt-0 {
  margin-top: 0 !important;
}

.header-title {
  font-size: 1rem;
  margin: 0 0 7px 0;
}

.card-box {
  background-color: #fff;
  padding: 1.2rem 0rem 0.2rem 0rem;
  /* list/create */
  -webkit-box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  margin-bottom: 1px;
  border-radius: 0.25rem;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.input-group {
  display: inline;
}

button[type="submit"],
button[type="button"].cancel-button {
  cursor: pointer;
}

#buttons {
  display: flex;
  flex-direction: row;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: rgb(113, 182, 249);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

.create-field {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.all-buttons {
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
}
</style>