// delay function execution
export function debounce(func)
{
    // clearTimeout is used when you want to prevent the callback from getting called before that would happen.
    clearTimeout(debounce);
    // Stores timeout id  and delays 400 millisecond
    debounce = setTimeout(() => {
        // call function
        func();
    }, 400);
}
export function generatePassword() {
    const length = 8;
    const alphabets = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numbers = "0123456789";
    const specialCharacters = "!@#$%^&*-=";
    const allCharacters = alphabets + numbers + specialCharacters;

    let password = '';
    // Ensure at least one character of each type
    password += alphabets[Math.floor(Math.random() * alphabets.length)];
    password += numbers[Math.floor(Math.random() * numbers.length)];
    password += specialCharacters[Math.floor(Math.random() * specialCharacters.length)];

    // Fill the rest of the password length with random characters
    for (let i = password.length; i < length; i++) {
      password += allCharacters[Math.floor(Math.random() * allCharacters.length)];
    }

    // Shuffle the password to mix the ensured characters
    password = password.split('').sort(() => 0.5 - Math.random()).join('');

    return password;
  }