<template>
  <div>
    <div v-if="activeTab === 'list'">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">WEEKLY PENWISE DATA</h4>
          </md-card-header>
          <md-card-content>
            <md-table
              v-model="users"
              :table-header-color="tableHeaderColor"
              class="custom-table"
            >
              <tr
                class="bordered-cell"
                v-if="Object.keys(reportData).length === 0"
              >
                <th></th>
                <th
                  colspan="3"
                  class="highlight-black-color"
                >
                  Day 1
                </th>
                <th colspan="4" class="highlight-black-color">Week 1</th>
                <th
                  colspan="4"
                  class="highlight-black-color"
                >
                  Week 2
                </th>
                <th colspan="4" class="highlight-black-color">Week 3</th>
                <th
                  colspan="4"
                  class="highlight-black-color"
                 
                >
                  Week 4
                </th>
                <th colspan="4" class="highlight-black-color">Week 5</th>
              </tr>
              <!-- Table Headers -->
              <tr class="bordered-cell" v-else>
                <th></th>
                <th
                  v-if="availableColumns.includes('Day 1')"
                  colspan="3"
                  class="highlight-black-color"
                 
                >
                  Day 1
                </th>
                <!-- Dynamic Week columns -->
                <template v-for="(week, index) in availableWeeks">
                  <th
                    :key="index"
                    :colspan="4"
                    class="highlight-black-color"
                   
                  >
                    {{ week }}
                  </th>
                </template>
              </tr>
              <tr
                v-if="dataFetched && Object.keys(reportData).length > 0"
                class="bordered-cell"
              >
                <th class="highlight-black-color">Group No</th>
                <th>Pen No.</th>
                <template v-if="availableColumns.includes('Day 1')">
                  <th>No of birds</th>
                  <th class="highlight-black-color">Avg wt/bird</th>
                </template>
                <!-- <th>No of birds</th>
                   <th class="highlight-black-color">Avg wt/bird</th> -->
                <template v-for="(week, index) in availableWeeks">
                  <th :key="index">No of birds</th>
                  <th :key="'feed-consumed-' + week">Feed consumed per bird</th>
                  <th :key="'avg-wt-bird-' + week">Avg wt/bird</th>
                  <th class="highlight-black-color" :key="'fcr-' + week">
                    FCR
                  </th>
                </template>
              </tr>
              <tr v-if="loading">
                <td colspan="100%">
                  <loading-bar></loading-bar>
                </td>
              </tr>
              
              <template
                v-if="dataFetched && Object.keys(reportData).length > 0"
              >
                <template v-for="(trialGroup, trialGroupId) in reportData">
                  <tr
                    v-for="(penData, penId, penIndex) in trialGroup.pens"
                    :key="penId"
                  >
                    <td
                      v-if="penIndex === 0"
                      class="font-weight-bold highlight-black-color highlight-bottom-color"
                      :rowspan="Object.keys(trialGroup.pens).length + 1"
                    >
                      T{{ trialGroupId }}
                    </td>
                    <td>{{ penId }}</td>
                    <!-- Day 1 -->
                    <template v-if="penData['Day 1']">
                      <td>{{ penData["Day 1"].no_of_birds || "-" }}</td>
                      <td class="highlight-black-color">
                        {{ penData["Day 1"].avg_wt_bird || "-" }}
                      </td>
                    </template>

                    <template v-for="week in availableWeeks">
                      <template v-if="penData[week]">
                        <td :key="week">
                          {{ penData[week].no_of_birds || "-" }}
                        </td>
                        <td :key="week">
                          {{ penData[week].feed_consumed_per_bird || "-" }}
                        </td>
                        <td :key="week">
                          {{ penData[week].avg_wt_bird || "-" }}
                        </td>
                        <td
                          :key="week"
                          class="highlight-black-color highlight-bg-color"
                        >
                          {{ penData[week].fcr || "-" }}
                        </td>
                      </template>
                    </template>
                  </tr>

                  <!-- Totals row -->
                  <template>
                    <tr
                      :key="'total-' + trialGroupId"
                      style="background-color: #fbeee6"
                    >
                      <td class="bordered-cell"><b>Total</b></td>
                      <!-- Week 1 Total -->
                      <template
                        v-if="
                          trialGroup.totals['Day 1'] &&
                          trialGroup.totals['Day 1'].no_of_birds > 0
                        "
                      >
                        <td class="font-weight-bold bordered-cell">
                          {{ trialGroup.totals["Day 1"].no_of_birds }}
                        </td>

                        <td
                          class="font-weight-bold bordered-cell highlight-black-color"
                        >
                          {{
                            trialGroup.totals["Day 1"].avg_wt_bird.toFixed(3)
                          }}
                        </td>
                      </template>

                      <template v-for="week in availableWeeks">
                        <template
                          v-if="
                            trialGroup.totals[week] &&
                            trialGroup.totals[week].no_of_birds > 0
                          "
                        >
                          <td
                            :key="week"
                            class="bordered-cell font-weight-bold"
                          >
                            {{ trialGroup.totals[week].no_of_birds }}
                          </td>
                          <td
                            :key="week"
                            class="bordered-cell font-weight-bold"
                          >
                            {{
                              trialGroup.totals[
                                week
                              ].feed_consumed_per_bird.toFixed(3)
                            }}
                          </td>
                          <td
                            :key="week"
                            class="bordered-cell font-weight-bold"
                          >
                            {{ trialGroup.totals[week].avg_wt_bird.toFixed(3) }}
                          </td>
                          <td
                            :key="week"
                            class="font-weight-bold bordered-cell highlight-black-color"
                          >
                            {{ trialGroup.totals[week].fcr.toFixed(3) }}
                          </td>
                        </template>
                      </template>
                    </tr>
                  </template>
                </template>

                <!-- <template>
                  <tr
                    :key="'total-' + trialGroupId"
                    class="border-dark"
                    style="background-color: #67e057"
                    v-if="Object.keys(reportData).length > 0"
                  >
                    <td><b>Grand Total</b></td>

                  
                    <template v-if="grandTotal['Day 1'].no_of_birds > 0">
                      <td class="font-weight-bold"></td>
                      <td class="font-weight-bold">
                        {{ grandTotal["Day 1"].no_of_birds }} Birds
                      </td>
                      <td class="font-weight-bold"></td>
                    </template>

                  
                    <template v-for="week in availableWeeks">
                      <template v-if="grandTotal[week]?.no_of_birds > 0">
                        <td :key="week" class="font-weight-bold">
                          {{ grandTotal[week].no_of_birds }} Birds
                        </td>
                        <td :key="week" class="font-weight-bold"></td>
                        <td :key="week" class="font-weight-bold"></td>
                        <td :key="week" class="font-weight-bold"></td>
                      </template>
                    </template>
                  </tr>
                </template> -->
              </template>
              <template
                v-if="
                  dataFetched === false &&
                  Object.keys(reportData).length === 0 &&
                  !this.showGenerate
                "
              >
                <tr>
                  <td colspan="100%" class="no-records-message">
                    No records found
                  </td>
                </tr>
              </template>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>
     
   <script>
import LoadingBar from "../components/LoadingBar.vue";
import jsPDF from "jspdf";
import "jspdf-autotable";
export default {
  name: "simple-table",
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      activeTab: "list", // Default active tab
      trialNoList: [],
      isDropdownOpen: false,
      selectedWeeks: [],
      availableColumns: [],
      availableWeeks: [],
      reportData: {},
      dataFetched: "",
      loading: false,
      showGenerate: true,
      expInfoId: "",
      trialNoDrp: "",
      startDate: "",
      endDate: "",
    };
  },
  components: {
    LoadingBar,
  },

  mounted() {
    //   this.getTrialNoList();
    const selectedTrial = this.$store.getters.getSelectedTrial;
    this.trialNoDrp = selectedTrial.id;
    this.generateWeeklyReport();
    //  this.trialData = selectedTrial;
  },
  computed: {
    grandTotal() {
      const totals = {
        "Day 1": { no_of_birds: 0 },
        "Week 1": { no_of_birds: 0 },
        "Week 2": { no_of_birds: 0 },
        "Week 3": { no_of_birds: 0 },
        "Week 4": { no_of_birds: 0 },
        "Week 5": { no_of_birds: 0 },
      };
      for (const trialGroup of Object.values(this.reportData)) {
        for (const penData of Object.values(trialGroup.pens)) {
          for (const week of Object.keys(totals)) {
            if (penData[week]) {
              totals[week].no_of_birds += penData[week].no_of_birds || 0;
            }
          }
        }
      }

      return totals;
    },
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    handleWeekSelection(selectedWeek, selectedIndex) {
      this.selectedWeeks = [];
      for (let i = 0; i <= selectedIndex; i++) {
        const week = this.availableWeeks[i];
        this.selectedWeeks.push(week);
      }
    },

    handleWeekDeselection(selectedWeek, selectedIndex) {
      for (let i = selectedIndex; i < this.availableWeeks.length; i++) {
        const week = this.availableWeeks[i];
        const index = this.selectedWeeks.indexOf(week);
        if (index !== -1) {
          this.selectedWeeks.splice(index, 1);
        }
      }
    },
    determineAvailableColumns() {
      this.availableColumns = [];
      this.availableWeeks = [];
      if (Object.keys(this.reportData).length > 0) {
        Object.values(this.reportData).forEach((trialGroup) => {
          Object.values(trialGroup.pens).forEach((penData) => {
            if (penData["Day 1"]) {
              if (!this.availableColumns.includes("Day 1")) {
                this.availableColumns.push("Day 1");
              }
            }
            for (let i = 1; i <= 5; i++) {
              const week = `Week ${i}`;
              if (penData[week]) {
                if (!this.availableWeeks.includes(week)) {
                  this.availableWeeks.push(week);
                }
              }
            }
          });
        });
        this.selectedWeeks = [...this.availableWeeks];
      }
    },

    generateWeeklyReport() {
      this.loading = true;
      this.showGenerate = false;
      this.dataFetched = "";
      axios
        .get(
          `indous-research/reports/weekly-penwise-report?experiment_id=${this.trialNoDrp}`
        )
        .then((response) => {
          const data = response.data;
          this.reportData = data;
          this.dataFetched = true;
          this.loading = false;
          this.determineAvailableColumns();
        })
        .catch((error) => {
          this.reportData = {};
          this.dataFetched = false;
          this.loading = false;
        });
    },
    isActive(tab) {
      return this.activeTab === tab;
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    calculateTotal(pens, weekOrDay, field) {
      return Object.values(pens)
        .reduce((total, penData) => {
          const value = penData[weekOrDay]?.[field];
          return total + (value ? parseFloat(value) : 0);
        }, 0)
        .toFixed(2);
    },
    //   getTrialNoList() {
    //     // if (!this.startDate) return;

    //     axios.get(`indous-research/reports/get-trial-list`).then((response) => {
    //       this.trialNoList = response.data.trialNoList;
    //       this.trialNo = this.trialNoList[0]?.trial_no;
    //       this.trialNoDrp = this.trialNoList[0]?.id;
    //       this.expInfoId = this.trialNoList[0]?.id;
    //       this.startDate = this.trialNoList[0]?.start_date;
    //       this.endDate = this.trialNoList[0]?.end_date;
    //     });


   
  },
};
</script>
     
     <style scoped>
.bordered-cell {
  border-bottom-color: #000000;
  border-top-color: #000000;
  border-bottom-width: 2px;
  border-top-width: 2px;
  border-style: solid;
}
.highlight-black-color {
  border-right-color: #000000;
  border-right-width: 2px;
}
.highlight-bottom-color {
  border-bottom-color: #000000;
  border-bottom-width: 2px;
  border-left-color: #000000;
  border-left-width: 2px;
}
.highlight-bg-color {
  background-color: #ffffe0;
}
.custom-total-row {
  border: 2px solid #000; /* Darker border color */
}
.custom-md-field {
  width: 190px;
}
.no-border {
  border: none; /* Remove border */
}
.no-records-message {
  text-align: center; /* Center the text horizontally */
  padding: 20px 0; /* Add space above and below the text */
  font-weight: bold; /* Make the text bold */
  color: #666; /* Optional: Change the text color for better visibility */
}

.custom-md-input {
  width: 50px;
  font-size: 14px;
}

.row {
  --ct-gutter-x: 1.5rem;
  --ct-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--ct-gutter-y));
  margin-right: calc(-0.5 * var(--ct-gutter-x));
  margin-left: calc(-0.5 * var (--ct-gutter-x));
}

.tab-content {
  padding: 20px 0 0 0;
  border: none;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #71b6f9 !important;
}

.align-items-center {
  align-items: center !important;
  padding: 0px 65px 0px 20px;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.col {
  flex: 1 0 0%;
}

.mt-0 {
  margin-top: 0 !important;
}

.header-title {
  font-size: 1rem;
  margin: 0 0 7px 0;
}

.card-box {
  background-color: #fff;
  padding: 1.2rem 0rem 0.2rem 0rem;
  /* list/create */
  -webkit-box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  margin-bottom: 1px;
  border-radius: 0.25rem;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.input-group {
  display: inline;
}

button[type="submit"],
button[type="button"].cancel-button {
  cursor: pointer;
}

#buttons {
  display: flex;
  flex-direction: row;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: rgb(113, 182, 249);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

.create-field {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.all-buttons {
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
}
</style>