<template>
  <footer class="footer">
    <div class="container">



      <div class="copyright text-center ">
        &copy; {{ new Date().getFullYear() }}
         Indous Research designed by Intellogicsoftware Pvt Ltd
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style>

</style>
