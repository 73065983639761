<template>
  <div>
    <div v-if="activeTab === 'list'">
      <div class="row align-items-center mb-3">
        <div class="col-md-4 d-flex">
          <label for="" class="d-flex mt-2 font-weight-bold">
            Start date
          </label>
          <input
            type="text"
            v-model="startDate"
            disabled
            class="form-control custom-input-width ml-2 pl-2"
          />
        </div>

        <div class="col-md-4 d-flex">
          <label for="" class="d-flex mt-2 font-weight-bold"> End date </label>
          <input
            type="text"
            v-model="endDate"
            disabled
            class="form-control custom-input-width ml-2 pl-2"
          />
        </div>
      </div>
      <div class="row align-items-center mb-5 mt-5">
        <div class="col-md-3"></div>
        <div class="col-md-2">
          <!-- <md-button class="greyblue-button">Generate Report</md-button> -->
          <button
            type="button"
            class="btn btn-sm btn-info"
            @click="fetchGroupwiseWeightReportList()"
            :disabled="!this.trialNoDrp"
          >
            <md-icon class="text-white">list</md-icon> Generate Report
          </button>
        </div>

        <div class="col-md-2">
          <!-- <md-button class="bg-info">
              <md-icon>download</md-icon> Export Data
            </md-button> -->
          <button
            type="button"
            class="btn btn-sm btn-primary"
            :disabled="!reportGenerated"
            @click="exportData()"
          >
            <md-icon class="text-white">download</md-icon>Export Data
          </button>
        </div>

        <div class="col-md-2">
          <button
            type="button"
            class="btn btn-sm btn-success"
            :disabled="!reportGenerated"
             @click="downloadPDF"
          >
            <md-icon class="text-white">download</md-icon> Download PDF
          </button>
        </div>

        <!-- 
                <md-field class="custom-md-field" style="margin:0px 0px 0px 0px">
                    <label>Search...</label>
                    <md-input v-model="initial"></md-input>
                </md-field>
                <md-icon>search</md-icon> -->
      </div>

      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">
              GROUPWISE MALE/FEMALE BIRD'S BODY WEIGHT AT 5TH WEEK
            </h4>
          </md-card-header>
          <md-card-content>
            <md-table
              :table-header-color="tableHeaderColor"
              class="custom-table"
              id="groupwiseWeightTable"
            >
              <!-- <thead> -->
              <tr>
                <th rowspan="2">Group</th>
                <th rowspan="2">Total Birds</th>
                <th rowspan="2">Male Birds</th>
                <th rowspan="2">Female Birds</th>
                <th colspan="2">Body Weight</th>
                <th rowspan="2">FCR</th>
              </tr>

              <tr>
                <th>Male Birds</th>
                <th>Female Birds</th>
              </tr>
              <!-- </thead> -->

              <tr v-if="!groupwiseWeightReportList.length && !load">
                <td colspan="16" class="text-center">
                  {{
                    reportGenerated
                      ? "No records found"
                      : "Please Generate report"
                  }}
                </td>
              </tr>

              <tr v-for="item in groupwiseWeightReportList" :key="item.id">
                <td>{{ item.trial_groups.trial_group_id }}</td>
                <td>{{ item.total_birds }}</td>
                <td>{{ item.total_male ?? ''}}</td>
                <td>{{ item.total_female ?? ''}}</td>
                <td>{{ item.avg_male_wt ?? ''}}</td>
                <td>{{ item.avg_female_wt ?? ''}}</td>
                <td class="font-weight-bold">{{ item.fcr ?? '' }}</td>
              </tr>
            </md-table>
            <loading-bar v-if="load"></loading-bar>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingBar from "../../components/LoadingBar.vue";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
export default {
  components: { LoadingBar },
  name: "GroupwiseWeight",
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      activeTab: "list", // Default active tab

      trialNoList: [],
      expInfoId: "",
      trialNoDrp: "",
      trialNo : "",

      groupwiseWeightReportList: [],

      load: false,
      reportGenerated: false,
    };
  },

  mounted() {
    // this.getTrialNoList();
    this.trialNoDrp =this.$store.getters.getSelectedTrial.id;
    this.expInfoId = this.$store.getters.getSelectedTrial.id;
    this.startDate = this.$store.getters.getSelectedTrial.start_date;
    this.endDate = this.$store.getters.getSelectedTrial.end_date;
    this.trialNo = this.$store.getters.getSelectedTrial.trial_no;
    this.filterData();
    // console.log(XLSX);
    // this.fetchTrialStage();
    // this.$material.locale.dateFormat = "dd-MM-yyyy";
  },
  watch: {
    // Watch for changes in the selected trial's id
    '$store.getters.getSelectedTrial.id': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          // Update related data when id changes
          this.trialNoDrp = newVal;
          this.expInfoId = newVal;
          this.startDate = this.$store.getters.getSelectedTrial.start_date;
          this.trialNo = this.$store.getters.getSelectedTrial.trial_no;
          this.endDate = this.$store.getters.getSelectedTrial.end_date;
          
          // Call the filterData method whenever the trial id changes
          this.filterData();
        }
      },
      immediate: true // Call handler immediately after the component is mounted
    }
  },

  methods: {
    filterData(){
      this.groupwiseWeightReportList = [];
      this.reportGenerated = false;
    },
    isActive(tab) {
      return this.activeTab === tab;
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },

    getTrialNoList() {
      // if (!this.startDate) return;

      axios
        .get(`/indous-research/experiment-information/get-trial-no-list`)
        .then((response) => {
          this.trialNoList = response.data.trialNoList;
          this.trialNo = this.trialNoList[0]?.trial_no;
          this.trialNoDrp = this.trialNoList[0]?.id;
          this.expInfoId = this.trialNoList[0]?.id;
          this.startDate = this.trialNoList[0]?.start_date;
          // this.fetchGroupwiseWeightReportList();
        });
    },

    fetchGroupwiseWeightReportList() {
      this.load = true;
      this.groupwiseWeightReportList = [];
      axios
        .get(
          `/indous-research/reports/groupwise-weight?exp_info_id=${this.trialNoDrp}`
        )
        .then((response) => {
          this.groupwiseWeightReportList =
            response.data.groupwiseWeightReportList;
          this.reportGenerated = true; // Enable buttons after report generation
          this.load = false;
        })
        .catch(() => {
          this.load = false;
        });
    },


    exportData() {
      axios
        .post(
          "/download-groupwise-excel-report",
          {
            reportData: this.groupwiseWeightReportList,
          },
          {
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "GroupwiseWeightReport.xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          // console.error("There was an error generating the report:", error);
        });
    },

  downloadPDF() {
    const doc = new jsPDF('landscape'); // Landscape orientation
    const tableColumn = [
      "Group", 
      "Total Birds", 
      "Male Birds", 
      "Female Birds", 
      "Male Birds Body Weight", 
      "Female Birds Body Weight", 
      "FCR"
    ];
    const tableRows = [];

    this.groupwiseWeightReportList.forEach(item => {
      const tableData = [
        item.trial_groups.trial_group_id,
        item.total_birds,
        item.total_male,
        item.total_female,
        item.avg_male_wt,
        item.avg_female_wt,
        item.fcr      //change
      ];
      tableRows.push(tableData);
    });

    // Add title to PDF
    doc.setFontSize(14);
    doc.text("GROUPWISE MALE/FEMALE BIRD'S BODY WEIGHT AT 5TH WEEK", 14, 22);

    const pageWidth = doc.internal.pageSize.width;
    const trialNo = `Trial No: ${this.trialNo}`;
    doc.setFontSize(10);
    doc.setFont("Helvetica", "bold");
    doc.text(trialNo, pageWidth-50, 22);
    
    doc.setFont("Helvetica", "normal");

    // Add table to PDF
    doc.autoTable(tableColumn, tableRows, {
      startY: 30,
      theme: 'striped', 
      headStyles: { fillColor: [41, 128, 185] }, // Header background color
      styles: { cellPadding: 3 },
      columnStyles: {
        0: { cellWidth: 'auto' }, 
        1: { cellWidth: 'auto' }, 
        2: { cellWidth: 'auto' }, 
        3: { cellWidth: 'auto' }, 
        4: { cellWidth: 'auto' }, 
        5: { cellWidth: 'auto' }, 
        6: { cellWidth: 'auto' }
      },

      didParseCell: function (data) {
          if ([6].includes(data.column.index)) {
            data.cell.styles.fontStyle = "bold";
          }
        },
    });

    // Save PDF
    doc.save("GroupwiseWeightReport.pdf");
  },
},
};
</script>

<style scoped>
.custom-md-field {
  /* Adjust the overall size of the md-field if needed */
  width: 190px;
  /* Adjust as needed */
}

.custom-md-input {
  /* Adjust the size of the input */
  width: 50px;
  /* Change the width as needed */
  /* height: 30px; Change the height as needed */
  /*padding: 5px;  Adjust padding as needed */
  font-size: 14px;
  /* Adjust font size as needed */
}

.row {
  --ct-gutter-x: 1.5rem;
  --ct-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--ct-gutter-y));
  margin-right: calc(-0.5 * var(--ct-gutter-x));
  margin-left: calc(-0.5 * var (--ct-gutter-x));
}

.tab-content {
  padding: 20px 0 0 0;
  border: none;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #71b6f9 !important;
}

.align-items-center {
  align-items: center !important;
  padding: 0px 65px 0px 20px;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.col {
  flex: 1 0 0%;
}

.mt-0 {
  margin-top: 0 !important;
}

.header-title {
  font-size: 1rem;
  margin: 0 0 7px 0;
}

.card-box {
  background-color: #fff;
  padding: 1.2rem 0rem 0.2rem 0rem;
  /* list/create */
  -webkit-box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  margin-bottom: 1px;
  border-radius: 0.25rem;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

/* .form-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
} */

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

/* input[type="text"],
input[type="password"],
input[type="email"],
select {
   width: 100%; 
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
} */

.input-group {
  display: inline;
}

/* .input-group input {
  flex: 1;
} */

/* .input-group button {
  margin-left: 10px;
  padding: 8px 12px;
  border: 1px solid #007bff;
  border-radius: 4px;
  background-color: #fff;
  color: #007bff;
  cursor: pointer;
} */

/* .input-group button:hover {
  background-color: #007bff;
  color: #fff;
} */

button[type="submit"],
button[type="button"].cancel-button {
  /* padding: 10px 20px; */
  /* border: none;
  border-radius: 4px; */
  cursor: pointer;
}

/* button[type="submit"].save-button {
  background-color: #28a745;
  color: #fff;
} */

/* button[type="button"].cancel-button {
  background-color: #ccc;
  color: #333;
  margin-left: 10px;
} */

#buttons {
  display: flex;
  flex-direction: row;
}

/* button[type="button"].cancel-button:hover {
  background-color: #b3b3b3;
} */

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: rgb(113, 182, 249);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

.create-field {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.all-buttons {
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
}
</style>