<template>
  <div>
    <div v-if="activeTab === 'list'">
      <div class="row align-items-center mb-3">
         <!-- <div class="col-md-4 d-flex">
           <label for="movie" class="mt-2 font-weight-bold"> Trial No:    </label>     
             <select name="" v-model="selectedTrial" @change="filterStartDate()" class="form-control width-10 ml-2 pl-2" id="">
              <option disabled>Select</option>
              <option :value="trial.id" v-for="(trial, index) in trials" :key="index">{{ trial.trial_no }}</option>
          </select>
          </div> -->

        <div class="col-md-4 d-flex">
          <label for="" class="d-flex mt-2 font-weight-bold"> Start date </label>
          <input
            type="text"
            :value="startDate"
            disabled
            class="form-control w-50 ml-2 pl-2"
          />
        </div>

        <div class="col-md-4 d-flex">
          <label for="" class="d-flex mt-2 font-weight-bold"> End date </label>
          <input
            type="text"
            :value="endDate"
            disabled
            class="form-control w-50 ml-2 pl-2"
          />
        </div>
      </div>

      <div class="row align-items-center mb-3">
        <div class="col-md-4">
          <!-- <label for="movie" class="custom-input-sm">Show         
             <select name="" class="form-control" id="">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="">All</option>
          </select></label> -->
        </div>
        <div class="col-md-2">
          <button type="button" @click="fetchWeek5Data" class="btn btn-sm btn-info" :disabled="selectedTrial == null">
            <md-icon class="text-white">list</md-icon> Generate Report
          </button>
        </div>

        <div class="col-md-2" v-if="week5Data.length > 0">
          <button @click="exportExcel" type="button" class="btn btn-sm btn-primary">
            <md-icon class="text-white">download</md-icon>Export Excel
          </button>
        </div>

        <div class="col-md-2" v-if="week5Data.length > 0">
          <button @click="downloadPDF" type="button" class="btn btn-sm btn-success">
            <md-icon class="text-white">download</md-icon> Download PDF
          </button>
        </div>
      </div>

      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">SEPARATE MALE AND FEMALE BIRDS WEIGHT AT 5TH WEEK</h4>
          </md-card-header>
          <md-card-content>
            <md-table class="custom-table" :table-header-color="tableHeaderColor">
                <tr></tr>
                <tr>
                  <th>Group No</th>
                  <th>Pen No.</th>
                  <th>No of Birds</th>
                  <th>Male</th>
                  <th>Female</th>
                  <th>Feed consumed per bird</th>
                  <th>Avg wt/bird</th>
                  <th>Avg wt/bird Male</th>
                  <th>Avg wt/bird Female</th>
                  <th>FCR</th>
                </tr>
                <tr v-if="generateReportMessage">
                  <td colspan="10" class="no-records-message">Please Generate report</td>
                </tr>
                <tr v-else-if="loading">
                  <td colspan="10"><loadingBar /></td>
                </tr>

                <tr v-else-if="errorMessage || !week5Data.length > 0">
                  <td colspan="10" class="no-records-message">Data not found</td>
                </tr>
                <template v-else v-for="trialGroup in week5Data" >
                  <tr v-for="(penData, index) in trialGroup.data" :key="penData.pen_id">
                    <template v-if="index === 0">
                      <td :rowspan="trialGroup.data.length + 1" class="font-weight-bold">{{ 'T' + trialGroup.trial_group_id }}</td>
                    </template>
                    <td>{{ penData.pen_id }}</td>
                    <td>{{ penData.no_of_birds }}</td>
                    <td>{{ penData.male }}</td>
                    <td>{{ penData.female }}</td>
                    <td class="font-weight-bold">{{ penData.feed_consumed_per_bird }}</td>
                    <td class="font-weight-bold">{{ penData.avg_body_wt_bird }}</td>
                    <td class="font-weight-bold custom-font-size">{{ penData.avg_male_wt }}</td>
                    <td class="font-weight-bold custom-font-size">{{ penData.avg_female_wt }}</td>
                    <td class="highlight-bg-color font-weight-bold custom-font-size">{{ penData.fcr }}</td>
                  </tr>
                  <tr :key="trialGroup.trial_group_id" class="custom-total-row">
                    <td class="font-weight-bold custom-font-size">Total</td>
                    <td class="font-weight-bold custom-font-size">{{ trialGroup.totals.no_of_birds }}</td>
                    <td class="font-weight-bold custom-font-size">{{ trialGroup.totals.male }}</td>
                    <td class="font-weight-bold custom-font-size">{{ trialGroup.totals.female }}</td>
                    <td class="font-weight-bold custom-font-size">{{ trialGroup.totals.feed_consumed_per_bird }}</td>
                    <td class="font-weight-bold custom-font-size">{{ trialGroup.totals.avg_body_wt_bird }}</td>
                    <td class="font-weight-bold custom-font-size">{{ trialGroup.totals.avg_male_wt }}</td>
                    <td class="font-weight-bold custom-font-size">{{ trialGroup.totals.avg_female_wt }}</td>
                    <td class="font-weight-bold custom-font-size">{{ trialGroup.totals.fcr }}</td>
                  </tr>
                </template>
                <tr v-if="week5Data.length > 0" class="custom-grand-total-row">
                  <td colspan="2" class="font-weight-bold custom-font-size">Grand Total</td>
                  <td class="font-weight-bold custom-font-size">{{ overAllTotals.no_of_birds }}</td>
                  <td class="font-weight-bold custom-font-size">{{ overAllTotals.male }}</td>
                  <td class="font-weight-bold custom-font-size">{{ overAllTotals.female }}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
            </md-table>
          </md-card-content>

        </md-card>
      </div>
    </div>
  </div>
</template>
  
  <script>
import LoadingBar from '../../components/LoadingBar.vue';
import jsPDF from "jspdf";
import "jspdf-autotable";
export default {
  name: "weight-5thweek",
  components: {
    LoadingBar
  },
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      activeTab: "list",
      week5Data: [],
      trials: [],
      overAllTotals: {},
      loading: true,
      errorMessage: false,
      generateReportMessage: false,
      selectedTrial: null,
      startDate: '',
      endDate: '',
      exportData: [],
      trialNo: "",
    }
  },
  watch: {
    // Watch for changes in the selected trial's id
    '$store.getters.getSelectedTrial.id': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          // Update related data when id changes
          this.selectedTrial =this.$store.getters.getSelectedTrial.id;
          this.startDate = this.$store.getters.getSelectedTrial.start_date;
          this.trialNo = this.$store.getters.getSelectedTrial.trial_no;
          this.endDate = this.$store.getters.getSelectedTrial.end_date;
          
          // Call the filterStartDate method whenever the trial id changes
          this.filterStartDate();
        }
      },
      immediate: true // Call handler immediately after the component is mounted
    }
  },
  mounted(){
    // this.fetchTrials();
    this.selectedTrial =this.$store.getters.getSelectedTrial.id;
    this.trialNo = this.$store.getters.getSelectedTrial.trial_no;
    this.startDate = this.$store.getters.getSelectedTrial.start_date;
    this.endDate = this.$store.getters.getSelectedTrial.end_date;
    this.filterStartDate();
  },
  methods: {
    fetchTrials() {
      this.loading = true;
      axios.get('/indous-research/reports/get-trial-list')
        .then((response) => {
            this.trials = response.data.trialNoList;
            this.selectedTrial = this.trials[0].id; 
            this.startDate = this.trials[0].start_date;
            this.endDate = this.trials[0].end_date;
            this.loading = false;
            this.generateReportMessage = true;
        })
        .catch((error) => {
            this.loading = false;
        });
    },
    filterStartDate() {
      // this.startDate = '';
      // this.endDate = '';
      this.week5Data = [];
      this.overAllTotals = {};
      this.generateReportMessage = true;
      // let trialData = this.trials.filter((i) => i.id == this.selectedTrial);
      // this.startDate = trialData[0].start_date;
      // this.endDate = trialData[0].end_date;
    },
    async fetchWeek5Data(){
      this.errorMessage = false;
      this.generateReportMessage = false;
      this.loading = true;
      this.week5Data = [];
      this.overAllTotals = {};
      try {
        const response = await axios.get(`/fetch-week5-report-data?experiment_info_id=${this.selectedTrial}&age_in_days_or_week=${35}`);
        this.week5Data = response.data.week5ReportData;
        this.overAllTotals = response.data.overAllTotals;
        this.loading = false;
        this.errorMessage = false;
        this.generateReportMessage = false;
      } catch (error) {
        this.loading = false;
        this.errorMessage = true;
        this.generateReportMessage = false;
      }
    },
    downloadPDF() {
      const doc = new jsPDF('landscape');
      doc.setFontSize(20);
      doc.setTextColor(40);

      const title = "Separate male and female birds weight at 5th week";
      const pageWidth = doc.internal.pageSize.width;
      const textWidth = doc.getTextWidth(title);
      const xPosition = (pageWidth - textWidth) / 2;
      doc.text(title, xPosition, 13);

      const trialNo = `Trial No: ${this.trialNo}`;
      const startDate = `Start Date: ${this.startDate}`;
      const endDate = `End Date: ${this.endDate}`;
      doc.setFontSize(10);
      doc.setFont("Helvetica", "bold");
      doc.text(trialNo, 10, 20);
      doc.text(startDate, pageWidth-50, 20);
      doc.text(endDate, pageWidth-50, 25);

      doc.setFont("Helvetica", "normal");

      const headers = [
        [
          "Group No", 
          "Pen No",
          "No of Birds", 
          "Male", 
          "Female", 
          "Feed Consumed Per Bird",
          "Avg wt/bird",
          "Avg wt/bird Male",
          "Avg wt/bird Female",
          "FCR"
        ]
      ];

      let body = [];
      let boldRows = [];
      let boldCells = [];

      this.week5Data.forEach(group => {
        group.data.forEach((pen, index) => {
          const row = [
            index === 0 ? `T${group.trial_group_id}` : "",
            pen.pen_id,
            pen.no_of_birds,
            pen.male,
            pen.female,
            pen.feed_consumed_per_bird,
            pen.avg_body_wt_bird || 0,
            pen.avg_male_wt || 0,
            pen.avg_female_wt || 0,
            pen.fcr
          ];
          body.push(row);
          if (index === 0) {
            boldCells.push({
              row: body.length - 1,
              column: 0,
            });
          }
        });

        // Add totals row after data
        const totals = group.totals;
        const totalsRow = [
          '',
          'Total',
          totals.no_of_birds,
          totals.male,
          totals.female,
          totals.feed_consumed_per_bird,
          totals.avg_body_wt_bird || 0,
          totals.avg_male_wt || 0,
          totals.avg_female_wt || 0,
          totals.fcr
        ];
        body.push(totalsRow);
        boldRows.push(body.length - 1);
      });

      // Add overall totals row at the end of all data
      const grandTotalRow = [
        "",
        "Grand Total",
        this.overAllTotals.no_of_birds,
        this.overAllTotals.male,
        this.overAllTotals.female,
        "",
        "",
        "",
        "", 
        ""
      ];
      body.push(grandTotalRow);
      boldRows.push(body.length - 1);

      doc.autoTable({
        startY: 30,
        margin: { top: 10, left: 10, right: 10 },
        head: headers,
        headStyles: {
          fillColor: [0, 57, 107],
          halign: "center",
          overflow: "linebreak",
          fontSize: 11,
          textColor: [255, 255, 255],
          lineColor: [0, 0, 0],
        },
        body: body,
        styles: {
          fontSize: 9,
          textColor: [0, 0, 0],
          cellPadding: 2.2,
          lineHeight: 1.6,
        },
        columnStyles: {
          0: { cellWidth: 'auto' },
          1: { cellWidth: 'auto' },
          2: { cellWidth: 'auto' },
          3: { cellWidth: 'auto' },
          4: { cellWidth: 'auto' },
          5: { cellWidth: 'auto' },
          6: { cellWidth: 'auto' },
          7: { cellWidth: 'auto' },
          8: { cellWidth: 'auto' },
          9: { cellWidth: 'auto' },
        },

        didParseCell: function (data) {
          if (boldRows.includes(data.row.index)) {
            data.cell.styles.fontStyle = "bold";
            data.cell.styles.fontSize = 10;
            data.cell.styles.fillColor = [255, 255, 150];
          }
          if (
            boldCells.some(
              (cell) =>
                cell.row === data.row.index && cell.column === data.column.index
            )
          ) {
            doc.setFontSize(8);
            doc.setFont("Helvetica", "bold");
          }
          if ([5, 6, 7, 8, 9].includes(data.column.index)) {
            data.cell.styles.fontStyle = "bold";
          }
          if ([7, 8, 9].includes(data.column.index)) {
            data.cell.styles.fontSize = 10;
          }
          if (data.row.index === body.length - 1) {
            data.cell.styles.fontStyle = "bold";
            data.cell.styles.fillColor = [103, 224, 87];
          }
        },
      });

      doc.save("week5_report.pdf");
    },

    exportExcel() {
      axios
        .post(
          "/download-week5-excel-report",
          {
            reportData: this.week5Data,
            overAllTotal: this.overAllTotals,
            startDate: `Start Date: ${this.startDate}`,
            endDate: `End Date: ${this.endDate}`,
            expNo: `Trial No: ${this.trialNo}`,
          },
          {
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "week5-report.xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          // console.error("There was an error generating the report:", error);
        });
    }
  },
};
</script>
  
  <style scoped>
.custom-md-field {
  width: 190px;
}

.custom-md-input {
  width: 50px;
  font-size: 14px;
}

.row {
  --ct-gutter-x: 1.5rem;
  --ct-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--ct-gutter-y));
  margin-right: calc(-0.5 * var(--ct-gutter-x));
  margin-left: calc(-0.5 * var (--ct-gutter-x));
}

.tab-content {
  padding: 20px 0 0 0;
  border: none;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #71b6f9 !important;
}

.align-items-center {
  align-items: center !important;
  padding: 0px 65px 0px 20px;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.col {
  flex: 1 0 0%;
}

.mt-0 {
  margin-top: 0 !important;
}

.header-title {
  font-size: 1rem;
  margin: 0 0 7px 0;
}

.card-box {
  background-color: #fff;
  padding: 1.2rem 0rem 0.2rem 0rem;
  -webkit-box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  margin-bottom: 1px;
  border-radius: 0.25rem;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.input-group {
  display: inline;
}

button[type="submit"],
button[type="button"].cancel-button {
  cursor: pointer;
}

#buttons {
  display: flex;
  flex-direction: row;
}


.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: rgb(113, 182, 249);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

.create-field {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.all-buttons {
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
}

.custom-font-size {
  font-size: 15px; 
}

.no-records-message {
  text-align: center; 
  padding: 20px 0; 
  font-weight: bold; 
  color: #666; 
}

.highlight-bg-color{
    background-color: #ffffe0;
  }
.custom-total-row{
  background-color: #FFFF96 ;
}
.custom-heading-row{
  background-color: #00396B;
  color: #FFFFFF;
}
.custom-grand-total-row{
  background-color: #67e057;
}
</style>