<template>
  <div>
    <div>
      <div class="row align-items-center mb-3">
        <div class="col">
          <!-- <h4 class="mt-0 header-title">User Management</h4> -->
          <!-- <p class="text-muted font-14 mb-0">List of Users</p> -->
        </div>

        <div class="col-auto">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a
                href="#"
                :class="['nav-link', { active: isActive('list') }]"
                @click.prevent="setActiveTab('list'), clearFields()"
              >
                List
              </a>
            </li>
            <li class="nav-item">
              <a
                href="#"
                :class="['nav-link', { active: isActive('create') }]"
                @click.prevent="setActiveTab('create'),getSubMenuWithPermission()"
              >
                Create
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="activeTab === 'list'">
      <div class="row align-items-center mb-3">
        <div class="col">
          <label for="movie" class="custom-input-sm"
            >Show
            <select
              name=""
              class="form-control"
              id=""
              v-model="paginate"
              @change="onChangeFilter(1)"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="">All</option>
            </select></label
          >
        </div>
        <md-field class="custom-md-field" style="margin: 0px 0px 0px 0px">
          <label>Search...</label>
          <md-input
            v-model="search"
            @input="$debounce(onChangeFilter)"
          ></md-input>
        </md-field>
        <md-icon>search</md-icon>
      </div>

      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">User Management</h4>
            <!-- <p class="category">List of Users</p> -->
          </md-card-header>
          <md-card-content>
            <md-table
              :table-header-color="tableHeaderColor"
              class="custom-table"
            >
              <tr>
                <th class="py-2">Sl No</th>
                <th class="py-2">Name</th>
                <th class="py-2">User name</th>
                <th class="py-2">Email</th>
             
                <th class="py-2">Reset Password</th>
                <th class="py-2">Action</th>
              </tr>

              <tr v-for="(item, index) in usersList?.data" :key="item.id">
                <td>{{ usersList.from + index }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.user_name }}</td>
                <td>{{ item.email }}</td>
               
                <td>
                  <button
                    @click="resetPasswordUser = item"
                    class="btn btn-info btn-sm round-2"
                    data-toggle="modal"
                    data-target="#exampleModal"
                  >
                    <i class="mdi mdi-lock mx-auto"></i>
                  </button>
                </td>
                <td>
                  <button
                    @click="onEdit(item)"
                    class="btn btn-info btn-sm round-2"
                  >
                    <i class="mdi mdi-pencil mx-auto"></i>
                  </button>
                </td>
              </tr>
            </md-table>
            <loading-bar v-if="load"></loading-bar>
          </md-card-content>
          <div class="row px-3">
            <div class="col-md-6">
              Showing
              {{ usersList.from ? usersList.from : 0 }}
              to
              {{ usersList.to ? usersList.to : 0 }} of
              {{ usersList.total ? usersList.total : 0 }}
              entries
            </div>
            <div class="col-md-6">
              <pagination
                :pagination="usersList"
                :offset="4"
                @paginate="onChangeFilter"
              ></pagination>
            </div>
          </div>
        </md-card>
      </div>
    </div>
    <div v-if="activeTab === 'create'">
      <form @submit.prevent="submit">
        <md-card class="mx-4" style="max-width: 68rem">
          <md-card-header data-background-color="green">
            <h4 class="title">User Management</h4>
          </md-card-header>
          <md-card-content class="pl-5">
            <div class="row">
              <div class="col-md-5">
                <md-field>
                  <label>Employee name</label>
                  <md-input v-model="fullName" type="text" required></md-input>
                </md-field>
                <validation :errors="errors" field="name"></validation>
                <div>
                  <md-field>
                    <label>Email</label>
                    <md-input v-model="email" type="mail" required></md-input>
                  </md-field>
                  <validation :errors="errors" field="email"></validation>
                </div>
                <div>
                  <md-field>
                    <label>User Name</label>
                    <md-input
                      v-model="userName"
                      type="text"
                      required
                    ></md-input>
                  </md-field>
                  <validation :errors="errors" field="user_name"></validation>
                </div>

                <div>
                  <div v-if="!isUpdate">
                    <md-button class="md-primary" @click="passwordGenerate()"
                      >Generate Password</md-button
                    >
                    <md-field>
                      <label>Confirm Password</label>
                      <md-input v-model="password" type="password"></md-input>
                    </md-field>
                    <validation :errors="errors" field="password"></validation>
                  </div>
                  <div v-if="!isUpdate">
                    <md-field>
                      <label>Confirm Password</label>
                      <md-input
                        v-model="confirmPassword"
                        type="password"
                      ></md-input>
                    </md-field>
                    <validation
                      :errors="errors"
                      field="confirm_password"
                    ></validation>
                  </div>
                  <div>
                    <md-button
                      v-if="!isUpdate"
                      type="submit"
                      class="md-success m-2"
                      :disabled="loadRequest"
                      >{{ !loadRequest ? "Save" : "Saving.." }}</md-button
                    >
                    <md-button
                      v-else
                      type="button"
                      @click="update()"
                      class="md-success m-2"
                      :disabled="loadRequest"
                      >{{ !loadRequest ? "Update" : "Updating..." }}</md-button
                    >
                    <md-button
                      type="button"
                      @click="clearFields(), setActiveTab('list')"
                      class="md-danger m-2"
                      >Cancel</md-button
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-7">
                <md-table class="table table-hover">
                  <thead>
                    <tr >
                      <th>Sub menu</th>
                      <th>
                        
                        <input
                          type="checkbox"
                          v-model="viewAll"
                          @change="onCheckBoxChange('view')"
                        />
                        View
                      </th>
                      <th>
                        
                        <input
                          type="checkbox"
                          v-model="addAll"
                          @change="onCheckBoxChange('add')"
                        />
                        Add
                      </th>
                      <th>
                        
                        <input
                          type="checkbox"
                          v-model="editAll"
                          @change="onCheckBoxChange('edit')"
                        />
                        Edit
                      </th>
                      <th>
                        <input
                          type="checkbox"
                          v-model="deleteAll"
                          @change="onCheckBoxChange('delete')"
                        />
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="item in sidebarItemsWithSubMenu">
                      <tr v-if="!Array.isArray(item)" :key="item.id">
                        <!-- <td>{{index+1}}</td> -->
                        <td>{{ item.name }}</td>
                        <td>
                          <input
                          @change="onChangeViewCheck(item.id,item.permissions[0].view,'view')"
                            type="checkbox"
                            v-model="item.permissions[0].view"
                          />
                        </td>
                        <td>
                          <input
                          @change="onChangeViewCheck(item.id,item.permissions[0].add,'add')"
                            type="checkbox"
                            v-model="item.permissions[0].add"
                          />
                        </td>
                        <td>
                          <input
                          @change="onChangeViewCheck(item.id,item.permissions[0].edit,'edit')"
                            type="checkbox"
                            v-model="item.permissions[0].edit"
                          />
                        </td>
                        <td>
                          <input
                          @change="onChangeViewCheck(item.id,item.permissions[0].delete,'delete')"
                            type="checkbox"
                            v-model="item.permissions[0].delete"
                          />
                        </td>
                      </tr>
                      <template v-else>
                        <tr :key="item[0].sub_menu">
                          <th colspan="5">{{ item[0].sub_menu }}</th>
                        </tr>
                        <tr
                          v-if="Array.isArray(item[0])"
                          :key="item[0][0].sub_menu"
                        >
                          <th colspan="5">{{ item[0][0].sub_menu }}</th>
                        </tr>

                        <template v-for="(subItem, index2) in item">
                          <template v-if="Array.isArray(subItem)">
                            <tr :key="subItem[0].sub_menu2">
                              <th colspan="5">
                                {{
                                  (subItem[0].sub_menu2
                                    ? index2 + 1 + "."
                                    : "") + subItem[0].sub_menu2
                                }}
                              </th>
                            </tr>
                            <tr v-for="subItem2 in subItem" :key="subItem2.id">
                              <!-- <td>{{(Object.keys(item).length)+(subItem.length)+(subItem2.length)+index3}}</td> -->
                              <td>{{ subItem2.name }}</td>
                              <td>
                                <input
                                  type="checkbox"
                                  v-model="subItem2.permissions[0].view"
                                />
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  v-model="subItem2.permissions[0].add"
                                />
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  v-model="subItem2.permissions[0].edit"
                                />
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  v-model="subItem2.permissions[0].delete"
                                />
                              </td>
                            </tr>
                          </template>

                          <template v-else>
                            <tr :key="subItem.id" v-if="subItem.slug=='user-management'">
                                <th colspan="5"></th>
                            </tr>
                            <tr :key="subItem.name">
                              <!-- <td>{{(Object.keys(item).length)+(subItem.length)+index2}}</td> -->
                              <td>{{ subItem.name }}</td>
                              <td>
                                <input
                                  type="checkbox"
                                  v-model="subItem.permissions[0].view"
                                />
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  v-model="subItem.permissions[0].add"
                                />
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  v-model="subItem.permissions[0].edit"
                                />
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  v-model="subItem.permissions[0].delete"
                                />
                              </td>
                            </tr>
                          </template>
                        </template>
                      </template>
                    </template>

                  </tbody>
                  <tbody v-if="permissionLoad">
                    <tr>
                      <td colspan="5"  class="text-center"> <loading-bar class="mx-auto" ></loading-bar></td>
                    </tr>
                 
                  </tbody>
                  
                </md-table>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </form>

    </div>
    <reset-password :user="resetPasswordUser"></reset-password>
  </div>
</template>
  
  <script>
import Pagination from "../components/Pagination.vue";
import LoadingBar from "../components/LoadingBar.vue";
import ResetPassword from "../components/ResetPassword.vue";
export default {
  name: "UserMannagement",
  components: { Pagination, ResetPassword, LoadingBar },
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      activeTab: "list", // Default active tab
      search: "",
      paginate: 5,
      load: false,
      usersList: {
        data: [],
        total: 5,
        per_page: 5,
        last_page: 1,
        from: 1,
        to: 1,
        current_page: 1,
      },
      roleList: [],
      fullName: "",
      userName: "",
      password: "",
      confirmPassword: "",
      selectedRole: "",
      email: "",
      resetPasswordUser: "",
      isUpdate: false,
      userId: "",
      loadRequest: false,
      errors: {},
      permissions: [],
      viewAll: false,
      addAll: false,
      editAll: false,
      deleteAll: false,
      permissionLoad:false
    };
  },
  mounted() {
    this.fetchUsersList();
    // this.permissions = this.$store.getters.getSubMenus
    this.getSubMenuWithPermission();
  },
  computed: {
    sidebarItemsWithSubMenu() {
      var groupBy = function (data, key) {
        // `data` is an array of objects, `key` is the key (or property accessor) to group by
        // reduce runs this anonymous function on each element of `data` (the `item` parameter,
        // returning the `storage` parameter at the end
        return data.reduce(function (storage, item) {
          // get the first instance of the key by which we're grouping
          {
            var group = item[key];

            // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
            storage[group] = storage[group] || [];

            // add this item to its group within `storage`

            storage[group].push(item);

            // return the updated storage to the reduce function, which will then loop through the next

            return storage;
          }
        }, {}); // {} is the initial value of the storage
      };
      var newSidebarItems = [];
      if (Object.keys(this.permissions).length === 0) {
        return [];
      }
      let sidebarItemsCopy = Object.values(this.permissions);
      let subMenusPushed = [];
      let index = 0;
      this.permissions.forEach((sidebarItem) => {
        if (!sidebarItem.sub_menu) {
          newSidebarItems[index] = sidebarItem;
          index++;
        } else {
          if (!subMenusPushed.includes(sidebarItem.sub_menu)) {
            subMenusPushed.push(sidebarItem.sub_menu);
            newSidebarItems[index] = sidebarItemsCopy.filter(
              (item) => item.sub_menu === sidebarItem.sub_menu
            );
            let data1 = [];
            let data = groupBy(newSidebarItems[index], "sub_menu2");
            Object.keys(data).map((key) => {
              if (key == "null") {
                data1.push(...data[key]);
              } else {
                data1.push(data[key]);
              }
            });

            newSidebarItems[index] = data1;
            index++;
          }
        }
      });

      return newSidebarItems;
    },
  },
  methods: {
    isActive(tab) {
      return this.activeTab === tab;
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    onCheckBoxChange(key) {
      this.permissions.forEach((item) => {
        if(!this.viewAll && key == 'view'){
          this.addAll = false;
          this.editAll = false;
          this.deleteAll = false
        }
        if (key!='view' ) {
          this.viewAll = true
        }
        

        

        item.permissions[0].view = this.viewAll ? true : false;
        item.permissions[0].add = this.addAll ? true : false;
        item.permissions[0].edit = this.editAll ? true : false;
        item.permissions[0].delete = this.deleteAll ? true : false;
      });
    },
    onChangeViewCheck(id,isChecked,key){
       this.permissions.forEach((item) => {
        if(item.id == id && !isChecked && key == 'view') 
        {
           item.permissions[0].add = false
           item.permissions[0].edit = false
           item.permissions[0].delete = false
        }
        if(item.id == id && isChecked && key != 'view') 
        {
           item.permissions[0].view = true
          
        }
       })
    },
    fetchUsersList() {
      this.load = true;
      this.usersList.data = [];
      axios
        .get(
          `/indous-research/user-management?search=${this.search}&paginate=${this.paginate}&page=${this.usersList.current_page}`
        )
        .then((response) => {
          this.usersList = response.data.usersList;
          this.load = false;
        })
        .catch((err) => {
          this.load = false;
        });
    },
    passwordGenerate() {
      this.password = this.confirmPassword = this.$generatePassword();
    },
    submit() {
      this.loadRequest = true;
      axios
        .post("/indous-research/user-management", {
          name: this.fullName,
          user_name: this.userName,
          email: this.email,
          password: this.password,
          confirm_password: this.confirmPassword,
          // role_id: 1,
          permissionList: JSON.stringify(this.permissions),
        })
        .then((response) => {
          this.$notify({
            message: response.data.message,
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
          this.loadRequest = false;
          this.setActiveTab("list");
          this.fetchUsersList();
        })
        .catch((error) => {
          this.loadRequest = false;
          this.errors = error.response.data.errors;
        });
    },
    update() {
      this.loadRequest = true;
      axios
        .put(`/indous-research/user-management/${this.userId}`, {
          name: this.fullName,
          user_name: this.userName,
          email: this.email,
          // role_id: this.selectedRole,
          permissionList: JSON.stringify(this.permissions),
        })
        .then((response) => {
          this.$notify({
            message: response.data.message,
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
          this.loadRequest = false;
          this.setActiveTab("list");
          this.fetchUsersList();
        })
        .catch((err) => {
          this.loadRequest = false;
        });
    },
    onChangeFilter(page = 1) {
      // page number
      this.usersList.current_page = page;

      this.fetchUsersList();
    },
    clearFields() {
      this.userId =
        this.fullName =
        this.email =
        this.userName =
        this.selectedRole =
        this.password =
        this.confirmPassword =
          "";
      this.isUpdate = false;
      this.errors = {};
    },
    getSubMenuWithPermission() {
      this.permissionLoad = true
      this.permissions = []
      axios
        .get(
          `/indous-research/user-role-permission/permission-list?userId=${this.userId}`
        )
        .then((response) => {
          this.permissions = response.data.subMenus;
          this.load = false;
          this.permissionLoad = false
        })
        .catch((err) => {
          this.load = false;
          this.permissionLoad = false
        });
    },
    savePermission() {},
    onEdit(item) {
      this.setActiveTab("create");
      this.isUpdate = true;
      this.userId = item.id;
      this.fullName = item.name;
      this.email = item.email;
      this.userName = item.user_name;
      // this.selectedRole = item.role.id;
      this.permissions = []
      this.getSubMenuWithPermission();
    },
  },
};
</script>
  
  <style scoped>
.custom-md-field {
  /* Adjust the overall size of the md-field if needed */
  width: 190px; /* Adjust as needed */
}

.struct {
  display: grid;
  grid-template-columns: 135px 260px 38px;
  grid-gap: 25px;
  grid-auto-rows: 50px;

  /*grid-auto-rows: 50px;  Set all rows to 50px height */
}

.custom-md-input {
  /* Adjust the size of the input */
  width: 50px; /* Change the width as needed */
  /* height: 30px; Change the height as needed */
  /*padding: 5px;  Adjust padding as needed */
  font-size: 14px; /* Adjust font size as needed */
}
.row {
  --ct-gutter-x: 1.5rem;
  --ct-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--ct-gutter-y));
  margin-right: calc(-0.5 * var(--ct-gutter-x));
  margin-left: calc(-0.5 * var (--ct-gutter-x));
}

.tab-content {
  padding: 20px 0 0 0;
  border: none;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #71b6f9 !important;
}

.align-items-center {
  align-items: center !important;
  padding: 0px 65px 0px 20px;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.col {
  flex: 1 0 0%;
}

.mt-0 {
  margin-top: 0 !important;
}
/* md-field, md-select, md-option{
    max-width: 90px;
  } */
.header-title {
  font-size: 1rem;
  margin: 0 0 7px 0;
}

.test1 {
  text-align: right;
}

.card-box {
  background-color: #fff;
  /* padding: 1.5rem 0rem 0rem 0rem; */
  -webkit-box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  margin-bottom: 1px;
  border-radius: 0.25rem;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

/* .form-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
} */

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

/* input[type="text"],
input[type="password"],
input[type="email"],
select {
   width: 100%; 
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
} */

.input-group {
  display: flex;
}

/* .input-group input {
  flex: 1;
} */

/* .input-group button {
  margin-left: 10px;
  padding: 8px 12px;
  border: 1px solid #007bff;
  border-radius: 4px;
  background-color: #fff;
  color: #007bff;
  cursor: pointer;
} */

/* .input-group button:hover {
  background-color: #007bff;
  color: #fff;
} */

button[type="submit"],
button[type="button"].cancel-button {
  /* padding: 10px 20px; */
  /* border: none;
  border-radius: 4px; */
  cursor: pointer;
}

/* button[type="submit"].save-button {
  background-color: #28a745;
  color: #fff;
} */

/* button[type="button"].cancel-button {
  background-color: #ccc;
  color: #333;
  margin-left: 10px;
} */

#buttons {
  display: flex;
  flex-direction: row;
}

/* button[type="button"].cancel-button:hover {
  background-color: #b3b3b3;
} */

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: rgb(113, 182, 249);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

.create-field {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.all-buttons {
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
}

.row-layout2 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 40px;
}

.form-style {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
  