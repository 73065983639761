<template>
  <div>
    <div>
      <div class="row align-items-center mb-3">
        <div class="col">
          <!-- <h4 class="mt-0 header-title">TRIAL GROUP</h4> -->
        </div>

        <div class="col-auto">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a
                href="#"
                :class="['nav-link', { active: isActive('list') }]"
                @click.prevent="setActiveTab('list'), clearFields()"
              >
                List
              </a>
            </li>
            <li class="nav-item" v-if="add && isLatest">
              <a
                href="#"
                :class="['nav-link', { active: isActive('create') }]"
                @click.prevent="setActiveTab('create')"
              >
                Create
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div v-if="activeTab === 'list'">
      <div class="row align-items-center mb-3">
        <div class="col">
          <label for="movie" class="custom-input-sm"
            >Show
            <select
              name=""
              class="form-control"
              id=""
              v-model="paginate"
              @change="onChangeFilter(1)"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="">All</option>
            </select></label
          >
        </div>

        <!-- <div class="col">
          <label for="movie" class="mt-2 font-weight-bold"> Trial No: </label>
          <select
            class="form-control width-10 ml-2 pl-2"
            v-model="trialNoDrp"
            @change="fetchTrialSchedulePlanList()"
          >
            <option v-for="item in trialNoList" :key="item.id" :value="item.id">
              {{ item.trial_no }}
            </option>
          </select>
        </div> -->

        <md-field class="custom-md-field" style="margin: 0px 0px 0px 0px">
          <label>Search...</label>
          <md-input
            v-model="search"
            @input="$debounce(onChangeFilter)"
          ></md-input>
        </md-field>
        <md-icon>search</md-icon>
      </div>

      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">DAY WISE ACTIVITY PLAN</h4>
            <!-- <p class="category">List of Users</p> -->
          </md-card-header>

          <md-card-content>
            <md-table
              :table-header-color="tableHeaderColor"
              class="custom-table"
            >
              <tr>
                <th class="py-2">Sl No</th>
                <th class="py-2">Date</th>
                <th class="py-2">Trial Stage</th>
                <th class="py-2">Day</th>
                <th class="py-2">Activity Plan</th>
                <th class="py-2">Status</th>
                <th class="py-2">Remarks</th>

                <th class="py-2" v-if="edit">Action</th>
              </tr>

              <tr
                v-for="(item, index) in trialSchedulePlanList?.data"
                :key="item.id"
              >
                <td>{{ trialSchedulePlanList.from + index }}</td>
                <td class="text-nowrap">{{ item.date }}</td>
                <td class="text-nowrap">{{ item.trial_stages.name }}</td>
                <td>{{ item.day ?? "--" }}</td>
                <td class="text-left">{{ item.activity }}</td>
                <td>{{ item.is_executed ? "Executed" : "Pending" }}</td>
                <td :class="item.remarks ? 'text-left' : 'text-nowrap'">
                  {{ item.remarks ?? "--" }}
                </td>
                <td v-if="edit">
                  <button @click="onEdit(item)">
                    <i class="mdi mdi-pencil mx-auto"></i>
                  </button>
                </td>
              </tr>
            </md-table>
            <loading-bar v-if="load"></loading-bar>
          </md-card-content>

          <div class="row px-3">
            <div class="col-md-6">
              Showing
              {{ trialSchedulePlanList.from ? trialSchedulePlanList.from : 0 }}
              to
              {{ trialSchedulePlanList.to ? trialSchedulePlanList.to : 0 }} of
              {{
                trialSchedulePlanList.total ? trialSchedulePlanList.total : 0
              }}
              entries
            </div>
            <div class="col-md-6">
              <pagination
                :pagination="trialSchedulePlanList"
                :offset="4"
                @paginate="onChangeFilter"
              ></pagination>
            </div>
          </div>
        </md-card>
      </div>
    </div>

    <div v-if="activeTab === 'create'">
      <div class="page">
        <md-card>
          <div id="form"></div>
          <md-card-header data-background-color="green">
            <h4 class="title">DAY WISE ACTIVITY PLAN</h4>
          </md-card-header>
          <md-card-content>
            <md-card-content style="padding: 15px 60px">
              <form @submit.prevent="submit()">
                <div class="row mt-2">

                  <!-- <div class="col-md-3">
                    <label for="movie" class="mt-2 font-weight-bold">
                      Trial No:
                    </label>
                    <select
                      class="form-control width-10 ml-2 pl-2"
                      v-model="expInfoId"
                      @change="filterStartDate()"
                    >
                      <option
                        v-for="item in trialNoList"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.trial_no }}
                      </option>
                    </select>
                    <validation
                      :errors="errors"
                      field="experiment_info_id"
                    ></validation>
                  </div> -->

                  <!-- startDate -->
                  <div class="col-md-3">
                    <label><span class="md-body-2">Start Date</span></label>
                    <!-- <md-datepicker v-model="startDate" md-immediately /> -->
                    <md-field class="p-0">
                      <md-input
                        class="p-0"
                        v-model="startDate"
                        readonly
                      ></md-input>
                    </md-field>
                    <validation
                      :errors="errors"
                      field="start_date"
                    ></validation>
                  </div>

                  <!-- trial stage drp -->

                  <div class="col-md-3">
                    <label><span class="md-body-2">Trial Stage</span></label>

                    <select class="form-control my-auto" v-model="trialStageId">
                      <option
                        v-for="item in trialStageList"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <validation
                      :errors="errors"
                      field="trial_stage_id"
                    ></validation>
                  </div>
                </div>

                <!-- //day and date -->

                <div class="row mt-2">
                  <div v-if="trialStageId == 2" class="col-md-2">
                    <label for="day" class="mt-2 font-weight-bold">
                      Day:
                    </label>
                    <input
                      type="number"
                      id="day"
                      v-model="day"
                      class="form-control width-10 ml-2 pl-2"
                      @input="validateDay()"
                    />
                    <validation :errors="errors" field="day"></validation>
                  </div>

                  <div v-if="trialStageId == 2" class="col-md-1"></div>
                  <!-- Date -->
                  <div class="col-md-3">
                    <label><span class="md-body-2">Date</span></label>
                    <!-- <md-datepicker v-model="startDate" md-immediately /> -->
                    <md-datepicker
                      v-model="date"
                      md-immediately
                    ></md-datepicker>

                    <validation :errors="errors" field="date"></validation>
                  </div>
                </div>

                <!-- //end of.. -->

                <div class="row">
                  <div class="col-md-6">
                    <label for="country"
                      ><span class="md-body-2">Activity Plan:</span></label
                    >
                    <textarea
                      class="w-100"
                      id="limited-textarea"
                      v-model="activityPlan"
                      maxlength="256"
                      rows="4"
                      @input="clearErrors()"
                    ></textarea>
                    <span>{{ activityPlan.length }}/256 characters</span>
                    <validation :errors="errors" field="activity"></validation>
                  </div>
                  <div class="col-md-3">
                    <label for="country"
                      ><span class="md-body-2">Remarks:</span></label
                    >
                    <textarea
                      id="limited-textarea"
                      v-model="remarks"
                      maxlength="256"
                      rows="4"
                      cols="30"
                      @input="clearErrors()"
                    ></textarea>
                    <span class="text-nowrap"
                      >{{ remarks?.length ?? 0 }}/256 characters</span
                    >
                  </div>
                </div>

                <div></div>

                <div class="row mt-5 mb-2">
                  <div class="col-md-2">
                    <label for="executed" class="font-weight-bold text-nowrap"
                      >Executed:
                      <input
                        type="checkbox"
                        id="executed"
                        name="executed"
                        class="w-50"
                        v-model="isExecuted"
                    /></label>
                  </div>
                </div>

                <div class="row justify-content-end">
                  <div id="buttons">
                    <div>
                      <md-button
                        v-if="!isUpdate"
                        type="submit"
                        class="md-success"
                        :disabled="requestStatus"
                        >{{ requestStatus ? "Saving..." : "Save" }}</md-button
                      >
                      <md-button
                        v-else
                        @click="update()"
                        type="button"
                        class="md-success"
                        :disabled="requestStatus"
                        >{{
                          requestStatus ? "Updating..." : "Update"
                        }}</md-button
                      >
                    </div>
                    <div style="padding-left: 10px">
                      <md-button
                        type="button"
                        @click="clearFields(), setActiveTab('list')"
                        class="md-danger"
                        >Cancel</md-button
                      >
                    </div>
                  </div>
                </div>
              </form>
            </md-card-content>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../components/Pagination.vue";
import LoadingBar from "../components/LoadingBar.vue";
export default {
  components: { Pagination, LoadingBar },
  name: "TrialGroup",
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      trialNoList: [],
      // expInfoId: "",
      // trialNoDrp: "",
      trialStageList: [],
      trialStageId: "",

      startDate: "",
      activityPlan: "",
      remarks: "",

      day: "",
      date: "",

      isExecuted: false,
      isLatest: false,

      id: "",
      search: "",
      paginate: 5,
      activeTab: "list", // Default active tab

      trialSchedulePlanList: {
        data: [],
        total: 5,
        per_page: 5,
        last_page: 1,
        from: 1,
        to: 1,
        current_page: 1,
      },

      errors: {},
      load: false,
      requestStatus: false,
      isUpdate: false,
      add:false,
      edit:false
    };
  },

  mounted() {
    this.add = this.$store.getters.getActiveSideBar.permissions[0].add
    this.edit = this.$store.getters.getActiveSideBar.permissions[0].edit
    this.checkLatestExperiment();
    this.fetchTrialSchedulePlanList();
    this.getTrialNoList();
    this.fetchTrialStage();
    
    this.$material.locale.dateFormat = "dd-MM-yyyy";
    this.startDate = this.$store.getters.getSelectedTrial.start_date;
    // console.log( this.$store.getters.setSelectedTrial);
    
  },

  methods: {
    isActive(tab) {
      return this.activeTab === tab;
    },
    setActiveTab(tab) {
      this.activeTab = tab;
      this.isUpdate = false;
    },

    checkLatestExperiment() {
      axios
        .get(`/indous-research/check-latest-experiment`)
        .then((response) => {
          this.isLatest = response.data.is_latest;
        })
        .catch((error) => {
          // console.error("Error checking latest experiment:", error);
        });
    },

    // filterStartDate() {
    //   let trialData = this.trialNoList.filter((i) => i.id == this.$store.getters.getSelectedTrial.id);
    //   this.startDate = trialData[0].start_date;
    // },

    validateDay() {
      // Remove any characters that are not digits
      // this.day = this.day.replace(/[^0-9]/g, '');

      if (this.day > 99) {
        this.day = parseInt(this.day.toString().slice(0, 2), 10); // Keep only the first two digits
      }
      if (this.day < 1) {
        this.day = "";
      }
    },

    getTrialNoList() {
      // if (!this.startDate) return;

      axios
        .get(`/indous-research/experiment-information/get-trial-no-list`)
        .then((response) => {
          this.trialNoList = response.data.trialNoList;
        });
    },

    fetchTrialStage() {
      axios.get(`/indous-research/master/trial-stage`).then((response) => {
        this.trialStageList = response.data.trialStageList;
      });
    },

    fetchTrialSchedulePlanList() {
      this.load = true;
      this.trialSchedulePlanList.data = [];
      axios
        .get(
          `/indous-research/trial-schedule-plan?search=${this.search}&paginate=${this.paginate}&page=${this.trialSchedulePlanList.current_page}`
        )
        .then((response) => {
          this.trialSchedulePlanList = response.data.trialSchedulePlanList;
          this.load = false;
        });
    },

    onChangeFilter(page = 1) {
      this.trialSchedulePlanList.current_page = page;

      this.fetchTrialSchedulePlanList();
    },

    submit() {
      this.requestStatus = true;
      axios
        .post("/indous-research/trial-schedule-plan", {
        //  experiment_info_id: this.expInfoId,
          start_date: this.startDate,
          trial_stage_id: this.trialStageId,

          day: this.day,
          date: this.date,

          activity: this.activityPlan,
          remarks: this.remarks,
          is_executed: this.isExecuted,
        })
        .then((response) => {
          this.requestStatus = false;
          this.$notify({
            message: response.data.message,
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
          this.clearFields();
          this.setActiveTab("list");
        })
        .catch((error) => {
          this.requestStatus = false;
          this.errors = error.response.data.errors;
        });
    },

    onEdit(item) {
      // display saved data in fields
      this.setActiveTab("create");
      this.id = item.id;
      // this.expInfoId = item.experiment_info_id;
      this.startDate = item.start_date;
      //else- // this.filterStartDate();
      this.trialStageId = item.trial_stage_id;
      this.day = item.day;
      this.date = item.date;

      this.activityPlan = item.activity;
      this.remarks = item.remarks;
      this.isExecuted = item.is_executed;

      this.isUpdate = true; // display update button if true else submit
    },

    update() {
      this.requestStatus = true;
      axios
        .put("/indous-research/trial-schedule-plan/" + this.id, {
          // experiment_info_id: this.expInfoId,
          start_date: this.startDate,
          trial_stage_id: this.trialStageId,
          day: this.day,
          date: this.date,
          activity: this.activityPlan,
          remarks: this.remarks,
          is_executed: this.isExecuted,
        })
        .then((response) => {
          this.requestStatus = false;
          this.$notify({
            message: response.data.message,
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
          this.clearFields();
          this.setActiveTab("list");
        })
        .catch((error) => {
          this.requestStatus = false;
          this.errors = error.response.data.errors;
        });
    },

    clearErrors() {
      this.errors = {};
    },

    clearFields() {
      this.trialStageId =
        this.day =
        this.date =
        this.activityPlan =
        this.remarks =
        this.search =
          "";
      this.isExecuted = false;
      // this.trialNoDrp = this.trialNoList[0].id;
      this.clearErrors();
      this.fetchTrialSchedulePlanList();
    },
  },
};
</script>

<style scoped>
.custom-md-field {
  /* Adjust the overall size of the md-field if needed */
  width: 190px;
  /* Adjust as needed */
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #71b6f9 !important;
}

.col {
  flex: 1 0 0%;
}

.mt-0 {
  margin-top: 0 !important;
}

.header-title {
  font-size: 1rem;
  margin: 0 0 7px 0;
}

.card-box {
  background-color: #fff;
  padding: 1.2rem 0rem 0.2rem 0rem;
  /* list/create */
  -webkit-box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  margin-bottom: 1px;
  border-radius: 0.25rem;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.input-group {
  display: inline;
}

.struct-last {
  display: grid;
  grid-template-columns: 240px 240px;
  grid-column-gap: 5%;
}

.sec-3 {
  padding: 20px 0px;
}

button[type="submit"],
button[type="button"].cancel-button {
  /* padding: 10px 20px; */
  /* border: none;
  border-radius: 4px; */
  cursor: pointer;
}

#buttons {
  display: flex;
  flex-direction: row;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: rgb(113, 182, 249);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

.create-field {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.all-buttons {
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
}
input[type="checkbox"] {
  widows: 1rem;
  height: 1rem;
}
</style>