<template>
  <div>
    <!-- <div>
      <div class="row align-items-center mb-3">
        <div class="col">
          <h4 class="mt-0 header-title">TARE WEIGHT (KGS)</h4>
        </div>

        <div class="col-auto">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a
                href="#"
                :class="['nav-link', { active: isActive('list') }]"
                @click.prevent="setActiveTab('list')"
                style="border-radius: 0.25rem;"
              >
                List
              </a>
            </li>
            <li class="nav-item">
              <a
                href="#"
                :class="['nav-link', { active: isActive('apply') }]"
                @click.prevent="setActiveTab('apply')"
                 style="border-radius: 0.25rem;"
              >
                Create
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div> -->

    
    <!-- <div v-if="activeTab === 'list'">
      <div class="row align-items-center mb-3">
        <div class="col">
          <label for="movie" class="custom-input-sm"
            >Show
            <select name="" class="form-control" id="">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="">All</option>
            </select></label
          >
        </div> -->
<!-- 
        <md-field class="custom-md-field" style="margin: 0px 0px 0px 0px">
          <label>Search...</label>
          <md-input v-model="initial"></md-input>
        </md-field>
        <md-icon>search</md-icon>
      </div> -->
  
    <!-- </div>  -->
    <!-- /2/ -->

    <div>
      <div>
        <div class="row align-items-center mb-3">
            <div class="col-auto">
            <ul class="nav nav-pills">
              <!-- <li class="nav-item">
                <a
                  href="#"
                  :class="['nav-link', { active: isSubTabActive('crateMaster') }]"
                  @click.prevent="setActiveSubTab('crateMaster')"
                >
                  Crate
                </a>
              </li> -->
              <li class="nav-item">
                <a
                  href="#"
                  :class="['nav-link', { active: isSubTabActive('feeder') }]"
                  @click.prevent="setActiveSubTab('feeder')"
                >
                  Feeder
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="#"
                  :class="['nav-link', { active: isSubTabActive('feederDrum') }]"
                  @click.prevent="setActiveSubTab('feederDrum')"
                >
                  Feeder Drum
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="#"
                  :class="['nav-link', { active: isSubTabActive('drinker') }]"
                  @click.prevent="setActiveSubTab('drinker')"
                >
                  Drinker
                </a>
              </li>

            </ul>
            </div>
        </div>
        </div>
      <!-- <ul class="nav nav-tabs">
        <li class="nav-item">
          <a
            href="#"
            :class="['cratemaster', { active: isSubTabActive('createMaster') }]"
            @click.prevent="setActiveSubTab('createMaster')"
          >
            Create Master
          </a>
        </li>
        <li class="nav-item">
          <a
            href="#"
            :class="['feeder', { active: isSubTabActive('feeder') }]"
            @click.prevent="setActiveSubTab('feeder')"
          >
            Feeder
          </a>
        </li>
        <li class="nav-item">
          <a
            href="#"
            :class="['nav-link', { active: isSubTabActive('feederDrum') }]"
            @click.prevent="setActiveSubTab('feederDrum')"
          >
            Feeder Drum
          </a>
        </li>
      </ul> -->

      <div class="tab-content">
        <!-- <div v-if="activeSubTab === 'crateMaster'">
          <crate-master :activeTabProp="activeTab"></crate-master>
        </div> -->
        <div v-if="activeSubTab === 'feeder'">
          <!-- Content for Feeder tab -->
          <feeder :activeTabProp="activeTab"></feeder>
        </div>
        <div v-if="activeSubTab === 'feederDrum'">
          <!-- Content for Feeder Drum tab -->
          <feeder-drum-master :activeTabProp="activeTab"></feeder-drum-master>
        </div>
        <div v-if="activeSubTab === 'drinker'">
          <!-- Content for Feeder Drum tab -->
          <drinker :activeTabProp="activeTab"></drinker>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Feeder from "../Master/Feeder.vue";
import FeederDrumMaster from "../Master/FeederDrumMaster.vue";
import CrateMaster from "../Master/CrateMaster.vue";
import Drinker from "../Master/Drinker.vue";

export default {
  components: { Feeder, FeederDrumMaster, Drinker },
  name: "simple-table",

  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      activeTab: "list", // Default active tab
      activeSubTab: "feeder",
     
    };
  },
  methods: {
    isActive(tab) {
      return this.activeTab === tab;
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },

    isSubTabActive(subTab) {
      return this.activeSubTab === subTab;
    },
    setActiveSubTab(subTab) {
      this.activeSubTab = subTab;
      this.activeTab = "list";
    },
  },
};
</script>
  
  <style scoped>
.custom-md-field {
  /* Adjust the overall size of the md-field if needed */
  width: 190px;
  /* Adjust as needed */
}

.custom-md-input {
  /* Adjust the size of the input */
  width: 50px;
  /* Change the width as needed */
  /* height: 30px; Change the height as needed */
  /*padding: 5px;  Adjust padding as needed */
  font-size: 14px;
  /* Adjust font size as needed */
}

.row {
  --ct-gutter-x: 1.5rem;
  --ct-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--ct-gutter-y));
  margin-right: calc(-0.5 * var(--ct-gutter-x));
  margin-left: calc(-0.5 * var (--ct-gutter-x));
}

.tab-content {
  padding: 20px 0 0 0;
  border: none;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #71b6f9 !important;
}


.align-items-center {
  align-items: center !important;
  padding: 0px 65px 0px 20px;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.col {
  flex: 1 0 0%;
}

.mt-0 {
  margin-top: 0 !important;
}

.header-title {
  font-size: 1rem;
  margin: 0 0 7px 0;
}

.card-box {
  background-color: #fff;
  padding: 1.2rem 0rem 0.2rem 0rem;
  /* list/create */
  -webkit-box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  margin-bottom: 1px;
  border-radius: 0.25rem;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

/* .form-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  } */

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

/* input[type="text"],
  input[type="password"],
  input[type="email"],
  select {
     width: 100%; 
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  } */

.input-group {
  display: inline;
}

/* .input-group input {
    flex: 1;
  } */

/* .input-group button {
    margin-left: 10px;
    padding: 8px 12px;
    border: 1px solid #007bff;
    border-radius: 4px;
    background-color: #fff;
    color: #007bff;
    cursor: pointer;
  } */

/* .input-group button:hover {
    background-color: #007bff;
    color: #fff;
  } */

button[type="submit"],
button[type="button"].cancel-button {
  /* padding: 10px 20px; */
  /* border: none;
    border-radius: 4px; */
  cursor: pointer;
}

/* button[type="submit"].save-button {
    background-color: #28a745;
    color: #fff;
  } */

/* button[type="button"].cancel-button {
    background-color: #ccc;
    color: #333;
    margin-left: 10px;
  } */

#buttons {
  display: flex;
  flex-direction: row;
}

/* button[type="button"].cancel-button:hover {
    background-color: #b3b3b3;
  } */

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: rgb(113, 182, 249);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
 /* border-radius: 0.25rem; */
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

.create-field {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.all-buttons {
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
}
</style>