<template>
    <div>

<div class="modal fade" :id="id" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" :id="id">Reset Password {{user ? `(${user.name})` :''}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
         <md-button class="md-primary" @click="passwordGenerate()">Generate Password</md-button>
     <md-field>
                  <label>New Password</label>
                  <md-input v-model="newPassword" type="text" required></md-input>
                </md-field>
                <validation :errors="errors" field="password"></validation>
                <md-field>
                  <label>Confirm Password</label>
                  <md-input v-model="confPassword" type="text" required></md-input>
                </md-field>
                <validation :errors="errors" field="confirm_password"></validation>
      </div>
      <div class="modal-footer">
        <button type="button" ref="closeBtn" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-success ml-1 my-auto" @click="submit">Save</button>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->

    </div>
</template>
<script>
import Validation from './NotificationPlugin/Validation.vue';
export default {
  components: { Validation },
    data:()=>({
      newPassword:'',
      confPassword:'',
      errors:{}
    }),
    props:{
        user:{
           default:''
          
        },
        id:{
           default:'exampleModal'
        }
    },
    watch:{
      user:function(){
        this.newPassword = this.confPassword = ""
      }
    },
    methods:{
        submit(){
            axios.put(`indous-research/user-management/reset-password/${this.user.id}`,{
                password:this.newPassword,
                confirm_password:this.confPassword,
            }).then((response) => {
                 this.$notify(
                {
                message:response.data.message,
                horizontalAlign: 'right',
                verticalAlign: 'top',
                type: 'success'
                })
                this.newPassword = this.confPassword = ""
                this.$refs.closeBtn.click();

            }).catch((err) => {
                this.errors = err.response.data.errors;
            });
        },
        passwordGenerate() {
          this.newPassword = this.confPassword = this.$generatePassword();
        },
    }
}
</script>