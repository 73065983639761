<template>
  <div>
    <div>
      <div class="row align-items-center mb-3">
        <div class="col">
          <!-- <h4 class="mt-0 header-title">TRIAL GROUP</h4> -->
        </div>

        <div class="col-auto">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a
                href="#"
                :class="['nav-link', { active: isActive('list') }]"
                @click.prevent="setActiveTab('list'),fetchTrialGroupList(),clearFields()"
              >
                List
              </a>
            </li>
            <li class="nav-item">
              <a
                href="#"
                :class="['nav-link', { active: isActive('create') }]"
                @click.prevent="setActiveTab('create')"
              >
                Create
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div v-if="activeTab === 'list'">
      <div class="row align-items-center mb-3">
        <div class="col">
          <label for="movie" class="custom-input-sm"
            >Show
            <select
              name=""
              class="form-control"
              id=""
              v-model="paginate"
              @change="onChangeFilter(1)"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="1000">All</option>
            </select></label
          >
        </div>

        <md-field class="custom-md-field" style="margin: 0px 0px 0px 0px">
          <label>Search...</label>
          <md-input v-model="search" @input="$debounce(onChangeFilter)"></md-input>
        </md-field>
        <md-icon>search</md-icon>
      </div>

      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">TRIAL GROUP</h4>
            <!-- <p class="category">List of Users</p> -->
          </md-card-header>

          <md-card-content>
            <md-table
              :table-header-color="tableHeaderColor"
              class="custom-table"
            >
              <tr>
                <th class="py-2">Sl No</th>
                <th class="py-2">Trial Group Id</th>
                <th class="py-2">Trial Group Name</th>
                <th class="py-2">Action</th>
              </tr>

              <tr v-for="(item, index) in trialGroupList?.data" :key="item.id">
                <td>{{trialGroupList.from + index }}</td>
                <td>{{ item.trial_group_id }}</td>
                <td>{{ item.trial_group_name }}</td>
                <td><button @click="onEdit(item)" class="btn btn-info btn-sm round-2"><i class="mdi mdi-pencil mx-auto"></i></button> </td>

              </tr>
            </md-table>
            <loading-bar v-if="load"></loading-bar>
          </md-card-content>
          <div class="row px-3">
            <div class="col-md-6">
              Showing
              {{ trialGroupList.from ? trialGroupList.from : 0 }}
              to
              {{ trialGroupList.to ? trialGroupList.to : 0 }} of
              {{ trialGroupList.total ? trialGroupList.total : 0 }}
              entries
            </div>
            <div class="col-md-6">
              <pagination
                :pagination="trialGroupList"
                :offset="4"
                @paginate="onChangeFilter"
              ></pagination>
            </div>
          </div>
        </md-card>
      </div>
    </div>

    <div v-if="activeTab === 'create'" class="mt-3">
      <form @submit.prevent="submit()">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
        >
          <md-card style="width: 55%">
            <md-card-header data-background-color="green">
              <h4 class="title">TRIAL GROUP</h4>
            </md-card-header>
            <md-card-content>
              <div class="form-group"></div>

              <div class="form-group">
                <md-field class="mb-1">
                  <label>Trial Group Id</label>
                  <md-input
                    v-model="trialGroupId"
                    type="id"
                    @input="clearErrors"
                  ></md-input>
                </md-field>
                <validation
                  :errors="errors"
                  field="trial_group_id"
                ></validation>
              </div>

              <div class="form-group">
                <md-field class="mb-1">
                  <label>Trial Group Name</label>
                  <md-input
                    v-model="trialGroupName"
                    type="name"
                    @input="clearErrors"
                  ></md-input>
                </md-field>
                <validation
                  :errors="errors"
                  field="trial_group_name"
                ></validation>
              </div>

              <div id="buttons">
                <div>
                  <md-button v-if="!isUpdate" type="submit" class="md-success" :disabled="requestStatus">{{requestStatus ? 'Saving...':'Save'}}</md-button>
                  <md-button v-else @click="update()"  type="button" class="md-success" :disabled="requestStatus">{{requestStatus ? 'Updating...':'Update'}}</md-button>
                </div>
                <div style="padding-left: 10px">
                  <md-button type="button" @click="clearFields(),setActiveTab('list')" class="md-danger">Cancel</md-button>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Pagination from "../../components/Pagination.vue";
import LoadingBar from "../../components/LoadingBar.vue";
export default {
  components: {  Pagination, LoadingBar },
  name: "TrialGroup",
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      id:"",
      search: "",
      paginate: 5,
      activeTab: "list", // Default active tab
      trialGroupList: {
        data: [],
        total: 5,
        per_page: 5,
        last_page: 1,
        from: 1,
        to: 1,
        current_page: 1,
      },
      trialGroupId: "",
      trialGroupName: "",
      errors: {},
      load: false,
      requestStatus:false,
      isUpdate:false
    };
  },
  mounted() {
    this.fetchTrialGroupList();
  },
  methods: {
    isActive(tab) {
      return this.activeTab === tab;
    },
    setActiveTab(tab) {
      this.activeTab = tab;
      this.isUpdate = false
    },

    fetchTrialGroupList() {
      this.load = true;
      this.trialGroupList.data = [];
      axios
        .get(
          `/indous-research/master/trial-group?search=${this.search}&paginate=${this.paginate}&page=${this.trialGroupList.current_page}`
        )
        .then((response) => {
          this.trialGroupList = response.data.trialGroupList;
          this.load = false;
        });
    },
    onChangeFilter(page = 1) { // page number
      this.trialGroupList.current_page = page;

      this.fetchTrialGroupList();
    },
    submit() {
      this.requestStatus = true
      axios
        .post("/indous-research/master/trial-group", {
          trial_group_id: this.trialGroupId,
          trial_group_name: this.trialGroupName,
        })
        .then((response) => {
          this.requestStatus = false
      this.$notify(
        {
          message:response.data.message,
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'success'
        })
        this.clearFields()
        })
        .catch((error) => {
          this.requestStatus = false
          this.errors = error.response.data.errors;
        });
    },
    onEdit(item){ // display saved data in fields
     this.setActiveTab('create')
     this.id = item.id;
     this.trialGroupId = item.trial_group_id;
     this.trialGroupName = item.trial_group_name;
     this.isUpdate = true; // display update button if true else submit
    }, 
    update(){
      this.requestStatus = true
      axios
        .put("/indous-research/master/trial-group/"+this.id, {
          trial_group_id: this.trialGroupId,
          trial_group_name: this.trialGroupName,
        })
        .then((response) => {
          this.requestStatus = false
     this.$notify(
        {
          message: response.data.message,
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'success'
        })
         this.clearFields()
         this.setActiveTab('list')
        })
        .catch((error) => {
          this.requestStatus = false
          this.errors = error.response.data.errors;
        });
    },
    clearErrors() {
      this.errors = {};
    },
    clearFields(){ // clear form fields after submit or update
      this.trialGroupId = this.trialGroupName = ""
      this.clearErrors()
      this.fetchTrialGroupList()
    }
  },
};
</script>

<style scoped>
.custom-md-field {
  /* Adjust the overall size of the md-field if needed */
  width: 190px;
  /* Adjust as needed */
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #71b6f9 !important;
}

.col {
  flex: 1 0 0%;
}

.mt-0 {
  margin-top: 0 !important;
}

.header-title {
  font-size: 1rem;
  margin: 0 0 7px 0;
}

.card-box {
  background-color: #fff;
  padding: 1.2rem 0rem 0.2rem 0rem;
  /* list/create */
  -webkit-box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  margin-bottom: 1px;
  border-radius: 0.25rem;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}


.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}



.input-group {
  display: inline;
}


button[type="submit"],
button[type="button"].cancel-button {
  /* padding: 10px 20px; */
  /* border: none;
  border-radius: 4px; */
  cursor: pointer;
}


#buttons {
  display: flex;
  flex-direction: row;
}



.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: rgb(113, 182, 249);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

.create-field {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.all-buttons {
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
}
</style>